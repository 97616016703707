<template>
  <synchronized-waypoint
  @toggleOneDirectionVisible="toggleWaypointWithLines"
  class="text-and-small-image color-dark-text d-flex px-075 pl-md-125 pl-xl-24 pr-xl-32 py-1 mb-4 mb-md-7 mb-xl-0 py-xl-2"
  :class="{
    'with-content': model.fields.richContent,
    'without-content': !model.fields.richContent
  }">
    <div 
    class="col-md-10 col-xl-9 pr-md-3 pr-xl-5">
      <split-text
      v-if="model.fields.richContent"
      tag='p'
      innerClasses="t9 t7-md t8-xl text-uppercase glossy"
      :text="model.fields.richContent"
      @splited="onSplited" />

      <div 
      class="content color-dark-text pt-1 pr-md-1 pr-lg-155 should-animate" 
      data-preset='opacity,y'
      data-fromy='-20'
      data-delay='1'
      v-if='model.fields.caption'>
        <p 
        class="t13-1 t16-2-md t13-xl text-uppercase" 
        v-html="model.fields.caption" />
      </div>
    </div>

    <div class="col-2 col-xl-3 d-none d-md-flex justify-content-end ">
      <div class="small-image should-animate mix-multiply"
      data-preset='opacity,y,rotate'
      data-fromy='50'
      data-delay='.3'
      data-fromrotate="-20"
      data-torotate="0">
        <synchronized-picture-div
        :image="model.fields.image"
        :src='getImg(model, "image", "?w=350")'
        :sources="[
            {
                'src': getImg(model, 'image', '?w=500'),
                'srcx2': getImg(model, 'image', '?w=600'),
                'width': 1921
            },

            {
                'src': getImg(model, 'image', '?w=400'),
                'srcx2': getImg(model, 'image', '?w=500'),
                'width': 1201
            },

            {
                'src': getImg(model, 'image', '?w=370'),
                'width': 768
            }
            ]" 
        class="position-relative"/>
      </div>
    </div>
  </synchronized-waypoint>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import WaypointTrigger from "@/mixins/WaypointTrigger";

export default {
  name: "text-and-small-image",

  data: () => ({
    delayAddition: .25
  }),

  props: ["model"],

  mixins: [WaypointTrigger]
};
</script>
<style lang="scss">
.text-and-small-image {
    .split-line {
        a {
        transition: .4s;
        position: relative;
        white-space: normal;
        opacity: .5;
        @include media-breakpoint-up(md) {
        opacity: 1;
        white-space: nowrap;
            &::after {
                content: "";
                position: absolute;
                top: 80%;
                left: 0;
                width: 100%;
                height: 1px;
                transform: scaleX(1);
                background-color: currentColor;
                transition: 0.4s;
                transform-origin: left;
            }
        }
        &:hover {
            opacity: .5;
            &::after {
              transform: scaleX(0);
            }
        }
        }
    }
}    
</style>
<style lang="scss" scoped="">
.text-and-small-image {
  min-height: 25vw;
  // @include media-breakpoint-up(xl) {
  //   &.with-content {
  //   }
  //   &.without-content {
  //       min-height: 25vw;
  //   }
  // }

  .small-image {
    width: 9vw;
    height: 10vw;
  }
}
</style>