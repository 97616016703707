var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('synchronized-waypoint',{staticClass:"inner-service-header position-relative",on:{"toggleOneDirectionVisible":_vm.toggleWaypoint}},[_c('custom-parallax',{staticClass:"w-100 h-100",attrs:{"wrapped":true,"absolute":true,"speedFactor":.2,"scaleFactor":.1}},[_c('synchronized-picture-div',{staticClass:"should-animate",attrs:{"image":_vm.image,"loading":"eager","src":_vm.getImg(_vm.image, "image", "?w=760&h=1120&fit=fill"),"sources":[
          {
              'src': _vm.getImg(_vm.image, 'image', '?w=2220'),
              'srcx2': _vm.getImg(_vm.image, 'image', '?w=2300'),
              'width': 1921
          },

          {
              'src': _vm.getImg(_vm.image, 'image', '?w=1500'),
              'srcx2': _vm.getImg(_vm.image, 'image', '?w=1600'),
              'width': 1201
          },

          {
              'src': _vm.getImg(_vm.image, 'image', '?w=1250&h=1000&fit=fill'),
              'width': 768
          }
          ],"data-props":"scale","data-fromscale":"1.1","data-toscale":"1","data-dur":"2","fill":true}})],1),_vm._v(" "),_c('div',{staticClass:"position-relative text-uppercase glossy d-flex flex-column align-items-center py-6 z-1"},_vm._l((_vm.items),function(link,index){return _c('h2',{staticClass:"service-link t8 t5-md t6-xl mb-05 should-animate text-center color-white",class:{"service-link--active": link.active},attrs:{"data-props":"opacity,y","data-delay":.3 + index/10,"data-toopacity":link.active ? 1 : .3}},[(!link.active)?_c('nuxt-link',{staticClass:"link-inner d-inline-block",attrs:{"to":link.url},domProps:{"innerHTML":_vm._s(link.fields.title)}}):_c('span',{staticClass:"link-inner d-inline-block",domProps:{"innerHTML":_vm._s(link.fields.title)}})],1)}),0),_vm._v(" "),_c('scrolling-indicator',{staticClass:"position-absolute b-0 center-x d-none d-md-block",attrs:{"text":"Scroll Down","caption":"For more"},on:{"onClick":_vm.scrollTo}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }