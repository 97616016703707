import { mapState } from 'vuex'
import { gsap } from 'gsap'
import ResizeHandler from '@/mixins/ResizeHandler'
import WaypointTrigger from '@/mixins/WaypointTrigger'
import _clamp from 'lodash/clamp'
import { ScrollingObservable } from "@/observables/ScrollingObservable.js"

var ProcessBox = {

    props: ["model", "index", "progress"],

    mixins: [
        ResizeHandler,
        WaypointTrigger
    ],

    watch: {
        computedProgress(val){
            this.timeline && this.timeline.progress(val)
        },

        visible(visible) {
            this.toggleWaypointWithLines({
                el: this.$el,
                visible
            })
        }
    },

    computed: {
        ...mapState('app', [
          'width',
          'height'
        ]),

        computedProgress() {
            // budz sa this.index
            return this.index == 0 ? 0 : _clamp(this.progress - this.index, 0, 1)
        },

        visible() {
            return this.computedProgress >= .15
        }
    },

    methods: {
        resizeHandler() {
            setTimeout(this.initTimeline, 50)
        },

        initTimeline() {
            this.timeline && this.timeline.seek(0).clear()
            
            this.timeline = gsap.timeline({
                paused: true
            })

            this.timeline.fromTo(this.$el, 1, {
                y: 0
            }, {
                y: -this.height,
                ease: 'Power1.easeInOut'
            })

            this.timeline.progress(this.computedProgress)
        }
    }

}

export default ProcessBox