import _map from 'lodash/map'
import _get from 'lodash/get'
import _uniq from 'lodash/uniq'
import _compact from 'lodash/compact'
import _includes from 'lodash/includes'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _each from 'lodash/each'
import _isObject from 'lodash/isObject'
import _intersection from 'lodash/intersection'

var Filters = {

    data: () => ({
        resetKey: 'all'
    }),

    props: {
        collection: Array,
        multiple: Boolean,
        singleFilterOnly: Boolean,
        field: String
    },

    computed: {
        options() {
            return this.getUniqueOpts(this.collection, this.field)
        },

        enabledOptions() {
          let coll = this.filterCollection(this.collection, this.$store.state.route.query, this.field)
          return this.getUniqueOpts(coll, this.field)
        },

        selectedOptions() {
            let selected = _get(this.$store, `state.route.query.${this.field}`)

            if(!selected){
              return []
            }

            if(this.multiple){
              return selected.split(",")
            } else{
              return [ selected ]
            }
        }
    },

    methods: {
        hasOptions(option) {
          return this.singleFilterOnly || _includes(this.enabledOptions, option)
        },

        isLink(option) {
          return this.hasOptions(option) || _includes(this.selectedOptions, option.sanitize())
        },

        isActive(option){
           return _includes(this.selectedOptions, option.sanitize())
        },

        getFilterUrl(value, field){
          if(!value){
            return { query : null }
          }


          // BUDZ - premosteno da za boje ne bude link za filter nego da ide na projekat....
          // if(field == 'color') {
          //   let page = _find(this.collection, (item, index) => {
          //     return _includes(item.fields.title.sanitize(), value) && item.fields.primaryProject
          //   })

          //   if(!page){
          //     console.warn("didn't find the coresponding page for color:", value)
          //   }

          //   return page ? page.url : "/"
          // }
          // BUDZ :: END

          let query = { ...this.$store.state.route.query }

          let slug = value.sanitize()

          let values = query[field] ? query[field].split(",") : []

          const index = values ? values.indexOf(slug) : -1
          if (index > -1) {
            values.splice(index, 1)
          } else if(slug){
            values.push(slug)
          }

          let newQuery
          if(this.singleFilterOnly){
            newQuery = {}
          } else{
            newQuery = query
          }

          newQuery[field] = query[field] == slug ? null 
          : this.multiple && values ? values.join(",")
          : slug

          return { query: newQuery }
        },

        getUniqueOpts(collection, field) {
          let opts = []

          _each(collection || this.collection, (c) => {
              let val = _get(c, `fields.${field || this.field}`)

              if(_isObject(val)){
                _each(val, v => opts.push(v))
              } else if(val){
                opts.push(val)
              }
          })

          return _uniq(_compact(opts))
        },

        getNumberOf(field, value, addLeadingZero) {
          let obj = {}
          obj[field] = value.sanitize()
          let collLength = this.filterCollection(this.collection, obj).length
          return !addLeadingZero || collLength >= 10 ? collLength : "0" + collLength
        },

        filterCollection(collection, params, keyToIgnore, debug) {
            return _filter(collection, c => {
              let show = true

              _each(params, (p,key) => {

                  if(p && keyToIgnore != key){
                    let val = _get(c, "fields." + key)
                    let selectedValues = p ? p.split(",") : []

                    if(_isObject(val)){
                      val = Object.values(_map(val, v => v.sanitize()))
                    } else {
                      val = [val]
                    }

                    if(!_intersection(selectedValues, val).length){
                      show = false
                    }
                  }
              })

              return show
            })
        }

    }

}

export default Filters;