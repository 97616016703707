import { gsap } from 'gsap'
import { AnimationsMixin } from 'synchronized-components'
import { mapState, mapActions } from 'vuex'
import DrawSVGPlugin from '~/plugins/scripts/DrawSVGPlugin.js'

var WaypointTrigger = {

    data: () => ({
        lines: [],
        visibleWaypoint: false,
        delayAddition: 0
    }),

    mixins: [ AnimationsMixin || {} ],

    computed: {
        ...mapState('app', [
          'animationDelay',
          'fontsLoaded'
        ])
    },

    mounted() {
        gsap.registerPlugin(DrawSVGPlugin)
    },

    watch: {
        fontsLoaded() {
            if(this.visibleWaypoint){
                this.toggleWaypointWithLines({
                    el: this.$el,
                    visible: true
                })
            }
        }
    },

    methods: {
        onSplited(elements) {
            this.splitedTo && clearTimeout(this.splitedTo)
            this.splitedTo = setTimeout(() => {                
                this.toggleWaypointWithLines({
                    el: this.$el,
                    visible: this.visibleWaypoint
                })
            }, 20)
        },

        toggleVisible(options){
            this.visibleWaypoint = options.visible
        },

        toggleWaypoint(options) {
            this.visibleWaypoint = options.visible

            if(!this.fontsLoaded){
                return
            }

            let dir = options.visible

            let elems = options.el.querySelectorAll('.should-animate')

            this.runAnimation({elems, visible: options.visible})

            this.drawLines(options)
        },

        toggleWaypointWithSVG(options) {
            let dir = options.visible

            let elems = options.el.querySelectorAll('.should-animate')
            
            this.drawLines(options)

            this.runAnimation({elems, visible: options.visible})

            this.visibleWaypoint = options.visible
        },

        drawLines(options) {
            let svgElems = options.el.querySelectorAll('.draw-me')

            if(!svgElems || !svgElems.length){
                return
            }

            let reversed = options.el.dataset.reversed
            let stagger = options.el.dataset.stagger || .1
            let delay = parseInt(options.el.dataset.delay || 0) + this.animationDelay

            console.log("delay:", delay)

            let from = reversed ? "100% 100%" : "0%"
            let to = reversed ? "0% 100%" : "100%"

            if(options.visible){
                gsap.fromTo(svgElems, {
                    drawSVG: from,
                }, {
                    duration: 1,
                    stagger,
                    drawSVG: to,
                    delay,
                    overwrite: true
                })
            } else{
                gsap.set(svgElems, {drawSVG: "0%"})
            }
        },

        // WAYPOINT
        toggleWaypointWithLines(options = {}) {

            if(!options.el){
                console.trace('no element:', options.el)
                return
            }

            let dir = options.visible

            this.visibleWaypoint = options.visible

            let elems = options.el.querySelectorAll('.should-animate')
            let splitEl = options.el.querySelectorAll('.split-text')


            this.runAnimation({ elems, visible: options.visible })


            this.drawLines(options)


            // setTimeout(() => {
                let lines = options.el.querySelectorAll('.split-line')

                if(!splitEl || !lines.length || !splitEl.length){
                    return
                }

                gsap.set(splitEl, {
                    opacity: 1
                })

                let from = {
                    opacity: 0
                }
                from[this.textDir || 'y'] = this.textMove || 40

                let to = {
                    opacity: 1,
                    clearProps: 'transform',
                    overwrite: true
                }
                to[this.textDir || 'y'] = 0

                to['stagger'] = options.visible && !options.instant ? this.textStagger || .1 : 0

                let f1 = options.visible ? from : to
                let f2 = options.visible ? to : from

                let duration = options.visible && !options.instant ? 1 : 0

                f2.delay = options.instant ? 0 
                    : options.visible ? this.delayAddition + this.animationDelay
                    : 0


                f2.onComplete = options.onComplete

                f2.ease = 'Power3.easeOut'

                if(lines && lines.length){
                    gsap.fromTo(lines, duration, f1, f2)
                }
            // }, 100)
        },

        // WAYPOINT
        toggleWaypointWithChars(options = {}) {
            if(!options.el){
                console.trace('no element:', options.el)
                return
            }

            let dir = options.visible
            this.visibleWaypoint = options.visible

            let elems = options.el.querySelectorAll('.should-animate')

            let splitEl = options.el.querySelectorAll('.split-text')

            this.runAnimation({ elems, visible: options.visible })

            let lines = options.el.querySelectorAll('.split-char')

            if(!splitEl || !lines.length || !splitEl.length){
                return
            }

            gsap.set(splitEl, {
                opacity: 1
            })

            let from = {
                // opacity: 0
            }
            from[this.textDir || 'yPercent'] = options.from || 100

            let to = {
                // opacity: 1,
                clearProps: 'transform',
                overwrite: true
            }
            to[this.textDir || 'yPercent'] = options.to || 0

            to['stagger'] = options.visible && !options.instant ? this.textStagger || .1 : 0

            let f1 = options.visible ? from : to
            let f2 = options.visible ? to : from

            let duration = options.visible && !options.instant ? 1 : 0

            f2.delay = options.instant ? 0 
                : options.visible ? this.delayAddition + this.animationDelay
                : 0

            f2.ease = 'Power3.easeOut'

            f2.onComplete = options.onComplete

            if(lines && lines.length){
                gsap.fromTo(lines, duration, f1, f2)
            }
        }
    }

}

export default WaypointTrigger;