<template>
  <synchronized-waypoint
  tag='section'
  @toggleOneDirectionVisible="toggleWaypointWithLines"
  class="before-and-after-swiper d-flex bg-dark-blue text-uppercase px-075 pt-075 pt-md-0 px-md-0 "
  :class="{
    'color-light-creme': !model.fields.blackText, 
    'color-dark-grey': model.fields.blackText
  }">

    <div 
    class="inner-wrapper position-relative w-100 flex-grow d-flex flex-column flex-md-wrap px-md-1 px-xl-0 pt-1 pt-xl-2"
    :class="{
      'flex-md-row-reverse': model.fields.reversed, 
      'flex-md-row': !model.fields.reversed,
      'pb-075 pb-md-0': !model.fields.paddingBottom,
      'pb-1 pb-md-2': model.fields.paddingBottom
    }"
    :style="{ backgroundColor: model.fields.backgroundColor }">

      <!-- ARROWS -->
      <div 
      v-if="isSwiper"
      data-preset='opacity,y'
      data-delay='.3'
      class="arrows-wrapper d-none d-md-flex align-items-center justify-content-end z-1 should-animate"
      :class="{'left': model.fields.reversed, 'right': !model.fields.reversed, 'white': !model.fields.blackText}">

        <div class="position-relative arrow-container d-flex align-items-center justify-content-center mr-1">
          <div class="arrow-parent on-top d-flex align-items-center justify-content-center position-absolute">
            <div 
            :class='prevClass'
            @click='prevSlide(true)'
            class="swiper-icon left prev-btn col-6 cursor-pointer " />
          </div>

          <div class="arrow-circle  position-absolute" />
        </div>

        <div class="position-relative arrow-container d-flex align-items-center justify-content-center ">
          <div class="arrow-parent on-top d-flex align-items-center justify-content-center position-absolute">
            <div
            @click='nextSlide(true)'
            :class='nextClass'
            class="swiper-icon next-btn col-6 cursor-pointer" />
          </div>

          <div class="arrow-circle position-absolute" />
        </div>
      </div>
      <!-- END :: ARROWS -->

      <!-- DESKTOP SWIPERS -->
      <div 
      class="d-none d-md-flex mb-xl-2 flex-column justify-content-md-end position-relative should-animate" 
      data-preset='opacity'
      :data-delay='.1 + swiperIndex / 10'
      v-for='(slides, swiperIndex) in sliders'
      :key="swiperIndex"
      :class="[
        swiperClass(swiperIndex),
        {
          'pl-05': !model.fields.reversed && swiperIndex == 0,
          'pr-05': model.fields.reversed && swiperIndex == 0,
          'justify-content-xl-between': !model.fields.headlineOnTop,
          'justify-content-xl-start': model.fields.headlineOnTop
        }
      ]">
        <!-- REMOVED CUZ IT MAKES NO SENSE -->
        <!-- <p 
        v-if="model.fields.headlineOnTop"
        class="mt-05 opacity-6 p1-xl roboto position-relative on-top" 
        :class="{'text-left': model.fields.reversed, 'text-right': !model.fields.reversed}"
        v-text="swiperIndex == 0 ? 'before' : 'after'" /> -->

        <div class="position-relative proportion">

          <swiper 
          :customOptions="swiperOptions(false)"
          :currentIndex.sync='currentIndex'
          :overflowVisible="false"
          ref='mySlider'
          class="slider-wrapper w-100 h-100 position-absolute t-0 l-0">

            <synchronized-picture-div
            v-for="(image, index) in slides"
            :key="index + '01'"
            :image="image"
            class='should-animate2'
            data-delay='.15'
            data-props="scale"
            :fill='true'
            :src='getImg(image, "image", "?w=600")'
            :sources="getSources(swiperIndex, image)" />
          </swiper>

          <p 
          class="top-text p p-md p-xl opacity-6 p1-xl roboto"
          :class="[textOrientation(swiperIndex), captionClass]"
          v-text="swiperIndex == 0 ? 'before' : 'after'" />
        </div>

        <div
        v-if="swiperIndex == 0 && model.fields.content && !model.fields.headlineOnTop"
        v-html="model.fields.content"
        class="roboto t15 t12-md t14-xl d-none d-xl-block pb-2" />
      </div>
      <!-- END :: DESKTOP SWIPERS -->

      <!-- MOBILE images and selector -->
      <div class="d-flex flex-column d-md-none justify-content-end flex-grow order-2">

        <div class="image-wrapper position-relative overflow-hidden">

          <custom-transition>
            <swiper 
            :key='activeImage'
            :customOptions="swiperOptions(true)"
            :currentIndex.sync='currentIndex'
            :overflowVisible="false"
            class="slider-wrapper w-100 h-100">

              <synchronized-picture-div
              v-for="(image, index) in sliders[activeSliderIndex]"
              :key="index + '01'"
              :image="model.fields.image"
              class='w-100'
              data-delay='.15'
              data-props="scale"
              :proportion="1.2"
              :src='getImg(image, "image", "?w=600")' />
            </swiper>
          </custom-transition>


        </div> 

        <div 
        class="py-1 px-1 d-md-none row"
        v-if="isSwiper">
          <div class="col-2">
            <arrow-left 
            class="icon prev-btn"
            @click='prevSlide(true)'
            :class="[{'white': !model.fields.blackText}, prevClass]" />
          </div>

          <div class="col d-flex flex-center">
            <span>{{ currentIndex + 1 }}</span>
            /
            <span>{{ sliders[activeSliderIndex].length }}</span>
          </div>

          <div class="col-2 d-flex justify-content-end">
            <arrow-left
            @click='nextSlide(true)' 
            class="icon next-btn"
            :class="[{'white': !model.fields.blackText}, nextClass]" />
          </div>
        </div>

        <div class="row link-wrapper">
          <div class="inner-link-wrapper d-flex flex-center left col-6">
            <span
            @click="activeImage = 'beforeImage', currentIndex = 0"
            class="t-14 link image-link"
            :class="{'active': activeImage == 'beforeImage'}"
            v-text="'before'" />
          </div>

          <div class="d-flex flex-center col-6">
            <span
            @click="activeImage = 'afterImage', currentIndex = 1"
            class="t-14 link image-link"
            :class="{'active': activeImage == 'afterImage'}"
            v-text="'after'" />
          </div>
        </div>
      </div>
      <!-- END :: MOBILE images and selector -->

      <!-- HEADLINE -->
      <div 
      data-preset='opacity,y'
      data-delay='.1'
      class="p-075 px-md-1 py-md-0 col-md-12 order-0 should-animate" 
      :class="{
        'col-xl d-flex justify-content-xl-end order-xl-3': !model.fields.headlineOnTop,
        'mb-md-6 d-flex flex-column-reverse flex-xl-row justify-content-xl-between': model.fields.headlineOnTop && !model.fields.reversed,
        'mb-md-6 d-flex flex-column-reverse flex-xl-row-reverse justify-content-xl-between': model.fields.headlineOnTop && model.fields.reversed
      }">
        <div 
        v-if="model.fields.content && model.fields.headlineOnTop"
        class="col-md-6 col-xl-3">
          <div
          v-html="model.fields.content"
          class="roboto t15 t12-md t14-xl" />
        </div>

        <div
        class="mb-2 mb-xl-0"
        :class="{
          'col-xl-6': !model.fields.headlineOnTop,
          'col-xl-4': model.fields.headlineOnTop,
          'mr-auto': model.fields.reversed,
          'text-xl-right': !model.fields.reversed
        }">
          <p 
          class="t9-1 t6-1-md t9-2-xl glossy col-md-6 col-xl-12"
          v-html="model.fields.headline" />
        </div>
      </div>
      <!-- END :: HEADLINE -->
    </div>
  </synchronized-waypoint>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _each from 'lodash/each'
import _filter from 'lodash/filter'

import ArrowLeft from '/assets/svg/arrow-left.svg?inline'

import WaypointTrigger from "@/mixins/WaypointTrigger"

import { SliderMixin } from 'synchronized-components'

export default {
  name: "before-and-after-swiper",

  components: {
    ArrowLeft
  },

  data: () => ({
    currentIndex: 0,
    currentIndexMobile: 0,
    activeImage: 'beforeImage'
  }),

  props: ["model", "isLast"],

  mixins: [
    WaypointTrigger,
    SliderMixin
  ],

  mounted() {
    this.syncSliders()
  },

  methods: {
      ...mapActions('app', [
          'setStateApp'
      ]),

      getSources(index, image) {
        console.log("index", index)
        return index == 0 ? this.smallImage(image) : this.bigImage(image)
      },


      bigImage(image) {
        return [{
              'src': this.getImg(image, 'image', '?w=1600'),
              'srcx2': this.getImg(image, 'image', '?w=2000'),
              'width': 1921
          },

          {
              'src': this.getImg(image, 'image', '?w=1400'),
              'srcx2': this.getImg(image, 'image', '?w=2000'),
              'width': 1201
          },

          {
              'src': this.getImg(image, 'image', '?w=800'),
              'width': 768
          }
        ]
      },

      smallImage(image) {
        return [{
              'src': this.getImg(image, 'image', '?w=800'),
              'srcx2': this.getImg(image, 'image', '?w=1000'),
              'width': 1921
          },

          {
              'src': this.getImg(image, 'image', '?w=700'),
              'srcx2': this.getImg(image, 'image', '?w=1000'),
              'width': 1201
          },

          {
              'src': this.getImg(image, 'image', '?w=800'),
              'width': 768
          }
        ]
      },


      swiperOptions(autoHeight)  {
        return {
          loop: this.isSwiper && this.width > 768,
          parallax: false,
          effect: 'drag',
          resistance: false,
          // resistanceRatio: 1,
          slidesPerView: 1,
          loopedSlides: 6,
          spaceBetween: 1,
          autoHeight: autoHeight,
          autoplay: false,
          grabCursor: this.isSwiper,
          allowTouchMove: this.isSwiper,
          // navigation: {
          //   nextEl: `.${this.prevClass}`,
          //   prevEl: `.${this.nextClass}`,
          // },
          breakpoints: {
            // 768: {
            //   spaceBetween: 40,
            // }
          }
        }
      },

      swiperClass(index) {
        let propSwiper = this.model.fields.orientationPosition

        let imageName = index == 0 ? 'before order-2' : 'after order-1'
        return `${propSwiper} ${imageName}`
      },

      textOrientation(index) {
        return (index == 0 && !this.model.fields.reversed) || (index == 1 && this.model.fields.reversed) ? 'text-left'
        : 'text-right'
      },

      syncSliders() {
        if(this.width < 768) {
          return
        }

        let sliders = _map(this.$refs.mySlider, 'mySwiper')

        _each(sliders, (slider, index) => {
            // first one to controll all other slides
            // console.log('index', index, sliders);
            if(index == 1){
                let others = _filter(sliders, (s, i) => index != i)
                slider.controller.control = others

            // all others to control first slide
            } else {
                slider.controller.control = sliders[1]
            }
        })
      },

      makeSlides(index){
        const propertyValues = Object.values(index == 0 ? this.model.fields.beforeGallery : this.model.fields.afterGallery)

        return propertyValues
        // return [...propertyValues.slice(index), ...propertyValues.slice(0, index)]
      }
  },

  computed: {
    ...mapState('app', [
      'width'
    ]),

    captionClass() {
      return this.model.fields.captionPosition ? 'top' : 'bottom'
    },

    prevClass() {
      return `prev-${this._uid}`
    },

    nextClass() {
      return `next-${this._uid}`
    },

    numberOfSliders() {
      return 2
    },

    activeSliderIndex() {
      return this.activeImage == 'beforeImage' ? 0 : 1
    },

    items() {
      return this.model.fields.beforeGallery
    },

    sliders(){
      let sliders = []

      for (let i = 0; i < this.numberOfSliders; i++) {
          sliders[i] = this.makeSlides(i)
      }
      return sliders
    },

    isSwiper(){
      return this.sliders[0].length > 1 && this.sliders[1].length > 1
    }
  }
};
</script>

<style lang="scss">
/*! purgecss start ignore */
.before-and-after-swiper {
  min-height: 90vh;
  
  @include media-breakpoint-up(xl){
    min-height: 45rem;
  }

  .inner-wrapper {
    border: 1px solid rgba(250, 246, 236, 0.15);

    @include media-breakpoint-up(md){
      border: none;
    }
  }

  .image-wrapper {
    border-top: 1px solid rgba(250, 246, 236, 0.15);
    border-bottom: 1px solid rgba(250, 246, 236, 0.15);
    // height: 65vw;
  }

  .link-wrapper {
    padding: 1rem 0;
    border-top: 1px solid rgba(250, 246, 236, 0.15);

    .inner-link-wrapper {
      padding: .6rem 0;

      &.left {
        border-right: 1px solid rgba(250, 246, 236, 0.15);
      }

      .image-link {
        opacity: .6;

        &.active {
          opacity: 1;
        }
      }
    }

  }

  .icon {
    width: 1rem;
    &.next-btn {
        transform: rotate(180deg);
    }
    &.white {
      .icon-inner {
        stroke: $lightCreme;
      }
    }
  }

  .slider {
    width: 100%;
  }

  .top-text {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 100%;

    &.top {
      bottom: 100%;
    }

    &.bottom {
      @include media-breakpoint-up(xl){
        top: 100%;
        bottom: auto;
      }
    }
  }

  .arrows-wrapper {
    position: absolute;
    top: $s1;
    right: $s1;
    bottom: auto;

    @include media-breakpoint-up(xl){
      bottom: $s1;
      top: auto;      
    }

    .arrow-container {
      width: 3rem;
      height:3rem;

      .arrow-parent {
        width: 3rem;
        height:2rem;
        transition: .4s;
      }

      .arrow-parent:hover > .swiper-icon {
        transform: translateX(20%) rotate(180deg);
        transition: .4s;

        &.left{
          transform: translateX(-20%);
          transition: .4s;
        }
      }

      .arrow-parent:hover + .arrow-circle {
        transform:scale(0);
        transition: .4s;
      }
    }

    &.left {
      @include media-breakpoint-up(xl){
        left: 3rem;
        right: auto;
      }
    }

    &.right {
      @include media-breakpoint-up(xl){
        right: 3rem;
        left: auto;
      }
    }

    .swiper-icon {
      background: url('/img/arrow.svg') no-repeat center;
      background-size: contain;
      width: 1rem;
      height: 1rem;
      transition: .4s;
      margin: 0;
      will-change: transform;

      @include media-breakpoint-up(xl){
        width: 4rem;
      }

      &.prev-btn {
        transform: rotate(0deg);
      }

      &.next-btn {
         transform: rotate(180deg)
      }
    }

    .arrow-circle {
      background: url('/img/arrow-circle.svg') no-repeat center;
      background-size: contain;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      transition: .4s;
      will-change: transform;
    }

    &.white {
      .swiper-icon {
        background: url('/img/arrow-white.svg') no-repeat center;
        background-size: contain;
      }

      .arrow-circle {
        background: url('/img/arrow-circle-white.svg') no-repeat center;
        background-size: contain;
      }
    }
  }
  
}
/*! purgecss end ignore */
</style>