<template>
   <synchronized-waypoint 
    @toggleOneDirectionVisible="toggleWaypoint" 
    class="inner-service-header position-relative">

    <!-- IMAGE -->
    <custom-parallax 
    :wrapped='true'
    class='w-100 h-100'
    :absolute='true' 
    :speedFactor='.2' 
    :scaleFactor='.1'>
      <synchronized-picture-div
        :image="image"
        loading="eager"
        :src='getImg(image, "image", "?w=760&h=1120&fit=fill")'
        :sources="[
            {
                'src': getImg(image, 'image', '?w=2220'),
                'srcx2': getImg(image, 'image', '?w=2300'),
                'width': 1921
            },

            {
                'src': getImg(image, 'image', '?w=1500'),
                'srcx2': getImg(image, 'image', '?w=1600'),
                'width': 1201
            },

            {
                'src': getImg(image, 'image', '?w=1250&h=1000&fit=fill'),
                'width': 768
            }
            ]" 
      class='should-animate'
      data-props='scale'
      data-fromscale='1.1'
      data-toscale='1'
      data-dur='2'
      :fill="true"/>
    </custom-parallax>
    <!-- END :: IMAGE -->

    <!-- TOP CONTRNT -->
    <div class="position-relative text-uppercase glossy d-flex flex-column align-items-center py-6 z-1">
      <!-- <h1
      data-props="opacity,y"
      data-delay=".2"
      class="t8 t5-md t6-xl mb-05 color-light-creme should-animate text-center"
      v-html="activeTitle" /> -->

      <h2
      data-props="opacity,y"
      :data-delay=".3 + index/10"
      :data-toopacity="link.active ? 1 : .3"
      v-for="(link, index) in items"
      class="service-link t8 t5-md t6-xl mb-05 should-animate text-center color-white"
      :class='{"service-link--active": link.active}'>

        <!-- {{link.fields.order}} / {{link.order}} -->

        <nuxt-link
        v-if="!link.active"
        class='link-inner d-inline-block'
        :to="link.url"
        v-html="link.fields.title" />

        <span 
        v-else
        v-html="link.fields.title"
        class='link-inner d-inline-block' />
      </h2>
    </div>
    <!-- END :: TOP CONTENT -->

    <!-- SCROLLING INDICATOR -->
    <scrolling-indicator 
    @onClick='scrollTo'
    text='Scroll Down'
    caption='For more'
    class='position-absolute b-0 center-x d-none d-md-block' />
    <!-- END :: SCROLLING INDICATOR -->

  </synchronized-waypoint>
</template>

<script>
import { mapState } from 'vuex'
import WaypointTrigger from '@/mixins/WaypointTrigger'

export default {
  name: "inner-service-header",

  props: ["image", "items", "activeTitle", "color", "opacity"],

  mixins: [WaypointTrigger],

  methods: {
    scrollTo(){
      this.$bus.$emit('scrollTo', this.$el.offsetHeight, 1000)
    }
  }
};
</script>

<style lang="scss" scoped="">
.inner-service-header {
  min-height: calc(100vh + 1px);

  .service-link {
    opacity: .3;

    &--active {
      opacity: 1;
    }
  }

  .link-inner {
    transition: opacity .4s;
    opacity: .7 !important;
    &:hover {
      @include media-breakpoint-up(lg){
        opacity: 1 !important;
      }
    }
  }
}
</style>