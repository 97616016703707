import _get from 'lodash/get'
import _each from 'lodash/each'
import _isNumber from 'lodash/isNumber'
import _isString from 'lodash/isString'
import _isObject from 'lodash/isObject'

const Proportions = {

    props: {
        proportion: {
          type: [Number, String, Object]
        },

        image: {
            type: Object,
            default: null
        },

        innerClasses: {
            type: String
        },

        align: {
            type: String,
            default: 'center'
        },

        alignMobile: {
            type: String,
            default: 'center'
        },

        breakpoints: {
            type: Array,
            default: () => [
              'mobile',
              'sm',
              'md',
              'lg',
              'xl',
              'xlg',
              'xxlg'
            ]
        }
    },

    computed: {
        cssVars() {
            let vars = {
                "--align" : this.align,
                "--align-mobile" : this.alignMobile
            }

            let imageSize = _get(this.image, 'fields.file.details.image')

            _each(this.breakpoints, breakpoint => {

                // console.log("")

                // proportion is same for all breakpoints
                if(_isString(this.proportion) || _isNumber(this.proportion)){

                    vars[`--${breakpoint}`] = `${this.proportion * 100}%`

                // exact match
                } else if (_get(this.proportion, breakpoint)){
                    vars[`--${breakpoint}`] = `${this.proportion[breakpoint] * 100}%`

                // there are breakpoint variations but not the exact match
                } else if (_isObject(this.proportion)){
                    var loc = this.breakpoints.indexOf(breakpoint)

                    for(let i = loc; i >= 0; --i) {
                        let previousBreakpoint = this.breakpoints[i]
                        let previousProportion = this.proportion[previousBreakpoint]

                        if(previousProportion){
                            vars[`--${breakpoint}`] = `${previousProportion * 100}%`
                            return
                        }
                    }
                }

                // take original proportions from the asset if it was passed / no proportion parameter in this case 
                else if(imageSize) {
                  vars[`--${breakpoint}`] = `${(imageSize.height / imageSize.width) * 100}%`
                }
            })

            return vars
        }
    }
}

export default Proportions