<template>
  <synchronized-waypoint
  @toggleOneDirectionVisible="toggleWaypointWithLines"
  class="inner-page-header position-relative color-white text-uppercase glossy overflow-hidden">

    <!-- ABSOLUTE WRAPPER -->
    <div 
    class='full-absolute d-flex flex-column pb-md-4'>

      <!-- TOP BAR -->
      <div 
      :class='{
        "fade-out": !quickView
      }'
      class='w-100 px-1 px-md-2 py-15 transition-opacity top-bar'>
          <div 
          class='row justify-content-between'>
            <!-- COUNTER -->
            <p class='text-uppercase roboto d-none d-md-block'>
              <span v-html='currentIndex' />
              <span>/</span>
              <span v-html='normalizedImages.length' />
            </p>
            <!-- END :: COUNTER -->

            <arrow-link 
            :light='true'
            text='View full project'
            :to='projectLink' />
          

            <nuxt-link 
            class='text-uppercase roboto link'
            :to='closeLink'>Close</nuxt-link>        
          </div>
      </div>
      <!-- END :: TOP BAR -->


      <!-- DESKTOP SLIDER WRAPPER -->
      <div 
      v-if='selectedProject'
      :style='{
        transform: `scale(${scale.val})`
      }'
      ref='mainWrapper'
      :class='{
        "active": !quickView
      }'
      class='flex-grow-1 overflow-hidden main-wrapper d-none d-md-block media-wrapper'>

        <!-- GSAP DRAGGABLE -->
        <div ref='wrapper' class='position-relative wrapper no-wrap z-2 h-100'>
          <div class='inner-wrapper overflow-hidden h-100 d-flex' ref='innerWrapper'>

            <!-- IMAGES -->
            <div 
            v-for='(image, index) in normalizedImages' 
            :key='index'
            :class='image.featured ? "z-2" : "z-1"'
            class='image-wrapper position-relative d-inline-block'>

              <parallax-image
                :currentIndex.sync='currentIndex'
                :isPrev='index == prevIndex'
                :isNext='index == nextIndex'
                v-if='image'
                @scaleTo='scaleTo'
                @scrollToLeft='scrollToLeft'
                :scale.sync='scale'
                :quickView='quickView'
                :index='index'
                :featured='image.featured'
                :image='image'
                :scrollLeft.sync='scrollLeft'
                :class='{
                  "hidden": !image.featured && expanded
                }'
                :src='getImg(image, null, "?h=1080")'
                class='contain h-100 img' />
            </div>
            <!-- END :: IMAGES -->

          </div>
        </div>
        <!-- END :: GSAP DRAGGABLE -->

      </div>
      <!-- END :: DESKTOP SLIDER WRAPPER -->


      <!-- SINGLE IMAGE -->
      <custom-parallax 
      v-else-if='model.fields.featuredImage'
      :class='{
        "active": !quickView
      }'
      class='full-absolute media-wrapper'
      :offset='0'
      :topOffset='.1'
      :speedFactor='.1' 
      :scaleFactor='.1'>
        <synchronized-picture-div
        :fill='true'
        loading="eager"
        :alt="model.fields.featuredImage.fields.title"
        :src='getImg(model, "featuredImage", "?w=760&h=1120&fit=fill")'
        :sources="[
            {
                'src': getImg(model, 'featuredImage', '?w=2250'),
                'srcx2': getImg(model, 'featuredImage', '?w=2400'),
                'width': 1921
            },

            {
                'src': getImg(model, 'featuredImage', '?w=1920'),
                'srcx2': getImg(model, 'featuredImage', '?w=2000'),
                'width': 1201
            },

            {
                'src': getImg(model, 'featuredImage', '?w=1250&h=1000&fit=fill'),
                'width': 768
            }
        ]"/>
      </custom-parallax>
      <!-- END :: SINGLE IMAGE -->


      <!-- MOBILE STACKED IMAGES -->
      <custom-transition>
        <div 
        v-if='quickView'
        :key='quickView'
        class='d-md-none px-1 overflow-auto mobile-stacked-images pt-2 bg-beige'>
          <div 
          v-for='(image, index) in normalizedImages' 
          :key='index'
          :class='image.featured ? "z-2" : "z-1"'
          class='image-wrapper position-relative mb-2'>

            <p class='text-uppercase roboto color-olive mb-1'>
              <span v-html='index + 1' />
              <span>/</span>
              <span v-html='normalizedImages.length' />
            </p>

            <image-div
            loading="eager"
              v-if='image'
              :image='image'
              :src='getImg(image, null, "?h=1080")'
              class='w-100 img' />
          </div>
        </div>
      </custom-transition>
      <!-- END :: MOBILE STACKED IMAGES -->


      <!-- MOBILE FEATURED IMAGE -->
      <custom-transition>
        <image-div
        loading="eager"
        :key='quickView'
        v-if='(model.fields.mobileFeaturedImage || model.fields.featuredImage) && !quickView'
        :image='model.fields.mobileFeaturedImage || model.fields.featuredImage'
        :src='getImg(model, mobileImage, "?h=1080")'
        class='full-absolute d-md-none media-wrapper active' />
      </custom-transition>
      <!-- END :: MOBILE FEATURED IMAGE -->


    </div>
    <!-- END :: ABSOLUTE WRAPPER -->

    <!-- TOP CONTENT -->
    <custom-transition>
      <div 
      :key='quickView'
      v-if='!quickView'
      class="position-relative pt-6 z-1">

        <div 
        v-if="model.fields.selectedWork"
        class="mb-1 d-flex flex-center t9 t5-md t7-xl should-animate"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.35'>
          <span class="brackets">(</span>

          <div 
          class="text-uppercase text-center"
          v-html="($t('selected-works'))"></div>

          <span class="brackets">)</span>
        </div>

        <split-text
        :visible='visibleWaypoint'
        type='lines'
        :wrapped='false'
        tag='h1'
        :innerClasses="{
          't7 t4-2-md headline t4-xl mb-7 mb-md-2 text-center px-1': model.fields.smallHeadlineFont,
          't3-1 t1-md headline t4-xl mb-7 mb-md-2 text-center px-1': !model.fields.smallHeadlineFont
        }"
        :text="stripHtmlWithBreaks(model.fields.title)"
        @splited="onSplited" />

        <div 
        v-if="!model.fields.selectedWork"
        class="d-flex flex-column justify-content-center color-pale-silver">
          <div 
          v-for="(item, index) in categories" 
          :key="index + '02'"
          class="d-flex flex-column align-items-center mb-2 mb-xl-1 should-animate"
          data-preset='opacity,y'
          data-fromy='50'
          :data-delay='.6 + index / 10'>
            <span 
            v-if='model.fields[item]'
            v-text="$t(item)"
            class="opacity-8 t14 t12-md roboto" />

            <div 
            v-if='model.fields[item]'
            class="d-flex align-items-center underline">
              <span 
              v-for="(text, i) in model.fields[item]" 
              :key="i + '03'">
                <span v-if="i != 0" class="mx-025 mx-md-05">/</span>
                <span 
                v-text="text"
                class="t17 t11-md t16-xl roboto" />
              </span>
            </div>

          </div>
        </div>
      </div>
    </custom-transition>
    <!-- END :: TOP CONTENT -->


    <!-- SCROLLING INDICATOR -->
    <custom-transition>
      <scrolling-indicator 
      :key='quickView'
      v-if='!quickView'
      @onClick='scrollTo'
      text='Scroll Down'
      caption='For more'
      class='position-absolute b-0 center-x d-none d-md-block' />
    </custom-transition>
    <!-- END :: SCROLLING INDICATOR -->

  </synchronized-waypoint>
</template>

<script>
import WaypointTrigger from "@/mixins/WaypointTrigger"
// import MouseParallax from '@/components/MouseParallax'


import ResizeHandler from '@/mixins/ResizeHandler'
import InertiaPlugin from '~/plugins/scripts/gsap/src/InertiaPlugin'
import Draggable from '~/plugins/scripts/gsap/src/Draggable'

import ParallaxImage from '@/components/projectDetail/ParallaxImage'

import imagesLoaded from 'imagesloaded'

import { gsap } from 'gsap'

import { mapState } from 'vuex'

export default {
  name: "inner-page-header",

  data: () => ({
    categories: ['elements', 'color', 'type'],
    delayAddition: .45,

    expanded: false,
    
    // drag
    scrollLeft: 0,
    totalWidth: 0,

    currentIndex: 1,

    scale: {
      val: 1
    }
  }),

  props: {
    model: Object,
    quickView: Boolean,
    images: [Array, Object],
    prevIndex: Number,
    nextIndex: Number,
    featuredIndex: Number,
    selectedProject: false
  },

  components: {
    ParallaxImage
  },

  created() {
    this.currentIndex = this.featuredIndex + 1

    this.expanded = !this.quickView
  },

  watch: {
    scrollLeft(x) {
      gsap.set(this.$refs.innerWrapper, { x })
    },

    quickView(qv) {
      // gsap draggable disable and smooth scroll disable
      this.toggleScrollable()

      this.dragger[0][qv ? 'enable' : 'disable']()
    }
  },

  computed: {
     ...mapState('app', [
      'width',
      'height'
    ]),

     mobileImage(){
      return this.model.fields.mobileFeaturedImage ? 'mobileFeaturedImage' : 'featuredImage'
     },

    normalizedImages() {
      return this.images ? this.images : [this.model.fields.featuredImage]
    },

    projectLink() {
      let query = { ...this.$store.state.route.query }
      delete query.quickView
      
      return {
        query
      }
    },

    closeLink() {
      let query = { ...this.$store.state.route.query }
      delete query.quickView
      delete query.project
      
      return {
        query
      }
    }
  },

  mixins: [WaypointTrigger],

  beforeDestroy() {
    this.$bus.$emit('enableScrollbar')
  },

  mounted() {
    this.toggleScrollable()

    console.log("log inner-page-header", this.images);

    console.log("MODEL", this.model)

    imagesLoaded(this.$el, () => {
      setTimeout(() => {
        this.$bus.$emit('resize')
      }, 50)
    })

    if(this.selectedProject){
      this.initDraggable()
    }

    this.toggleWaypoint({
      visible: true,
      el: this.$el
    })
  },

  methods: {
    toggleScrollable() {
      setTimeout(() => { this.$bus.$emit(this.quickView ? 'disableScrollbar' : 'enableScrollbar') }, 100)
    },

    scrollToLeft(x) {
      gsap.to(this.$refs.innerWrapper, 1.2, {
        ease: "Power3.easeOut",
        overwrite: true,
        x
      })
    },

    scrollTo(){
      this.$bus.$emit('scrollTo', this.$el.offsetHeight, 1000)
    },

    scaleTo(scale, dur = 1.4) {

      if(scale == 1){
        this.expanded = false
      }

      gsap.to(this.scale, dur, {
        val: scale,
        ease: 'Power4.easeInOut',
        overwrite: true,
        delay: .25,
        onComplete: () => {
          if(scale != 1){
            this.expanded = true
          }
        }
      })
    },

    updateX(scrollLeft, draggingDelta) {
        if(this.blocked){
          return
        }

        this.scrollLeft = scrollLeft
    },

    initDraggable() {
        let self = this
        gsap.registerPlugin(InertiaPlugin)
        gsap.registerPlugin(Draggable)

        this.dragger = Draggable.create(this.$refs.innerWrapper, {
            bounds: this.$refs.wrapper,
            dragClickables: false,
            edgeResistance: 0.9,
            type: 'x',
            inertia: true,
            throwProps: true,
            autoScroll: false,
            dragClickables: true,

            onPress:function() {
              self.to = setTimeout(() => {
                self.$emit('update:dragging', true)
              }, 100)
            },

            onRelease: function() {
              self.to && clearTimeout(self.to)
              self.$emit('update:dragging', false)
            },

            onThrowUpdate: function(e) {
              // , InertiaPlugin.getVelocity(this.target, "x")
              self.updateX(this.x)
            },
            onDrag: function(e) {
              // , InertiaPlugin.getVelocity(this.target, "x")
              self.updateX(this.x)
            }
        })

        if(!this.quickView){
          this.dragger[0].disable()
        }
    }
  }
};
</script>

<style lang="scss" scoped="">
.inner-page-header {
  min-height: 100vh;
  height:110vh;

  .top-bar {
    background: $gold;
    color: $black;

    @include media-breakpoint-up(md){
      background: transparent;
      color: $olive;
    }
  }

  .media-wrapper{
    will-change: filter;
    transition: filter 1s;

    &.active{
      filter: grayscale(1) brightness(0.4);
    }
  }

  .main-wrapper{
    // transition: transform ease-out 1.2s;
  }

  .wrapper{
    // transform: scale(.7);
  }


  .inner-wrapper{
    width: max-content;
    will-change: transform;
  }



  @include media-breakpoint-up(md){
    height: calc(100vh + 1px);
    min-height: 700px;
  }

  .brackets {
    font-size: 2.5em;

    @include media-breakpoint-up(md){
      font-size: 3em;      
    }
  }

  .underline {
    // border-bottom: 1px solid $paleSilver;
    @include media-breakpoint-up(xl) {
      border-bottom: none;
    }
  }
}
</style>