<template>
  <div class="styling-services position-relative glossy text-uppercase pt-2 pt-md-3 pb-2">

    <!-- HEADER -->
    <services-header/>
    <!-- END :: HEADER -->


    <!-- GALLERY -->
    <div class="px-075 pl-md-2 pr-xl-2 negative-margin">
        <!-- IMAGE AND 2 TEXTS -->
        <div class="d-md-flex pr-075">

          <synchronized-waypoint
          @toggleOneDirectionVisible="toggleWaypoint" 
          class="custom-col-1">
            <div 
            data-delay='.1'
            data-preset='opacity,y'
            class="position-relative image-1 ml-auto t9 t7-md t7-xl d-none d-md-block should-animate">
              <synchronized-proportion-div
              :proportion="{
                mobile: .32,
                md: 1.412,
                xl: 1.412
              }">
                  <custom-parallax 
                  :wrapped='true'
                  class='w-100 h-100'
                  :absolute='true' 
                  :speedFactor='.1' 
                  :scaleFactor='.05'>
                    <component 
                    :is='model.fields.relatedProjects && model.fields.relatedProjects[0] ? "nuxt-link" : "div"'
                    :to="model.fields.relatedProjects && model.fields.relatedProjects[0] ? model.fields.relatedProjects[0].url : ''"
                    class="image-link d-block h-100">
                        <synchronized-picture-div
                        :fill="true"
                        :src='getImg(model.fields.gallery[0], "gallery[0]", "?w=675")'
                        :sources="[
                            {
                                'src': getImg(model.fields.gallery[0], 'gallery[0]', '?w=770'),
                                'srcx2': getImg(model.fields.gallery[0], 'gallery[0]', '?w=800'),
                                'width': 1921
                            },

                            {
                                'src': getImg(model.fields.gallery[0], 'gallery[0]', '?w=586'),
                                'srcx2': getImg(model.fields.gallery[0], 'gallery[0]', '?w=600'),
                                'width': 1201
                            },

                            {
                                'src': getImg(model.fields.gallery[0], 'gallery[0]', '?w=480'),
                                'width': 768
                            }
                            ]"
                        :alt="gallery[0].fields.title" />
                    </component>
                  </custom-parallax>
              </synchronized-proportion-div>
            </div>

            <div
            class="position-relative d-none d-md-block">
              <p 
              class="small-number left t18 t15-md t14-xl should-animate"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.1'>02</p>

              <div 
              class="should-animate"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.2'>
                <div
                v-html="headlines[1]"
                class="t9 t7-md t7-xl d-md-inline" />
              </div>
            </div>
          </synchronized-waypoint>

          <div class="custom-col-2 d-flex align-items-end pl-md-1 justify-content-md-start justify-content-center text-center text-md-left">
            <synchronized-waypoint
            @toggleOneDirectionVisible="toggleWaypoint"
            class="position-relative headline-1 t9 t7-md t7-xl">
              <p 
              class="small-number top t18 t15-md t14-xl should-animate"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.2'>01</p>

              <div class="should-animate mb-2 mb-md-0"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.3'>
                <div 
                v-html="headlines[0]"
                class="t9 t7-md t7-xl d-md-inline" />
              </div>

              <p 
              class="small-number left t18 t15-md t14-xl should-animate d-md-none d-block"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.4'>02</p>

              <div 
              class="should-animate d-md-none d-block§"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.5'>
                <div
                v-html="headlines[1]"
                class="t9 t7-md t7-xl d-md-inline" />
              </div>
            </synchronized-waypoint>
          </div>
        </div>
        <!-- END :: IMAGE AND 2 TEXTS -->

        <!-- TWO IMAGES -->
        <synchronized-waypoint
        @toggleOneDirectionVisible="toggleWaypoint"
        class="two-images-wrarpper row t9 t7-md t7-xl">
          <div class="custom-col-1-1" />

          <div class="custom-col-2-1 row pr-xl-6 pl-md-1 justify-content-center justify-content-md-start text-center text-md-left">
            
            <div
            class="box-1 pr-xl-075 d-flex flex-column justify-content-end">
              <div
              class="position-relative">
                <p 
                class="small-number top t18 t15-md t14-xl should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.2'>03</p>

                <div 
                class="should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.3'>
                  <div 
                  v-html="headlines[2]"
                  class="t9 t7-md t7-xl d-md-inline" />
                </div>
              </div>

              <div 
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.2'
              class="position-relative w-100 mix-multiply d-none d-md-block should-animate">
                <synchronized-proportion-div
                :proportion="{
                  mobile: .32,
                  md: 1.27,
                  xl: 1.278
                }">
                    <custom-parallax 
                    :wrapped='true'
                    class='w-100 h-100'
                    :absolute='true' 
                    :speedFactor='.1' 
                    :scaleFactor='.05'>
                        <component 
                        :is='model.fields.relatedProjects && model.fields.relatedProjects[1] ? "nuxt-link" : "div"'
                        :to="model.fields.relatedProjects && model.fields.relatedProjects[1] ? model.fields.relatedProjects[1].url : ''"
                        class="image-link d-block h-100">
                          <synchronized-picture-div
                          :fill="true"
                          :src='getImg(model.fields.gallery[1], "gallery", "?w=675")'
                          :sources="[
                              {
                                  'src': getImg(model.fields.gallery[1], 'gallery', '?w=770'),
                                  'srcx2': getImg(model.fields.gallery[1], 'gallery', '?w=1770'),
                                  'width': 1921
                              },

                              {
                                  'src': getImg(model.fields.gallery[1], 'gallery', '?w=586'),
                                  'srcx2': getImg(model.fields.gallery[1], 'gallery', '?w=1586'),
                                  'width': 1201
                              },

                              {
                                  'src': getImg(model.fields.gallery[1], 'gallery', '?w=480'),
                                  'width': 768
                              }
                              ]"
                          :alt="gallery[1].fields.title" />
                        </component>
                  </custom-parallax>
                </synchronized-proportion-div>
              </div>
            </div>

            <div 
            data-preset='opacity,y'
            data-delay='.1'
            class="box-2 pl-075 should-animate">
              <div class="position-relative w-100 mix-multiply d-none d-md-block">
                <synchronized-proportion-div
                :proportion="{
                  mobile: .32,
                  md: 1.543,
                  xl: 1.386
                }">
                    <custom-parallax 
                    :wrapped='true'
                    class='w-100 h-100'
                    :absolute='true' 
                    :speedFactor='.1' 
                    :scaleFactor='.05'>
                        <component 
                        :is='model.fields.relatedProjects && model.fields.relatedProjects[2] ? "nuxt-link" : "div"'
                        :to="model.fields.relatedProjects && model.fields.relatedProjects[2] ? model.fields.relatedProjects[2].url : ''"
                        class="image-link d-block h-100">
                          <synchronized-picture-div
                          :fill="true"
                          :src='getImg(model.fields.gallery[2], "gallery", "?w=675")'
                          :sources="[
                              {
                                  'src': getImg(model.fields.gallery[2], 'gallery', '?w=770'),
                                  'srcx2': getImg(model.fields.gallery[2], 'gallery', '?w=1770'),
                                  'width': 1921
                              },

                              {
                                  'src': getImg(model.fields.gallery[2], 'gallery', '?w=586'),
                                  'srcx2': getImg(model.fields.gallery[2], 'gallery', '?w=1586'),
                                  'width': 1201
                              },

                              {
                                  'src': getImg(model.fields.gallery[2], 'gallery', '?w=480'),
                                  'width': 768
                              }
                              ]"
                          :alt="gallery[2].fields.title" />
                        </component>
                  </custom-parallax>
                </synchronized-proportion-div>
              </div>
            </div>
          </div>
        </synchronized-waypoint>
        <!-- END :: TWO IMAGES -->

        <!-- IMAGE AND THREE HEADLINES -->
        <synchronized-waypoint
        @toggleOneDirectionVisible="toggleWaypoint"
        class="row mt-md-4">

          <div 
          class="custom-col-1 d-md-flex justify-content-md-start text-left d-none">
            <div class="position-relative t9 t7-md t7-xl headline-5">
              <div class="position-relative">
                <p 
                class="small-number left t18 t15-md t14-xl should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.1'>05</p>

                <div 
                class="should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.2'>
                  <div 
                  v-html="headlines[4]"
                  class="t9 t7-md t7-xl d-md-inline" />
                </div>
              </div>
            </div>
          </div>

          <div class="custom-col-2 pr-md-3 pr-xl-8 pl-md-1 mb-1 mb-md-0 d-flex flex-column align-items-md-start align-items-center text-center text-md-left">
            <div
            class="position-relative">
              <p 
              class="small-number left t18 t15-md t14-xl should-animate"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.3'>04</p>

              <div 
              class="should-animate mb-2 mb-md-1"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.4'>
                <div 
                v-html="headlines[3]"
                class="t9 t7-md t7-xl d-md-inline" />
              </div>

              <div class="position-relative d-block d-md-none mb-2 mb-md-0">
                <p 
                class="small-number left t18 t15-md t14-xl should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.5'>05</p>

                <div 
                class="should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.6'>
                  <div 
                  v-html="headlines[4]"
                  class="t9 t7-md t7-xl d-md-inline" />
                </div>
              </div>
            </div>

            <div 
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.4'
            class="position-relative w-100 d-none d-md-block should-animate">
                <proportion-div 
                :proportion="1.189">
                    <custom-parallax 
                    :wrapped='true'
                    class='w-100 h-100'
                    :absolute='true' 
                    :speedFactor='.1' 
                    :scaleFactor='.05'>
                        <component 
                        :is='model.fields.relatedProjects && model.fields.relatedProjects[3] ? "nuxt-link" : "div"'
                        :to="model.fields.relatedProjects && model.fields.relatedProjects[3] ? model.fields.relatedProjects[3].url : ''"
                        class="image-link d-block h-100">
                            <synchronized-picture-div
                            :fill="true"
                            :src='getImg(model.fields.gallery[3], "gallery", "?w=675")'
                            :sources="[
                                {
                                    'src': getImg(model.fields.gallery[3], 'gallery', '?w=1300'),
                                    'srcx2': getImg(model.fields.gallery[3], 'gallery', '?w=1560'),
                                    'width': 1921
                                },

                                {
                                    'src': getImg(model.fields.gallery[3], 'gallery', '?w=770'),
                                    'srcx2': getImg(model.fields.gallery[3], 'gallery', '?w=950'),
                                    'width': 1201
                                },

                                {
                                    'src': getImg(model.fields.gallery[3], 'gallery', '?w=480'),
                                    'width': 768
                                }
                                ]"
                              :alt="gallery[3].fields.title" />
                        </component>
                    </custom-parallax>
                </proportion-div>
            </div>
          </div>

          <div
          class="d-flex align-items-md-end text-center text-md-left justify-content-md-between flex-column flex-md-row  col-xl-9 mx-auto bottom-row pl-md-4 pl-lg-5 pl-xl-0">
            <div class="position-relative headline-6 ml-md-4 ml-xl-6 mb-4 mb-md-0">
              <p class="small-number left t18 t15-md t14-xl should-animate"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.1'>06</p>

              <div 
              class="should-animate"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.2'>
                <div
                v-html="headlines[5]"
                class="t9 t7-md t7-xl d-md-inline" />
              </div>
            </div>

            <!-- SMALL LINK -->
            <div 
            class="pr-md-3 pr-xl-4 should-animate"
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.3'>
              <nuxt-link
              to="/?contact=true"
              class="t13 t9-md t10-xl work mr-xl-2 py-075 py-xl-0">
                <span class='link reversed' v-text="'get in touch'"></span>
              </nuxt-link>
            </div>
            <!-- END :: SMALL LINK -->
          </div>
        </synchronized-waypoint>
        <!-- END :: IMAGE AND THREE HEADLINES -->

    </div>
    <!-- END :: GALLERY -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import _get from 'lodash/get'
import WaypointTrigger from "@/mixins/WaypointTrigger"
import ServicesHeader from '@/components/services/ServicesHeader'

export default {
  name: "styling-services",

  components: {
    ServicesHeader
  },

  props: ["model"],

  mixins: [WaypointTrigger],

  computed: {
    ...mapState('app', [
      'width'
    ]),

    headlines(){
      return _get(this.model, 'fields.headlines')
    },

    gallery(){
      return _get(this.model, 'fields.gallery')
    }
  }
};
</script>

<style lang="scss" scoped="">
.styling-services {
  .image-wrapper {
    margin-top: -12vw;
  }

  .image-1 {
    width: 32%;

    @include media-breakpoint-up(md) {
      width: 58%;
      margin-bottom: -.85em;
    }

    @include media-breakpoint-up(lg) {
      width: 63%;
    }

    @include media-breakpoint-up(xl) {
      width: 61%;
    }
  }

  .headline-1 {
    margin-bottom: 2.222rem;
    @include media-breakpoint-up(md) {
      padding-bottom: 1em;
      margin-bottom: 0;
    }
  }

  .headline-5 {
    margin-bottom: 2.222rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      padding-top: 2em;
    }
  }

  .two-images-wrarpper {
    margin-bottom: 2.222rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      margin-top: -.85em;
    }
  }

  .small-number {
    font-family: "WorkSans-Regular";
    @include media-breakpoint-up(md){
      position: absolute;
    }

    &.left {
      top: .6em;
      left: -1.3rem;
    }

    &.top {
      bottom: 100%;
      left: 0;
    }
  }

  .headline-6 {
    
  }

  .custom-col-1 {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 42%;
    }
  }

  .custom-col-1-1 {
    width: 100%;
    @include media-breakpoint-up(md) {
        width: 34%;
    }
    @include media-breakpoint-up(xl) {
      width: 42%;
    }
  }

  .custom-col-2-1 {
    width: 100%;
    @include media-breakpoint-up(md) {
        width: 66%;
    }
    @include media-breakpoint-up(xl) {
      width: 58%;
    }
    .box-1 {
      @include media-breakpoint-up(md) {
        width: 38%;
      }
      @include media-breakpoint-up(lg) {
        width: 39%;
      }
      @include media-breakpoint-up(xl) {
        width: 46%;
      }
    }

    .box-2 {
      @include media-breakpoint-up(md) {
        width: 62%;
      }
      @include media-breakpoint-up(lg) {
        width: 61%;
      }
      @include media-breakpoint-up(xl) {
        width: 54%;
      }
    }
  }

  .custom-col-2 {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 58%;
    }

    .box-1 {
      @include media-breakpoint-up(md) {
        width: 46%;
      }
      @include media-breakpoint-up(lg) {
        width: 39%;
      }
      @include media-breakpoint-up(xl) {
        width: 46%;
      }
    }

    .box-2 {
      @include media-breakpoint-up(md) {
        width: 54%;
      }
      @include media-breakpoint-up(lg) {
        width: 61%;
      }
      @include media-breakpoint-up(xl) {
        width: 54%;
      }
    }
  }
  .bottom-row {
    @include media-breakpoint-up(md) {
      margin-top: -3.5vw;
    }
    @include media-breakpoint-up(lg) {
      margin-top: -3.5vw;
    }
  }

  // .negative-margin {
  //   @include media-breakpoint-up(xl) {
  //     margin-top: -5vw;
  //   }
  // }

  .image-link {
    transition: .4s;
    &:hover {
      filter: grayscale(100%);
    }
  }
}
</style>