<template>
  <synchronized-scroll 
  :isMobile='!$device.isDesktop'
  class="bg-light-creme">
    <div class='overflow-hidden'>
      <!-- Page Header -->
      <inner-service-header 
      :image="model.fields.image"
      :items="services"
      :activeTitle="model.fields.title"
      :color="model.fields.headerLinksColor"
      :opacity="model.fields.headerLinksOpacity" />

      <!-- Page Components -->
      <component 
      v-if="model.fields.components"
      v-for='(component, index) in model.fields.components'
      :model='component'
      :is='getComponentTemplate(component)'
      :key='index' />

      <more-work :items="model.fields.featuredProjects" />

      <service-footer :items="otherServices" />
    </div>
  </synchronized-scroll>
</template>

<script>
import BasePage from '@/mixins/BasePage';
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import _sortBy from 'lodash/sortBy'
import _map from 'lodash/map'
import _get from 'lodash/get'

import InnerServiceHeader from '@/components/services/InnerServiceHeader'
import ServiceFooter from '@/components/services/ServiceFooter'
import ServiceInfoStyling from '@/components/serviceInfo/ServiceInfoStyling'
import ServiceInfoInteriorDesign from '@/components/serviceInfo/ServiceInfoInteriorDesign'
import ServiceInfoFashionDesign from '@/components/serviceInfo/ServiceInfoFashionDesign'
import ServiceInfoDesignDirection from '@/components/serviceInfo/ServiceInfoDesignDirection'
import StylingServices from '@/components/services/StylingServices'
import FashionServices from '@/components/services/FashionServices'
import InteriorServices from '@/components/services/InteriorServices'

import MoreWork from '@/components/project/MoreWork'

export default {
  name: 'ServiceInnerPage',

  components: {
    InnerServiceHeader,
    ServiceFooter,
    MoreWork,
    ServiceInfoStyling,
    ServiceInfoInteriorDesign,
    ServiceInfoFashionDesign,
    ServiceInfoDesignDirection,
    StylingServices,
    FashionServices,
    InteriorServices
  },

  mixins: [BasePage],

  async asyncData ({$contentful, route, store, error}) {
    const urls = store.getters['pages/getState']('urls')

    let services = await $contentful.loadByType({
      'content_type': 'service',
      'include': 3,
      'order': 'fields.order',
      'limit': 10
    }, {
        parse: true,
        single: false,
        urls
    })


    let activeIndex = _findIndex(services, s => s.fields.slug == route.params.id)

    services = _map(services, (s, index) => {
      if(index < activeIndex){
        s.order = s.fields.order + services.length
      } else{
        s.active = index == activeIndex
        s.order = s.fields.order
      }

      return s
    })

    services = _sortBy(services, ['order'])

    
    const activePage = _find(services, s => s.fields.slug == route.params.id)
    const otherServices = _filter(services, s => s.fields.slug != route.params.id)

    if(!activePage){
       error({
        statusCode: 404,
        message: "The server successfully processed the request and is not returning any content."
      })
      return
    }

    store.dispatch('pages/SET_STATE', {
      activePage
    })

    return {
      model: activePage,
      services,
      otherServices
    }
  },

  mounted() {

    let activeIndex = _findIndex(this.services, s => s.fields.slug == this.$route.params.id)

    // services =  _sortBy(services, (s, i) => i < activeIndex ? _get(s, 'fields.order') + activeIndex + 1
    // : _get(s, 'fields.order'))


    this.$store.dispatch('app/setStateApp', {
      passedViewport: false
    })
  }
}
</script>