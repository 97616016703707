<template>
  <synchronized-waypoint
  @toggleOneDirectionVisible="toggleWaypointWithLines"
  class="text-and-image-four bg-pale-silver px-075 px-md-1 pt-3 pt-md-6 pt-xl-1 pb-1">
   
    <div 
    class="pb-2 roboto t14 t11-md t13-xl text-center pb-md-6 pb-xl-6 should-animate"
    data-preset='opacity,y'
    data-fromy='50'
    data-delay='.1'>II</div>

    <split-text
    :visible='visibleWaypoint'
    type='lines'
    :wrapped='false'
    tag='h4'
    innerClasses="color-dark-text t9 t7-md t7-xl glossy text-uppercase"
    :text="stripHtmlWithBreaks(model.fields.headline[0])"
    @splited="onSplited" />

    <split-text
    :visible='visibleWaypoint'
    type='lines'
    tag='h4'
    innerClasses="color-dark-text t9 t7-md t7-xl glossy text-uppercase pr-md-5 pr-xl-8 d-inline d-md-block"
    :text="stripHtmlWithBreaks(model.fields.headline[1])"
    @splited="onSplited" />

    <h4
    v-html="model.fields.headline[2]"
    class="t9 t7-md t7-xl glossy text-uppercase d-inline d-md-none should-animate"
    data-preset='opacity,y'
    data-fromy='50'
    data-delay='.3' />

    <div class="d-flex">
      <div class="d-flex flex-column justify-content-end justify-content-md-between">
        <h4
        v-html="model.fields.headline[2]"
        class="color-dark-text t9 t7-md t7-xl glossy text-uppercase d-none d-md-block should-animate"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.3' />

        <split-text
        :visible='visibleWaypoint'
        type='lines'
        tag='h4'
        innerClasses="color-dark-text t9 t7-md t7-xl glossy text-uppercase text-md-right"
        :text="stripHtmlWithBreaks(model.fields.content[0])"
        @splited="onSplited" />
      </div>

      <synchronized-proportion-div
      :proportion="{
        mobile: .318,
        md: .1905,
        xl: .1605
      }"
      class="mt-4 mt-md-0 mb-md-025 mb-lg-075 ml-05 ml-md-1 mr-md-05 custom-col mix-multiply should-animate"
      data-preset='opacity,y,rotate'
      data-fromy='50'
      data-delay='.2'
      data-fromrotate="-10"
      data-torotate="0">
        <synchronized-picture-div
        :fill="true"
        :src='getImg(model, "image", "?w=350")'
        :sources="[
            {
                'src': getImg(model, 'image', '?w=700'),
                'srcx2': getImg(model, 'image', '?w=800'),
                'width': 1921
            },

            {
                'src': getImg(model, 'image', '?w=400'),
                'srcx2': getImg(model, 'image', '?w=500'),
                'width': 1201
            },

            {
                'src': getImg(model, 'image', '?w=600'),
                'width': 768
            }
            ]"
          :alt="model.fields.image.fields.title"/>
      </synchronized-proportion-div>

    </div>

    <split-text
    tag='h4'
    @splited="onSplited"
    v-if="index > 0"
    v-for="(item, index) in content"
    :key="index"
    innerClasses="color-dark-text text-md-right should-animate t9 t7-md t7-xl glossy text-uppercase"
    data-preset='opacity,y'
    data-fromy='50'
    :data-delay='.2 + index / 10'
    :text="stripHtmlWithBreaks(item)" />

  </synchronized-waypoint>
</template>

<script>
import { mapState } from 'vuex'
import _get from 'lodash/get'
import WaypointTrigger from "@/mixins/WaypointTrigger"

export default {
  name: "text-and-image-four",

  props: ["model"],

  mixins: [WaypointTrigger],

  computed: {
    ...mapState('app', [
      'width'
    ]),
    content() {
      return _get(this.model, 'fields.content')
    }
  }
};
</script>

<style lang="scss" scoped="">
.text-and-image-four {
    margin-top: -1px;
  .custom-col {
    width: 100%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;

    @include media-breakpoint-up(md){
      max-width: 27.3vw;
    }

    @include media-breakpoint-up(xl){
      max-width: 24vw;
    }
  }
}
</style>