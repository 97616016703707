<template>
  <div class="projets-grid row pb-3 px-1 px-xl-2 bg-beige">
    <project-preview
      class='w-100 preview-wrapper mix-multiply'
      v-for="(project, index) in items"
      :key="index + _uid"
      :isActive='isActive(project)'
      :model="project" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProjectPreview from '@/components/project/ProjectPreview'
import gridConfig from '@/components/project/gridConfig.json'

export default {
  name: "projets-grid",

  components: {
    ProjectPreview
  },

  data: () => ({
    gridConfig
  }),

  props: ["items"],

  methods: {
    isActive(project) { 
      return this.$store.state.route.query.project == project.fields.slug
    }
  }
}
</script>

<style lang="scss" scoped="">
.projets-grid {
  .preview-wrapper {
    margin-bottom: 6.25rem;

    @include media-breakpoint-up(md){
      margin-bottom: 9.37rem;
    }

    @include media-breakpoint-up(xl){
      margin-bottom: 17.8rem;
    }
  }
}
</style>