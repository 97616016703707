<template>
  <a 
  href='#'
  @mouseenter='hovered = true'
  @mouseleave='hovered = false'
  :class='{
    "active": isSelected,
    "flex-row-reverse": reversed
  }'
  @click.prevent='toggleFilter'
  class='text-uppercase d-none d-xl-flex align-items-center filter-toggler'>
    <span>{{$t(attr)}}</span>
    <close-btn 
    class='ml-1' 
    :class='reversed ? "mr-1" : "ml-1"'
    :open='isSelected'></close-btn>
  </a>
</template>

<script>
export default {
  data: () => ({
    hovered: false
  }),

  name: "filter-toggler",

  props: {
    attr: String,
    openFilter: String,
    reversed: Boolean
  },

  methods: {
      toggleFilter() {
        this.$emit('update:openFilter', this.attr)
      }
  },

  computed: {
    isSelected() {
      return this.attr == this.openFilter || this.hovered
    }
  }
}
</script>

<style lang="scss" scoped="">
.filter-toggler {
  opacity: 0.4;
  transition: opacity .4s;

  &.active{
    opacity: 1;
  }
}
</style>