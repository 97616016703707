<template>
<component 
:is='tag'
ref="waypoint"
:data-id='`waypoint-${_uid}`'
class="synchronized-waypoint">
   <slot></slot>
</component>
</template>

<script>
import { ScrollingObservable } from "@/observables/ScrollingObservable.js"
import _isNumber from 'lodash/isNumber'
import _get from 'lodash/get'

export default {
    name: 'synchronized-waypoint',
    data: () => ({
        observer: null,
        visibleOneDirection: false,
        visible: false,
        scrollingEl: false,
        pageSize: {}
    }),


    props: {
        debug: Boolean,

        elementId: null,

        rootMargin: Number,

        horizontal: Boolean,

        opts: {
            default: () => null
        },

        currentScroll: {
            default: false
        },

        tag: {
            default: 'div',
            type: String
        }
    },

    beforeDestroy() {
        this.observer.disconnect()
        window.removeEventListener('resize', this.resizeHandler)
    },

    mounted(){
        this.resizeHandler(true)
        window.addEventListener('resize', this.resizeHandler)

        this.scrollingEl = this.elementId ? document.getElementById(this.elementId) || this.$el : this.$el

        if (!('IntersectionObserver' in window) ||
            !('IntersectionObserverEntry' in window) ||
            !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
            this.visibleOneDirection = this.visible = true
        } else{           
            this.observer = new IntersectionObserver(this.callback, this.opts)

            setTimeout(() => {
                this.observer.observe(this.$el)
            })
        }
    }, 

    watch: {
        visibleOneDirection(visible) {
            this.$emit('toggleOneDirectionVisible', {
                visible: visible,
                el: this.$el
            })
        },

        visible(visible) {
            this.$emit('toggleVisible', {
                visible: visible,
                el: this.$el
            })            
        }
    },

    methods: {
        resizeHandler(resizing){
            if(!resizing || this.visibleOneDirection){
                return
            }

            setTimeout(() => {
                this.pageSize = {
                    width: window.innerWidth,
                    height: window.innerHeight
                }

                ScrollingObservable.scrollingDown = true
                let bounds = this.$el.getBoundingClientRect()
                
                this.visible = this.visibleOneDirection = bounds.top < this.pageSize.height
            })
        },

        callback(entries)  {
            let entry = entries[0]
            const current = this.horizontal ? entry.boundingClientRect.x : entry.boundingClientRect.y
            let size = this.pageSize[this.horizontal ? "width" : "height"]


            this.intersected = entry.isIntersecting

            if(!this.threshold || this.threshold[0] == 0 || current >= 0){
                this.visible = this.intersected
            }

            if(this.intersected || (current <= 0)){
                this.visibleOneDirection = true
                return
            }

            let scrollDirection = this.horizontal ? ScrollingObservable.scrollingLeft : ScrollingObservable.scrollingDown

            if(!scrollDirection && !this.intersected && -current < size){
                this.visibleOneDirection = false   
            }
        }
    }
}

</script>