<template>
  <synchronized-waypoint
  @toggleOneDirectionVisible="toggleWaypoint"
  class="more-work row position-relative bg-light-olive pb-2 pb-md-4">
    <div class="col-md-6 order-1 order-md-0">
      <!-- IMAGE 1 -->
      <nuxt-link
      :to="items[0].url">
        <div class="image-wrapper image-wrapper-1">
          <div class="overflow-hidden">

            <synchronized-proportion-div 
            :proportion='{
              mobile: 1.123,
              md: 1.355,
              xl: 1.233
            }'>
              <custom-parallax 
              :wrapped='true'
              class='w-100 h-100 image-wrapper should-animate2'
              data-preset="scale"
              data-dur="1.5"
              data-fromscale='1.1'
              data-toscale='1'
              :absolute='true' 
              :speedFactor='.1' 
              :scaleFactor='.1'>
                

                <synchronized-picture-div
                :fill="true"
                :image="items[0].fields['relatedImage', 'featuredImage']"
                :src='getImg(items[0], ["relatedImage", "featuredImage"], "?w=1400")'
                :sources="[
                    {
                        'src': getImg(items[0], ['relatedImage', 'featuredImage'], '?w=1400'),
                        'srcx2':  getImg(items[0], ['relatedImage', 'featuredImage'], '?w=1400'),
                        'width': 1921
                    },

                    {
                        'src': getImg(items[0], ['relatedImage', 'featuredImage'], '?w=1400'),
                        'srcx2': getImg(items[0], ['relatedImage', 'featuredImage'], '?w=1400'),
                        'width': 1201
                    },

                    {
                        'src': getImg(items[0], ['relatedImage', 'featuredImage'], '?w=1400'),
                        'width': 768
                    }
                    ]"
                    class="position-relative image w-100 h-100"
                     />
              </custom-parallax>
            </synchronized-proportion-div>
          </div>

          <div class="image-wrapper-1-caption t14 t15-md t14-xl float-md-right pt-025 pt-md-05 pr-05 roboto text-uppercase">
            <p v-html="stripHtml(items[0].fields.title)" />

            <p 
            v-if="items[0].fields.client"
            v-html="items[0].fields.client" />
          </div>
        </div>
      </nuxt-link>
      <!-- END :: IMAGE 1 -->
    </div>

    <div class="col-md-6 bg-heavy-metal">
      <!-- IMAGE 2 -->
      <nuxt-link
      :to="items[1].url">
        <div class="image-wrapper image-wrapper-2 px-075 px-md-0 mt-6 mt-md-0 overflow-hidden bg-heavy-metal">
          <div>

            <synchronized-proportion-div
            class="mix-overlay"
            :proportion='{
              mobile: 1.123,
              md: 1.355,
              xl: 1.233
            }'>
                <custom-parallax 
                :wrapped='true'
                class='w-100 h-100 image-wrapper should-animate2'
                data-preset="scale"
                data-dur="1.5"
                data-fromscale='1.1'
                data-toscale='1'
                :absolute='true' 
                :speedFactor='.1' 
                :scaleFactor='.1'>
                  <synchronized-picture-div
                  :fill="true"
                  :image="items[1].fields['relatedImage', 'featuredImage']"
                  :src='getImg(items[1], ["relatedImage", "featuredImage"], "?w=1400")'
                  :sources="[
                      {
                          'src': getImg(items[1], ['relatedImage', 'featuredImage'], '?w=1400'),
                          'srcx2':  getImg(items[1], ['relatedImage', 'featuredImage'], '?w=1400'),
                          'width': 1921
                      },

                      {
                          'src': getImg(items[1], ['relatedImage', 'featuredImage'], '?w=1400'),
                          'srcx2': getImg(items[1], ['relatedImage', 'featuredImage'], '?w=1400'),
                          'width': 1201
                      },

                      {
                          'src': getImg(items[1], ['relatedImage', 'featuredImage'], '?w=1400'),
                          'width': 768
                      }
                      ]" 
                  class="position-relative w-100 h-100 image"
                  v-if='getImg(items[1], ["relatedImage", "featuredImage"], "?w=1400")'/>
                </custom-parallax>
            </synchronized-proportion-div>

            <div class="caption-wrapper t14 t15-md t14-xl pt-025 pt-md-0 roboto text-uppercase">
              <p v-html="stripHtml(items[1].fields.title)" />

              <p 
              v-if="items[1].fields.client"
              v-html="items[1].fields.client" />
            </div>
          </div>
        </div>
      </nuxt-link>
      <!-- END :: IMAGE 2 -->

      <!-- BG TEXT -->
      <div class="text-wrapper bg-heavy-metal text-uppercase t5-xl glossy color-grey-text py-2 p-md-1">
        <div 
        v-if="width > 768"
        v-html="($t('more-work-text'))"
        class="pb-xl-1 pt-xl-5 pr-xl-2" />
      </div>
      <!-- END :: BG TEXT -->
    </div>

    <!-- MIDDLE BOX -->
    <div class="more-wrapper text-uppercase d-flex flex-column justify-content-between py-1 py-xl-2 px-md-2 px-xl-3 order-0 order-md-2 should-animate"
    data-preset='y'
    data-fromy='200'
    data-delay='.1'>
      <div />

      <nuxt-link 
      :to="navigation.fields.moreWorkPage.url"
      class="link-item">
          <p 
          class="t9 t7-md t7-xl text-center glossy" 
          v-html="($t('more-our-work'))" />
      </nuxt-link>

      <div class="d-flex flex-center">
        <nuxt-link 
        :to="navigation.fields.moreWorkPage.url"
        class="t10-xl link reversed text-center d-none d-md-block work"
        v-text="'view more'" />
      </div>
    </div>
    <!-- END :: MIDDLE BOX -->

    <div class="d-flex flex-center text-uppercase d-md-none order-3 w-100 mt-3">
      <nuxt-link 
      :to="navigation.fields.moreWorkPage.url"
      class="t13 t13-mdt10-xl link reversed text-center"
      v-text="'view more'" />
    </div>
  </synchronized-waypoint>
</template>

<script>
import { mapState } from 'vuex'
import WaypointTrigger from "@/mixins/WaypointTrigger"

export default {
  name: "more-work",

  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  mixins: [WaypointTrigger],

  computed: {
    ...mapState('app', [
      'width'
    ]),
    ...mapState('pages', [
      'navigation'
    ])
  }
};
</script>

<style lang="scss" scoped="">
.more-work {
  .more-wrapper {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 60vw;
    height: 60vw;
    background-color: $sandstone;

    @include media-breakpoint-up(md){
      height: 35.15vw;
      width: 32.2%;
    }

    @include media-breakpoint-up(xl){
      height: 36.1vw;
      width: 32.8%;
      padding-top: 16rem;
    }
  }

  .text-wrapper {

    @include media-breakpoint-up(md){
      min-height: 65vw;
    }

    @include media-breakpoint-up(xl){
      min-height: 40vw;
    }
  }

  .image-wrapper{
     .image {
        transform: scale(1.05);
        transition: transform .4s;
    }

    &:hover {
      // .image-wrapper{
      //   transform: scale(1.2);
      // }

      .image {
        transform: scale(1);
      }
    }
  }

  .image-wrapper-1 {
    padding: $s1 * .75 $s1 * .75 0 $s1 * .75;
    @include media-breakpoint-up(md) {
      padding: 0 0 0 $s1 * .75;
    }

    @include media-breakpoint-up(xl){
      padding: 0 0 0 7.3rem;
    }

    .image-wrapper-1-caption {
      color: #353632;
      text-transform: uppercase;
    }
  }


  .image-wrapper-2 {
    position: relative;
    filter: grayscale(100%);
    @include media-breakpoint-up(md){
      position: absolute;
      left: 0;
      top: 0;
      width: 94.6%;
    }

    @include media-breakpoint-up(xl) {
      width: 74.9%;
    }

    .caption-wrapper {
    color: #90938B;
      padding: .5rem !important;  
      @include media-breakpoint-up(lg){
        padding: 0 !important;
        position: absolute;
        left: .5rem;
        top: auto;
        bottom: .5rem;
      }
    }
  }
  .link-item {
    p{
        transition: .4s;
    }
    &:hover {
        p {
            opacity: .5;
        }
    }
  }
}
</style>