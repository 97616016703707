<template>
<section class='media-component my-075 my-md-0'>

  <synchronized-waypoint
  v-if='model.fields.imageSize == "Small"'
  @toggleOneDirectionVisible="toggleWaypoint" 
  class="media-component overflow-hidden">

      <media
      class="should-animate"
      data-preset="scale"
      data-dur="1.4"
      data-fromscale='1.1'
      data-toscale='1'
      :class="{'col-md-6 col-xl-4 mx-auto px-4 px-md-3 custom-padding': model.fields.imageSize == 'Small'}"
      :proportion="null"
      :model="model"
      imageSize="?w=920" />


  </synchronized-waypoint>

   <proportion-div
   :proportion="null"
   innerClasses='position-absolute'
   v-else
   :image='model.fields.media'
   class="media-component">

      <custom-parallax
      class='full-absolute overflow-hidden'
      :wrapped='true'
      :scaleFactor='model.fields.contain ? 0 : -.1 '
      :speedFactor='model.fields.contain ? 0 : .1'
      :class="{'mix-multiply': model.fields.multiply}">
        <media
        :proportion="null"
        class='w-100 h-100'
        :fill='true'
        :class="{
            'col-md-6 col-xl-4 mx-auto px-4 px-md-3 custom-padding': model.fields.imageSize == 'Small'
        }"
        :model="model" />
      </custom-parallax>

  </proportion-div>

</section>

</template>

<script>
import _get from 'lodash/get'
import { mapState } from 'vuex'
import Media from '@/components/media/Media'
import WaypointTrigger from "@/mixins/WaypointTrigger"

export default {
  name: "media-component",

  components: {
    Media
  },

  props: ["model"],

  mixins: [WaypointTrigger],

  computed: {
    ...mapState('app', [
      'width'
    ]),
    mediaProportion() {
      return _get(this.model, 'fields.imageSize') == 'Large' ? .75
      : _get(this.model, 'fields.imageSize') == 'Small' ? this.width > 1200 ? 1.37 : 1.265
      : .625
    }
  }
};
</script>

<style lang="scss" scoped="">
.media-component {
  .custom-padding {
    margin: 8.5rem 0;
    @include media-breakpoint-up(md) {
      margin: 13rem 0;
    }
    @include media-breakpoint-up(xl) {
      margin: 12rem;
    }
  }
}
</style>