<template>
  <synchronized-waypoint
  @toggleOneDirectionVisible="toggleWaypoint"  
  :class='[{
    "project-preview--hovered": hovered
  }, `project-preview--${model.fields.gridTemplate}`]'
  class="project-preview">

      <!-- OPTIONAL TOP CONTENT -->
      <div 
      v-if='width > 1200 && config.contentAbove'
      :class='`justify-content-${config.justify}`'
      data-preset='opacity,y'
      class='row w-100 text-uppercase t13 mb-1 should-animate pl-xl-1'>
        <div 
        v-html='model.fields.projectDescription'
        class='content col-md-6'></div>
      </div>
      <!-- END :: OPTIONAL TOP CONTENT -->

      <!-- IMAGE CARDS -->
      <div 
      class='should-animate d-block'
      data-preset='opacity,y'
      data-delay='.1'>
        <div
        :class='`justify-content-${config.justify}`'
        class='row w-10 gutter-md'>
        
          <nuxt-link 
          @mouseenter.native='hovered = true'
          @mouseleave.native='hovered = false'
          :to='model.url'
          v-for='(image, index) in images' 
          :key='index'
          class='image-card item'
          :class='[config.landscapeImage == index ? "col-12 col-xl-6" : "col-md-6 col-xl-3", {
            "d-none d-xl-block": config.landscapeImage ? index != config.landscapeImage : index > 0,
            "d-none d-md-block": config.landscapeImage ? index == config.landscapeImage : index < 2,
            "d-block": config.landscapeImage ? index == config.landscapeImage : index == 0,
          }]'>
            <synchronized-picture-div
            :src='getImg(image, "image", "?h=750")'
            :alt="image.fields.title"
            :sources="[
                {
                    'src': getImg(image, 'image', `?w=${1100 * getImgRatio(index)}`),
                    'srcx2': getImg(image, 'image', `?w=${1300 * getImgRatio(index)}`),
                    'width': 1921
                },

                {
                    'src': getImg(image, 'image', `?w=${920 * getImgRatio(index)}`),
                    'srcx2': getImg(image, 'image', `?w=${1120 * getImgRatio(index)}`),
                    'width': 1201
                },

                {
                    'src': getImg(image, 'image', `?w=${1150 * getImgRatio(index)}`),
                    'width': 768
                }
            ]"
            class='project-image'
            :proportion='config.landscapeImage == index ? .65 : 1.33' />
          </nuxt-link>

        </div>
      </div>
      <!-- END :: IMAGE CARDS -->

      <!-- BOTTOM CONTENT -->
      <div 
      :class='`justify-content-${config.justify}`'
      data-preset='opacity,y'
      data-delay='.25'
      class='d-flex flex-column flex-xl-row w-100 mt-1 should-animate'>
        <div 
        class="d-flex flex-column mb-05 mb-lg-1"
        :class='[config.numberOfImages == 4 ? "col-xl" : "col-xl-6", {
          "pl-xl-025": (config.justify == "end" && config.numberOfImages != 4),
          "pl-xl-05": model.fields.gridTemplate == 2
        }]'>

          <div class="d-flex color-beigev4">
            <p
            class='text-uppercase enabled t13 t11-1-md t14-xl link pt-1 pt-md-0 pb-025'
            v-html='model.fields.client' />
          </div>

          <nuxt-link 
          @mouseenter.native='hovered = true'
          @mouseleave.native='hovered = false'
          v-html='stripHtml(model.fields.title)'
          :to='model.url'
          class='pr-1 t9 t7-md t7-xl glossy lh-1 text-uppercase mb-md-025 mb-xl-0 project-title' />

          <div class="d-flex color-beigev4">
            <nuxt-link 
            class='text-uppercase enabled t13 t11-1-md t14-xl link pt-1 pt-md-0 pb-025'
            :to='quickUrl'>Quick View</nuxt-link>
          </div>
        </div>

        <div 
        v-if='width < 1200 || !config.contentAbove'
        class='content col-xl-3'>
          <div 
          class="text-uppercase t13 t11-1-md t13-xl pl-xl-075 col-md-10 col-lg-8 col-xl-12" 
          v-html='model.fields.projectDescription' />
        </div>

        <div 
        v-if="config.narroowWidth"
        class="col-md-3" />
      </div>
      <!-- END :: BOTTOM CONTENT -->

  </synchronized-waypoint>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import _get from 'lodash/get'
import WaypointTrigger from "@/mixins/WaypointTrigger"

const COMPONENT_CONFIG = [{
  numberOfImages: 4,
  contentAbove: false,
  justify: 'end'
},{
  numberOfImages: 2,
  contentAbove: true,
  justify: 'end'
},{
  numberOfImages: 2,
  contentAbove: false,
  narroowWidth: true,
  landscapeImage: 1,
},{
  numberOfImages: 3,
  contentAbove: false,
  justify: 'end'
},{
  numberOfImages: 1,
  contentAbove: true,
  justify: 'start',
  landscapeImage: 0
}]

export default {
  name: "project-preview",

  data: () => ({
    hovered: false
  }),

  props: {
    model: Object
  },

  mixins: [WaypointTrigger],

  methods: {
    getImgRatio(index) {
      return this.config.landscapeImage == index ? 1 : .5
    }
  },

  computed: {
    ...mapState('app', [
      'width'
    ]),

    quickUrl() {
      return {
        query: { 
          ...this.$store.state.route.query,
          project: this.model.fields.slug,
          quickView: true
        }
      }
    },

    config() {
      let template = _get(this.model, 'fields.gridTemplate') || 0
      return COMPONENT_CONFIG[template - 1]
    },

    images() {
      return this.model.fields.gridGallery && this.width <= 768 ? this.model.fields.gridGallery.slice(0, 2) : this.model.fields.gridGallery ? this.model.fields.gridGallery.slice(0, this.config.numberOfImages) : []
    }
  }
};
</script>

<style lang="scss" scoped="">
.project-preview {
  .project-image{
    // height: 30vw;
    transition: filter 0.4s;
    filter: grayscale(100%);
  }

  .project-title{
    transition: opacity .4s;
    left: -0.04em;
    position: relative;
    /deep/ {
      @include media-breakpoint-down(lg) {
        br{
          display: none;
        }
      }
    }
  }

  &--hovered{
    .project-image{
      filter: grayscale(0%);
    }
    .project-title {
      opacity: .5;
    }
  }
}
</style>