<template>
<div class=''>
  <transition 
  :mode='!menuOpen ? `out-in` : ``'
  v-on:enter="enter"
  v-on:leave="leave"
  v-bind:css="false">
      <section :key='pageKey'>

        <div class='t-0 l-0 page' ref='page'>
          <slot />
        </div>

        <div class='full-fixed bg-beige page-overlay z-6' ref='overlay' />
      </section>
  </transition>

  <!-- <div
  ref='logo'
  class='position-fixed center transition-logo z-7 disabled' 
  v-html='Logo' /> -->

  <div
  ref='shape'
  class='position-fixed w-100 center z-7 disabled transition-shape' 
  v-html='TransitionShape' />


</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { gsap } from 'gsap'
// import Logo from '@/assets/svg/logo.svg?raw'
import TransitionShape from '@/assets/svg/transition.svg?raw'
import DrawSVGPlugin from '~/plugins/scripts/DrawSVGPlugin'

export default {
  name: "page-transition",

  props: ["pageKey"],

  data: () => ({
    // Logo,
    TransitionShape
  }),

  mounted() {
    gsap.registerPlugin(DrawSVGPlugin)
    // this.leave(this.$el, () => {
    //   this.enter(this.$el)
    // })
  },

  methods: {
    ...mapActions('app', [
        'setStateApp'
    ]),

    enter(el, done)  {

      setTimeout(() => {
        this.$bus.$emit('transitionEnterStart')
        this.setStateApp({
            menuOpen: false 
        })
      }, 300)

      // TODO :: CHECK WHY 400?
      setTimeout(() => {
        this.$store.dispatch("app/setStateApp", {
          animationDelay: 0
        })
      }, 400)


      if(!this.menuOpen){        
        gsap.from(this.$refs.page, .8, {
          opacity: 0.2,
          delay: 1
        })

        // let letters = this.$refs.logo.querySelectorAll('path')
        // gsap.to(letters, .5, {
        //   delay: .65,
        //   opacity: 0
        // })

        let paths = this.$refs.shape.querySelectorAll('path')
        gsap.to(paths, .5, {
          delay: .65,
          opacity: 0
        })

        gsap.set(this.$refs.overlay, {
          scaleY: 1
        })

        gsap.to(this.$refs.overlay, 1.4, {
          scaleY: 0,
          transformOrigin: 'top',
          ease: 'Power4.easeInOut',
          delay: .5,
          onComplete: done
        })
      }


    },

    leave(el, done)  {

      this.$bus.$emit('transitionStart')

      const onComplete = () => {
        window.scrollTo(0, 0)
        this.setStateApp({
            scrollTop: 0,
            instantTransition: false,
            // menuOpen: false 
        })
        done()
      }
      
      this.$store.dispatch("app/setStateApp", {
        animationDelay: .7
      })

      if(this.menuOpen){
        gsap.set(el, {
          position: 'absolute',
          willChange: 'opacity',
          width: '100%',
          height: '100%',
          zIndex: 3,
          top: 0,
          left: 0
        })

        gsap.to(el, .6, {
          opacity: 0,
          delay: .1,
          onComplete
        })
        return
      }


      // LOGO
      // let letters = this.$refs.logo.querySelectorAll('path')
      // gsap.fromTo(letters, .8, {
      //   opacity: 0
      // },{
      //   delay: .7,
      //   stagger: .035,
      //   opacity: 1
      // })


      let drawElems = this.$refs.shape.querySelectorAll('.draw-me')
      gsap.fromTo(drawElems, {
          drawSVG: '0%',
      }, {
          duration: 1.6,
          ease: 'Power1.easeOut',
          delay: 1,
          drawSVG: '100%',
          overwrite: true
      })

      gsap.set(drawElems, {
        opacity: 1
      })


      let fadeElems = this.$refs.shape.querySelectorAll('.fade-me')
      gsap.fromTo(fadeElems, {
          opacity: 0
      }, {
          duration: .5,
          delay: 1.6,
          opacity: 1,
          overwrite: true
      })


      gsap.to(this.$refs.page, 1.6, {
        y: this.height / 2,
        delay: .1,
        opacity: 0,
        ease: 'Power4.easeInOut'
      })

      gsap.to(this.$refs.overlay, 1.6, {
        scaleY: 1,
        transformOrigin: 'top',
        ease: 'Power4.easeInOut',
        delay: .1,
        onComplete
      })
    }
  },

  computed: {
    ...mapState('app', [
      'width',
      'height',
      'menuOpen'
    ])
  }
};
</script>

<style lang="scss" scoped="">
section{
  will-change: height;
}

#sun{
  opacity: 0;
}

.transition-shape{
  & /deep/ path {
    opacity: 0;
  }
}

// .transition-logo{
//   width: 13rem;
//   // z-index: 999999999;
//   & /deep/ path {
//     fill: $black;
//     opacity: 0;
//   }
// }

.page-overlay{
  pointer-events: none;
  transform: scaleY(0);
  transform-origin: top;
  // z-index: 99999999;
}
</style>