<template>
    <div 
    class="synchronized-proportion-div position-relative" 
    :style='cssVars'>
        <div
        class='w-100 h-100 t-0 l-0' 
        :class='[innerClasses, {
            "position-absolute": proportion
        }]'>
            <slot></slot>
        </div>
    </div>
</template>
<script>
import _isNumber from 'lodash/isNumber'
import Proportions from './Proportions'

export default {
    data: () => ({
        imageRatio: null
    }),

    name: "synchronized-proportion-div",

    mixins: [Proportions],

    mounted(){
        if(!this.src || this.image || _isNumber(this.proportion)){
            return
        }

        let self = this

        let image = new Image()

        image.addEventListener(
            "load",
            () => {
                self.naturalHeight = image.naturalHeight
                self.naturalWidth = image.naturalWidth
                self.imageRatio = self.naturalHeight / self.naturalWidth

                setTimeout(() => {
                    self.$bus.$emit('resize')
                }, 100)
            },
            false
        )

        image.src = this.src
    }
}
</script>

<style lang='scss' scoped>
$sizes: (
  "mobile": var(--mobile), 
  "sm": var(--sm),
  "md": var(--md),
  "lg": var(--lg),
  "xl": var(--xl),
  "xlg": var(--xlg)
);

.synchronized-proportion-div {
    position: relative;

    @each $name, $value in $sizes {
      @if $name == 'mobile' {
        padding-bottom: $value;
      } @else {
        @include media-breakpoint-up($name) {
          padding-bottom: $value;
        }
      }
    }    
}
</style>