<!-- CONTENTFUL MEDIA COMPONENT -->
<template>
<section class="media-component position-relative">
  <!-- IMAGE OR VIDEO OR VIMEO -->
  <div class="position-relative w-100 h-100">
    <component
    :is='componentType'
    :loop='computedVideoOpts.loop'
    :autoplay='computedVideoOpts.autoplay'
    :forcePoster='computedVideoOpts.forcePoster'
    :muted='computedVideoOpts.muted'
    :playsinline='computedVideoOpts.playsinline'
    :controls='computedVideoOpts.controls'
    :playing.sync='videoPlaying'
    :src='src'
    :fill='fill'
    :contain="contain"
    :multiply="multiply"
    :proportion='proportion'
    :image='asset'
    :posterUrl='posterUrl' />
  </div>
  <!-- END :: IMAGE OR VIDEO OR VIMEO -->

</section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _size from 'lodash/size'
import _merge from 'lodash/merge'

export default {
  name: "media-component",

  data: () => ({
     swiperOptions: {
        loop: true,
        effect: 'fade',
        slidesPerView: 1,
        autoplay: {
          delay: 2000
        }
    },

    videoPlaying: false
  }),

  watch: {
    playing(p) {
      if(!this.computedVideoOpts.autoplay && p){
        return
      }

      this.videoPlaying = p
    }
  },

  mounted() {
    if(this.computedVideoOpts.autoplay){
      this.videoPlaying = this.playing
    }

    // console.log("media", this.model, this.src);
  },

  props: {
    model: [Object, Array],

    playing: {
      type: Boolean,
      default: true
    },

    posterUrl: {
      type: String,
      default: null
    },

    attrName: {
      type: String,
      default: 'media'
    },

    vimeoAttrName: {
      type: String,
      default: 'vimeoId'
    },

    imageSize: {
      type: String,
      default: '?w=1920'
    },

    proportion: [Number, Boolean],

    fill: Boolean,

    videoOpts: Object,

    contain: Boolean,

    multiply: Boolean
  },

  methods: {
      ...mapActions('app', [
          'setStateApp'
      ])
  },

  computed: {
    ...mapState('app', [
      'width'
    ]), 

    computedModel() {
      return _get(this.model, 'sys') ? this.model 
        : _size(this.model) == 1 ? this.model[0]
        : this.model
    },

    asset() {
      return _get(this.computedModel, 'fields.file') ? this.computedModel : _get(this.model, `fields.${this.attrName}`)
    },

    computedVideoOpts()  {
        const defaultOptions = {
          loop: true,
          autoplay: true,
          forcePoster: false,
          muted: true,
          playsinline: false,
          controls: false
        }

        var options = _merge({}, defaultOptions, this.videoOpts)
        return options
    },

    isVideo(media){
      return _includes(_get(this.asset, 'fields.file.contentType'), 'video')
    },

    componentType() {
      return this.vimeoId ? 'vimeo-html-video' 
            : this.isVideo ? "html-video" 
            : "synchronized-picture-div"
    },

    vimeoId() {
      return _get(this.computedModel, `fields.${this.vimeoAttrName}`)
    },

    src() {
      return this.vimeoId ? this.vimeoId 
        : this.isVideo ? _get(this.asset, 'fields.file.url') 
        : this.getImg(this.computedModel, this.attrName, this.imageSize)
    }
  }

};
</script>