
<template>
<section class='projects-filter position-relative z-1'>

  <!-- MOBILE TOGGLER -->
  <div 
  @click='filtersVisible = true'
  class='d-xl-none text-uppercase color-gray-second d-flex justify-content-center w-100 px-1 px-md-0'>
    <div class='d-flex flex-center filter-toggle-btn w-100'>
      <!-- <div 
      v-for="(filterOpt, index) in filterOptions"
      :key="index + '01'"
      class="col-6 d-flex justify-content-between py-1 t13 t11-1-md t13-xl no-wrap" 
      :class="{'flex-row-reverse pl-1': index == 1, 'separator pr-1': index == 0}">
        <p 
        :class='index == 0 ? "pr-1" : "pl-1"'
        class="text-uppercase" 
        v-html="$t(filterOpt)" />

        <p
        class="link active"
        v-html="selectedFilter(filterOpt)" />
      </div> -->

      <span class='mr-05 t11 py-1'>Filters</span>

      <span class='position-relative'>
        <!-- <img src='/img/close-circle.svg' /> -->
        <span class='position-absolute center'>+</span>
      </span>
    </div>
  </div>

  <div class="px-1 px-md-0 d-xl-none">
    <div class="clear-wrapper py-1 w-100 text-center">
      <nuxt-link
      data-preset='opacity,y'
      :data-delay='.8'
      class='should-animate2 text-uppercase color-gray-second'
      :to='{query: {}}'>
        <p 
        v-html='`ALL PROJECTS`'
        class="t11" />
      </nuxt-link>
    </div>
  </div>
  <!-- END :: MOBILE TOGGLER -->

  <custom-transition :dl='.1' :noAdditionalClasses='true'>
    <div 
    :key='computedFiltersVisible'
    v-if='computedFiltersVisible'
    :class='{
      "bg-beige": true,
      "filter-options-wrapper--mobile": $device.isMobile
    }'
    :style='wrapperStyle'
    class='filter-options-wrapper d-flex flex-column'
    ref='filters'>

        <div class='filters-header px-1 pt-1 row justify-content-end align-items-center d-xl-none'>
          <!-- <p class='text-uppercase'>Filters</p> -->

          <button 
          class='close-btn position-relative' 
          @click='filtersVisible = false'>
            <img 
            class="close-btn" 
            src="/img/close-btn.svg">
          </button>
        </div>

        <!-- LINKS FILTER -->
        <div class='flex-grow-1 d-xl-flex align-items-xl-center overflow-hidden'>
          <filter-toggler 
          :openFilter.sync='openFilter'
          :attr='filterFields[0]' />

          <div class='col-xl px-xl-4'>
            <transition
            @enter="enterFilters"
            @leave="leaveFilters"
            v-bind:css="false">
              <div :key='openFilter'>
                
                  <links-filter
                  :selectedFilters.sync='selectedFilters'
                  data-preset='opacity,y'
                  :orderedOptions='getOptions(filterField)'
                  :data-delay='.6 + index / 10'
                  class='text-uppercase should-animate2 w-100'
                  v-for='(filterField, index) in computedFilterFields'
                  :key='index'
                  :singleFilterOnly='true'
                  :class='{
                    "mb-1 mb-xl-0": index == 0
                  }'
                  :secondFilter="index == 1"
                  :reverse="index == 1"
                  :multiple='false'
                  :collection='collection'
                  :field='filterField' />
                </div>

            </transition>
          </div>

          <filter-toggler 
          :reversed='true'
          :openFilter.sync='openFilter'
          :attr='filterFields[1]' />
        </div>
        <!-- END :: LINKS FILTER -->

        <!-- DESKTOP - ALL PROJECTS -->
        <div class='d-none d-xl-inline-flex flex-center py-1 lh-1 all-wrapper'>
          <nuxt-link 
          class='text-uppercase opacity-5 link'
          v-html='`ALL PROJECTS`'
          :to='{query: {}}' />
        </div>
        <!-- END :: DESKTOP - ALL PROJECTS -->

        <!-- REMOVED BY ZENIA REQUEST -->
        <!-- <div class='row d-xl-none p-05'> -->
          <!-- <nuxt-link 
          data-preset='opacity,y'
          :data-delay='.8'
          class='should-animate2 btn col p m-05'
          v-html='`ALL PROJECTS`'
          :to='{query: {}}' /> -->

          <!-- <button 
          @click='filtersVisible = false'
          class='d-xl-none btn btn--default btn--dark col p m-05'>Apply</button> -->
        <!-- </div> -->
        <!-- END :: REMOVED BY ZENIA REQUEST -->

    </div>
  </custom-transition>

</section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LinksFilter from '@/components/filters/LinksFilter'
import FilterToggler from '@/components/filters/FilterToggler'
import ResizeHandler from '@/mixins/ResizeHandler'

import _pick from 'lodash/pick'
import _filter from 'lodash/filter'
import { gsap } from 'gsap'

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import { ScrollingObservable } from "@/observables/ScrollingObservable.js"

export default {
  name: "projects-filter",

  data: () => ({
    filtersVisible: false,
    topBound: 0,
    filterOptions: ['type', 'color'],
    openFilter: 'type',

    selectedFilters: {
      type: null,
      color: null
    }
  }),

  components: {
    LinksFilter,
    FilterToggler
  },

  props: {
    filterFields: {
      type: [Array,String]
    },

    collection: {
      type: [Array, Object]
    },
  },

  watch: {
    computedFiltersVisible(filterOpen) {
      this.setStateApp({ filterOpen })
      this.$nextTick(this.resizeHandler)

      if(filterOpen){
        this.$bus.$emit('disableScrollbar')
        disableBodyScroll(this.$el)
      } else{
        this.$bus.$emit('enableScrollbar')
        enableBodyScroll(this.$el)
      }
    },

    filterQuery() {
      this.filtersVisible = false
    },

    width (val){
      if(val >= 1200){
        this.setStateApp({
          filterOpen: false
        })
        this.$bus.$emit('enableScrollbar')
      }
    }
  },

  mixins: [
    ResizeHandler
  ],

  methods: {
      ...mapActions('app', [
          'setStateApp'
      ]),

      getOptions(field) {
        return this[field]
      },

      resizeHandler() {
        if(this.computedFiltersVisible && this.$refs.filters){
          // this.topBound = -this.$refs.filters.getBoundingClientRect().top 
        }
      },

      selectedFilter(filterOpt) {
        return this.selectedFilters[filterOpt]
        // return this.filterQuery[filterOpt || '']
      },

      leaveFilters(el, onComplete) {
        el.classList.add('transitioning-out')

        gsap.to(el, .9, {
          yPercent: 200,
          delay: .1,
          onComplete
        })
      },

      enterFilters(el, onComplete) {
        gsap.from(el, .9, {
          yPercent: -200,
          delay: .1,
          onComplete
        })
      }
  },

  mounted() {
  },

  computed: {
    ...mapState('app', [
      'width'
    ]),

    ...mapState('pages', [
      'color',
      'type'
    ]),

    computedFilterFields() {
      return this.width < 1200 ? this.filterOptions : [this.openFilter]
    },

    filterQuery() {
      return _pick(this.$store.state.route.query, this.filterFields)
    },

    currentY() {
      return this.$device.isDesktop && this.width < 1200 ? this.topBound + ScrollingObservable.scrollTop : 0
    },

    wrapperStyle() {
      return {
        transform: `translateY(${this.currentY}px)`
      }
    },

    computedFiltersVisible() {
      return this.width >= 1200 || this.filtersVisible
    }
  }
}
</script>

<style lang="scss" scoped="">
.projects-filter {
  margin: 0 -1rem;
  will-change: auto;

  @include media-breakpoint-up(md) {
    margin: 0;
  }
}

.inner-link {
  color: rgba($blackv2, 0.4);
}

@mixin btn{
  border: 1px solid $gray;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
}

.btn{
  @include btn;

  color: $gray;

  &--dark{
    background: $gray;
    color: $white;
  }
}

.filters-header{
  // border-bottom: 1px solid #8B897F4D;
}

.filter-toggle-btn{
  border-top: 1px solid #8B897F4D;
  border-bottom: 1px solid #8B897F4D;
}

.clear-wrapper {
  border-bottom: 1px solid #8B897F4D; 
}

.close-btn{
  // color: $red;
  padding: 0;
  width: 1rem;
  height: 1rem;
}

.separator {
  border-right: 1px solid #8B897F4D;
}

.filter-options-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $beige;
  z-index: 10;
  overflow-y: auto;

  @include media-breakpoint-up(xl) {
    position: relative;
    height: auto!important;
  }

  @include media-breakpoint-up(xl){
    border-bottom: 1px solid #8B897F4D;
    border-top: 1px solid #8B897F4D;
  }

  // &--mobile{
  //   height: 100%!important;
  //   overflow: auto;
  // }

  .all-wrapper {
    border-top: 1px solid #8B897F4D;
  }

  .first-filter-wrapper {
    // border-right: 1px solid #8B897F4D; 
  }
}
</style>