<template>
<main>
    <client-only>
        <navigation />
    </client-only>

    <client-only>
        <fullscreen-menu 
        ref="fullscreen" />
    </client-only>

    <page-transition
    :pageKey="pageKey">
        <nuxt :key="pageKey" />
    </page-transition>

    <intro 
    v-if='loading'
    :loading.sync='loading' />

</main>

</template>

<script>
import Vue from 'vue'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _each from 'lodash/each'
import Navigation from '@/components/Navigation'
import DeviceDetection from '@/mixins/DeviceDetection'
import FullscreenMenu from '@/components/FullscreenMenu'
import Intro from '@/components/Intro'
import { gsap } from 'gsap'

import { mapState, mapActions } from 'vuex'

export default {

    components: {
        Navigation,
        Intro,
        FullscreenMenu
    },

    mixins: [
        DeviceDetection
    ],

    data: () => ({
        loading: true,
        fontSize: null
    }),

    head()  {
        if (!this.activePage || !this.activePage.seo) {
            return {
                //should return default meta
            }
        }

        let meta =  _map(this.activePage.seo, (value, index) => {
            let obj = {
                hid: index,
                content: value.value
            }

            obj[value.attribute] = index

            return obj
        })

        let canonical 
        let websiteUrl = 'https://kimkneipp.com'

        if(this.isQuickView){            
            meta.push({
                name: "robots",
                content: "noindex"
            })

            canonical = {
                rel: "canonical",
                href: websiteUrl + _get(this.selectedProject, 'url')
            }
        } else{
            canonical = {
                rel: "canonical",
                href: websiteUrl + this.activePage.url
            }
        }

        return {
            title: _get(this.activePage, 'seo.title.value'),
            meta: meta,
            link: [ canonical ]
        }
    },

    watch: {
        cssVars(val){
            let isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)

            // if(isSafari){                
            this.setVars(val)
            // }
        }
    },

    computed: {
        ...mapState('pages', [
          'activePage',
          'projects'
        ]),

        isQuickView() {
            return this.$store.state.route.query.project || this.$store.state.route.query.quickView
        },

        ...mapState('app', [
          'menuOpen',
          'scrolled',
          'width'
        ]),

        pageKey()  {
            return _get(this.activePage, 'sys.id') || 'not-found'
        },

        cssVars() {
            return {
                "--font-size": `${4.2666 * this.width / 100}px`,
                "--font-size-sm": `${3 * this.width / 100}px`,
                "--font-size-md": `${2.083 * this.width / 100}px`,
                "--font-size-lg": `${1.814 * this.width / 100}px`,
                "--font-size-xl": `${.9375 * this.width / 100}px`
            }
        },

        // @TODO : should go to store probably
        selectedProject() {
          let query = this.$store.state.route.query.project
          return _find(this.projects, (p) => p.fields.slug == query)
        }
    },

    created()  {
        Vue.prototype.$webp = this.$store.getters['app/getState']('webp')
    },

    beforeMount()  {
        this.detectDevice()

        this.resizeHandler()
        window.addEventListener('resize', this.resizeHandler)
        window.addEventListener('orientationchange', this.resizeHandler)
        document.addEventListener('touchstart', function()  {}, true)
        this.setVars(this.cssVars)

        if(window._fontsAreLoaded){
            this.setStateApp({ fontsLoaded: true })
        } else{
            window.addEventListener('fonts.loaded', () => {
                this.setStateApp({ fontsLoaded: true })
                this.$bus.$emit('resize')
            })
        }
    },

    mounted() {
        let dark = window.matchMedia("(prefers-color-scheme: dark)").matches;
        let color = dark ? 'white' : 'black'

        console.info('%c ↘ Synchronized Studio', 
            `font-size: 30px;color: ${color}; text-align: center; font-family: sans-serif;`,'\n','\n', 
            'made by https://synchronized.studio');
        
        let el = document.createElement('canvas')
        gsap.ticker.add(() => {            
            try { el.getContext('webgl', { powerPreference: "high-performance" }) } catch(e) {
                console.warn("something went wrong with forcing the high-performance")
            }
        })
    },

    methods: {
         ...mapActions('app', [
            'setStateApp'
        ]),

        setVars(val){
            _each(val, (v, key) => {
                document.documentElement.style.setProperty(key, v)
            })
        },

        onMouseMove(e)  {
            this.setStateApp({
                mouse: {                    
                    x: e.pageX,
                    y: e.pageY
                }
            })
        },

        resizeHandler()  {
            this.setStateApp({
                width: window.innerWidth,
                height: window.innerHeight
            })

            this.$bus.$emit('resize')
        }
    }

}

</script>

<style lang="scss">
html{
    font-size: var(--font-size);

    @include media-breakpoint-up(sm) {
        font-size: var(--font-size-sm);
    }

    @include media-breakpoint-up(md) {
        font-size: var(--font-size-md);
    }

    @include media-breakpoint-up(lg) {
        font-size: var(--font-size-lg);
    }

    @include media-breakpoint-up(xl) {
        font-size: var(--font-size-xl);
    }
}
</style>
