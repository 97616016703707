<template>
  <synchronized-scroll 
  :isMobile='!$device.isDesktop'
  class="projects-page">
    <div class='overflow-hidden'>
      
      <!-- MAGIC HEADER 🧙‍♂️ -->
      <inner-page-header 
      :selectedProject='selectedProject'
      :prevIndex='parseInt((allImages.length - 1) / 2) - 1'
      :nextIndex='parseInt((allImages.length - 1) / 2) + 1'
      :featuredIndex='parseInt((allImages.length - 1) / 2)'
      :images='allImages'
      :quickView='!!quickView'
      :model="model" />
      <!-- END :: MAGIC HEADER -->

      <custom-transition>
        <div 
        class="bg-light-creme" 
        :key='quickView'
        v-if='!quickView'>

          <!-- Page Components -->
          <!-- {{model.fields.components}} -->

          <component 
          v-if='model && model.fields'
          v-for='(component, index) in model.fields.components'
          :bigPadding='index == 0'
          :model='component'
          :isLast='index == model.fields.components.length - 1'
          :is='getComponentTemplate(component)'
          :key='index' />

          <more-work 
          v-if='model.fields.relatedProjects'
          :items="model.fields.relatedProjects" />

          <!-- Page Footer -->
          <page-footer 
          :hideFin='true'
          v-if="!model.fields.disableFooter"
          :olive="true" />

        </div>
      </custom-transition>

    </div>
  </synchronized-scroll>
</template>

<script>
import BasePage from '@/mixins/BasePage';

import InnerPageHeader from '@/components/headers/InnerPageHeader'
import MediaComponent from '@/components/media/MediaComponent'
import TwoImages from '@/components/imageComponents/TwoImages'
import ImageAndInfo from '@/components/imageComponents/ImageAndInfo'
import TextAndSmallImage from '@/components/textAndImage/TextAndSmallImage'
import MoreWork from '@/components/project/MoreWork'
import BeforeAndAfterSwiper from '@/components/beforeAndAfter/BeforeAndAfterSwiper'
import PageFooter from '@/components/PageFooter'

import _each from 'lodash/each'
import _get from 'lodash/get'
import _set from 'lodash/set'

export default {
  name: 'ProjectDetail',

  components: {
    InnerPageHeader,
    MediaComponent,
    TwoImages,
    TextAndSmallImage,
    ImageAndInfo,
    MoreWork,
    BeforeAndAfterSwiper,
    PageFooter
  },

  mixins: [BasePage],

  props: {
    model: Object,
    quickView: Boolean,
    selectedProject: false
  },

  mounted() {
    console.log("COMPONENTS:", this.model)
  },  

  watch: {
  },

  methods: {
    isAsset(field) {
      if(_get(field, 'sys.type') == "Asset"){
          
          let imageHeight = _get(field, 'fields.file.details.image.height')

          // only images higher then 768px --- to prevent shitty quality
          if(imageHeight > 768){
           return true
          }

      } else{
        return false
      }
    }
  },

  computed: {
    allImages() {
      let images = []

      _each(this.model.fields.components, (component) => {
        _each(component.fields, (field, key) => {

          if(typeof field == 'object' && !field.sys){

            _each(field, (f) => {
              if(this.isAsset(f)) {
                images.push(f)
              }
            })

          } else{
            if(this.isAsset(field)){
              images.push(field)
            }
          }

        })
      })

      _set(this.model, 'fields.featuredImage.featured', true)

      images.splice(
        parseInt(images.length / 2), 
        0, 
        this.model.fields.featuredImage
      )

      return images
    }
  }
}
</script>