<template>
<main class='bg-beige page'>
  <synchronized-scroll 
  :isMobile='!$device.isDesktop' 
  v-show='$device.isDesktop || !selectedProject'
  :class='{
    "quickview-mode": !!isQuickView || selectedProject
  }'
  :pageId='model.sys.id'
  class="projects-page bg-beige"
  > 
    <div class='overflow-hidden inner-page-wrapper'>

      <synchronized-waypoint 
      data-delay='1.25'
      @toggleOneDirectionVisible="toggleWaypoint"
      class="position-relative p-1 p-xl-2">
      
        <!-- HEADLINE -->
        <div 
        data-preset='opacity,y'
        data-delay='.2'
        class="headline-wrapper position-relative color-gray-second should-animate">
          <h1
          class="text-uppercase glossy d-md-none t3 text-center"
          v-html="$t('project-header')" />

          <text-fill
            tag="h1"
            class="d-none d-md-block"
            :text="($t('project-header'))"
            innerClasses="text-uppercase glossy" />
        </div>
        <!-- END :: HEADLINE -->

        <!-- <div
        class='w-100 d-flex d-md-none'
        v-html='ProjectDecorationMob' /> -->

       <!--  <div
        class='w-100 d-none d-md-flex d-xl-none'
        v-html='ProjectTabletDecoration' /> -->

        <div
        class='w-100 d-flex desktop-decor'
        v-html='ProjectDesktopDecoration' />

        <!-- FILTERS -->
        <projects-filter
        class='should-animate not-will-change'
        data-delay='.3'
        data-preset='opacity,y'
        :collection='projects'
        :filterFields='filterFields' />
        <!-- END :: FILTERS -->

      </synchronized-waypoint>
      <!-- END :: PROJECTS HEADER -->

      <!-- Projects -->
      <custom-transition mode='out-in'>
        <projects-grid
        :key='filterKey'
        :items="filtered" />
      </custom-transition>

      <!-- Page Footer -->
      <page-footer 
      :hideFin='true'
      v-if="!model.fields.disableFooter" />

    </div>
  </synchronized-scroll>

  <transition 
  @enter='onEnter'
  @leave='onLeave'
  :css='false'>
    <project-detail 
    :selectedProject='selectedProject'
    :key='!!selectedProject'
    :quickView='!!isQuickView'
    :model='selectedProject'
    v-if='selectedProject' />
  </transition>

</main>
</template>

<script>
import PageComponents from '@/mixins/PageComponents';
import BasePage from '@/mixins/BasePage';
import { mapState, mapActions } from 'vuex'

import ProductsFilter from '@/components/project/ProductsFilter'
import ProjectsGrid from '@/components/project/ProjectsGrid'

import ProjectsFilter from '@/components/filters/ProjectsFilter'

import ProjectDesktopDecoration from '@/assets/svg/desktop-decore.svg?raw'
import ProjectTabletDecoration from '@/assets/svg/tablet-decore.svg?raw'
import ProjectDecorationMob from '@/assets/svg/mobile-decore.svg?raw'

import WaypointTrigger from '@/mixins/WaypointTrigger'

import _get from 'lodash/get'
import _pick from 'lodash/pick'
import _find from 'lodash/find'
import _sortBy from 'lodash/sortBy'
import _isEmpty from 'lodash/isEmpty'

import { gsap } from 'gsap'

import FilterMixin from '@/mixins/filters'

import ProjectDetail from '@/components/projectDetail/ProjectDetail'

import { ScrollingObservable } from "@/observables/ScrollingObservable.js"

export default {
  name: 'ProjectsPage',

  data: () => ({
    currentScroll: 0,
    ProjectDesktopDecoration,
    ProjectTabletDecoration,
    ProjectDecorationMob,

    filterFields: ['type', 'color']
  }),

  reactiveProvide: {
    name: 'scrollTop',
    include: ['currentScroll'],
  },

  components: {
    ProductsFilter,
    ProjectsGrid,
    ProjectsFilter,
    ProjectDetail
  },

  mixins: [BasePage, PageComponents, WaypointTrigger, FilterMixin],

  computed: {
    ...mapState('app', [
      'width'
    ]),

    ...mapState('pages', [
      'projects'
    ]),

    filterKey(){
      return JSON.stringify(this.filterQuery)
    },

    filterQuery() {
      return _pick(this.$store.state.route.query, this.filterFields)
    },

    filtered() {
      let projs = this.filterCollection(this.projects, this.filterQuery, false, true)

      if(!_isEmpty(this.filterQuery)){
        projs = _sortBy(projs, (p) => p.fields.primaryProject)
      }

      return projs
    },

    isQuickView() {
      return this.$store.state.route.query.quickView
    },

    selectedProject() {
      let query = this.$store.state.route.query.project
      return _find(this.projects, (p) => p.fields.slug == query)
    }
  },

  mounted() {
      this.$store.dispatch('app/setStateApp', {
        passedViewport: true
      })
  },

  watch: {
    filterKey() {
      this.$store.dispatch('app/setStateApp', {
        menuOpen: false
      })
    },

    selectedProject(sp) {
      this.$store.dispatch('app/setStateApp', {
        passedViewport: !sp 
      })

      if(sp) {
        ScrollingObservable.scrollTop = 0
        ScrollingObservable.scrollingDown = false
        ScrollingObservable.scrollingStarted = false
      }
    }
  },

  methods: {
    scrollToFilters(dur) {
        let bounds = this.$refs.filters.getBoundingClientRect()
        this.$bus.$emit('scrollTo', bounds.top, dur)
    },

    onEnter(el, onComplete) {

      // setTimeout(() => {        
        gsap.from(el, 1.4, {
          yPercent: 100,
          delay: .1,
          opacity: 0,
          ease: 'Power4.easeInOut',
          onComplete
        })
      // }, 175)
    },

    onLeave(el, onComplete) {
      console.log("EL", el)

      gsap.to(el, .6, {
        // yPercent: 100,
        delay: .1,
        // ease: 'Power1.easeInOut'
        opacity: 0,
        onComplete

      })
    }
  },

  async asyncData ({$contentful, route, store}) {
    const urls = store.getters['pages/getState']('urls')

    const activePage = await $contentful.loadActivePage({
      route: route.path,
      id: route.name,
      urls
    })

    // let projects = $contentful.loadByType({
    //   content_type: 'project',
    // },{
    //   parse: true,
    //   urls
    // })

    store.dispatch('pages/SET_STATE', {
      activePage
    })

    return Promise.all([activePage]).then((values) => {      

      store.dispatch('pages/SET_STATE', {
        activePage: values[0]
      })

      return {
        model: values[0]
      }
    })
  }
}
</script>

<style lang='scss'>
  .projects-page{

    .inner-page-wrapper{
      transition: opacity 1s;
    }

    &.quickview-mode{
      .inner-page-wrapper{
        opacity: 0.15;
      }
    }

    .desktop-decor svg{
      width: 100%;
      height: 37.5vw;

      @include media-breakpoint-up(xl){
        height: 15vw;
      }

      path{
        stroke-width: 2px;

        @include media-breakpoint-up(md){
          stroke-width: 1px;
        }
      }
    }

    .headline-wrapper {
      padding-top: 7.43rem;

      @include media-breakpoint-up(md){
        padding-top: 12.125rem;
      } 

      @include media-breakpoint-up(lg){
        padding-top: 5.16rem;
      } 
    }
  }

</style>
