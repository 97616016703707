<template>
  <div class="close-btn" :class='{"open": open}'>
    <span class='line line--horizontal'></span>
    <span class='line line--vertical'></span>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "close-btn",

  props: {
    open: Boolean
  }
};
</script>

<style lang="scss" scoped="">
.close-btn {
  .line {
    display: block;
    width: .8em;
    height: 1px;
    transform-origin: center;
    transition: transform .4s, opacity .4s;
    background: $black;

    &--vertical{
      transform: rotate(90deg);
      position: absolute;
      margin-top: -1px;
    }
  }

  &.open{
    .line--vertical{
      transform: rotate(0deg);
      opacity: 0;
    }
  }
}
</style>