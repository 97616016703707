<template>
  <div 
  :class="{'pt-md-1 pt-lg-2' : model.fields.paddingTop}"
  class="image-and-info color-dark-text row pb-1 pb-xl-2">
    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypoint"
    class="custom-col pl-075 pl-md-0 pr-075 pr-md-0 overflow-hidden mb-05 mb-md-0">
      <synchronized-picture-div 
      v-if="model.fields.image"
      class="position-relative image should-animate"
      data-preset="scale"
      data-dur="5"
      data-fromscale='1.1'
      data-toscale='1'
      :alt="imageAlt"
      :src='getImg(model, "image", "?h=600")'
      :sources="[
        {
          'src': getImg(model, 'image', '?w=1300', 90),
          'srcx2': getImg(model, 'image', '?w=1450'),
          'width': 1921
        },

        {
          'src': getImg(model, 'image', `?w=1100`),
          'srcx2': getImg(model, 'image', '?w=1350'),
          'width': 1200
        },

        {
          'src': getImg(model, 'image', '?w=700'),
          'width': 768
        }
      ]" />
    </synchronized-waypoint>

    <div class="col-md px-075 pr-md-0 pl-lg-32 d-flex flex-column justify-content-between">

      <synchronized-waypoint 
      v-if='model.fields.description || model.fields.content' 
      @toggleOneDirectionVisible="toggleWaypointWithLines">
        <div 
        class="mb-1 mb-md-025 mb-lg-24 pr-md-1 pr-lg-32">
          <split-text
          :visible='visibleWaypoint'
          type='lines'
          :wrapped='false'
          tag='p'
          innerClasses="t10-12 t8-1-md t8c-xl text-uppercase glossy mt-1 mt-md-0 mb-3 mb-md-5 mb-xl-0"
          :text="stripHtmlWithBreaks(model.fields.description || model.fields.content)"
          @splited="onSplited" />
        </div>
          
        <div 
        class="content color-dark-text t13-1 t16-2-md t13-xl pr-md-1 pr-lg-155 should-animate text-uppercase" 
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.4'
        v-if='model.fields.content && model.fields.description'
        v-html="model.fields.content" />

      </synchronized-waypoint>

      <synchronized-waypoint
      v-if='infoBoxes && infoBoxes.length'
      @toggleOneDirectionVisible="toggleWaypoint"
      class="roboto mt-2">
        <div 
        class="should-animate"
        :class="{'mb-3 mb-xl-5': infoBoxes && infoBoxes.length}"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.1'>
          <div 
          v-if="model.fields.infoHeadline"
          v-html="model.fields.infoHeadline"
          class="t13 t11-md p2-xl text-uppercase " />
        </div>

        <div v-if='infoBoxes' class="row">
          <div 
          class="col-md-6 t14 text-uppercase info-box should-animate"
          data-preset='opacity,y'
          data-fromy='50'
          :data-delay='.2 + index / 10'
          v-for="(item, index) in infoBoxes"
          :key='index'>
            <div class="col-xl-8">
              <p 
              v-html="item.fields.title"
              class="opacity-6 t14 t15-md t14-xl" />

              <client-only>
                <div 
                v-html="item.fields.text"
                class="t14 t15-md t14-xl"></div>
              </client-only>
            </div>
          </div>
        </div>
      </synchronized-waypoint>
    </div>
  </div>
</template>

<script>
import WaypointTrigger from "@/mixins/WaypointTrigger"
import _get from 'lodash/get'
import _filter from 'lodash/filter'

export default {
  name: "image-and-info",

  props: ["model"],

  mixins: [WaypointTrigger],

  mounted() {
    console.log('mm:', this.infoBoxes)
  },

  computed: {
    imageAlt() {
      return _get(this.model, 'fields.image.fields.title')
    },

    infoBoxes() {
      return Object.values(_filter(this.model.fields.infoBox, box => !!_get(box, 'fields.title')))
    }
  }
}
</script>

<style lang="scss" scoped="">
.image-and-info {
  .custom-col {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 54.43%;
    }
    .image {
      min-height: 114.7vw;

      @include media-breakpoint-up(md) {
        min-height: 80vw;
      }

      @include media-breakpoint-up(xl) {
        min-height: 72.61vw;
      }
    }
  }
  .info-box {
    @include media-breakpoint-down(sm) {
        &:not(:last-child) {
          margin-bottom: $s2;
        }
    }
    @include media-breakpoint-up(md) {
      &:not(:nth-last-child(-n+2)) {
        margin-bottom: $s2;
      }
    }
    // @include media-breakpoint-up(xl) {
    //   margin-top: $s2;
    // }
  }
}
</style>