import HeroHeader from '@/components/headers/HeroHeader'

import ServicesWrapper from '@/components/services/ServicesWrapper'
import ContactComponent from '@/components/contactComponent/ContactComponent'
import AboutHeader from '@/components/headers/AboutHeader'

import TextAndImageThree from '@/components/textAndImage/TextAndImageThree'
import TextAndImageFour from '@/components/textAndImage/TextAndImageFour'
import TextAndImageFive from '@/components/textAndImage/TextAndImageFive'

import GalleryAndText from '@/components/galleryAndText/GalleryAndText'

import WhereWeAre from '@/components/whereWeAre/WhereWeAre'

import PageFooter from '@/components/PageFooter'

const PageComponents = {
    components: {
        HeroHeader,
        ServicesWrapper,
        ContactComponent,
        AboutHeader,
        TextAndImageThree,
        TextAndImageFour,
        TextAndImageFive,
        GalleryAndText,
        WhereWeAre,

        PageFooter,
    }

}

export default PageComponents;