/* Module: pages */
import stringify from 'json-stringify-safe'

import _get from 'lodash/get'
import _mapValues from 'lodash/mapValues'
import _mapKeys from 'lodash/mapKeys'

// STATE -----------------------------------------------------------------------

const state = () => ({
  activePage: null,
  translations: {},
  navigation: {},
  projects: {},
  footer: {},
  intro: {},
  urls: [],
  type: [],
  color: []
})

// GETTERS ---------------------------------------------------------------------

const getters = {
  getState: state => prop => state[prop],

  getParsedPages: state => state.parsedPages,
  getPages: state => state.pages,

  getUrls: state => state.urls,

  getManyByAttr: state => (type, value, collection = 'urls') =>
    state[collection].filter(page => _get(page, type) === value),

  getByAttr: state => (type, value, collection = 'urls') =>
    state[collection].find(page => _get(page, type) === value)
}

// MUTATIONS -------------------------------------------------------------------

const mutations = {
  SET_STATE: (state, { prop, value }) => {
    state[prop] = value
  }
}

// ACTIONS ---------------------------------------------------------------------

const actions = {
  // @TODO create store helper functions.
  SET_STATE ({ commit }, obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        commit('SET_STATE', { prop: key, value: obj[key] })
      }
    }
  },

  async LOAD_BY_TYPE({ commit, getters, dispatch }, opts) {
    const urls = getters.getState('urls')

    let entities = await this.$contentful.loadByType({
      content_type: opts.content_type,
      include: opts.include,
      limit: opts.limit,
      locale: opts.locale
    }, {
        parse: opts.parse,
        urls
    })

    if(opts.single){
      entities = entities[0]
    }

    entities = JSON.parse(stringify(entities, null, 2))

    commit('SET_STATE', {
      prop: opts.state,
      value: entities
    })

    return entities
  },

  async LOAD_PROJECTS ({ commit, getters, dispatch }, locale) {
    const urls = getters.getState('urls')

    let entities = await this.$contentful.loadByType({
      content_type: 'projectsWrapper',
      include: 6,
      limit: 1,
      locale
    }, {
        parse: true,
        urls
    })

    entities = entities[0]
    entities = JSON.parse(stringify(entities, null, 2))

    let projects = entities.fields.projects
    let types = Object.values(entities.fields.types)
    let colors = Object.values(entities.fields.colors)

    commit('SET_STATE', {
      prop: 'projects',
      value: projects
    })

    commit('SET_STATE', {
      prop: 'type',
      value: types
    })

    commit('SET_STATE', {
      prop: 'color',
      value: colors
    })

    return projects

  },

  async LOAD_INTRO ({ commit, getters, dispatch }, locale) {
    return dispatch('LOAD_BY_TYPE', {
      content_type: 'intro',
      include: 1,
      limit: 1,
      parse: true,
      single: true,
      state: 'intro',
      locale
    })
  },

  async LOAD_FOOTER ({ commit, getters, dispatch }, locale) {
    return dispatch('LOAD_BY_TYPE', {
      content_type: 'footer',
      include: 1,
      limit: 1,
      parse: true,
      single: true,
      state: 'footer',
      locale
    })
  },

  async LOAD_NAVIGATION ({ commit, getters, dispatch, $contentful }, locale) {

    return dispatch('LOAD_BY_TYPE', {
      content_type: 'navigation',
      include: 1,
      limit: 1,
      parse: true,
      single: true,
      state: 'navigation',
      locale
    })
  },

  async LOAD_TRANSLATIONS ({ commit, getters, dispatch }, locale) {
    const translations = await this.$contentful.loadByType({
      content_type: 'translation',
      include: 1,
      limit: 1000,
      locale
    })

    if(!translations){
        return
    }

    const tr = _mapValues(
      _mapKeys(translations, item => item.fields.title),
      (t, k) => t.fields.translation
    )

    commit('SET_STATE', {
      prop: 'translations',
      value: tr
    })
  }
}

// =============================================================================

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}