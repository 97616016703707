var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('synchronized-waypoint',{staticClass:"inner-page-header position-relative color-white text-uppercase glossy overflow-hidden",on:{"toggleOneDirectionVisible":_vm.toggleWaypointWithLines}},[_c('div',{staticClass:"full-absolute d-flex flex-column pb-md-4"},[_c('div',{staticClass:"w-100 px-1 px-md-2 py-15 transition-opacity top-bar",class:{
      "fade-out": !_vm.quickView
    }},[_c('div',{staticClass:"row justify-content-between"},[_c('p',{staticClass:"text-uppercase roboto d-none d-md-block"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.currentIndex)}}),_vm._v(" "),_c('span',[_vm._v("/")]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.normalizedImages.length)}})]),_vm._v(" "),_c('arrow-link',{attrs:{"light":true,"text":"View full project","to":_vm.projectLink}}),_vm._v(" "),_c('nuxt-link',{staticClass:"text-uppercase roboto link",attrs:{"to":_vm.closeLink}},[_vm._v("Close")])],1)]),_vm._v(" "),(_vm.selectedProject)?_c('div',{ref:"mainWrapper",staticClass:"flex-grow-1 overflow-hidden main-wrapper d-none d-md-block media-wrapper",class:{
      "active": !_vm.quickView
    },style:({
      transform: ("scale(" + (_vm.scale.val) + ")")
    })},[_c('div',{ref:"wrapper",staticClass:"position-relative wrapper no-wrap z-2 h-100"},[_c('div',{ref:"innerWrapper",staticClass:"inner-wrapper overflow-hidden h-100 d-flex"},_vm._l((_vm.normalizedImages),function(image,index){return _c('div',{key:index,staticClass:"image-wrapper position-relative d-inline-block",class:image.featured ? "z-2" : "z-1"},[(image)?_c('parallax-image',{staticClass:"contain h-100 img",class:{
                "hidden": !image.featured && _vm.expanded
              },attrs:{"currentIndex":_vm.currentIndex,"isPrev":index == _vm.prevIndex,"isNext":index == _vm.nextIndex,"scale":_vm.scale,"quickView":_vm.quickView,"index":index,"featured":image.featured,"image":image,"scrollLeft":_vm.scrollLeft,"src":_vm.getImg(image, null, "?h=1080")},on:{"update:currentIndex":function($event){_vm.currentIndex=$event},"update:current-index":function($event){_vm.currentIndex=$event},"scaleTo":_vm.scaleTo,"scrollToLeft":_vm.scrollToLeft,"update:scale":function($event){_vm.scale=$event},"update:scrollLeft":function($event){_vm.scrollLeft=$event},"update:scroll-left":function($event){_vm.scrollLeft=$event}}}):_vm._e()],1)}),0)])]):(_vm.model.fields.featuredImage)?_c('custom-parallax',{staticClass:"full-absolute media-wrapper",class:{
      "active": !_vm.quickView
    },attrs:{"offset":0,"topOffset":.1,"speedFactor":.1,"scaleFactor":.1}},[_c('synchronized-picture-div',{attrs:{"fill":true,"loading":"eager","alt":_vm.model.fields.featuredImage.fields.title,"src":_vm.getImg(_vm.model, "featuredImage", "?w=760&h=1120&fit=fill"),"sources":[
          {
              'src': _vm.getImg(_vm.model, 'featuredImage', '?w=2250'),
              'srcx2': _vm.getImg(_vm.model, 'featuredImage', '?w=2400'),
              'width': 1921
          },

          {
              'src': _vm.getImg(_vm.model, 'featuredImage', '?w=1920'),
              'srcx2': _vm.getImg(_vm.model, 'featuredImage', '?w=2000'),
              'width': 1201
          },

          {
              'src': _vm.getImg(_vm.model, 'featuredImage', '?w=1250&h=1000&fit=fill'),
              'width': 768
          }
      ]}})],1):_vm._e(),_vm._v(" "),_c('custom-transition',[(_vm.quickView)?_c('div',{key:_vm.quickView,staticClass:"d-md-none px-1 overflow-auto mobile-stacked-images pt-2 bg-beige"},_vm._l((_vm.normalizedImages),function(image,index){return _c('div',{key:index,staticClass:"image-wrapper position-relative mb-2",class:image.featured ? "z-2" : "z-1"},[_c('p',{staticClass:"text-uppercase roboto color-olive mb-1"},[_c('span',{domProps:{"innerHTML":_vm._s(index + 1)}}),_vm._v(" "),_c('span',[_vm._v("/")]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.normalizedImages.length)}})]),_vm._v(" "),(image)?_c('image-div',{staticClass:"w-100 img",attrs:{"loading":"eager","image":image,"src":_vm.getImg(image, null, "?h=1080")}}):_vm._e()],1)}),0):_vm._e()]),_vm._v(" "),_c('custom-transition',[((_vm.model.fields.mobileFeaturedImage || _vm.model.fields.featuredImage) && !_vm.quickView)?_c('image-div',{key:_vm.quickView,staticClass:"full-absolute d-md-none media-wrapper active",attrs:{"loading":"eager","image":_vm.model.fields.mobileFeaturedImage || _vm.model.fields.featuredImage,"src":_vm.getImg(_vm.model, _vm.mobileImage, "?h=1080")}}):_vm._e()],1)],1),_vm._v(" "),_c('custom-transition',[(!_vm.quickView)?_c('div',{key:_vm.quickView,staticClass:"position-relative pt-6 z-1"},[(_vm.model.fields.selectedWork)?_c('div',{staticClass:"mb-1 d-flex flex-center t9 t5-md t7-xl should-animate",attrs:{"data-preset":"opacity,y","data-fromy":"50","data-delay":".35"}},[_c('span',{staticClass:"brackets"},[_vm._v("(")]),_vm._v(" "),_c('div',{staticClass:"text-uppercase text-center",domProps:{"innerHTML":_vm._s((_vm.$t('selected-works')))}}),_vm._v(" "),_c('span',{staticClass:"brackets"},[_vm._v(")")])]):_vm._e(),_vm._v(" "),_c('split-text',{attrs:{"visible":_vm.visibleWaypoint,"type":"lines","wrapped":false,"tag":"h1","innerClasses":{
        't7 t4-2-md headline t4-xl mb-7 mb-md-2 text-center px-1': _vm.model.fields.smallHeadlineFont,
        't3-1 t1-md headline t4-xl mb-7 mb-md-2 text-center px-1': !_vm.model.fields.smallHeadlineFont
      },"text":_vm.stripHtmlWithBreaks(_vm.model.fields.title)},on:{"splited":_vm.onSplited}}),_vm._v(" "),(!_vm.model.fields.selectedWork)?_c('div',{staticClass:"d-flex flex-column justify-content-center color-pale-silver"},_vm._l((_vm.categories),function(item,index){return _c('div',{key:index + '02',staticClass:"d-flex flex-column align-items-center mb-2 mb-xl-1 should-animate",attrs:{"data-preset":"opacity,y","data-fromy":"50","data-delay":.6 + index / 10}},[(_vm.model.fields[item])?_c('span',{staticClass:"opacity-8 t14 t12-md roboto",domProps:{"textContent":_vm._s(_vm.$t(item))}}):_vm._e(),_vm._v(" "),(_vm.model.fields[item])?_c('div',{staticClass:"d-flex align-items-center underline"},_vm._l((_vm.model.fields[item]),function(text,i){return _c('span',{key:i + '03'},[(i != 0)?_c('span',{staticClass:"mx-025 mx-md-05"},[_vm._v("/")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"t17 t11-md t16-xl roboto",domProps:{"textContent":_vm._s(text)}})])}),0):_vm._e()])}),0):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('custom-transition',[(!_vm.quickView)?_c('scrolling-indicator',{key:_vm.quickView,staticClass:"position-absolute b-0 center-x d-none d-md-block",attrs:{"text":"Scroll Down","caption":"For more"},on:{"onClick":_vm.scrollTo}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }