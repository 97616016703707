import storeModules from '@/store/modules'
import dynamicRoutes from '@/routes.json'
import _includes from 'lodash/includes'

// ACTIONS
export const actions = {
  async nuxtServerInit ({ dispatch, commit, state }, { req }) {

    const acceptHeader = req.headers.accept
    dispatch('app/setStateApp', {
      webp: _includes(acceptHeader, 'webp'),
      width: this.$device.isMobile ? 320 : 1920
    })

    dispatch('pages/SET_STATE', {
      urls: dynamicRoutes
    })

    const route = state.route.path
    const locale = this.$contentful.getLocale(route)

    return Promise.all([
      dispatch('pages/LOAD_TRANSLATIONS', locale), 
      dispatch('pages/LOAD_NAVIGATION', locale),
      dispatch('pages/LOAD_FOOTER', locale),
      dispatch('pages/LOAD_PROJECTS', locale)
    ]).then((values) => values)
  }
}

// MODULES
export const modules = storeModules