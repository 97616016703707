<template>
  <synchronized-waypoint
  @toggleOneDirectionVisible="toggleWaypoint" 
  class="service-footer bg-light-olive color-light-grey glossy text-uppercase d-flex flex-column flex-center text-center pb-1 px-075 pt-2 pt-md-7">
    <h2
    class="t8 t6-md t6-xl opacity-3 mb-1 mb-md-2 should-animate color-beige opacity-03"
    data-preset='opacity,y'
    data-fromy='50'
    data-delay='.1'
    data-toopacity='.3'
    v-html="$t('other-services')" />

    <h2
    v-for="(link, index) in items"
    :key="index"
    class="t8 t6-md t6-xl mb-075 link d-flex flex-column flex-center should-animate mb-1 mb-md-2 mb-xl-1"
    data-preset='opacity,y'
    data-fromy='50'
    :data-delay='.1 + index / 10'
    :style="{color: link.fields.color, opacity: link.fields.opacity}">
      <span class="t14 t12-md t17-1-xl roboto mb-025">0{{ index + 1 }}</span>

      <nuxt-link
      :to="link.url"
      v-html="link.fields.title" />
    </h2>
  </synchronized-waypoint>
</template>

<script>
import WaypointTrigger from "@/mixins/WaypointTrigger";

export default {
  name: "service-footer",

  props: ["items"],

  mixins: [WaypointTrigger]
};
</script>

<style lang="scss" scoped="">
.service-footer {
  .opacity-03{
    opacity: 0.3;
  }

  .small-text {
    font-size: 12px;

    @include media-breakpoint-up(xl){
      font-size: .77rem;
    }
  }
}
</style>