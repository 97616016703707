<template>
<transition v-on:enter="enter"
            v-on:leave="leave"
            :mode='mode'
            v-bind:css="false">
   <slot></slot>
</transition>

</template>

<script>
import { gsap } from 'gsap'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'menuTransition',

    computed: {
    },

    props: {
        
    },

    methods: {
         ...mapActions('app', [
            'setStateApp'
        ]),

        enter(el, onComplete)  {
            this.tl = gsap.timeline({
                onComplete
            })

            this.tl.fromTo(el, 1, {
                yPercent: 100
            }, {
                yPercent: 1
            })
        },

        leave(el, onComplete)  {
            gsap.to(el, 1, {
                yPercent: 100,
                onComplete
            })

        }
    }
}

</script>