<template>
<div class="synchronized-scroll" 
:class='{
    "no-scrollbar" : noScrollbar,
    "mobile-version": isMobile
}'>
    <div ref='scroller' class='scroller'>
        <slot></slot>
    </div>
</div>
</template>

<script>
import _isNumber from 'lodash/isNumber'
import smoothscroll from 'smoothscroll-polyfill'
// import Scrollbar from '@/plugins/scripts/smooth-scrollbar'
import Scrollbar from 'smooth-scrollbar'

import { mapActions } from 'vuex'
import { ScrollingObservable } from "@/observables/ScrollingObservable.js"
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'
// import ResizeHandler from '@/mixins/ResizeHandler'

class EdgeEasingPlugin extends Scrollbar.ScrollbarPlugin {
    constructor() {
        super(...arguments)
        this._remainMomentum = {
            x: 0,
            y: 0,
        }

        this.options.disabled = false
    }
    transformDelta(delta) {

        if(this.options.disabled){
            this.scrollbar.setMomentum(0, 0)
            return { x: 0, y: 0 }
        }

        const { limit, offset, } = this.scrollbar;

        const x = this._remainMomentum.x + delta.x + (this.options.horizontal ? delta.y : 0);

        const y = this._remainMomentum.y + delta.y;


        // clamps momentum within [-offset, limit - offset]
        this.scrollbar.setMomentum(
            Math.max(-offset.x, Math.min(x, limit.x - offset.x)), 
            Math.max(-offset.y, Math.min(y, limit.y - offset.y))
        )

        return { x: 0, y: 0 }
    }
    onRender(remainMomentum) {
        Object.assign(this._remainMomentum, remainMomentum)
    }
}
EdgeEasingPlugin.pluginName = 'edgeEasing'


export default {
    name: 'synchronized-scroll',

    props: {
        customOptions: {
            type: Object,
            default: () => ({
                damping: 0.1,
                renderByPixels: true,
                alwaysShowTracks: false,
                keyDownDisabled: false
            })
        },
        preventEvents: Boolean,
        noScrollbar: Boolean,
        forceEvents: Boolean,
        disabled: Boolean,
        startTop: Number,
        isMobile: Boolean,
        horizontal: Boolean
    },

    // mixins: [ResizeHandler],

    beforeDestroy()  {
        if (this.scrollbar) {
            this.scrollbar.removeListener()
        }

        window.removeEventListener('scroll', this.onScroll)
        window.removeEventListener('keydown', this.onKeyDown)
        window.removeEventListener('scroll', this.onScroll)

        this.$bus.$off('scrollTo')
        this.$bus.$off('updateScrollbar', this.updateScrollbar)
        this.$bus.$off('enableScrollbar', this.enable)
        this.$bus.$off('disableScrollbar', this.disable)
        this.$bus.$off('enableKeydown', this.enableKeyDown)
        this.$bus.$off('disableKeydown', this.disableKeyDown)
    },

    mounted()  {
        smoothscroll.polyfill()
        window.addEventListener('keydown', this.onKeyDown)
        this.initSmooth(this.$el)
    },

    watch: {
        disabled(d) {
            this[d ? 'disable' : 'enable']()
        },

        startTop(st){
            this.scrollbar && this.scrollbar.scrollTo(0, st, 0)
        },

        horizontal() {
            this.resetValues()
        }
    },

    methods: {
        ...mapActions('app', [
            'setStateApp'
        ]),

        resetValues() {
            ScrollingObservable.scrollTop = 0
            ScrollingObservable.scrollingDown = false
            ScrollingObservable.scrollingStarted = false
            ScrollingObservable.passedViewport = false
        },

        // resizeHandler() {
        //   document.documentElement.style.height = `${this.$refs.scroller.offsetHeight}px`
        // },

        onKeyDown(e)  {
            if(this.disabled || this.keyDownDisabled || !this.$bus){
                return
            }

            //up
            if(e.shiftKey && e.keyCode == 32){
                this.$bus.$emit('scrollTo', ScrollingObservable.scrollTop - 300)
            } else if (e.keyCode === 38) {
                this.$bus.$emit('scrollTo', ScrollingObservable.scrollTop - 150)
            }
            // down
            else if(e.keyCode == 32){
                this.$bus.$emit('scrollTo', ScrollingObservable.scrollTop + 300)
            } else if(e.keyCode === 40){
                this.$bus.$emit('scrollTo', ScrollingObservable.scrollTop + 150)
            }
        },

        enableKeyDown() {
            this.keyDownDisabled = false
        },

        disableKeyDown(){
            this.keyDownDisabled = true
        },

        updateScrollbar() {
            this.scrollbar && this.scrollbar.update()
        },

        enable() {
            if(!this.scrollbar){
                return
            }

            this.scrollbar.updatePluginOptions('edgeEasing', { disabled: false })
        },

        disable() {
            if(!this.scrollbar){
                return
            }

            this.scrollbar.updatePluginOptions('edgeEasing', { disabled: true })
        },

        onScroll()  {
            if(this.disabled) {
                return
            }

            ScrollingObservable.scrollingDown = ScrollingObservable.scrollTop < window.scrollY
            ScrollingObservable.scrollTop = window.scrollY
            ScrollingObservable.scrollingStarted = window.scrollY > 10
            ScrollingObservable.passedViewport = window.scrollY > window.innerHeight - 50
        },

        onSmoothScroll(status) {
            if(this.disabled) {
                return
            }

            // gsap.ticker.add(() => {
                ScrollingObservable.scrollingDown = ScrollingObservable.scrollTop < status.offset.y
                ScrollingObservable.scrollingLeft =  ScrollingObservable.scrollLeft < status.offset.x

                // console.log("scrolling left", ScrollingObservable.scrollLeft)

                ScrollingObservable.scrollLeft = status.offset.x
                ScrollingObservable.scrollTop = status.offset.y
                ScrollingObservable.scrollingStarted = status.offset.y > 10
                ScrollingObservable.passedViewport = status.offset.y > window.innerHeight - 50
            // }, true, true)



            // console.log("STOP", this.$refs.scroller.scrollTop)
            // this.$refs.scroller.scrollTop = status.offset.y

            // window.scrollTo({
            //   top: status.offset.y,
            //   left: 0,
            //   behavior: 'auto'
            // })

            // this.$refs.scroller.scrollTop = status.offset.y
            // ScrollTrigger.update()
        },

        initNonSmooth(){
            window.removeEventListener('scroll', this.onScroll)
            window.addEventListener('scroll', this.onScroll)

            if(!this.$bus){
                return
            }

            this.$bus.$on('scrollTo', (scrollTop, immediate) => {
                window.scrollTo({
                    top: scrollTop,
                    behavior: immediate ? 'auto' : 'smooth'
                })

                ScrollingObservable.scrollTop = scrollTop
            })
        },

        initSmooth(el)  {
            Scrollbar.use(EdgeEasingPlugin)

            if(this.scrollbar){
                this.scrollbar.removeListener()
                return
            }
            
            if (this.isMobile || this.noSmooth) {
                return this.initNonSmooth()
            }

            this.scrollbar = Scrollbar.init(el, this.customOptions)
            this.scrollbar.addListener(this.onSmoothScroll)

            this.scrollbar.updatePluginOptions('edgeEasing', { horizontal: this.horizontal })

            // gsap.registerPlugin(ScrollTrigger)
            // ScrollTrigger.scrollerProxy('.scroller', {
            //   scrollTop(value) {
            //     console.log("loglje")
            //     if (arguments.length) {
            //       this.scrollbar.scrollTop = value;
            //     }
            //     return this.scrollbar.scrollTop
            //   }
            // })
            // ScrollTrigger.defaults({
            //   scroller: ".scroller"
            // })
            // this.scrollbar.addListener(ScrollTrigger.update)

            // set starting top offset
            if(this.startTop){
                this.scrollbar.setPosition(0, this.startTop)
                // this.updateScroll(this.startTop)
            }

            // disable / enable on start
            this[this.disabled ? 'disable' : 'enable']()
            

            if (this.preventEvents || !this.$bus) {
                return
            }
            
            this.$bus.$on('updateScrollbar', this.updateScrollbar)
            this.$bus.$on('enableScrollbar', this.enable)
            this.$bus.$on('disableScrollbar', this.disable)
            this.$bus.$on('enableKeydown', this.enableKeyDown)
            this.$bus.$on('disableKeydown', this.disableKeyDown)
            this.$bus.$on('scrollTo', this.onScrollTo)

        },

        onScrollTo(scrollTop, dur, onComplete) {
            let duration = _isNumber(dur) ? dur : 600

            this.scrollbar.scrollTo(0, scrollTop, duration, { 
                callback: () => {
                    onComplete && onComplete()
                } 
            })

            if(duration == 0){
                // this.updateScroll(scrollTop)
            }
        }
    }
}
</script>

<style>
html,
body {
  height: 100%;
  width: 100%;
  /*overflow: hidden;*/
  /*scroll-behavior: auto!important;*/
}

.synchronized-scroll {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

}
.synchronized-scroll:not(.mobile-version){
    position: absolute!important;
    height:  100vh;
}
</style>