<template>
  <synchronized-waypoint
  @toggleOneDirectionVisible="toggleWaypoint" 
  class="two-images d-flex flex-center"
  :class="{
    'bg-dark-grey': model.fields.darkBackground,
    'pt-md-1 pt-lg-2': model.fields.paddingTop,
    'pb-075 pb-md-1 pb-lg-2': model.fields.paddingBottom,
  }">

    <div 
    class="row w-100" 
    :class="{'col-11 col-md-7 col-xl-5 px-075 px-md-0 custom-padding': model.fields.smallImages}">

        <div 
        :class="[
        getElementClasses(index),
        {'pt-075 pt-md-0 mb-075 mb-md-0': index == 0 || model.fields.smallImages}
        ]"
        v-for='(image, index) in images'
        :key='index'
        class="position-relative">
          <div
          v-if='image'
          class='image-wrapper w-100 h-100 overflow-hidden'
          :class="{'mix-multiply': model.fields.multiply}">
            <synchronized-picture-div
            :image="model.fields[getImgName(index)]"
            :proportion="imageProportion"
            class="should-animate"
            data-preset="scale"
            data-dur="1.4"
            data-fromscale='1.1'
            data-toscale='1'
            :src="image"
            :sources="[
              {
                'src': getImg(model, getImgName(index), `?w=${1200 * imageRatio}`, 90),
                'srcx2': getImg(model, getImgName(index), `?w=${1400 * imageRatio}`, 90),
                'width': 1921
              },

              {
                'src': getImg(model, getImgName(index), `?w=${960 * imageRatio}`),
                'srcx2': getImg(model, getImgName(index), `?w=${1100 * imageRatio}`),
                'width': 1200
              },

              {
                'src': getImg(model, getImgName(index), `?w=${500 * imageRatio}`),
                'width': 768
              }
            ]" />

          </div>
        </div>
      </div>

    </div>

  </synchronized-waypoint>
</template>

<script>
import { mapState } from 'vuex'
import WaypointTrigger from "@/mixins/WaypointTrigger"
import _includes from 'lodash/includes'

export default {
  name: "two-images",

  components: {},

  props: ["model"],

  mixins: [WaypointTrigger],

  methods: {
      isFramed(index) {
        return _includes(this.framedImages, index)
      },

      getElementClasses(index) {
        return {
          'col-md-6 px-075 px-md-05': !this.model.fields.smallImages,
          'col-6 px-025 px-md-075': this.model.fields.smallImages,
          [`framed framed--${index + 1}`]: this.isFramed(index + 1)
        }
      },

      getImgName(index) {
        return index == 0 ? 'firstImage' : 'secondImage'
      }
  },

  mounted() {
    console.log("AA", this.model, this.model.fields.paddingBottom)
  },

  computed: {
    ...mapState('app', [
      'width'
    ]),

    imageRatio() {
      return this.model.fields.smallImages ? .5 : 1
    },

    framedImages() {
      let images = this.model.fields.framed ? Object.values(this.model.fields.framed) : []
      return images.map(i => parseInt(i))
    },

    images() {
      return [
        this.getImg(this.model, 'firstImage', '?w=768'),
        this.getImg(this.model, 'secondImage', '?w=768')
      ]
    },

    imageProportion() {
      return !this.model.fields.smallImages ? 1.3 // own proportion
      : this.width <= 767 ? 1.44 // mobile
      : this.width <= 1200 ? 1.382 // tablet
      : 1.36 // desktop
    }
  }
};
</script>

<style lang="scss" scoped="">
/*! purgecss start ignore */
.two-images {
  @include media-breakpoint-up(md) {
    margin: 0 -.5rem;
  }

  .framed{
    .image-wrapper{
      border: 1px solid $black;
    }

    & /deep/ img{
      transform: scale(1.02);
    }

    @include media-breakpoint-up(md) {
      &--1{
        padding-left: 1.5rem!important;
      }

      &--2{
        padding-right: 1.5rem!important;
      }  
    }  
  }

  .first-image {
    @include media-breakpoint-down(sm) {
      margin-bottom: 12px;
    }
  }

  .custom-padding {
    margin: 8.5rem 0;

    @include media-breakpoint-up(md) {
      margin: 13rem 0;
    }

    @include media-breakpoint-up(xl) {
      margin: 12.2rem 0;
    }
  }
}
/*! purgecss end ignore */
</style>