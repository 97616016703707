<template>
  <div class="links-filter color-gray-second">

    <div 
    v-if='showField'
    class="w-100 filter-wrapper mb-1 d-xl-none "
    :class="{'second-filter': secondFilter}">
      <p 
      v-html='$t(field)'
      data-preset='opacity,x'
      :data-delay='.1'
      class='text-uppercase lh-1 should-animate2 filter-field col-xl-1 pb-1 px-1'
      :class="{
        'mr-xl-175': !reverse, 
        'ml-xl-175 mr-xl-0 justify-content-xl-end': reverse
      }" />
    </div>
  
    <div class='d-flex flex-column flex-xl-row flex-xl-wrap col-9 col-xl py-xl-1 justify-content-xl-center px-1'>

      <div
      v-if='showAll'
      class='position-relative d-inline-flex text-capitalize filter-link no-wrap should-animate2 mb-1 mr-lg-175 mr-1 col-xl'
      data-preset='opacity,x'
      :data-delay='.2'>
        <nuxt-link 
        class="inner-link position-relative tiny lh-1 text-uppercase link"
        :to='getFilterUrl(null, field)'
        :class='{
          "active" : !selectedOptions.length
        }'>
          <span
          class='tiny lh-1'
          v-html="$t('all')" />

          <sup 
          v-if='showCount'
          class="projects-number position-absolute l-100 t17"
          v-html='collection.length'
           />
        </nuxt-link>
      </div>


      <!-- OPTIONS -->
      <div
      v-for='(option, index) in sortedOptions'
      :key='index'
      data-preset='opacity,x'
      :data-delay='.3 + index / 30'
      class='position-relative d-inline-flex d-xl-flex text-capitalize filter-link no-wrap should-animate2 mx-md-1 mb-1 mb-xl-0'
      :class='{
      }'>
          <!-- :is='hasOptions(option) ? "nuxt-link" : "div"'  -->
          <component
          :is='isLink(option) ? "nuxt-link" : "div"'
          :class='{
            "active" : isActive(option),
            "link": !!isLink(option)
          }'
          class="inner-link position-relative tiny lh-1 text-uppercase" 
          :to='getFilterUrl(option, field)'>
            <span class='tiny mb-0 lh-1' v-html='option' />

            <sup 
            v-if='showCount'
            class="projects-number position-absolute l-100 t17"
            v-html='getNumberOf(field, option)'
             />
          </component>
      </div>
      <!-- END :: OPTIONS -->

    </div>
  </div>
</template>

<script>
import FilterMixin from '@/mixins/filters'
import { mapState, mapActions } from 'vuex'
import _extend from 'lodash/extend'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _includes from 'lodash/includes'
import _orderBy from 'lodash/orderBy'
import _indexOf from 'lodash/indexOf'

export default {
  name: "links-filter",

  props: {
    showAll: false,

    showCount: {
      default: true,
      type: Boolean
    },

    showField: {
      default: true,
      type: Boolean
    },

    reverse: {
      type: Boolean
    },

    selectedFilters: {
      type: Object
    },

    orderedOptions: {
      type: [Array, Object]
    },

    secondFilter: {
      type: Boolean
    }
  },

  mixins: [
    FilterMixin,
  ],

  watch: {
    selectedOptions(opts) {
      this.updateSelectedFilters()
    }
  },

  mounted() {
    // console.log("orderedOptions:", this.orderedOptions)
  },

  methods: {
    updateSelectedFilters() {
      let selected = _extend({}, this.selectedFilters)
      selected[this.field] = _find(this.enabledOptions, (opt) => opt.sanitize() == this.selectedOptions[0])
      this.$emit('update:selectedFilters', selected)
    }
  },

  created() {
    this.updateSelectedFilters()
  },

  computed: {
    sortedOptions() {
      let orderedOptions = _orderBy(this.options, opt => _indexOf(this.orderedOptions, opt))
      return _filter(orderedOptions, opt => _includes(this.orderedOptions, opt))
    }
  }
}
</script>

<style lang="scss" scoped="">
  .links-filter{
      width: 100%;

      @include media-breakpoint-up(xl) {
        width: 20em;        
      }
  }

  .second-filter {
    border-top: 1px solid #8B897F4D;
    padding-top: $s1;
  }

  .projects-number{
    opacity: 0;
    transform: translateY(100%);
    transition: opacity .4s, transform .5s;
  }

  .filter-field{
    width: 3.25em;

    @include media-breakpoint-up(xl) {
      width: 7em;
    }
  }

  .filter-wrapper {
    border-bottom: 1px solid #8B897F4D;
  }

  .inner-link {
      display: flex;
      color: rgba($blackv2, 0.4);

      &.active{
        color: $black;

        .projects-number{
          opacity: 1;
          transform: translateY(0%);
        }
      }
  }

  div.inner-link{
    opacity: 0.4;
  }
</style>