var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('synchronized-waypoint',{staticClass:"more-work row position-relative bg-light-olive pb-2 pb-md-4",on:{"toggleOneDirectionVisible":_vm.toggleWaypoint}},[_c('div',{staticClass:"col-md-6 order-1 order-md-0"},[_c('nuxt-link',{attrs:{"to":_vm.items[0].url}},[_c('div',{staticClass:"image-wrapper image-wrapper-1"},[_c('div',{staticClass:"overflow-hidden"},[_c('synchronized-proportion-div',{attrs:{"proportion":{
            mobile: 1.123,
            md: 1.355,
            xl: 1.233
          }}},[_c('custom-parallax',{staticClass:"w-100 h-100 image-wrapper should-animate2",attrs:{"wrapped":true,"data-preset":"scale","data-dur":"1.5","data-fromscale":"1.1","data-toscale":"1","absolute":true,"speedFactor":.1,"scaleFactor":.1}},[_c('synchronized-picture-div',{staticClass:"position-relative image w-100 h-100",attrs:{"fill":true,"image":_vm.items[0].fields['relatedImage', 'featuredImage'],"src":_vm.getImg(_vm.items[0], ["relatedImage", "featuredImage"], "?w=1400"),"sources":[
                  {
                      'src': _vm.getImg(_vm.items[0], ['relatedImage', 'featuredImage'], '?w=1400'),
                      'srcx2':  _vm.getImg(_vm.items[0], ['relatedImage', 'featuredImage'], '?w=1400'),
                      'width': 1921
                  },

                  {
                      'src': _vm.getImg(_vm.items[0], ['relatedImage', 'featuredImage'], '?w=1400'),
                      'srcx2': _vm.getImg(_vm.items[0], ['relatedImage', 'featuredImage'], '?w=1400'),
                      'width': 1201
                  },

                  {
                      'src': _vm.getImg(_vm.items[0], ['relatedImage', 'featuredImage'], '?w=1400'),
                      'width': 768
                  }
                  ]}})],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"image-wrapper-1-caption t14 t15-md t14-xl float-md-right pt-025 pt-md-05 pr-05 roboto text-uppercase"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.stripHtml(_vm.items[0].fields.title))}}),_vm._v(" "),(_vm.items[0].fields.client)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.items[0].fields.client)}}):_vm._e()])])])],1),_vm._v(" "),_c('div',{staticClass:"col-md-6 bg-heavy-metal"},[_c('nuxt-link',{attrs:{"to":_vm.items[1].url}},[_c('div',{staticClass:"image-wrapper image-wrapper-2 px-075 px-md-0 mt-6 mt-md-0 overflow-hidden bg-heavy-metal"},[_c('div',[_c('synchronized-proportion-div',{staticClass:"mix-overlay",attrs:{"proportion":{
            mobile: 1.123,
            md: 1.355,
            xl: 1.233
          }}},[_c('custom-parallax',{staticClass:"w-100 h-100 image-wrapper should-animate2",attrs:{"wrapped":true,"data-preset":"scale","data-dur":"1.5","data-fromscale":"1.1","data-toscale":"1","absolute":true,"speedFactor":.1,"scaleFactor":.1}},[(_vm.getImg(_vm.items[1], ["relatedImage", "featuredImage"], "?w=1400"))?_c('synchronized-picture-div',{staticClass:"position-relative w-100 h-100 image",attrs:{"fill":true,"image":_vm.items[1].fields['relatedImage', 'featuredImage'],"src":_vm.getImg(_vm.items[1], ["relatedImage", "featuredImage"], "?w=1400"),"sources":[
                    {
                        'src': _vm.getImg(_vm.items[1], ['relatedImage', 'featuredImage'], '?w=1400'),
                        'srcx2':  _vm.getImg(_vm.items[1], ['relatedImage', 'featuredImage'], '?w=1400'),
                        'width': 1921
                    },

                    {
                        'src': _vm.getImg(_vm.items[1], ['relatedImage', 'featuredImage'], '?w=1400'),
                        'srcx2': _vm.getImg(_vm.items[1], ['relatedImage', 'featuredImage'], '?w=1400'),
                        'width': 1201
                    },

                    {
                        'src': _vm.getImg(_vm.items[1], ['relatedImage', 'featuredImage'], '?w=1400'),
                        'width': 768
                    }
                    ]}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"caption-wrapper t14 t15-md t14-xl pt-025 pt-md-0 roboto text-uppercase"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.stripHtml(_vm.items[1].fields.title))}}),_vm._v(" "),(_vm.items[1].fields.client)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.items[1].fields.client)}}):_vm._e()])],1)])]),_vm._v(" "),_c('div',{staticClass:"text-wrapper bg-heavy-metal text-uppercase t5-xl glossy color-grey-text py-2 p-md-1"},[(_vm.width > 768)?_c('div',{staticClass:"pb-xl-1 pt-xl-5 pr-xl-2",domProps:{"innerHTML":_vm._s((_vm.$t('more-work-text')))}}):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"more-wrapper text-uppercase d-flex flex-column justify-content-between py-1 py-xl-2 px-md-2 px-xl-3 order-0 order-md-2 should-animate",attrs:{"data-preset":"y","data-fromy":"200","data-delay":".1"}},[_c('div'),_vm._v(" "),_c('nuxt-link',{staticClass:"link-item",attrs:{"to":_vm.navigation.fields.moreWorkPage.url}},[_c('p',{staticClass:"t9 t7-md t7-xl text-center glossy",domProps:{"innerHTML":_vm._s((_vm.$t('more-our-work')))}})]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-center"},[_c('nuxt-link',{staticClass:"t10-xl link reversed text-center d-none d-md-block work",attrs:{"to":_vm.navigation.fields.moreWorkPage.url},domProps:{"textContent":_vm._s('view more')}})],1)],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-center text-uppercase d-md-none order-3 w-100 mt-3"},[_c('nuxt-link',{staticClass:"t13 t13-mdt10-xl link reversed text-center",attrs:{"to":_vm.navigation.fields.moreWorkPage.url},domProps:{"textContent":_vm._s('view more')}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }