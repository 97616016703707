<template>
  <div class='page'>
    <!-- SCROLLING SKELETON -->
    <smooth-scroll 
    :disabled='disabled'
    class="h-100"
    :class="innerClass"
    :isMobile='$device.isMobileOrTablet'
    :scrollTop.sync='currentScroll'
    :pageId='pageId'>

      <div class='overflow-hidden position-relative'>

        <!-- COMPONENTS -->
        <slot />
        <!-- END :: COMPONENTS -->

      </div>

    </smooth-scroll>
    <!-- END :: SCROLLING SKELETON -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { gsap } from 'gsap'
import _get from 'lodash/get'

export default {
  name: "scrolling-page",

  data: () => ({
    currentScroll: 0,
  }),

  props: ["pageId", "innerClass", "darkNav", "disabled"],
  
  reactiveProvide: {
      name: 'scrollingProps',
      include: ['currentScroll']
  },

  components: {},

  // watch: {
  //   currentScroll(scrollTop, oldTop) {
  //     let scrollStart = scrollTop > 10

  //     let passedViewport = _get(this.activePage, 'fields.darkNav') || scrollTop > this.height - 50
      
  //     this.setStateApp({
  //       scrollStart,
  //       passedViewport,
  //       scrolled: oldTop < scrollTop && scrollStart
  //     })
  //   },
  // },

  methods: {
    ...mapActions('app', [
      'setStateApp'
    ])
  },

  computed: {
    ...mapState('app', [
      'height'
    ]),

    ...mapState('pages', [
      'activePage'
    ]),
  },

  mounted() {
    this.setStateApp({
      scrollStart: false,
      scrolled: false
    })
  }
}
</script>