import { render, staticRenderFns } from "./MoreWork.vue?vue&type=template&id=49676201&scoped=true&"
import script from "./MoreWork.vue?vue&type=script&lang=js&"
export * from "./MoreWork.vue?vue&type=script&lang=js&"
import style0 from "./MoreWork.vue?vue&type=style&index=0&id=49676201&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49676201",
  null
  
)

export default component.exports