<template>
  <div class="fashion-services position-relative glossy text-uppercase pt-2 pt-md-7 pb-1 pb-md-2">

    <!-- HEADER -->
    <services-header 
    :originalDecor="true"/>
    <!-- END :: HEADER -->

    <!-- GALLERY -->
    <div class="px-md-1 px-xl-2">
        <!-- IMAGE AND 2 TEXTS -->
        <div class="d-flex pr-md-075 mb-1 mb-xl-0">
          <synchronized-waypoint
          @toggleOneDirectionVisible="toggleWaypoint"
          class="custom-col-1 d-none d-md-block">
            <div 
            data-preset='opacity,y'
            :data-delay='.1'
            class="position-relative image-1 ml-auto t9 t7-md t7-xl mb-md-1 d-none d-md-block overflow-hidden should-animate">
              <proportion-div
              :proportion="1.35">
                <custom-parallax 
                :wrapped='true'
                class='w-100 h-100'
                :absolute='true' 
                :speedFactor='.1' 
                :scaleFactor='.04'>
                  <component 
                  :is='model.fields.relatedProjects && model.fields.relatedProjects[0] ? "nuxt-link" : "div"'
                  :to="model.fields.relatedProjects && model.fields.relatedProjects[0] ? model.fields.relatedProjects[0].url : ''"
                  class="image-link d-block h-100">
                    <synchronized-picture-div
                    :fill="true"
                    :src='getImg(model.fields.gallery[0], "gallery[0]", "?w=675")'
                    :sources="[
                        {
                            'src': getImg(model.fields.gallery[0], 'gallery[0]', '?w=800'),
                            'srcx2': getImg(model.fields.gallery[0], 'gallery[0]', '?w=1000'),
                            'width': 1921
                        },

                        {
                            'src': getImg(model.fields.gallery[0], 'gallery[0]', '?w=600'),
                            'srcx2': getImg(model.fields.gallery[0], 'gallery[0]', '?w=700'),
                            'width': 1201
                        },

                        {
                            'src': getImg(model.fields.gallery[0], 'gallery[0]', '?w=550'),
                            'width': 768
                        }
                        ]"
                    :alt="gallery[0].fields.title" />
                  </component>    
                </custom-parallax>
              </proportion-div>
            </div>

            <div 
            data-preset='opacity,y'
            :data-delay='.2'
            class="position-relative w-100 mix-multiply col-md-8 col-xl-6 ml-auto d-none d-md-block pl-md-1 pl-lg-0 should-animate">
              <proportion-div
              :proportion="1.29">
                <custom-parallax 
                :wrapped='true'
                class='w-100 h-100'
                :absolute='true' 
                :speedFactor='.1' 
                :scaleFactor='.04'>
                  <component
                  :is='model.fields.relatedProjects && model.fields.relatedProjects[2] ? "nuxt-link" : "div"'
                  :to="model.fields.relatedProjects && model.fields.relatedProjects[2] ? model.fields.relatedProjects[2].url : ''"
                  class="image-link d-block h-100">
                    <synchronized-picture-div
                    :fill="true"
                    :src='getImg(model.fields.gallery[2], "gallery", "?w=675")'
                    :sources="[
                        {
                            'src': getImg(model.fields.gallery[2], 'gallery', '?w=700'),
                            'srcx2': getImg(model.fields.gallery[2], 'gallery', '?w=1700'),
                            'width': 1921
                        },

                        {
                            'src': getImg(model.fields.gallery[2], 'gallery', '?w=535'),
                            'srcx2': getImg(model.fields.gallery[2], 'gallery', '?w=1535'),
                            'width': 1201
                        },

                        {
                            'src': getImg(model.fields.gallery[2], 'gallery', '?w=517'),
                            'width': 768
                        }
                        ]"
                    :alt="gallery[2].fields.title" />
                  </component>
                </custom-parallax>
              </proportion-div>
            </div>

          </synchronized-waypoint>

        <div class="custom-col-2 d-flex flex-column justify-content-between px-075 pl-md-1 ml-xl-075">

            <synchronized-waypoint
            @toggleOneDirectionVisible="toggleWaypoint"
            class="position-relative t9 t7-md t7-xl mb-1 text-center text-md-left">
              <p class="t18 t15-md t14-xl work should-animate"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.1'>01</p>

              <div class="should-animate"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.2'>
                <div 
                v-html="headlines[0]"
                class="t9 t7-md t7-xl d-md-inline" />
              </div>
            </synchronized-waypoint>

            <synchronized-waypoint
            @toggleOneDirectionVisible="toggleWaypoint">
              <div
              class="position-relative mb-1 text-center text-md-left">
                <p 
                class="small-number work t18 t15-md t14-xl should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.1'>02</p>

                <div class="should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.2'>
                  <div 
                  v-html="headlines[1]"
                  class="t9 t7-md t7-xl d-md-inline" />
                </div>
              </div>

              <div 
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.2'
              class="position-relative w-100 mix-multiply mb-1 d-none d-md-block should-animate">
                  <div class="col-md-8 col-xl-6 pr-md-1 overflow-hidden">
                    <proportion-div
                    :proportion="1.33" >
                      <custom-parallax 
                      :wrapped='true'
                      class='w-100 h-100'
                      :absolute='true' 
                      :speedFactor='.1' 
                      :scaleFactor='.04'>
                        <component
                        :is='model.fields.relatedProjects && model.fields.relatedProjects[1] ? "nuxt-link" : "div"'
                        :to="model.fields.relatedProjects && model.fields.relatedProjects[1] ? model.fields.relatedProjects[1].url : ''"
                        class="image-link d-block h-100">

                          <synchronized-picture-div
                          :fill="true"
                          :src='getImg(model.fields.gallery[1], "gallery", "?w=675")'
                          :sources="[
                              {
                                  'src': getImg(model.fields.gallery[1], 'gallery', '?w=800'),
                                  'srcx2': getImg(model.fields.gallery[1], 'gallery', '?w=1800'),
                                  'width': 1921
                              },

                              {
                                  'src': getImg(model.fields.gallery[1], 'gallery', '?w=600'),
                                  'srcx2': getImg(model.fields.gallery[1], 'gallery', '?w=1600'),
                                  'width': 1201
                              },

                              {
                                  'src': getImg(model.fields.gallery[1], 'gallery', '?w=546'),
                                  'width': 768
                              }
                              ]"
                          :alt="gallery[1].fields.title" />
                        </component>
                      </custom-parallax>
                    </proportion-div>
                  </div>
              </div>

              <div
              class="position-relative mb-1 text-center text-md-left">
                <p class="small-number t18 t15-md t14-xl should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.1'>03</p>
                <div class="should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.2'>
                  <div 
                  v-html="headlines[2]"
                  class="t9 t7-md t7-xl d-md-inline" />
                </div>
              </div>
            </synchronized-waypoint>

            <synchronized-waypoint
            @toggleOneDirectionVisible="toggleWaypoint"
            class="position-relative text-center text-md-left t9 t7-md t7-xl top-2em">
                <p 
                class="small-number t18 t15-md t14-xl should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.1'>04</p>

                <div 
                class="should-animate mb-1 mb-md-0"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.1'>
                  <div 
                  v-html="headlines[3]"
                  class="t9 t7-md t7-xl d-md-inline" />
                </div>

                <div class="d-md-none position-relative d-flex flex-column  align-items-center mb-1 text-center should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.2'>
                    <p class="small-number left t18 t15-md t14-xl mt-xl-05 mr-05">06</p>

                    <div 
                    v-html="headlines[5]"
                    class="t9 t7-md t7-xl lh-07-xl" />

                    <div 
                    v-html="headlines[6]"
                    class="t9 t7-md t7-xl pl-md-5 pl-xl-7" />
                </div>

                <div 
                class="d-md-none text-center mb-1 d-md-none should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.3'>
                    <p 
                    class="small-number t18 t15-md t14-xl mt-xl-05 mr-05">07</p>

                    <div 
                    v-html="headlines[7]"
                    class="t9 t7-md t7-xl" />
                </div>

                <!-- SMALL LINK -->
                <div 
                class="should-animate d-flex justify-content-center d-md-none mb-1 mt-2"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.1'>
                    <nuxt-link
                    to="/"
                    class="t13 t9-md t10-xl work link reversed mb-md-025"
                    v-text="'get in touch'" />
                </div>
                <!-- END :: SMALL LINK -->
            </synchronized-waypoint> 
          </div>

        </div>
        <!-- END :: IMAGE AND 2 TEXTS -->

        <!-- IMAGE AND THREE HEADLINES -->
        <synchronized-waypoint
        @toggleOneDirectionVisible="toggleWaypoint" 
        class="top-headline headline-and-three-headline d-flex flex-wrap">
            <!-- LEFT HEADLINES -->
            <div
            class="custom-col-3 d-none d-md-flex flex-column justify-content-between pr-1">
                <div class="position-relative d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-end mb-1 mb-md-0">
                    <p 
                    class="small-number t18 t15-md t14-xl mt-xl-05 mr-05 should-animate"
                    data-preset='opacity,y'
                    data-fromy='50'
                    data-delay='.2'>05</p>

                    <div 
                    v-html="headlines[4]"
                    class="t9 t7-md t7-xl text-center text-md-left text-xl-right should-animate lh-82"
                    data-preset='opacity,y'
                    data-fromy='50'
                    data-delay='.3' />
                </div>

                <div class="position-relative d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-end mb-1 mb-md-0 text-center text-md-left">
                    <p class="small-number left t18 t15-md t14-xl mt-xl-05 mr-05 should-animate"
                    data-preset='opacity,y'
                    data-fromy='50'
                    data-delay='.3'>06</p>

                    <div class="position-relative">
                        <div 
                        v-html="headlines[5]"
                        class="t9 t7-md t7-xl lh-07-xl should-animate lh-82"
                        data-preset='opacity,y'
                        data-fromy='50'
                        data-delay='.1' />

                        <div 
                        v-html="headlines[6]"
                        class="t9 t7-md t7-xl should-animate lh-82 position-absolute t-100 l-0"
                        data-preset='opacity,y'
                        data-fromy='50'
                        data-delay='.2' />
                    </div>
                </div>
            </div>
            <!-- END :: LEFT HEADLINES -->

            <div 
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.2'
            class="col-md d-flex flex-row-reverse flex-md-row pl-md-025 should-animate">
                <div class="image-custom-col-1 pr-075 pl-1 px-md-05 mb-md-075 overflow-hidden">
                    <synchronized-proportion-div
                    :proportion="{
                      mobile: 1.27,
                      md: 1.17,
                      xl: 1.17
                    }">
                      <custom-parallax 
                      :wrapped='true'
                      class='w-100 h-100'
                      :absolute='true' 
                      :speedFactor='.1' 
                      :scaleFactor='.04'>
                        <component
                        :is='model.fields.relatedProjects && model.fields.relatedProjects[3] ? "nuxt-link" : "div"'
                        :to="model.fields.relatedProjects && model.fields.relatedProjects[3] ? model.fields.relatedProjects[3].url : ''"
                        class="image-link d-block h-100">
                          <synchronized-picture-div
                          :fill="true"
                          :src='getImg(model.fields.gallery[3], "gallery", "?w=500")'
                          :sources="[
                              {
                                  'src': getImg(model.fields.gallery[3], 'gallery', '?w=1100'),
                                  'srcx2': getImg(model.fields.gallery[3], 'gallery', '?w=2100'),
                                  'width': 1921
                              },

                              {
                                  'src': getImg(model.fields.gallery[3], 'gallery', '?w=750'),
                                  'srcx2': getImg(model.fields.gallery[3], 'gallery', '?w=1750'),
                                  'width': 1201
                              },

                              {
                                  'src': getImg(model.fields.gallery[3], 'gallery', '?w=600'),
                                  'width': 768
                              }
                              ]"
                          :alt="gallery[3].fields.title" />
                        </component>
                      </custom-parallax>
                    </synchronized-proportion-div>
                </div>

                <div class="image-custom-col-2 pl-075 px-md-0 px-xl-05 d-flex flex-column justify-content-end justify-content-md-start">
                  <proportion-div
                  :proportion="1.18">
                      <custom-parallax 
                      :wrapped='true'
                      class='w-100 h-100'
                      :absolute='true' 
                      :speedFactor='.1' 
                      :scaleFactor='.04'>
                        <component
                        :is='model.fields.relatedProjects && model.fields.relatedProjects[3] ? "nuxt-link" : "div"'
                        :to="model.fields.relatedProjects && model.fields.relatedProjects[3] ? model.fields.relatedProjects[3].url : ''"
                        class="image-link d-block h-100">
                          <synchronized-picture-div
                          :fill="true"
                          :src='getImg(model.fields.gallery[4], "gallery", "?w=675")'
                          :sources="[
                              {
                                  'src': getImg(model.fields.gallery[4], 'gallery', '?w=800'),
                                  'srcx2': getImg(model.fields.gallery[4], 'gallery', '?w=1800'),
                                  'width': 1921
                              },

                              {
                                  'src': getImg(model.fields.gallery[4], 'gallery', '?w=600'),
                                  'srcx2': getImg(model.fields.gallery[4], 'gallery', '?w=1600'),
                                  'width': 1201
                              },

                              {
                                  'src': getImg(model.fields.gallery[4], 'gallery', '?w=546'),
                                  'width': 768
                              }
                              ]"
                          :alt="gallery[4].fields.title" />
                        </component>
                      </custom-parallax>
                    </proportion-div>
                </div>
            </div>
        </synchronized-waypoint>

        <synchronized-waypoint
        @toggleOneDirectionVisible="toggleWaypoint" 
        class="d-none d-md-flex headline-and-three-headline">

            <div class="custom-col-3 d-flex position-relative">
                <div class="position-absolute d-flex">
                    <p class="small-number t18 t15-md t14-xl mt-xl-05 mr-05 should-animate"
                    data-preset='opacity,y'
                    data-fromy='50'
                    data-delay='.1'>07</p>

                    <div 
                    v-html="headlines[7]"
                    class="t9 t7-md t7-xl should-animate pl-md-025"
                    data-preset='opacity,y'
                    data-fromy='50'
                    data-delay='.2' />
                </div>

                <div class="hidden-text d-flex">
                    <p class="small-number left t18 t15-md t14-xl mt-xl-05 mr-05 should-animate"
                    data-preset='opacity,y'
                    data-fromy='50'
                    data-delay='.3'>06</p>

                    <div 
                    v-html="headlines[5]"
                    class="t9 t7-md t7-xl lh-07-xl should-animate lh-82"
                    data-preset='opacity,y'
                    data-fromy='50'
                    data-delay='.1' />
                </div>
            </div>

            <div class="d-none d-md-flex flex-wrap col-md">
                <div class="image-custom-col-1 d-flex justify-content-end">
                    <!-- SMALL LINK -->
                    <div 
                    class="should-animate d-flex align-items-end pr-md-05"
                    data-preset='opacity,y'
                    data-fromy='50'
                    data-delay='.3'>
                        <nuxt-link
                        to="/?contact=true"
                        class="t13 t9-md t10-xl work mr-xl-2 py-075 py-xl-0">
                          <span class='link reversed' v-text="'get in touch'"></span>
                        </nuxt-link>
                    </div>
                    <!-- END :: SMALL LINK -->
                </div>

                <div class="image-custom-col-2" />
            </div>

        </synchronized-waypoint>
        <!-- END :: IMAGE AND THREE HEADLINES -->
    </div>
    <!-- END :: GALLERY -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import _get from 'lodash/get'
import WaypointTrigger from "@/mixins/WaypointTrigger";
import ServicesHeader from '@/components/services/ServicesHeader'

export default {
  name: "fashion-services",

  props: ["model"],

  components: {
    ServicesHeader
  },

  mounted() {
    // console.log("fashion-services", this.model);
  },

  mixins: [WaypointTrigger],

  computed: {
    ...mapState('app', [
      'width'
    ]),

    headlines(){
      return _get(this.model, 'fields.headlines')
    },

    gallery(){
      return _get(this.model, 'fields.gallery')
    }
  }
};
</script>

<style lang="scss" scoped="">
.fashion-services {

  .image-wrapper {
    margin-top: -12vw;
  }

  .top-2em {
    position: relative;
    top: .2em;

    @include media-breakpoint-up(xl){
      top: -.2em;
    }
  }

  .image-1 {
    width: 32%;

    @include media-breakpoint-up(md) {
      width: 77.6%;
    }

    @include media-breakpoint-up(xl) {
      width: 61%;
      // margin-bottom: -.85em;
    }
  }

  .headliine-1 {
    padding-bottom: 1em;
  }

  .headliine-5 {
    padding-top: 2em;
  }

  .two-images-wrarpper {
    margin-top: -.85em;
  }

  .small-number {
    font-family: "WorkSans-Regular";

    &.left {
      top: .6em;
      left: -1.3rem;
    }

    &.top {
      bottom: 100%;
      left: 0;
    }
  }

  .custom-col-1 {
    // width: 100%;
    @include media-breakpoint-up(md) {
        width: 44.5%;
    }

    @include media-breakpoint-up(xl) {
        width: 42.5%;
    }
  }

  .custom-col-2 {
    width: 100%;
    @include media-breakpoint-up(md) {
        width: 57.5%;
    }
  }

  a.image-link {
    transition: .4s;
    &:hover {
      filter: grayscale(100%);
    }
  }

  .headline-6 {
    position: relative;
    left: 14.7vw;
    @include media-breakpoint-up(xl) {
        left: 16.7vw;
    }
  }

  .headline-and-three-headline {
    @include media-breakpoint-up(md){
        padding-left: 5.6rem;
    }

    @include media-breakpoint-up(xl){
        padding-left: 12.3rem;
    }
  }

  .top-headline {
    @include media-breakpoint-up(md){
        margin-bottom: 4rem;
    }

    @include media-breakpoint-up(lg){
        margin-bottom: 8.8rem;        
    }
  }

  .hidden-text {
    opacity: 0;
    pointer-events: none;
  }

  .custom-col-3 {
    width: auto;
  }

  .image-custom-col-1 {
    width: 60%;

    @include media-breakpoint-up(md){
        width: 73%;
    }
  }

  .image-custom-col-2 {
    width: 40%;

    @include media-breakpoint-up(md){
        width: 27%;
    }
  }
}
</style>