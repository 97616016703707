<template>
  <div class="interior-services position-relative glossy text-uppercase pt-2 pt-md-3 pb-075 pb-md-2">

    <!-- HEADER -->
    <services-header 
    :originalDecor="true"/>
    <!-- END :: HEADER -->


    <!-- GALLERY -->
    <div class="px-md-125 px-md-2">
      <!-- IMAGE AND 2 TEXTS -->
      <div class="d-flex">
        <div class="col-md-5 d-none d-md-block">
          <synchronized-waypoint
          @toggleOneDirectionVisible="toggleWaypoint"
          class="position-relative ml-auto col-xl-8 pl-md-3 overflow-hidden">
            <proportion-div
            class='should-animate'
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.1'
            :proportion="1.35">
              <custom-parallax 
              :wrapped='true'
              class='w-100 h-100'
              :absolute='true' 
              :speedFactor='.1' 
              :scaleFactor='.05'>
                <component 
                :is='model.fields.relatedProjects && model.fields.relatedProjects[0] ? "nuxt-link" : "div"'
                :to="model.fields.relatedProjects && model.fields.relatedProjects[0] ? model.fields.relatedProjects[0].url : ''"
                class="image-link d-block h-100">
                    <synchronized-picture-div
                    :fill="true"
                    :image="model.fields.gallery[0]"
                    :alt="gallery[0].fields.title"
                    :src='getImg(model.fields.gallery[0], "gallery", "?w=700")'
                    :sources="[
                        {
                            'src': getImg(model.fields.gallery[0], 'gallery', '?w=1400'),
                            'srcx2': getImg(model.fields.gallery[0], 'gallery', '?w=1500'),
                            'width': 1921
                        },

                        {
                            'src': getImg(model.fields.gallery[0], 'gallery', '?w=1020'),
                            'srcx2': getImg(model.fields.gallery[0], 'gallery', '?w=1100'),
                            'width': 1201
                        },

                        {
                            'src': getImg(model.fields.gallery[0], 'gallery', '?w=750'),
                            'width': 768
                        }
                    ]" />
                </component>
              </custom-parallax>
            </proportion-div>
          </synchronized-waypoint>
        </div>
        <div class="col-md-7 pl-md-075 d-md-flex">
          <synchronized-waypoint
          @toggleOneDirectionVisible="toggleWaypoint"
          class="d-flex flex-column justify-content-between h-100">
            <div class="position-relative t9 t7-md t7-xl mb-1 text-center text-md-left">
                <p class="t18 t15-md t14-xl work should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.1'>01</p>

                <div
                class="should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.2'>
                  <div 
                  v-html="headlines[0]"
                  class="t9 t7-md t7-xl d-md-inline" />
                </div>
            </div>

            <div class="d-md-flex text-center text-md-left mb-1 mb-md-0">
              <p 
              class="small-number work t18 t15-md t14-xl pull-up-nmb mr-md-05 should-animate"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.3'>02</p>

              <div
              class="should-animate"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.4'>
                <div 
                v-html="headlines[1]"
                class="t9 t7-md t7-xl d-md-inline" />
              </div>
            </div>
          </synchronized-waypoint>

          <synchronized-waypoint
          @toggleOneDirectionVisible="toggleWaypoint"
          class="small-image mix-multiply col-md-4 col-xl-3 ml-auto d-none d-md-flex align-items-end mb-md-025 mb-xl-1 t9 t7-md t7-xl">
            <proportion-div
            :proportion="1.22"
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.4'
            class="w-100 should-animate">
              <custom-parallax 
              :wrapped='true'
              class='w-100 h-100'
              :absolute='true' 
              :speedFactor='.1' 
              :scaleFactor='.05'>
                <component 
                :is='model.fields.relatedProjects && model.fields.relatedProjects[1] ? "nuxt-link" : "div"'
                :to="model.fields.relatedProjects && model.fields.relatedProjects[1] ? model.fields.relatedProjects[1].url : ''"
                class="image-link d-block h-100">
                    <synchronized-picture-div
                    :image="model.fields.gallery[1]"
                    :src='getImg(model, "gallery", "?w=700")'
                    :fill="true"
                    :sources="[
                        {
                            'src': getImg(model.fields.gallery[1], 'gallery', '?w=570'),
                            'srcx2': getImg(model.fields.gallery[1], 'gallery', '?w=1570'),
                            'width': 1921
                        },

                        {
                            'src': getImg(model.fields.gallery[1], 'gallery', '?w=500'),
                            'srcx2': getImg(model.fields.gallery[1], 'gallery', '?w=1500'),
                            'width': 1201
                        },

                        {
                            'src': getImg(model.fields.gallery[1], 'gallery', '?w=750'),
                            'width': 768
                        }
                        ]"
                    class="w-100"
                    :alt="gallery[1].fields.title" />
                </component>
              </custom-parallax>
            </proportion-div>
          </synchronized-waypoint>
        </div>
      </div>

      <div class="d-flex justify-content-center justify-content-md-start middle-row t9 t7-md t7-xl">
        <div class="custom-col-middle-row d-md-flex justify-content-center justify-content-md-end right-box mb-1 mb-md-0">
          <synchronized-waypoint
          @toggleOneDirectionVisible="toggleWaypoint"
          class="d-md-flex mt-md-2 mt-xl-4 pt-md-1 text-center text-md-left">
            <p class="small-number work t18 t15-md t14-xl pull-up-nmb mr-md-05 should-animate"
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.1'>03</p>

            <div
            class="should-animate"
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.2'>
              <div 
              v-html="headlines[2]"
              class="t9 t7-md t7-xl d-md-inline" />
            </div>
          </synchronized-waypoint>
        </div>

        <synchronized-waypoint
          @toggleOneDirectionVisible="toggleWaypoint" 
          class="col-md d-none d-md-block">
          <div 
          data-preset='opacity,y'
          data-fromy='50'
          data-delay='.4'
          class="mix-multiply ml-auto d-flex align-items-end pl-md-1 should-animate">
            <proportion-div
            :proportion="1.185"
            class="w-100">
              <custom-parallax 
              :wrapped='true'
              class='w-100 h-100'
              :absolute='true' 
              :speedFactor='.1' 
              :scaleFactor='.05'>
                <component 
                :is='model.fields.relatedProjects && model.fields.relatedProjects[2] ? "nuxt-link" : "div"'
                :to="model.fields.relatedProjects && model.fields.relatedProjects[2] ? model.fields.relatedProjects[2].url : ''"
                class="image-link d-block h-100">
                    <synchronized-picture-div
                    :fill="true"
                    :image="model.fields.gallery[2]"
                    :src='getImg(model, "gallery", "?w=700")'
                    :sources="[
                        {
                            'src': getImg(model.fields.gallery[2], 'gallery', '?w=1050'),
                            'srcx2': getImg(model.fields.gallery[2], 'gallery', '?w=2050'),
                            'width': 1921
                        },

                        {
                            'src': getImg(model.fields.gallery[2], 'gallery', '?w=750'),
                            'srcx2': getImg(model.fields.gallery[2], 'gallery', '?w=750'),
                            'width': 1201
                        },

                        {
                            'src': getImg(model.fields.gallery[2], 'gallery', '?w=550'),
                            'width': 768
                        }
                        ]"
                    class="w-100"
                    :alt="gallery[2].fields.title" />
                </component>
              </custom-parallax>
            </proportion-div>
          </div>
        </synchronized-waypoint>

      </div>

      <div class="middle-row-2 t9 t7-md t7-xl mb-1 mb-md-0 pt-025">
        <div class="col-md-9 col-xl-8 d-md-flex justify-content-md-end pr-md-125 pr-lg-0">
          <synchronized-waypoint
          @toggleOneDirectionVisible="toggleWaypoint"
          class="d-md-flex mr-md-3 mr-lg-5 mr-xl-3 text-center text-md-left pr-lg-1">
            <p class="small-number work t18 t15-md t14-xl pull-up-nmb mr-md-05 should-animate"
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.1'>04</p>

            <div 
            class="should-animate"
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.2'>
              <div 
              v-html="headlines[3]"
              class="t9 t7-md t7-xl d-md-inline" />
            </div>
          </synchronized-waypoint>
        </div>
      </div>

      <div class="d-flex mt-md-3 mt-xl-4">
        <div class="offset-md-4 w-100 pl-xl-2">
          <synchronized-waypoint
          @toggleOneDirectionVisible="toggleWaypoint"
          class="d-md-flex text-center text-md-left mb-1 mb-md-025 mb-xl-0">
            <p class="small-number work t18 t15-md t14-xl pull-up-nmb mr-md-05 should-animate"
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.1'>05</p>

            <div 
            class="should-animate"
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.2'>
              <div 
              v-html="headlines[4]"
              class="t9 t7-md t7-xl d-md-inline" />
            </div>
          </synchronized-waypoint>

          <div class="middle-row-3 d-block d-md-none">
            <synchronized-waypoint
            @toggleOneDirectionVisible="toggleWaypoint"
            class="col-md-9 d-flex flex-column flex-md-row align-items-center align-items-md-end justify-content-between mx-auto px-xl-2">
              <div
              class="d-md-flex mr-xl-3 text-center text-md-left mb-2 mb-md-0">
                <p class="small-number work t18 t15-md t14-xl pull-up-nmb mr-md-05 should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.1'>06</p>

                <div 
                class="should-animate"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.2'>
                  <div 
                  v-html="headlines[5]"
                  class="t9 t7-md t7-xl d-md-inline" />
                </div>
              </div>

              <!-- SMALL LINK -->
              <div 
              class="mb-2 should-animate"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.2'>
                  <nuxt-link
                  to="/?contact=true"
                  class="t13 t9-md t10-xl work mr-xl-2 py-1 py-xl-0">
                    <span class='link reversed' v-text="'get in touch'"></span>
                  </nuxt-link>
              </div>
              <!-- END :: SMALL LINK -->
            </synchronized-waypoint>
          </div>

          <synchronized-waypoint
          @toggleOneDirectionVisible="toggleWaypoint"
          class="d-flex flex-row-reverse flex-md-row align-items-end align-items-md-start pl-md-1 ml-md-075">
            <div 
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.2'
            class="mix-multiply col-6 col-md-8 pr-075 pr-md-0 should-animate">
              <proportion-div
              :proportion="width > 768 ? 1.18 : 1.32">
                <custom-parallax 
                :wrapped='true'
                class='w-100 h-100'
                :absolute='true' 
                :speedFactor='.1' 
                :scaleFactor='.05'>
                  <component 
                  :is='model.fields.relatedProjects && model.fields.relatedProjects[3] ? "nuxt-link" : "div"'
                  :to="model.fields.relatedProjects && model.fields.relatedProjects[3] ? model.fields.relatedProjects[3].url : ''"
                    class="image-link d-block h-100">
                      <synchronized-picture-div
                      :fill="true"
                      :src='getImg(model, "gallery", "?w=700")'
                      :sources="[
                          {
                              'src': getImg(model.fields.gallery[3], 'gallery', '?w=1100'),
                              'srcx2': getImg(model.fields.gallery[3], 'gallery', '?w=2100'),
                              'width': 1921
                          },

                          {
                              'src': getImg(model.fields.gallery[3], 'gallery', '?w=770'),
                              'srcx2': getImg(model.fields.gallery[3], 'gallery', '?w=1770'),
                              'width': 1201
                          },

                          {
                              'src': getImg(model.fields.gallery[3], 'gallery', '?w=600'),
                              'width': 768
                          }
                          ]"
                      class="w-100 should-animate h"
                      data-preset="scale"
                      data-dur="2"
                      data-fromscale='1.1'
                      data-toscale='1'
                      :alt="gallery[3].fields.title" />
                    </component>
                </custom-parallax>
              </proportion-div>
            </div>

            <div 
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.35'
            class="mix-multiply col-6 col-md-4 px-075 px-md-0 pl-md-05 pl-xl-1 should-animate">
              <proportion-div
              :proportion="width > 768 ? 1.28 : 1.27">
                <custom-parallax 
                :wrapped='true'
                class='w-100 h-100'
                :absolute='true' 
                :speedFactor='.1' 
                :scaleFactor='.05'>
                    <component 
                    :is='model.fields.relatedProjects && model.fields.relatedProjects[4] ? "nuxt-link" : "div"'
                    :to="model.fields.relatedProjects && model.fields.relatedProjects[4] ? model.fields.relatedProjects[4].url : ''"
                    class="image-link d-block h-100">
                      <synchronized-picture-div
                      :fill="true"
                      :image="model.fields.gallery[4]"
                      :src='getImg(model, "gallery", "?w=700")'
                      :sources="[
                          {
                              'src': getImg(model.fields.gallery[4], 'gallery', '?w=673'),
                              'srcx2': getImg(model.fields.gallery[4], 'gallery', '?w=1673'),
                              'width': 1921
                          },

                          {
                              'src': getImg(model.fields.gallery[4], 'gallery', '?w=513'),
                              'srcx2': getImg(model.fields.gallery[4], 'gallery', '?w=1513'),
                              'width': 1201
                          },

                          {
                              'src': getImg(model.fields.gallery[4], 'gallery', '?w=437'),
                              'width': 768
                          }
                          ]"
                      class="w-100"
                      :alt="gallery[4].fields.title" />
                    </component>
                </custom-parallax>
              </proportion-div>
            </div>
          </synchronized-waypoint>
        </div>
      </div>

      <div class="middle-row-3 d-none d-md-block t9 t7-md t7-xl">
        <synchronized-waypoint
        @toggleOneDirectionVisible="toggleWaypoint"
        class="col-md-9 col-lg-10 col-xl-9 d-flex align-items-end justify-content-between mx-auto pl-lg-4 pr-lg-3">
          <div 
          data-preset='opacity,y'
          data-fromy='50'
          data-delay='.2'
          class="d-flex mr-md-3 pl-md-05 pl-lg-0 should-animate">
            <p class="small-number work t18 t15-md t14-xl pull-up-nmb mr-md-05 should-animate"
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.1'>06</p>

            <div
            class="should-animate"
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.3'>
              <div 
              v-html="headlines[5]"
              class="t9 t7-md t7-xl d-md-inline" />
            </div>
          </div>

          <!-- SMALL LINK -->
          <div 
          class="pr-md-3 pr-lg-4 mr-xl-1 should-animate"
          data-preset='opacity,y'
          data-fromy='50'
          data-delay='.3'>
              <nuxt-link
              to="/"
              class="t13 t9-md t10-xl work link reversed mr-md-075 mr-lg-0"
              v-text="'get in touch'" />
          </div>
          <!-- END :: SMALL LINK -->
        </synchronized-waypoint>
      </div>
      <!-- END :: IMAGE AND 2 TEXTS -->

    </div>
    <!-- END :: GALLERY -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import _get from 'lodash/get'
import WaypointTrigger from "@/mixins/WaypointTrigger"
import ServicesHeader from '@/components/services/ServicesHeader'

export default {
  name: "interior-services",

  components: {
    ServicesHeader
  },
  props: ["model"],

  mixins: [WaypointTrigger],

  computed: {
    ...mapState('app', [
      'width'
    ]),

    headlines(){
      return _get(this.model, 'fields.headlines')
    },

    gallery(){
      return _get(this.model, 'fields.gallery')
    }
  }
};
</script>

<style lang="scss" scoped="">
.interior-services {

  .image-1 {
    width: 32%;
    @include media-breakpoint-up(md) {
      width: 88.6%;
    }

    @include media-breakpoint-up(lg) {
      width: 45%;
    }

    @include media-breakpoint-up(xl) {
      width: 61%;
      // margin-bottom: -.85em;
    }
  }

  .small-image {
    padding-bottom: 1em;
  }

  .headliine-1 {
    padding-bottom: 1em;
  }

  .headliine-5 {
    padding-top: 2em;
  }

  .two-images-wrarpper {
    margin-top: -.85em;
  }

  .small-number {
    font-family: "WorkSans-Regular";

    &.left {
      top: .6em;
      left: -1.3rem;
    }

    &.top {
      bottom: 100%;
      left: 0;
    }
  }

  .middle-row {
    @include media-breakpoint-up(md) {
      margin-top: -1em;
    }
    // @include media-breakpoint-up(xlg) {
    //   margin-top: -2vw;
    // }

    .custom-col-middle-row {
      @include media-breakpoint-up(md){
        width: 60%;
      }

      @include media-breakpoint-up(lg){
        width: 57.5%;
      }

      @include media-breakpoint-up(xl){
        width: 58.6%;
      }
    }
  }

  .middle-row-2 {
    @include media-breakpoint-up(md) {
        margin-top: -1em;
    }
  }

  .middle-row-3 {
    @include media-breakpoint-up(md) {
      margin-top: -1em;
    }
  }
  
  a.image-link {
    transition: .4s;
    &:hover {
      filter: grayscale(100%);
    }
  }

  .pull-up-nmb {
    margin-top: .01em;
  }
}
</style>