<template>
  <div class="intro full-fixed bg-beige overflow-hidden">
    <div 
    :style='introSvgStyle'
    ref='introSvg'
    class='w-100 position-absolute intro-svg' v-html='IntroSvg' />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { gsap } from 'gsap'
import _each from 'lodash/each'
import IntroSvg from '@/assets/svg/intro.svg?raw'
import DrawSVGPlugin from '~/plugins/scripts/DrawSVGPlugin.js'

export default {
  name: "intro",

  data: () => ({
    IntroSvg,
    introSvgStyle: {}
  }),

  mixins: [],

  watch: {
    fontsLoaded() {
      setTimeout(this.initAnimation, 10)
    },

    width(w) {
      let navLogo = document.querySelector('.logo-text')

      if(w > 768){        
        gsap.set(navLogo, {
          clearProps: 'all'
        })
      } else {
        gsap.set(navLogo, {
          display: 'none'
        })
      }
    }
  },

  mounted() {
    gsap.registerPlugin(DrawSVGPlugin)

    if(this.fontsLoaded){
      setTimeout(this.initAnimation, 10)
    }
  },

  methods: {
      ...mapActions('app', [
          'setStateApp'
      ]),

      initAnimation() {
        this.tl = gsap.timeline({})

        this.introSvgStyle = {
          height: `${this.$refs.introSvg.offsetHeight}px`,
          width: `${this.$refs.introSvg.offsetWidth}px`,
          left: `${(this.width - this.$refs.introSvg.offsetWidth) / 2}px`,
          top:  `${(this.height - this.$refs.introSvg.offsetHeight) / 2}px`,
        }

        let introElements = this.$el.querySelectorAll('.intro-element')
        let logo = this.$el.querySelector('.intro-logo')
        let svgElems = this.$el.querySelectorAll('.intro-draw')
        let leftText = this.$el.querySelectorAll('.intro-text-left')
        let rightText = this.$el.querySelectorAll('.intro-text-right')
        let circles = this.$el.querySelectorAll('.intro-circle')
        let navLogo = document.querySelector('.logo-text')

        let navBounds = navLogo.getBoundingClientRect()
        let logoBounds = logo.getBoundingClientRect()

        let toCenter = (this.height / 2) - navBounds.top - (navBounds.height / 2)

        this.tl.set(navLogo, {
          y: toCenter
        })

        // fade them in all -- 0 opacity for server side render
        this.tl.set(introElements, {
          opacity: 1
        })

        
        // logo
        this.tl.fromTo(navLogo, {
          opacity: 0,
          scale: .6,
        }, {
          duration: 1,
          transformOrigin: '50% 50%',
          smoothOrigin: false,
          scale: 1,
          opacity: .8,
        }, .2)
        


        // lines
        this.tl.fromTo(svgElems, {
          drawSVG: '0%',
          opacity: 0,
        }, {
          duration: 2,
          opacity: 1,
          stagger: .05,
          drawSVG: '100%',
          delay: .1,
          overwrite: true
        }, .3)


        // circles
        _each(circles, (c, i) => {
          let rotation = i % 2 == 0 ? -190 : -90
          let rotationTo = rotation - 200

          this.tl.fromTo(c, {
            scale: 0.5,
            rotation,
            opacity: 0.2,
          }, {
            duration: 3.25,
            opacity: .2,
            rotation: rotationTo,
            scale: 1,
            smoothOrigin: false,
            transformOrigin: '50% 50%',
            ease: 'Power4.easeInOut'
          }, 1.1)
          
          this.tl.fromTo(c, {
            drawSVG: '0%',
          }, {
            duration: 5.25,
            drawSVG: '100%',
            ease: 'Power1.easeOut'
          }, 1.1)
        })


        // texts
        this.tl.fromTo(leftText, {
          x: this.width / 10,
          opacity: 0,
        }, {
          duration: 1.7,
          x: 0,
          opacity: 1,
          // stagger: -.1,
          overwrite: true,
          ease: 'Power4.easeOut'
        }, 2.8)

        this.tl.fromTo(rightText, {
          x: -this.width / 10,
          opacity: 0,
        }, {
          duration: 1.7,
          x: 0,
          opacity: 1,
          // stagger: -.1,
          overwrite: true,
          ease: 'Power4.easeOut'
        }, 2.8)


        // OUT ANIMATION

        // fade out the elements
        this.tl.to(introElements, {
          opacity: 0,
          duration: 1,
          onComplete: () => {
            this.setStateApp({ animationDelay: 0 })
          }
        }, 3.7)

        // reduce the height
        this.tl.to(this.$el, {
          height: 0,
          duration: 1.6,
          overwrite: true,
          ease: 'Power4.easeOut',
          onComplete: () => {
            this.$emit('update:loading', false)
          }
        }, 4)


        if(this.width <= 768) {

        } else {

        }

        this.tl.to(navLogo, {
          [this.width <= 768 ? 'opacity' : 'y']: 0,
          duration: 1.6,
          color: 'white',
          ease: 'Power4.easeOut',
          onComplete: () => {
            this.tl.set(navLogo, {
              display: this.width <= 768 ? 'none' : 'block'
            })
          }
        }, 4.1)
        
      }
  },

  computed: {
    ...mapState('app', [
      'width',
      'height',
      'fontsLoaded'
    ])
  }
};
</script>

<style lang="scss" scoped="">
/*! purgecss start ignore */
.intro {
  will-change: height;
  z-index: 2;

  .intro-svg /deep/ {
    .intro-element{
      opacity: 0;
    }
  }
}
/*! purgecss end ignore */
</style>