<template>
  <section 
  class="where-we-are position-relative">
    <div class="bg-pale-silver pt-md-7">

      <synchronized-waypoint
      @toggleOneDirectionVisible="toggleWaypoint"
      class="d-flex flex-md-row flex-column-reverse">

        <div 
        class="col-md-7 position-relative z-1 box-1 px-075 px-md-0 position-relative z-2">
          <div 
          class="bg-light-creme p-075 p-xl-1 d-flex flex-column should-animate color-dark-text"
          data-preset='y'
          data-not-instant='true'
          data-fromy='100'
          data-delay='.1'>
            <div class="d-flex">

              <!-- LEFT BOX -->
              <div class="col-custom d-flex flex-column justify-content-between">
                <h3
                v-if="boxes[0].fields.caption"
                v-html="boxes[0].fields.caption"
                class="t14 t11-md t13-xl text-uppercase pt-075" />

                <h3
                v-if="boxes[0].fields.content[0]"
                v-html="boxes[0].fields.content[0]"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.2'
                class="t9 t7-md t7-xl glossy text-uppercase d-none d-md-block should-animate" />

              </div>
              <!-- END :: LEFT BOX -->

              <!-- IMAGE 1 -->
              <div class="col-md p-05 should-animate"
              data-preset='opacity,y,rotate'
              data-fromy='50'
              data-delay='.2'
              data-fromrotate="-10"
              data-torotate="0">
                <div class="image-shadow bg-light-creme4 px-05 pt-05 pb-1 pb-xl-2 mix-multiply mb-5 mb-md-0">
                  <synchronized-picture-div
                  :image="boxes[0].fields.image"
                  :src='getImg(boxes[0], "image", "?w=636")'
                  :sources="[
                      {
                          'src': getImg(boxes[0], 'image', '?w=1228'),
                          'srcx2': getImg(boxes[0], 'image', '?w=1400'),
                          'width': 1921
                      },

                      {
                          'src': getImg(boxes[0], 'image', '?w=650'),
                          'srcx2': getImg(boxes[0], 'image', '?w=700'),
                          'width': 1201
                      },

                      {
                          'src': getImg(boxes[0], 'image', '?w=520'),
                          'width': 768
                      }
                      ]" 
                  class="h-100 w-100" 
                  :alt="boxes[0].fields.image.fields.title"/>
                </div>
              </div>
              <!-- IMAGE 1 -->

            </div>
            <div 
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.3'
            class="pb-md-7 pb-xl-8 should-animate" >
              <h3
              v-if="boxes[0].fields.content[0]"
              v-html="boxes[0].fields.content[0]"
              class="t9 t7-md t7-xl glossy text-uppercase d-block d-md-none" />

              <h3
              v-if="index > 0"
              v-for="(item, index) in boxes[0].fields.content"
              :key="index"
              v-html="item"
              class="t9 t7-md t7-xl glossy text-uppercase" />
            </div>
          </div>
          <!-- END :: LEFT BOX -->
        </div>

        <!-- RIGHT IMAGE -->
        <div class="right-image col-md-5 p-md-05 should-animate bg-sandstone"
        data-preset='y'
        data-fromy='100'
        data-delay='.2'>
          <proportion-div
          :proportion="1.03">
            <synchronized-picture-div
            :image="model.fields.images[0]"
            :src='getImg(model.fields.images[0], "image", "?w=750")'
            :sources="[
                {
                    'src': getImg(model.fields.images[0], 'image', '?w=1240'),
                    'srcx2': getImg(model.fields.images[0], 'image', '?w=2240'),
                    'width': 1921
                },

                {
                    'src': getImg(model.fields.images[0], 'image', '?w=880'),
                    'srcx2': getImg(model.fields.images[0], 'image', '?w=1880'),
                    'width': 1201
                },

                {
                    'src': getImg(model.fields.images[0], 'image', '?w=664'),
                    'width': 768
                }
                ]"
            class="h-100 image" 
            :alt="model.fields.images[0].fields.title" />
          </proportion-div>
        <!-- END :: RIGHT IMAGE -->
        </div>
      </synchronized-waypoint>

      <synchronized-waypoint
      @toggleOneDirectionVisible="toggleWaypoint"
      class="d-flex down-wrapper bg-sandstone">
        <div class="col-md-3 d-none d-md-flex align-items-end pl-md-4 pl-xl-10 should-animate"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.1'>
          <h3
          v-if="model.fields.content[0]"
          v-html="model.fields.content[0]"
          class="color-blackv2 t9 t5-md t3-1-xl glossy text-uppercase" />
        </div>

        <!-- CENTERED BOX -->
        <div class="bg-light-creme4 custom-box px-075 px-md-1 px-xl-15 py-1 pb-xl-15 pt-5 pt-md-1 pt-xl-3 box-2 mb-md-05 mb-xl-075 should-animate text-right color-dark-text"
        data-preset='y'
        data-fromy='100'
        data-delay='.1'>
            <h2
            v-for="(item, index) in boxes[1].fields.content"
            :key="index"
            v-html="item"
            class="t9 t7-md t7-xl glossy text-uppercase text-right mb-1 mb-md-2 mb-xl-0 d-inline d-xl-block" />

            <div class="image-shadow col-5 col-md-5 col-xl-3 bg-light-creme3 px-05 pt-05 pb-1 pb-xl-175 ml-md-auto mix-multiply card-image mr-xl-1">
                <synchronized-picture-div
                :image="boxes[1].fields.image"
                class="h-100 w-100" 
                :src='getImg(boxes[1], "image", "?w=430")'
                :alt="boxes[1].fields.image.fields.title"
                :sources="[
                  {
                      'src': getImg(boxes[1], 'image', '?w=550'),
                      'srcx2': getImg(boxes[1], 'image', '?w=1550'),
                      'width': 1921
                  },

                  {
                      'src': getImg(boxes[1], 'image', '?w=450'),
                      'srcx2': getImg(boxes[1], 'image', '?w=1450'),
                      'width': 1201
                  },

                  {
                      'src': getImg(boxes[1], 'image', '?w=450'),
                      'width': 768
                  }
                ]"/>
            </div>            
        </div>
        <!-- END :: CENTERED BOX -->
      </synchronized-waypoint>  

      <!-- DOWN CONTENT -->
      <synchronized-waypoint
      @toggleOneDirectionVisible="toggleWaypoint" 
      class="bottom-part px-075 pl-md-4 pl-xl-10 pr-md-6 pr-xl-7 pt-3 pt-md-0 pb-md-025 bg-sandstone pb-3">
        <h3
        v-if="model.fields.content[0]"
        v-html="model.fields.content[0]"
        :class='{
          "should-animate": $device.isDesktop
        }'
        class="t9 t5-md t3-1-xl glossy text-uppercase d-inline d-md-none"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.1' />

        <h3
        v-if="model.fields.content[1]"
        v-html="model.fields.content[1]"
        class="color-blackv2 t9 t5-md t3-1-xl glossy text-uppercase d-inline d-md-block pr-xlg-6"
        :class='{
          "should-animate": $device.isDesktop
        }'
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.1' />

        <div class="d-flex align-items-center mix-multiply mb-md-025 mb-lg-0"
        data-preset='opacity,y'
        data-fromy='50'
        :class='{
          "should-animate": $device.isDesktop
        }'
        data-delay='.2'>
          <image-div
          :src="getImg(model.fields.images[1], '?w=1400')"
          class="small-image mr-1"
          :alt="model.fields.images[1].fields.title" />

          <h3
          v-if="model.fields.content[2]"
          v-html="model.fields.content[2]"
          class="color-blackv2 t9 t5-md t3-1-xl glossy text-uppercase" />
        </div>

        <h3
        v-if="model.fields.content[3]"
        v-html="model.fields.content[3]"
        class="color-blackv2 t9 t5-md t3-1-xl glossy text-uppercase d-inline d-md-block pr-xlg-6"
        :class='{
          "should-animate": $device.isDesktop
        }'
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.3' />

        <h3
        v-if="model.fields.content[4]"
        v-html="model.fields.content[4]"
        :class='{
          "should-animate": $device.isDesktop
        }'
        class="t9 t5-md t3-1-xl glossy text-uppercase d-inline d-md-none"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.4' />

      </synchronized-waypoint>
      <!-- END :: DOWN CONTENT -->

      <!-- BOTTOM PART IMAGE AND CONTENT -->
      <div class="pixel-spacing px-075 pl-md-4 pl-xl-10 bg-sandstone pb-2 pb-md-6 d-md-flex flex-wrap">
        <div class="custom-col custom-col--1 d-none d-md-block">
          <synchronized-waypoint
          @toggleOneDirectionVisible="toggleWaypoint" 
          class="col-md-7 d-flex flex-column justify-content-between">

            <h3
            v-if="model.fields.content[4]"
            v-html="model.fields.content[4]"
            class="color-blackv2 t9 t5-md t3-1-xl glossy text-uppercase no-wrap d-none d-md-block should-animate"
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.2' />

            <!-- DECORATION NUMBER VI -->
            <div
            class="d-flex align-items-center justify-content-between custom-margin-top">
              <span 
              class="roboto t14 t11-md t13-xl should-animate"
              data-preset='opacity,y'
              data-fromy='50'
              data-delay='.8'>VI</span>
            </div>
            <!-- END :: DECORATION NUMBER VI -->
          </synchronized-waypoint>
        </div>

        <div
        class="custom-col custom-col--2">
          <div class="row mb-md-025 mb-lg-075 mb-05">
            <div class="d-md-flex flex-column w-md-auto mb-125 mb-md-0">
              <synchronized-waypoint
              @toggleOneDirectionVisible="toggleWaypointWithLines"
              class="flex-grow d-flex flex-md-column justify-content-between justify-content-md-start">
                <h3
                class="t9 t5-md t3-1-xl glossy text-uppercase no-wrap d-none d-md-block fade-out">
                  hid 
                </h3>

                <!-- DECORATION NUMBER MOBILE VI -->
                <div
                class="d-flex align-items-center justify-content-between custom-margin-top d-md-none">
                  <span 
                  class="roboto t14 t11-md t13-xl should-animate"
                  data-preset='opacity,y'
                  data-fromy='50'
                  data-delay='.8'>VI</span>
                </div>
                <!-- END :: DECORATION NUMBER MOBILE VI -->

                <!-- DECORATION NUMBERS VII -->
                <p 
                class="roboto t14 t11-md t13-xl should-animate custom-margin-top"
                data-preset='opacity,y'
                data-fromy='50'
                data-delay='.9'>VII</p>
                <!-- END :: DECORATION NUMBERS VII -->
              </synchronized-waypoint>

              <synchronized-waypoint
              v-if="model.fields.description[0]"
              @toggleOneDirectionVisible="toggleWaypointWithLines">
                <h4
                v-html="model.fields.description[0]"
                class="t10-1 t8-md t8c-xl glossy text-uppercase text-right d-none d-md-block should-animate top-line"
                data-preset='y'
                data-fromy='50'
                data-delay='.1'/>
              </synchronized-waypoint>
            </div>

            <synchronized-waypoint
            @toggleOneDirectionVisible="toggleWaypointWithLines" 
            class='col-md overflow-hidden position-relative pl-md-025 pl-lg-1'>
              <proportion-div
              :proportion="1.116"
              class="should-animate"
              data-preset="scale"
              data-dur="5"
              daata-delay='.1'
              data-fromscale='1.1'
              data-toscale='1'>
                <synchronized-picture-div
                :src='getImg(model.fields.images[2], "image", "?w=660")'
                :sources="[
                {
                    'src': getImg(model.fields.images[2], 'image', '?w=1240'),
                    'srcx2': getImg(model.fields.images[2], 'image', '?w=2240'),
                    'width': 1921
                },

                {
                    'src': getImg(model.fields.images[2], 'image', '?w=851'),
                    'srcx2': getImg(model.fields.images[2], 'image', '?w=1851'),
                    'width': 1201
                },

                {
                    'src': getImg(model.fields.images[2], 'image', '?w=580'),
                    'width': 768
                }
                ]"
                class="h-100 w-100" 
                :alt="model.fields.images[2].fields.title" />
              </proportion-div>
            </synchronized-waypoint>
          </div>

          <synchronized-waypoint
          @toggleOneDirectionVisible="toggleWaypointWithLines"
          class="pr-lg-7">

            <split-text
            v-if="index > 0 && width >= 768"
            v-for="(item, index) in description"
            :key="index"
            :text="item"
            @splited="onSplited"
            innerClasses=""
            class="t10-1 t8-md t8c-xl glossy text-uppercase text-center text-md-left d-none d-md-block" />

            <split-text
            v-if='width < 768'
            :visible='visibleWaypoint'
            type='lines'
            :wrapped='false'
            tag='h1'
            innerClasses="t10-1 t8-md t8-xl glossy text-uppercase text-center d-block d-md-none"
            :text="stripHtmlWithBreaks(model.fields.mobileDescription)"
            @splited="onSplited" />

          </synchronized-waypoint>
        </div>
      </div>
      <!-- END :: BOTTOM PART IMAGE AND CONTENT -->
      
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import _get from 'lodash/get'
import WaypointTrigger from "@/mixins/WaypointTrigger"

export default {
  name: "where-we-are",

  data: () => ({
    delayAddition: .2
  }),

  props: ["model"],

  mixins: [WaypointTrigger],

  computed: {
    ...mapState("app", ["width"]),

    boxes() {
      return _get(this.model, 'fields.boxes')
    },
    content() {
      return _get(this.model, 'fields.content')
    },
    description() {
      return _get(this.model, 'fields.description')
    }
  }
};
</script>

<style lang="scss" scoped="">
/*! purgecss start ignore */
.where-we-are {
  .col-custom {
    flex: 0 0 45%;
    max-width: 38%;

    @include media-breakpoint-up(md) {
      max-width: 45%;
    }
  }
  .image {
    position: relative;
    left: -20%;
    width: 120%;
  }
  .box-1 {
    @include media-breakpoint-up(md) {
      margin-top: -26vw;
    }
    @include media-breakpoint-up(xl) {
      margin-top: -10vw;
    }
  }

  .down-wrapper {
    @include media-breakpoint-up(md) {
      margin-top: -19vw;
    }
    @include media-breakpoint-up(xl) {
      margin-top: -13vw;
    }
  }

  .right-image {
    @include media-breakpoint-up(md){
      top: -5rem;
    }

    @include media-breakpoint-up(xl){
      top: 0;
    }
  }

  .small-image {
    width: 6rem;
    height: 3.5rem;

    @include media-breakpoint-up(md) {
      width: 2.63rem;
      height: 2.95rem;
    }

    @include media-breakpoint-up(xl) {
      width: 5rem;
      height: 6rem;
    }
  }

  .box-2 {
    margin-top: -3em;
    @include media-breakpoint-up(md) {
      margin-top: 0;
      z-index: 2
    }
  }

  .bottom-part {
    margin-top: -1px  ;
  }

  .custom-box {
    width: 100%;

    @include media-breakpoint-up(md){
      flex: 0 0 55%;
      max-width: 55%;
    }

    @include media-breakpoint-up(xl){
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
  }

  .card-image {
    margin-top: -1.8em;

    @include media-breakpoint-up(md){
      margin-top: 1rem;
    }

    @include media-breakpoint-up(xl){
      margin-top: -.6em;
    }
  }

  .top-line {
    position: relative;
    top: .2em;
  }

  .custom-margin-top {
    @include media-breakpoint-up(md){
      margin-top: 95/768*100vw;
    }

    @include media-breakpoint-up(xl){
      margin-top: 310/1920*100vw;
    }
  }

  .custom-col {
    @include media-breakpoint-up(md){
      &--1 {
        width: 42%;
      }

      &--2 {
        width: 58%;
      }
    }

    @include media-breakpoint-up(lg){
      &--1 {
        width: 43%;
      }

      &--2 {
        width: 57%;
      }
    }
  }
}
/*! purgecss end ignore */
</style>