<template>
  <synchronized-scroll :isMobile='!$device.isDesktop'>
    <div class='overflow-hidden'>

      <hero-header 
      :model='model.fields.header' />

      <sticky-sections 
      :sections='model.fields.components' />
    </div>
  </synchronized-scroll>
</template>

<script>
import BasePage from '@/mixins/BasePage';
import { mapState, mapActions } from 'vuex'

import HeroHeader from '@/components/headers/HeroHeader'
import StickySections from '@/components/stickySections/StickySections'

export default {
  name: 'Homepage',

  data: () => ({}),

  components: {
    HeroHeader,
    StickySections
  },

  mixins: [BasePage],

  mounted() {
     this.$store.dispatch('app/setStateApp', {
      passedViewport: false
    })
  },

  async asyncData ({$contentful, route, store}) {
    const urls = store.getters['pages/getState']('urls')

    const activePage = await $contentful.loadActivePage({
      route: route.path,
      id: route.name,
      urls,
      include: 4
    })

    store.dispatch('pages/SET_STATE', {
      activePage
    })

    return {
      model: activePage
    }
  }
}
</script>
