<template>
  <div class="service-info-styling bg-beige text-uppercase glossy">
    <!-- TOP CONTENT -->
    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines"
    class="color-dark-text top-content px-075 px-md-1 pt-1">

      <div class="pr-xl-7">
        <split-text
        class="pr-md-4 pr-xl-2"
        :visible='visibleWaypoint'
        type='lines'
        :wrapped='false'
        tag='p'
        innerClasses="t9 t7-md t7-xl"
        :text="stripHtmlWithBreaks(model.fields.content)"
        @splited="onSplited" />
      </div>

      <div class="row">
        <div class="col-md-7 offset-md-5">

          <split-text
          :visible='visibleWaypoint'
          type='lines'
          :wrapped='false'
          tag='p'
          innerClasses="t9 t7-md t7-xl"
          :text="stripHtmlWithBreaks(model.fields.offsetContent)"
          @splited="onSplited" />

        </div>
      </div>
    </synchronized-waypoint>
    <!-- END TOP CONTENT -->

    <!-- MIDDLE CONTENT -->
    <div class="row px-075 px-md-0 mb-1 mb-md-0">
      <div class="col-md-5">
        
      </div>

      <synchronized-waypoint
      @toggleOneDirectionVisible="toggleWaypointWithLines"
      class="col-md-7">
        <div class="color-text-color text-none mb-05 col-10 col-md-12 pr-xl-5">
          <split-text
          :visible='visibleWaypoint'
          type='lines'
          :wrapped='false'
          tag='p'
          innerClasses="t10 t8-md t8-xl"
          :text="stripHtmlWithBreaks(model.fields.imageText)"
          @splited="onSplited" />
        </div>

      </synchronized-waypoint>
    </div>
    <!-- END :: MIDDLE CONTENT -->

    <div class="row">
      <!-- LEFT IMAGE BOX -->
      <div class="d-flex flex-column justify-content-between col-md-5 position-relative z-1 px-075 px-md-0">
        <synchronized-waypoint
        @toggleOneDirectionVisible="toggleWaypoint"
        class="pl-md-1 mb-1 mb-md-0">
          <div 
          class="should-animate"
          data-preset='opacity,y'
          data-fromy='50'
          data-delay='.1'>
            <nuxt-link
            to="/"
            class="roboto link reversed work t13 t14-md t10-xl"
            v-text="'get in touch'" />
          </div>
        </synchronized-waypoint>

        <synchronized-waypoint
        @toggleOneDirectionVisible="toggleWaypoint">
          <div class="left-wrapper bg-white p-075 pr-md-2 pt-md-2 should-animate"
          data-preset='y'
          data-fromy='100'
          data-delay='.1'>
            <div class="col-9 mb-3 mb-xl-5">
              <synchronized-picture-div
              :image="model.fields.mediumImage"
              :src='getImg(model, "mediumImage", "?w=350")'
              :sources="[
                  {
                      'src': getImg(model, 'mediumImage', '?w=900'),
                      'srcx2': getImg(model, 'mediumImage', '?w=1200'),
                      'width': 1921
                  },

                  {
                      'src': getImg(model, 'mediumImage', '?w=650'),
                      'srcx2': getImg(model, 'mediumImage', '?w=700'),
                      'width': 1201
                  },

                  {
                      'src': getImg(model, 'mediumImage', '?w=300'),
                      'width': 768
                  }
                  ]" 
              :proportion="1.04" 
              :alt="model.fields.mediumImage.fields.title" />
            </div>

            <div class="row align-items-end">
              <div class="col t13 t11-md p-xl">
                <!-- <nuxt-link
                to="/"
                class="roboto link"
                v-text="'view styling works'" /> -->

                <arrow-link 
                text='view our projects'
                to='/our-projects' />
              </div>

              <div class="col-2">
              <synchronized-picture-div
              :image="model.fields.smallImage"
              :src='getImg(model, "smallImage", "?w=100")'
              :sources="[
                  {
                      'src': getImg(model, 'smallImage', '?w=200'),
                      'srcx2': getImg(model, 'smallImage', '?w=200'),
                      'width': 1921
                  },

                  {
                      'src': getImg(model, 'smallImage', '?w=150'),
                      'srcx2': getImg(model, 'smallImage', '?w=150'),
                      'width': 1201
                  },

                  {
                      'src': getImg(model, 'smallImage', '?w=150'),
                      'width': 768
                  }
                  ]" 
                :proportion="1.04" 
                :alt="model.fields.smallImage.fields.title" />
              </div>
            </div>
          </div>
        </synchronized-waypoint>
      </div>
      <!-- END :: LEFT IMAGE BOX -->

      <!-- RIGHT IMAGE BOX -->
      <synchronized-waypoint
      @toggleOneDirectionVisible="toggleWaypoint"
      class="col-md-7 middle-content">
        <proportion-div
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.3'
        class='should-animate'
        :proportion="1.06">
          <custom-parallax 
          :wrapped='true'
          class='w-100 h-100'
          :absolute='true' 
          :speedFactor='.2' 
          :scaleFactor='.1'>
            <synchronized-picture-div
            :fill="true"
            :image="model.fields.largeImage"
            :src='getImg(model, "largeImage", "?w=700")'
            :sources="[
                {
                    'src': getImg(model, 'largeImage', '?w=1500'),
                    'srcx2': getImg(model, 'largeImage', '?w=1600'),
                    'width': 1921
                },

                {
                    'src': getImg(model, 'largeImage', '?w=1300'),
                    'srcx2': getImg(model, 'largeImage', '?w=1500'),
                    'width': 1201
                },

                {
                    'src': getImg(model, 'largeImage', '?w=750'),
                    'width': 768
                }
                ]" 
            :alt="model.fields.largeImage.fields.title" />
          </custom-parallax>
        </proportion-div>
      </synchronized-waypoint>
      <!-- END :: RIGHT IMAGE BOX -->
    </div>

  </div>
</template>

<script>
import WaypointTrigger from "@/mixins/WaypointTrigger"

export default {
  name: "service-info-styling",

  data: () => ({
    delayAddition: .3
  }), 

  props: ["model"],

  mixins: [WaypointTrigger]
};
</script>

<style lang="scss" scoped="">
.service-info-styling {
  .left-wrapper {
    @include media-breakpoint-up(md){
      width: 120%;
    }

    @include media-breakpoint-up(xl){
      width: 105%; 
    }
  }

  .top-content {
    margin-bottom: 4rem;

    @include media-breakpoint-up(md){
      margin-bottom: 7rem;      
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 14.2rem;
    }
  }

  .small-arrow {
    width: 1rem;
    height: 1rem;
    background: url('/img/arrow-right.svg') no-repeat;
  }

  .middle-content {
    margin-top: -5rem;
    @include media-breakpoint-up(md) {
        margin-top: 0;
    }
  }
}
</style>