<template>
    <div 
    :class='{
        "open": menuOpen
    }'
    class="fullscreen-menu color-white position-fixed l-0 t-0 p-xl-1 z-4 overflow-auto">
        <div class="fullscreen-menu-inner bg-light-creme w-100 minh-100 d-flex flex-column-reverse flex-xl-row justify-content-end">

            <div class="col-xl-6 flex-grow text-md-center py-1 pt-md-2 px-1 px-md-0 d-flex flex-column justify-content-md-end position-relative">
                <div class="gradient position-absolute r-0 t-0 h-100"></div>

                <div 
                data-fromy='200'
                class="d-none d-xl-block flex-grow mb-1 pb-md-0 should-animate2 position-relative">
                    <img
                    class='position-absolute h-100 w-auto center-x b-0 kim-image'
                    :src='getImg(navigation, "image", "?w=377")'
                    :sources="[
                    {
                        'src': getImg(navigation, 'image', '?h=600'),
                        'srcx2': getImg(navigation, 'image', '?h=800'),
                        'width': 1921
                    },

                    {
                        'src': getImg(navigation, 'image', '?h=500'),
                        'srcx2': getImg(navigation, 'image', '?h=700'),
                        'width': 1201
                    },

                    {
                        'src': getImg(navigation, 'image', '?w=412'),
                        'width': 768
                    }
                    ]"
                    alt="Kim Kneipp" />
                </div>

                <!-- ABOUT LINK -->
                <div 
                data-preset='opacity,y'
                data-delay='.2'
                class='should-animate d-flex flex-column align-items-start align-items-md-center'>
                    <div class="d-flex flex-md-column color-blackv2 pb-1">
                        <div 
                        class="p roboto pr-025 pr-md-05 item-index pb-1">0.01</div>

                        <nuxt-link
                        @click.native="closeMenu($route.fullPath)"
                        :to='{path: "/", query: {service: true}}'
                        v-html="`Services`"
                        class="color-blackv2 d-block opacity-link t6 tlarge-md t4-xl color-blackv2 glossy text-uppercase d-block" />
                    </div>

                    <div class="d-flex flex-md-column color-blackv2 pb-1">
                        <div 
                        class="p roboto pr-025 pr-md-05 item-index pb-1">0.02</div>
                        
                        <nuxt-link 
                        @click.native="closeMenu(navigation.fields.fullscreenPages[0].url)"
                        :to="navigation.fields.fullscreenPages[0].url"
                        class="color-blackv2 d-block opacity-link">

                            <span
                            v-html="navigation.fields.fullscreenPages[0].fields.title"
                            class="t6 tlarge-md t4-xl color-blackv2 glossy text-uppercase d-block" />
                            <!-- <span
                            v-html="$t('kim-kneipp')" 
                            class="t6 tlarge-md t4-xl color-blackv2 glossy text-uppercase" /> -->
                        </nuxt-link>
                    </div>

                    <div class="d-flex flex-md-column color-blackv2">
                        <div 
                        class="p roboto pr-025 pr-md-05 item-index pb-1">0.03</div>

                        <nuxt-link
                        @click.native="closeMenu($route.fullPath)"
                        :to='{path: "/", query: {contact: true}}'
                        v-html="`Contact`"
                        class="d-block opacity-link t6 tlarge-md t4-xl color-blackv2 glossy text-uppercase d-block" />
                    </div>
                </div>
            </div>

            <div class="col-xl-6 pt-md-2 pr-md-5 pr-xl-1">
                <div class="pr-md-1 h-100">
                    <div 
                    data-props='y'
                    data-fromy='300'
                    data-toy='0'
                    data-dur='1.4'
                    class="bg-sandstone d-flex h-100 flex-column should-animate">

                        <!-- PROJECTS LINK -->
                        <div 
                        data-props='y'
                        data-fromy='100'
                        data-toy='0'
                        data-dur='1.4'
                        class="color-blackv2 pl-1 pr-5 py-1 p-md-2 should-animate d-flex">
                            <span 
                            class="t15-1 t17-md t15-xl roboto pr-025 pr-md-05 item-index d-none">(1)</span>

                            <div>
                                <nuxt-link
                                @click.native="closeMenu(navigation.fields.fullscreenPages[1].url)"
                                :to="navigation.fields.fullscreenPages[1].url"
                                v-html="navigation.fields.fullscreenPages[1].fields.title"
                                class="t6 t3-md t4-xl color-blackv2 glossy text-uppercase opacity-link" />
                            </div>

                        </div>
                        <!-- END :: PROJECTS LINK -->

                        <div 
                        data-props='y'
                        data-fromy='500'
                        data-toy='0'
                        data-dur='1.4'
                        class="row flex-grow bg-brown color-blackv2 pr-05 pr-md-6 pr-xl-2 py-05 mb-05 should-animate">
                            <div 
                            data-props='y'
                            data-fromy='300'
                            data-toy='0'
                            data-dur='1.4'
                            class="col-7 col-xl-6 bg-grey pt-05 pb-1 pl-1 pr-1 pr-md-0 pl-xl-2 d-flex flex-column justify-content-between should-animate">
                                <div class="t14 t16-md t13-xl text-uppercase mb-3 mb-sm-4 mb-md-2">by type</div>

                                <div class="flex-column d-flex">
                                        <nuxt-link
                                        :to='{
                                            path: navigation.fields.fullscreenPages[1].url,
                                            query: {
                                                type: item.sanitize()
                                            }
                                        }'
                                        v-for="(item, index) in leftLinks"
                                        :key="index"
                                        class="item-cat">
                                            <div class="d-flex align-items-center align-items-md-start item">
                                                <span class="t15-1 t17-md t15-xl roboto pr-025 pr-md-05 item-index">0{{index + 1}}</span>
                                                <div
                                                class="t10-2 t8-1-md t12-xl text-uppercase glossy"
                                                v-html="item" />
                                            </div>
                                        </nuxt-link>
                                    </div>
                            </div>
                            <div class="col-5 col-xl-6 d-flex flex-column justify-content-between pt-05 pb-1 pl-1 pl-xl-2">
                                <div class="t14 t16-md t13-xl text-uppercase mb-3 mb-3 mb-sm-4 mb-md-2">by Colour</div>

                                    <div class="flex-column d-flex">
                                        <nuxt-link
                                        v-for="(item, index) in rightLinks"
                                        :to='item.url'
                                        :key="index"
                                        class="item-cat">
                                            <div class="d-flex align-items-center align-items-md-start item">
                                                <span class="t15-1 t17-md t15-xl roboto pr-025 pr-md-05 item-index">0{{index + 1}}</span>
                                                <div
                                                class="t10-2 t8-1-md t12-xl text-uppercase glossy"
                                                v-html="item.title" />
                                            </div>
                                        </nuxt-link>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FilterMixin from '@/mixins/filters'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _includes from 'lodash/includes'
import _each from 'lodash/each'
import WaypointTrigger from '@/mixins/WaypointTrigger'
import ResizeHandler from '@/mixins/ResizeHandler'
import { gsap } from 'gsap'
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import _orderBy from 'lodash/orderBy'
import _indexOf from 'lodash/indexOf'

export default {
  name: "fullscreen-menu",

  data: () => ({
  }),

  props: ["model"],

  mixins: [
    FilterMixin,
    WaypointTrigger,
    ResizeHandler
  ],

  watch: {
    menuOpen(open) {
        if(open){
            !this.$device.isIos && disableBodyScroll(this.$el)
            this.enter()
        } else{
            !this.$device.isIos && enableBodyScroll(this.$el)
            this.leave()
        }
    }
  },

  mounted() {
    this.leave(0)

    this.$elems = this.$el.querySelectorAll('.should-animate')
    this.$links = this.$el.querySelectorAll('.item-cat')
  },

  methods: {
    ...mapActions('app', [
        'setStateApp'
    ]),

    resizeHandler(){
        // let yPercent = this.menuOpen ? -100 : 0

        // gsap.set(this.$el, {
        //     yPercent
        // })
    },

    enter(el)  {
        // gsap.to(this.$el, 1, {
        //     yPercent: -100,
        //     overwrite: true,
        //     delay: .1
        // })

        this.$el.classList.add('open')

        this.to && clearTimeout(this.to)
        
        gsap.to(this.$elems, 1.2, {
            y: 0,
            delay: .1,
            opacity: 1
        })


        gsap.to(this.$links, .6, {
            y: 0,
            delay: .8,
            opacity: 1,
            stagger: .05
        })

        // this.toggleWaypoint({
        //   el: this.$el,
        //   visible: true
        // })
    },

    leave(duration = 1)  {

        this.$el.classList.remove('open')

        this.to = setTimeout(() => {
            _each(this.$elems, (el) => {
                let data = el.dataset

                gsap.set(el, {
                    y: data.fromy
                })

                gsap.set(this.$links, {
                    y: 30,
                    opacity: 0
                })
            })
        }, 600 * duration)

        // gsap.to(this.$el, duration, {
        //     yPercent: 0,
        //     ease: 'Power4.easeInOut',
        //     clearProps: 'transform',
        //     overwrite: true,
        //     onComplete: () => {
        //         _each(this.$elems, (el) => {
        //             let data = el.dataset

        //             gsap.set(el, {
        //                 y: data.fromy
        //             })

        //             gsap.set(this.$links, {
        //                 y: 30,
        //                 opacity: 0
        //             })
        //         })
        //     }
        // })
    },

    closeMenu(url) {
      if(!url || (url == this.$route.fullPath)){
        this.setStateApp({
            menuOpen: false
        })
      }
    }
  },

  computed: {
    ...mapState('pages', [
      'navigation',
      'projects',
      'type',
      'color'
    ]),

    ...mapState('app', [
      'menuOpen',
      'width'
    ]),

    leftLinks() {
        let links = this.getUniqueOpts(this.projects, 'type')
        
        return _orderBy(links, opt => {
            return _indexOf(this.type, opt)
        })
    },

    rightLinks() {
        // let links = this.getUniqueOpts(this.projects, 'color')

        // return _orderBy(links, opt => {
        //     return _indexOf(this.color, opt)
        // })

        let parsed = _map(this.color, color => {

            let page = _find(this.projects, (item, index) => {
              return _includes(item.fields.title.sanitize(), color) && item.fields.primaryProject
            })

            return {
                url: page ? page.url : '/',
                title: color
            }
        })

        return parsed
        // _filter(this.collection, c => c.fields.primaryProject)
    },

    items() {
      return _get(this.navigation, 'fields.pages')
    }
  },

  components: {}
};
</script>

<style lang="scss" scoped="">
.fullscreen-menu {
    overflow: hidden;
    width: 100%;
    height: 100%;
    will-change: transform;
    transform: translate3d(0, 120%, 0);
    transition: transform .6s ease-in-out;

    @include media-breakpoint-up(md){
        height: 100%;
    }

    .tlarge-md{
        @include media-breakpoint-between(md,lg){
            font-size: 10vmax!important;
        }
    }

    .kim-image {
        max-height: 25vmax;
    }

    .fullscreen-menu-inner {
        // overflow-y: auto;

        // @media screen and (orientation: landscape){
        //     min-height: 20rem!important;
        // }
        
        // @include media-breakpoint-up(xl){
        //     overflow-y: hidden;
        // }
    }

    &.open{
        transition: transform 1s;
        transform: translate3d(0, 0, 0);
    }

    .should-animate{
        opacity: 1;
    }

    .gradient {
        background: rgb(250,246,236);
        @include media-breakpoint-up(xl){
            background: linear-gradient(90deg, #FAF6EC 0%, #939374 100%);
        }
        width: 2rem;
        mix-blend-mode: multiply;
        opacity: 0.2;
    }

    .item-cat {
        will-change: transform,opacity;
        &:not(:last-child) {
            margin-bottom: .25rem;

            @include media-breakpoint-up(md){
                margin-bottom: .5rem;
            }

            @include media-breakpoint-up(xl) {
                margin-bottom: 1rem;
            }
        }
        .item {
            transition: .8s;
        }
        &:hover {
            .item {
                @include media-breakpoint-up(lg) {
                    transform: translateX(5%);
                }
            }
        }
    }
}
</style>