<template>
  <div class="products-filter py-2 py-md-4">
    <synchronized-waypoint    
    @toggleOneDirectionVisible="toggleWaypoint"
    class="row justify-content-center">
        <nuxt-link 
        v-for='(option, index) in options'
        :key='index'
        class='text-uppercase filter-link text-center tiny medium-md h5-xl d-flex flex-center shoudl-animate'
        data-preset='opacity,y'
        data-fromy='10' 
        :data-delay='.2 + index / 10'
        :class='{
          "active": isActive(stripHtml(option.fields.title))
        }'
        :to='getFilterUrl(stripHtml(option.fields.title), field)'>
            <span v-html='stripHtml(option.fields.title)' />
        </nuxt-link>
    </synchronized-waypoint>
  </div>
</template>

<script>
import _includes from 'lodash/includes'
import _get from 'lodash/get'
import WaypointTrigger from "@/mixins/WaypointTrigger"

export default {
  name: "products-filter",

  data: () => ({
    multiple: true,
    field: `category`
  }),

  props: ["options"],

  mixins: [WaypointTrigger],

  methods: {
    getFilterUrl(value, field){
        if(!value){
          return { query : null }
        }

        let query = { ...this.$store.state.route.query }

        let slug = value.sanitize()

        let values = query[field] ? query[field].split(",") : []

        const index = values ? values.indexOf(slug) : -1
        if (index > -1) {
          values.splice(index, 1)
        } else if(slug){
          values.push(slug)
        }

        query[field] = query[field] == slug ? null 
        : this.multiple && values ? values.join(",")
        : slug

        return { query }
      },

      isActive(option){
          return _includes(this.selectedOptions, option.sanitize())
      }
  },

  computed: {
    selectedOptions() {
        let selected = _get(this.$store, `state.route.query.${this.field}`)

        if(!selected){
          return []
        }

        if(this.multiple){
          return selected.split(",")
        } else{
          return [ selected ]
        }
    }
  },

  components: {}
};
</script>

<style lang="scss" scoped="">
.products-filter {
  .filter-link{
    width: 9em;
    border: 1px solid $black;
    border-radius: 2em;
    padding: .5em;
    margin: 0 .2rem;
    transition: all 0.4s;
    will-change: transform color background;

    @include media-breakpoint-up(md) {
        width: 16em;
        padding: .5em 1.5em;
        margin: 0 .5rem;
    }

    &:hover {
      @include media-breakpoint-up(lg){
        background: $black;
        color: $white;
      }
    }

    &:active {
      transform: scale(.97);
    }

    &.active{
      background: $black;
      color: $white;
    }
  }
}
</style>