<template>
  <div class="text-and-image-three color-dark-text bg-pale-silver px-md-1 pb-xl-6">
    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines">
      <div class="d-flex justify-content-between d-md-block px-075 px-md-0">
        <div></div>

        <div class="custom-padding pt-md-4 pt-xl-025 roboto t14 t11-md t13-xl should-animate"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.1'>I</div>

        <h3
        v-if="model.fields.caption"
        v-html="model.fields.caption"
        class="t14 t11-md t13-xl text-uppercase d-block d-md-none should-animate color-black"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.2' />
      </div>
      <div class="row px-075 px-md-0">
        <div class="col-md-2 col-xl-3">
          <h3
          v-if="model.fields.caption"
          v-html="model.fields.caption"
          class="t14 t11-md t13-xl text-uppercase d-none d-md-block should-animate"
          data-preset='opacity,y'
          data-fromy='50'
          data-delay='.2' />

        </div>
        <div class="col-md-10 col-xl-9 pl-md-2 pl-xl-0 pr-xl-7 pr-xl-05 pr-xl-2 pr-md-4">

          <split-text
          :visible='visibleWaypoint'
          type='lines'
          :wrapped='false'
          tag='h4'
          innerClasses="t9 t7-md t7-xl glossy text-uppercase main-headline"
          :text="stripHtmlWithBreaks(model.fields.headline[0])"
          @splited="onSplited" />

        </div>    
      </div>
    </synchronized-waypoint>

    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines"
    class="d-flex px-075 px-md-0 headline">

      <div class="offset-md-3 offset-xl-4 pl-md-4 pl-xl-5">
        <h4
        v-html="model.fields.headline[1]"
        class="t9 t7-md t7-xl glossy text-uppercase should-animate"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.35' />
      </div>

      <div class="col-md pl-1 pl-md-0">
        <synchronized-proportion-div
        :proportion="{
          mobile: .32,
          md: .28,
          xl: .28
        }"
        class="float-md-left ml-md-1 mr-md-05 ml-xl-0 col-7 col-md-5 should-animate ml-auto"
        data-preset='opacity,y,rotate'
        data-fromy='50'
        data-delay='.2'
        data-fromrotate="10"
        data-torotate="0">
          <synchronized-picture-div
          :fill="true" 
          :src='getImg(model, "image", "?w=400")'
          :sources="[
              {
                  'src': getImg(model, 'image', '?w=650'),
                  'srcx2': getImg(model, 'image', '?w=700'),
                  'width': 1921
              },

              {
                  'src': getImg(model, 'image', '?w=580'),
                  'srcx2': getImg(model, 'image', '?w=600'),
                  'width': 1201
              },

              {
                  'src': getImg(model, 'image', '?w=500'),
                  'width': 768
              }
              ]"
            :alt="model.fields.image.fields.title"/>
        </synchronized-proportion-div>
        
        <div 
        class="pr-xl-2 should-animate d-none d-md-block"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.55'>
          <h4
          v-html="model.fields.contentTwo" 
          class="t14 t11-md t13-xl roboto text-uppercase content-0" />
        </div>
      </div>


    </synchronized-waypoint>

    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines">
        <div 
        class="pl-075 pr-075 pr-xl-2 should-animate d-block d-md-none content-two"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.55'>
              <h4
              v-html="model.fields.contentTwo" 
              class="t14 t11-md t13-xl roboto text-uppercase content-0" />
        </div>
    </synchronized-waypoint>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import WaypointTrigger from "@/mixins/WaypointTrigger"

export default {
  name: "text-and-image-three",

  props: ["model"],

  mixins: [WaypointTrigger],

  computed: {
    ...mapState('app', [
      'width'
    ])
  }
};
</script>

<style lang="scss">
/*! purgecss start ignore */
.text-and-image-three {
  .main-headline {
    @media only screen and (max-width: 320px) {
      font-size: 29px !important;
    }
  }
}
/*! purgecss end ignore */
</style>

<style lang="scss" scoped="">
/*! purgecss start ignore */
.text-and-image-three {
  margin-top: -1px;
  .content-0 {
    @include media-breakpoint-up(md) {
      padding-top: 11vw;
      padding-left: 12vw;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 13.8vw;
        padding-left: 12vw;
    }
  }
  .custom-padding {
    padding-bottom: 3rem;
    @include media-breakpoint-up(md) {
      padding-bottom: 6rem;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: 13.8rem;
    }
  }

  .content-two {
    margin-top: -.5em;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
  .headline {
    margin-top: -1.8em;
    @include media-breakpoint-up(xl) {
      margin-top: -3.9em;
    }
    @media only screen and (max-width: 320px) {
      img {
        padding-left: 2rem !important;
      }
    }
  }
}
/*! purgecss end ignore */
</style>