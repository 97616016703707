<template>
  <div 
  data-delay='.65'
  class="contact-component px-xl-5 pt-xl-5 pb-xl-1 disabled color-blackv2">
    <div class="inner-wrapper row enabled h-100 px-xl-05">

      <!-- LEFT COLUMN -->
      <div class="col-xl-6 h-100 bg-beige text-center py-2 py-md-3 py-xl-2 d-flex flex-column justify-content-between">
        <div class="gradient position-absolute r-0 t-0 h-100" />
        
        <div class='top-part'>
            <h2
            v-html="`Contact`"
            data-preset='opacity,y'
            data-delay='.1'
            class="t2-1 t1-md glossy text-uppercase text-center should-animate d-inline-block" />

            <h2
            v-html="model.fields.headline"
            data-preset='opacity,y'
            data-delay='.2'
            class="t2-1 t1-md glossy text-uppercase mb-md-1 mb-xl-0 should-animate" />
        </div>


        <div class="d-flex flex-column">
          <!-- MAIL -->
          <div 
          data-preset='opacity,y'
          data-delay='.25'
          class="d-flex mx-auto py-05 pt-md-0 should-animate">
            <a 
            target="_blank"
            rel="noopener"
            :href="'mailto:' + model.fields.email"
            class="roboto t14 t12-md t17-xl py-075 d-inline-block color-dark-text">
                <span class='link reversed' v-html="model.fields.email"></span>
            </a>
          </div>
          <!-- END :: MAIL -->

          <!-- PHONE -->
          <div 
          data-preset='opacity,y'
          data-delay='.3'
          class="d-flex mx-auto mb-05 mb-md-075 should-animate">
            <a 
            target="_blank"
            rel="noopener"
            :href="'tel:' + model.fields.phone"
            class="roboto t14 t12-md t17-xl py-075 d-inline-block color-dark-text">
                <span class='link d-inline-block' v-html="model.fields.phone"></span>
            </a>
          </div>
          <!-- END :: PHONE -->

          <!-- ADDRESS -->
          <div 
          data-preset='opacity,y'
          data-delay='.35'
          class="d-flex mx-auto should-animate color-dark-text">
            <p
            v-html="model.fields.address"
            class="t14 t12-md t17-xl roboto" />
          </div>
          <!-- END :: ADDRESS -->

          <!-- SMALL IMAGE -->
          <div 
          data-preset='opacity,y'
          data-delay='.4'
          class="col-3 col-md-2 mx-auto my-1 bg-beigev2 px-05 pt-05 pb-1 mix-multiply should-animate image-shadow hidden-portrait-lg">
            <div class="h-100">
              <synchronized-picture-div
                :image="model.fields.images[0]"
                :src='getImg(model.fields.images[0], "image", "?w=377")'
                :sources="[
                    {
                        'src': getImg(model.fields.images[0], 'image', '?w=426'),
                        'srcx2': getImg(model.fields.images[0], 'image', '?w=500'),
                        'width': 1921
                    },

                    {
                        'src': getImg(model.fields.images[0], 'image', '?w=385'),
                        'srcx2': getImg(model.fields.images[0], 'image', '?w=400'),
                        'width': 1201
                    },

                    {
                        'src': getImg(model.fields.images[0], 'image', '?w=412'),
                        'width': 768
                    }
                    ]"
              class="h-100 w-100 position-relative" 
              :alt="model.fields.images[0].fields.title"/>
            </div>
          </div>
          <!-- END :: SMALL IMAGE -->
        </div>



        <!-- SUBHEADLINE -->
        <split-text
        @splited="onSplited"
        :text="model.fields.subheadline"
        tag='h3'
        innerClasses="t3 t3-md glossy text-uppercase lh-08" />
        <!-- END :: SUBHEADLINE -->

      </div>
      <!-- END :: LEFT COLUMN -->


      <!-- RIGHT COLUMN -->
      <div class="col-xl-6 h-100 bg-beigev2 d-flex flex-column justify-content-between position-relative">
        <div class=" d-flex flex-column h-100"> 
            <!-- <img 
            src="/img/contact-bg.webp"
            class="image-decore position-absolute l-0 t-0 h-100 " 
            alt="Kim Kneipp"> -->

            <synchronized-picture-div
            :fill="true"
            class="image-decore"
            :alt="'Kim Kneipp'"
            :src='"/img/contact-bg.webp"'
            :sources="[
                {
                  'src': '/img/contact-bg.webp',
                  'srcx2': '/img/contact-bg.webp',
                  'width': 1921
                },

                {
                  'src': '/img/contact-bg.webp',
                  'srcx2': '/img/contact-bg.webp',
                  'width': 1201
                },

                {
                  'src': '/img/contact-bg.webp',
                  'width': 768
                }
            ]" />

            <div class='flex-grow-1'>
                <div class="d-flex pl-md-1 pt-md-1">

                    <!-- IMAGE 1 -->
                    <div class='col-7'>
                        <div 
                        data-preset='opacity,y'
                        data-delay='.1'
                        class="w-100 bg-light-creme3 image-shadow px-05 pt-05 pb-3 mix-multiply should-animate image-1">
                            <synchronized-picture-div
                            :image="model.fields.images[1]"
                            :src='getImg(model.fields.images[1], "image", "?w=300")'
                            :sources="[
                                {
                                    'src': getImg(model.fields.images[1], 'image', '?w=772'),
                                    'srcx2': getImg(model.fields.images[1], 'image', '?w=1772'),
                                    'width': 1921
                                },

                                {
                                    'src': getImg(model.fields.images[1], 'image', '?w=562'),
                                    'srcx2': getImg(model.fields.images[1], 'image', '?w=1562'),
                                    'width': 1201
                                },

                                {
                                    'src': getImg(model.fields.images[1], 'image', '?w=700'),
                                    'srcx2': getImg(model.fields.images[1], 'image', '?w=800'),
                                    'width': 990
                                },


                                {
                                    'src': getImg(model.fields.images[1], 'image', '?w=500'),
                                    'width': 768
                                }
                            ]"
                            class="h-100 w-100 position-relative" 
                            alt="Kim Kneipp"/>
                        </div>
                    </div>
                    <!-- END :: IMAGE 1 -->

                    <div class="d-flex flex-column w-100">

                        <!-- IMAGE 2 -->
                        <div 
                        data-preset='opacity,y'
                        data-delay='.2'
                        data-fromy='200'
                        class="image-box-2 pr-md-1 pr-lg-0 should-animate mix-multiply">
                            <div class="col-lg-9 bg-light-creme3 image-shadow px-075 pt-05 pb-1">

                                <synchronized-picture-div
                                :image="model.fields.images[2]"
                                :src='getImg(model.fields.images[2], "image", "?w=750")'
                                :sources="[
                                    {
                                        'src': getImg(model.fields.images[2], 'image', '?w=1240'),
                                        'srcx2': getImg(model.fields.images[2], 'image', '?w=2240'),
                                        'width': 1921
                                    },

                                    {
                                        'src': getImg(model.fields.images[2], 'image', '?w=880'),
                                        'srcx2': getImg(model.fields.images[2], 'image', '?w=1880'),
                                        'width': 1201
                                    },

                                    {
                                        'src': getImg(model.fields.images[2], 'image', '?w=664'),
                                        'width': 768
                                    }
                                    ]"
                                class="h-100 w-100 position-relative" 
                                alt="Kim Kneipp"/>
                            </div>
                        </div>  
                        <!-- END :: IMAGE 2 -->

                        <!-- DESKTOP -->
                        <div 
                        class="px-md-2 pt-md-2 pb-md-1 p-xl-2 d-none d-md-flex flex-column flex-grow justify-content-end position-relative">
                            
                            <!-- FOLOW US -->
                            <!-- <span 
                            data-preset='opacity,y'
                            data-delay='.3' 
                            class="t15 t16-1-md t15-xl roboto text-uppercase should-animate d-inline-block">{{$t('')}}</span> -->
                            <!-- END :: FOLOW US -->

                            <div class="d-flex justify-content-end">
                                <!-- NEWSLETTER -->
                                <div 
                                data-preset='opacity,y'
                                data-delay='.4'
                                class="follow-desk-wrapper position-relative text-right should-animate t15 t16-1-md t15-xl roboto text-uppercase">
                                    <span v-html="$t('follow-us')" />

                                    <!-- DECORATION LINE 2 -->
                                    <div
                                    v-html='ContactLine2'  
                                    class="line-decor-2" 
                                    alt="Kim Kneipp" />
                                    <!-- END :: DECORATION LINE 2 -->
                                </div>
                                <!-- END :: NEWSLETTER -->
                            </div>
                        </div>
                        <!-- END :: DESKTOP -->

                    </div>
                </div>

                <div class="bottom-wrapper d-flex pl-md-1 pb-5 pb-md-0">

                    <!-- IMAGE 3 -->
                    <div 
                    data-fromy='200'
                    data-preset='opacity,y'
                    data-delay='.4'
                    class="col-custom bg-light-creme3 image-shadow px-075 pb-1 pb-md-2 pt-075 mix-multiply image-box-3 should-animate hidden-portrait-lg">
                        <synchronized-picture-div
                        :image="model.fields.images[3]"
                        :src='getImg(model.fields.images[3], "image", "?w=200")'
                        :sources="[
                            {
                                'src': getImg(model.fields.images[3], 'image', '?w=300'),
                                'srcx2': getImg(model.fields.images[3], 'image', '?w=500'),
                                'width': 1921
                            },

                            {
                                'src': getImg(model.fields.images[3], 'image', '?w=300'),
                                'srcx2': getImg(model.fields.images[3], 'image', '?w=500'),
                                'width': 1201
                            },

                            {
                                'src': getImg(model.fields.images[3], 'image', '?w=300'),
                                'width': 768
                            }
                            ]"
                        class="h-100 w-100 position-relative" 
                        alt="Kim Kneipp"/>
                    </div>
                    <!-- END :: IMAGE 3 -->


                    <!-- MOBILE -->
                    <div class="px-1 px-md-2 pt-1 pb-3 pt-md-2 pb-md-1 p-xl-2 d-flex d-md-none flex-column flex-grow justify-content-end position-relative">

                        <div class="d-flex justify-content-end">
                            <div class="position-relative">
                                <p
                                data-preset='opacity,y'
                                data-delay='.5' 
                                class="text-right t15 t16-1-md t15-xl roboto text-uppercase"
                                v-html="$t('follow-us')" />

                                <div
                                v-html='ContactLine2' 
                                class="line-decor-2" 
                                alt="Kim Kneipp" />
                            </div>

                        </div>
                    </div>
                    <!-- END :: MOBILE -->

                    <!-- DESKTOP -->
                    <div class="pr-md-5 pb-md-4 flex-grow d-none d-md-flex align-items-end justify-content-end">
                        <div class="small-image d-flex flex-column align-items-end position-relative z-1 mix-multiply should-animate">
                            <a
                            rel="noopener"
                            :href="model.fields.instagram"
                            target="_blank"  
                            data-preset='opacity,y'
                            data-delay='.5' 
                            class="t15 t16-1-md t15-xl roboto text-uppercase mb-025"
                            v-html="$t('instagram')" />
                                
                            <div class="col-custom-2 position-relative">

                                <!-- DECORATION LINE -->
                                <div
                                v-html='ContactLine' 
                                class="line-decor-1" 
                                alt="Kim Kneipp" />
                                <!-- END :: DECORATION LINE -->

                                <a
                                rel="noopener"
                                :href="model.fields.instagram"
                                target="_blank"  
                                data-preset='opacity,y'
                                data-delay='.5'
                                class="bg-light-creme3 image px-md-025 pt-md-025 pb-md-075 d-block image-shadow">
                                    <synchronized-picture-div
                                    :image="model.fields.images[2]"
                                    :src='getImg(model.fields.images[2], "image", "?w=100")'
                                    :sources="[
                                        {
                                            'src': getImg(model.fields.images[2], 'image', '?w=100'),
                                            'srcx2': getImg(model.fields.images[2], 'image', '?w=200'),
                                            'width': 1921
                                        },

                                        {
                                            'src': getImg(model.fields.images[2], 'image', '?w=100'),
                                            'srcx2': getImg(model.fields.images[2], 'image', '?w=200'),
                                            'width': 1201
                                        },

                                        {
                                            'src': getImg(model.fields.images[2], 'image', '?w=100'),
                                            'width': 768
                                        }
                                    ]"
                                    class="h-100 w-100 position-relative" 
                                    alt="Kim Kneipp"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- END :: DESKTOP -->
                </div>

                <!-- MOBLE -->
                <div class="pr-4 pr-md-5 pb-4 flex-grow d-flex d-md-none align-items-end justify-content-end">
                    <div class="d-flex flex-column align-items-center">
                        <a 
                        rel="noopener"
                        :href="model.fields.instagram"
                        target="_blank" 
                        class="t15 t16-1-md t15-xl roboto text-uppercase z-1 mb-025"
                        v-html="$t('instagram')" />
                            
                        <div class="col-custom-2 image-shadow px-025 pt-025 pb-075 mix-multiply position-relative">

                            <!-- DECORATION LINE -->
                            <div
                            v-html='ContactLine' 
                            class="line-decor-1" 
                            alt="Kim Kneipp" />
                            <!-- END :: DECORATION LINE -->

                            <synchronized-picture-div
                            :image="model.fields.images[2]"
                            :src='getImg(model.fields.images[2], "image", "?w=750")'
                            :sources="[
                                {
                                    'src': getImg(model.fields.images[2], 'image', '?w=1240'),
                                    'srcx2': getImg(model.fields.images[2], 'image', '?w=2240'),
                                    'width': 1921
                                },

                                {
                                    'src': getImg(model.fields.images[2], 'image', '?w=880'),
                                    'srcx2': getImg(model.fields.images[2], 'image', '?w=1880'),
                                    'width': 1201
                                },

                                {
                                    'src': getImg(model.fields.images[2], 'image', '?w=664'),
                                    'width': 768
                                }
                                ]"
                            class="h-100 w-100 position-relative" 
                            alt="Kim Kneipp"/>
                        </div>
                    </div>
                </div>
                <!-- END :: MOBILE -->

            </div>

            <!-- COPYRIGHT -->
            <div class="d-flex align-items-center justify-content-between p-1 position-relative z-1">
                <span class="t15 t16-1-md t15-xl text-uppercase">kim kneipp©</span>
                <img 
                src="/img/line-decor3.svg"
                class="line-decor-3" 
                alt="Kim Kneipp">
                <span class="t15 t16-1-md t15-xl text-uppercase">est.2007</span>
            </div>
            <!-- END :: COPYRIGHT -->

        </div>

      </div>
      <!-- END :: RIGHT COLUMN -->

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import StickySection from '@/mixins/StickySection'
import ContactLine from '@/assets/svg/contact-line.svg?raw'
import ContactLine2 from '@/assets/svg/contact-line2.svg?raw'

export default {
  name: "contact-component",

  data: () => ({
    delayAddition: .65,
    ContactLine,
    ContactLine2
  }),

  props: ["model"],

  mounted(){
    if(this.isContactPage){
        setTimeout(this.scrollTo, 30)
    }
  },

  methods: {
    scrollTo(){
        this.$bus.$emit('scrollTo', this.height * (this.$device.isMobileOrTablet ? 2.2 : 3), 0)
        this.$router.push("/")
    }
  },

  watch: {
    isContactPage(iscp) {
        if(iscp){
            this.scrollTo()
        }
    }
  },

  computed: {
    isContactPage() {
        return this.$store.state.route.query.contact == 'true'
    }
  },

  mixins: [StickySection]
};
</script>

<style lang="scss" scoped="">
.contact-component {

    .top-part, .split-text /deep/ h3 {
        opacity: 0.8;
    }

    @include media-breakpoint-up(lg) {
        @media (min-aspect-ratio: 1500/720) {

            .inner-wrapper {
                // overflow-y: auto;
            }

            .hidden-portrait-lg{
                display: none!important;
            }

            .follow-desk-wrapper {
                margin-top: $s4;
            }

            .small-image {
                margin-top: $s6;
            }

            .line-decor-1 {
                width: 7vw;
            }

            .line-decor-2 {
                width: 34vw;
            }

            .image-1{
                position: absolute;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        height: 100%;

        h2{
            font-size: 10vmin!important;
        }

        /deep/ h3{
            font-size: 9vmin!important;   
        }
    }

    @include media-breakpoint-up(xlg) {

        h2{
            font-size: 11.5vmin!important;
        }

        /deep/ h3{
            font-size: 9vmin!important;   
        }
    }



    @media (max-aspect-ratio: 11/8) and (min-width: 1200px) {
        h2{
            font-size: 8vw!important;
        }

        /deep/ h3{
            font-size: 6vw!important;   
        }
    }
        


    .col-custom {
        width: 39vw;
        @include media-breakpoint-up(xl) {
            width: 15vw;
        }
    }

    .col-custom-2 {
        width: 100%;

        @include media-breakpoint-up(xl) {
            width: 110%
        }
    }

    .image-box-2 {
        position: relative;
        left: -.2vw;
    }

    .image-box-3 {
        position: relative;
        top: -1.5vw;
        left: .3vw;
    }

    .image-decore {
        width: 99%;

        @include media-breakpoint-up(xl){
            width: 100%;
        }
    }

    .line-decor-1 {
        position: absolute;
        right: 100%;
        bottom: 100%;
        height: auto;
        width: 26vw;

        @include media-breakpoint-up(md) {
            width: 17vw;
        }

        @include media-breakpoint-up(lg) {
            width: 20vw;
        }

        @include media-breakpoint-up(xl) {
            width: 8vw;
        }
    }

    .line-decor-2 {
        position: absolute;
        right: 100%;
        top: 100%;
        height: auto;
        width: 70vw;

        @include media-breakpoint-up(md) {
            width: 42vw;
        }

        @include media-breakpoint-up(xl) {
            width: 21vw;
        }
    }

    .line-decor-3 {
        width: 52%;
        height: 4px;
        @include media-breakpoint-up(md) {
            width: 90%;
        }
    }

    .bottom-wrapper {
        margin-top: -1em;

        @include media-breakpoint-up(md){
            margin-top: 0;
        }
    }
    .small-image {
        .col-custom-2 {
            .image {
                transition: transform .4s;
            }
        }
        &:hover {
            .col-custom-2 {
                .image {
                    transform: rotate(-10deg);
                }
            }
        }
    }
}
</style>