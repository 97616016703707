<template>
    <figure 
    :style='cssVars'
    class="synchronized-picture-div overflow-hidden" 
    :class="{
        'position-absolute w-100 h-100 t-0 l-0' : fill,
        'position-relative': !fill,
        'w-100 h-100': !proportion
    }">
        <div :class='{ "position-absolute w-100 h-100 t-0 l-0": proportion }'>
           <!--  <template v-if='loading == "eager"'>
                <link
                v-for='(img, index) in sources.reverse()'
                :key='img.src'
                rel="preload" 
                as="image" 
                :href="img.src" 
                :media="getMedia(img, index)">
            </template> -->
            
            <picture>
              <source 
              v-for='(img, index) in sources'
              :key='index'
              :media="`(min-width:${img.width}px)`" 
              :srcset="`${img.src} 1x, ${img.src} 2x`">

              <source 
              :srcset="`${src} 1x, ${src} 2x`">

              <img 
              :loading="computedLoading"
              :class="[innerClasses, classes]"
              :src='$device.isIE || !sources || !sources.length ? src : null'
              class='w-100 h-100 position-absolute t-0 l-0 picture'
              :alt="computedAlt">
            </picture>
            <slot></slot>
        </div>

    </figure>
</template>
<script>
import _get from 'lodash/get'
import _map from 'lodash/map'
import Proportions from './Proportions'

export default {
    name: "synchronized-picture-div",

    mixins: [Proportions],

    props: {
        innerClasses: {
            type: String,
            default: ''
        },

        src: {
            type: String
        },

        sources: {
            type: Array,
            default: () => {}
        },

        contain: {
            type: Boolean,
            default: false
        },

        alt: {
            type: String,
            default: null
        },

        fill: {
            type: Boolean,
            default: false
        },

        loading: {
            type: [String, Boolean],
            default: 'lazy'
        }
    },

    methods: {
        getMedia(img, index) {
            let next = _get(this.sources, index + 1)

            let media = `(min-width: ${img.width}px)`

            if(next) {
                media += ` and (max-width: ${next.width}px)`
            }

            return media
        }
    },
    
    computed: {
        computedAlt() {
            return this.alt ? this.alt 
                : this.image ? _get(this.image, 'fields.title')
                : this.$store ? this.$store.getters['app/getState']('siteName')
                : 'Unclassified image'
        },

        computedLoading() {
            return this.$device.isMobileOrTablet ? this.loading : 'eager'
        },

        imageSrcSet() {
            let set = _map(this.sources, (img) => {
                return `${img.src} ${img.width}w`
            })

            return set.join(",")
        }, 

        classes() {
            return {
                'cover' : !this.contain,
                'contain' : this.contain,
                'bg-center': true
             }
        }
    }
};
</script>

<style lang='scss' scoped>
$sizes: (
  "mobile": var(--mobile), 
  "sm": var(--sm),
  "md": var(--md),
  "lg": var(--lg),
  "xl": var(--xl),
  "xlg": var(--xlg)
);

.synchronized-picture-div{
    position: relative;

    @each $name, $value in $sizes {
      @if $name == 'mobile' {
        padding-bottom: $value;
      } @else {
        @include media-breakpoint-up($name) {
          padding-bottom: $value;
        }
      }
    }    
}

.picture{
    object-position: var(--align-mobile);
    @media only screen and (min-width: 768px) {
        object-position: var(--align)
    }
}
</style>