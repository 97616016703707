var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"navigation w-100",class:{
  "loaded": _vm.loaded,
  "pull-up": (_vm.scrolled && !_vm.activePage.fields.isHp) || _vm.isQuickView || _vm.filterOpen && !_vm.menuOpen
}},[_c('div',{key:_vm.passedViewport,staticClass:"color-white z-3 contact-link nav-element disabled",class:{
        "difference": _vm.passedViewport
      }},[_c('div',{staticClass:"should-animate",attrs:{"data-preset":"opacity","data-delay":"1.6"}},[_c('nuxt-link',{staticClass:"text-uppercase text-size link float-left d-inline-block pull-up-element enabled",attrs:{"to":{path: "/", query: {contact: true}}},domProps:{"innerHTML":_vm._s("Contact")}})],1)]),_vm._v(" "),_c('div',{staticClass:"z-3 logo-wrapper nav-element disabled",class:{
        "difference": _vm.passedViewport
      }},[_c('div',{staticClass:"pull-up-element"},[_c('nuxt-link',{staticClass:"should-animate d-inline-block enabled no-tap-highlight",attrs:{"data-preset":"opacity","data-delay":"1.2","to":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":"/img/logo.svg","alt":"Kim Kneipp"}})]),_vm._v(" "),_c('p',{staticClass:"logo-text color-white text-center roboto text-uppercase position-relative d-none d-md-block",class:_vm.loaded || _vm.passedViewport ? "color-white" : "color-black"},[_vm._v("style & "),_c('br'),_vm._v(" sustainability")])],1)]),_vm._v(" "),_c('custom-transition',{staticClass:"d-flex align-items-center",attrs:{"noAdditionalClasses":true,"mode":_vm.menuOpen ? "out-in" : ""}},[_c('div',{key:!_vm.menuOpen,staticClass:"hamburger-wrapper z-5 nav-element disabled no-tap-highlight",class:{
        "difference": _vm.passedViewport && !_vm.menuOpen
      }},[_c('div',{staticClass:"cursor-pointer no-select should-animate not-will-change",attrs:{"data-preset":"opacity","data-delay":"1.6"}},[_c('div',{staticClass:"d-flex align-items-center pull-up-element enabled",on:{"click":function($event){$event.preventDefault();return _vm.toggleMenu()}}},[_c('hamburger')],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }