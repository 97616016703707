<template>
  <component 
  :is='tag'
  class="text-fill position-relative w-100">

    <span 
    ref='placeholder'
    class='w-100 d-block' />

    <span
    :style='headlineStyle'
    ref='headline'
   :class="[innerClasses, { 
        'headline--top': top, 
        'headline--bottom': !top,
        'fade-out': !initialized
    }]"
    class='position-relative d-inline-block l-0 display-1 headline no-wrap'>    
      <span v-html="text" />
      <slot />
    </span>

  </component>
</template>

<script>
import ResizeHandler from "@/mixins/ResizeHandler"

export default {
  name: "text-fill",

  data: () => ({
    headlineScale: 1,
    initialized: false
  }),

  props: {
    tag: {
      type: String,
      default: 'h1'
    },

    innerClasses: String,

    text: String,

    top: Boolean
  },

  mixins: [
    ResizeHandler
  ],

  methods: {
    resizeHandler() {
      this.headlineScale = 1
      this.$nextTick(this.getScale)
    },

    getScale() {
      if(!this.$refs.placeholder){
        return
      }

      let phWidth = this.$refs.placeholder.offsetWidth
      let elWidth = this.$refs.headline.offsetWidth 
      this.headlineScale = phWidth / elWidth

      this.$nextTick(() => {
        this.initialized = true
      })
    }
  },

  computed: {
    headlineStyle() {
      return {
        'transform': `scale(${this.headlineScale})`
      }
    }
  }
};
</script>

<style lang="scss" scoped="">
.text-fill {
  .headline{
    &--top{
      transform-origin: top left;
    }

    &--bottom{
      transform-origin: bottom left;
    }
  }
}
</style>