<template>
  <div 
  :style='cssVars'
  class="synchronized-column">
    <slot />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import _each from 'lodash/each'

export default {
  name: "synchronized-column",

  data: () => ({
    breakpoints: {
      'mobile': 320,
      'md': 768,
      'xl': 1921
    }
  }),

  props: {
    mobile: {
      type: Number,
      default: 320
    },

    md: {
      type: Number,
      default: 768
    },

    xl: {
      type: Number,
      default: 1922
    }
  },

  computed: {
    cssVars() {
      let vars = {}

      _each(this.breakpoints, (breakpointSize, index) => {
        if(this[index]){
          vars[`--${index}`] = `${this[index] / breakpointSize * 100}%`
        }
      })

      return vars
    }
  }
};
</script>

<style lang="scss" scoped="">
$sizes: (
  "mobile": var(--mobile), 
  "md": var(--md),
  "xl": var(--xl)
);

@mixin makeColumn($value) {
  flex: 0 0 $value;
  max-width: $value;
}

.synchronized-column{  
  width: 100%;

  @each $name, $value in $sizes {

    @if $name == 'mobile' {
      @include makeColumn($value);
    } @else {
      @include media-breakpoint-up($name) {
        @include makeColumn($value);
      }
    }
  }
}
</style>