<template>
  <div class="service-info-interior-design bg-gold text-uppercase  py-1 glossy">

    <!-- TOP CONTENT -->
    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines"
    class="color-text-color t9 t7-md t7-xl top-content px-075 pl-md-1 pr-md-6 pr-xl-8 mb-3 mb-xl-4">
      <split-text
      :visible='visibleWaypoint'
      type='lines'
      :wrapped='false'
      tag='p'
      innerClasses="pre-wrap-md"
      :text="stripHtmlWithBreaks(model.fields.content)"
      @splited="onSplited" />
    </synchronized-waypoint>
    <!-- END TOP CONTENT -->

    <!-- MIDDLE CONTENT -->
    <div class="color-text-color row justify-content-md-center justify-content-xl-end pl-075 pl-md-8 pl-xl-0 mb-3 mb-md-1 mb-xl-2 middle-content">
      <synchronized-waypoint
      @toggleOneDirectionVisible="toggleWaypointWithLines"
      class="custom-col t9 t7-md t7-xl">
        <split-text
        :visible='visibleWaypoint'
        type='lines'
        :wrapped='false'
        tag='p'
        innerClasses="pre-wrap-md"
        :text="stripHtmlWithBreaks(model.fields.offsetContent)"
        @splited="onSplited" />
      </synchronized-waypoint>
    </div>
    <!-- END :: MIDDLE CONTENT -->

    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypoint"
    class="mb-1 px-075 d-md-none">
      <div
      class="should-animate"
      data-preset='opacity,y'
      data-fromy='50'
      data-delay='.2'>
       <nuxt-link
        to="/?contact=true"
        class="t13 t9-md t10-xl work mr-xl-2 py-075 py-xl-0">
          <span class='link reversed' v-text="'get in touch'"></span>
        </nuxt-link>
      </div>
    </synchronized-waypoint>

    <div class="row">
      <!-- LEFT IMAGE BOX -->
      <synchronized-waypoint 
      @toggleOneDirectionVisible="toggleWaypoint" 
      class="custom-left-column image-wrapper d-flex flex-column justify-content-between order-2 order-md-1">
        <!-- SMALL LINK -->
        <div class="mb-3 mb-md-4 mb-xl-5 pl-md-1 d-none d-md-block should-animate"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.2'>
          <nuxt-link
          to="/"
          class="t13 t9-md t10-xl work link reversed"
          v-text="'get in touch'" />
        </div>
        <!-- END :: SMALL LINK -->

        <div class="position-relative overflow-hidden h-100">
          <proportion-div
          :proportion="width >= 768 ? 1.177 : 1.128">
            <custom-parallax 
            :wrapped='true'
            class='w-100 h-100'
            :absolute='true' 
            :speedFactor='.2' 
            :scaleFactor='.1'>
              <synchronized-picture-div
              :fil='true'
              :image="model.fields.largeImage"
              :src='getImg(model, "largeImage", "?w=500")'
              :sources="[
                  {
                      'src': getImg(model, 'largeImage', '?w=1400'),
                      'srcx2': getImg(model, 'largeImage', '?w=1600'),
                      'width': 1921
                  },

                  {
                      'src': getImg(model, 'largeImage', '?w=600'),
                      'srcx2': getImg(model, 'largeImage', '?w=700'),
                      'width': 1201
                  },

                  {
                      'src': getImg(model, 'largeImage', '?w=750'),
                      'width': 768
                  }
              ]"
              :alt="model.fields.largeImage.fields.title"
              class="should-animate"
              data-preset="scale"
              data-dur="5"
              data-fromscale='1.1'
              data-toscale='1' />
            </custom-parallax>
          </proportion-div>
        </div>
      </synchronized-waypoint>
      <!-- END :: LEFT IMAGE BOX -->

      <!-- RIGHT IMAGE BOX -->
      <synchronized-waypoint 
      @toggleOneDirectionVisible="toggleWaypointWithLines"
      class="d-flex flex-column justify-content-between custom-right-column position-relative z-1 px-075 px-md-0 order-1 order-md-2">
        <!-- BOX SECTION -->
        <div
        class="right-wrapper mb-md-2 flex-grow position-relative">
          <div 
          class="inner-wrapper d-flex flex-column bg-beige justify-content-xl-between p-075 pr-md-5 pr-xl-7 pt-md-05 pb-xl-1 should-animate"
          data-preset='y'
          data-fromy='100'
          data-delay='.1'>
            <div class="mb-5 mb-xl-7 px-05 pt-05 pb-2 pb-xl-4 bg-light-creme3 mix-multiply image-shadow">
              <synchronized-picture-div
              :proportion="{
                mobile: 1.123,
                xl: 1.052,
                md: 1.052
              }"
              :src='getImg(model, "smallImage", "?w=600")'
              :sources="[
                  {
                      'src': getImg(model, 'smallImage', '?w=913'),
                      'srcx2': getImg(model, 'smallImage', '?w=1200'),
                      'width': 1921
                  },

                  {
                      'src': getImg(model, 'smallImage', '?w=604'),
                      'srcx2': getImg(model, 'smallImage', '?w=800'),
                      'width': 1201
                  },

                  {
                      'src': getImg(model, 'smallImage', '?w=300'),
                      'width': 768
                  }
                  ]"
              :alt="model.fields.smallImage.fields.title" />
            </div>

            <div class="ml-xl-1">
             <arrow-link 
             text='view our projects'
             to='/our-projects' />
            </div>
            
          </div>
        </div>
        <!-- END :: BOX SECTION -->

        <!-- IMAGE OFFSET TEXT -->
        <div
        class="pl-md-1 d-none d-md-block">
          <split-text
          :visible='visibleWaypoint'
          type='lines'
          :wrapped='false'
          tag='p'
          innerClasses="color-text-color t9 t7-md t7-xl pre-wrap-md pre-wrap-md"
          :text="stripHtmlWithBreaks(model.fields.imageOffsetText)"
          @splited="onSplited" />
        </div>
        <!-- END :: IMAGE OFFSET TEXT -->
      </synchronized-waypoint>
      <!-- END :: RIGHT IMAGE BOX -->
    </div>

    <!-- BOTTOM CONTENT -->
    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines"
    class="color-text-color px-075 pl-md-1 mt-1 mt-md-025 mt-xl-0">
      <div
      class="t9 t7-md t7-xl pre-wrap-md d-md-none"
      v-html="model.fields.imageOffsetText" />

      <split-text
      :visible='visibleWaypoint'
      type='lines'
      :wrapped='false'
      tag='p'
      innerClasses="t9 t7-md t7-xl pre-wrap-md pre-wrap-md"
      :text="stripHtmlWithBreaks(model.fields.imageText)"
      @splited="onSplited" />

    </synchronized-waypoint>
    <!-- BOTTOM CONTENT -->
  </div>
</template>

<script>
import WaypointTrigger from "@/mixins/WaypointTrigger";
import { mapState } from 'vuex'

export default {
  name: "service-info-interior-design",

  data: () => ({
    delayAddition: .3
  }), 

  props: ["model"],

  computed: {
    ...mapState('app', [
      'width'
    ])
  },

  mixins: [WaypointTrigger]
};
</script>

<style lang="scss" scoped="">
.service-info-interior-design {
  .right-wrapper {
    @include media-breakpoint-up(md){
      left: -8.44rem;
    }

    @include media-breakpoint-up(xl){
      left: -20.5vw;
    }

    .inner-wrapper {
        @include media-breakpoint-up(md) {
            width: 43vw;
        }
        @include media-breakpoint-up(xl){
            height: 100%;
        }
    }
  }

  .custom-col {
    @include media-breakpoint-up(md){
      width: 100%;
    }

    @include media-breakpoint-up(xl){
      width: calc(54% + #{$s7});      
    }
  }

  .image-wrapper {
    margin-top: -$s4;

    @include media-breakpoint-up(md){
      margin-top: 0;
    }
  }

  .middle-content {
    @include media-breakpoint-up(md) {
      position: relative;
      left: -1rem;
    }

    @include media-breakpoint-up(xl) {
      left: 0;
    }
  }

  .custom-left-column {
    width: 100%;

    @include media-breakpoint-up(md){
      width: 56%;
    }
  }

  .custom-right-column {
    width: 100%;

    @include media-breakpoint-up(md){
      width: 44%;
    }
  }
}
</style>