<template>
<div class='scrolling-indicator-wrapper position-absolute b-0 center-x d-none d-md-block'>
  <scrolling-indicator-svg ref='indicator' class='scrolling-indicator' />

  <p
  @click='$emit("onClick")'
  class='position-absolute b-0 text-center center-x text-uppercase d-flex flex-column align-items-center lh-1 pb-05 cursor-pointer'>
    <span class='color-pale-silver d-inline-block text-element glossy'>{{caption}}</span>
    <span class='color-gold h6 d-inline-block text-element glossy'>{{text}}</span>
  </p>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ScrollingIndicatorSvg from '@/assets/svg/scrolling-indicator.svg?inline'
import { gsap } from 'gsap'

export default {
  name: "scrolling-indicator",

  components: {
    ScrollingIndicatorSvg
  },

  props: {
    text: String,
    caption: String
  },

  mounted() {
    setTimeout(() => {
      this.initAnimation()
    }, this.animationDelay * 1200)
  },

  methods: {
     initAnimation() {
      if(!this.$refs.indicator) {
        return
      }

      let arrows = this.$refs.indicator.querySelectorAll('.arrow')
      let texts = this.$el.querySelectorAll('.text-element')

      this.timeline = gsap.timeline({
        repeat: -1
      })

      this.timeline.set(this.$el, {
        opacity: 1
      })

      this.timeline.fromTo(this.$refs.indicator, {
        yPercent: 10,
        rotationY: -90,
        scale: 0.2,
        opacity: 0.5
      }, {
        opacity: 1,
        yPercent: 0,
        rotationY: 0,
        scale: 1,
        duration: 1.2,
        ease: 'Power1.easeInOut',
        transformOrigin: 'bottom'
      })

      this.timeline.fromTo(arrows, {
        opacity: 0.3,
        y: -250
      }, {
        opacity: 1,
        y: 150,
        duration: (i) => 2.6 - i / 1.5,
        stagger: .2,
        ease: 'Power1.easeOut',
        repeat: 1,
        repeatDelay: .25
      })


      this.timeline.to(this.$refs.indicator, {
        yPercent: 50,
        opacity: 0,
        scale: .4,
        rotationY: -90,
        duration: 1.2,
        transformOrigin: 'bottom'
      }, "-=.5")

      this.timeline.fromTo(texts, {
        yPercent: -70,
        opacity: 0
      }, {
        yPercent: 0,
        opacity: 1,
        duration: 1,
        stagger: -.1
      }, "-=.5")

      this.timeline.to(texts, {
        opacity: 0,
        yPercent: 100,
        stagger: -.1,
        duration: 1
      }, "+=1")

    }
  },

  computed: {
    ...mapState('app', [
      'animationDelay'
    ])
  }
};
</script>

<style lang="scss" scoped="">
.scrolling-indicator-wrapper {
  opacity: 0;
}
</style>