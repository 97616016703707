<template>
  <div class="about-header row bg-pale-silver px-075 px-md-1 pt-5 pt-md-6 pb-8 pb-md-025 position-relative">
    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines"
    class="col-md-6">

      <split-text
      :visible='visibleWaypoint'
      type='lines'
      :wrapped='false'
      tag='h1'
      innerClasses="tlarge t2-md t3-xl glossy text-uppercase text-center color-dark-text d-block d-md-none mb-1"
      :text="stripHtmlWithBreaks(model.fields.headline)"
      @splited="onSplited" />

      <div class="bg-beigev2 mb-1 mb-md-0 px-05 pt-05 pb-2 pb-xl-4 should-animate"
      data-preset='opacity,y,rotate'
      data-fromy='500'
      data-delay='.1'
      data-fromrotate="-25"
      data-torotate="0">
        <synchronized-picture-div
        loading="eager"
        :image="model.fields.image"
        :src='getImg(model, "image", "?w=750")'
        :sources="[
            {
                'src': getImg(model, 'image', '?w=1240'),
                'srcx2': getImg(model, 'image', '?w=1300'),
                'width': 1921
            },

            {
                'src': getImg(model, 'image', '?w=880'),
                'srcx2': getImg(model, 'image', '?w=1000'),
                'width': 1201
            },

            {
                'src': getImg(model, 'image', '?w=664'),
                'width': 768
            }
        ]" />
      </div>
    </synchronized-waypoint>

    <div class="col-md-6 d-flex flex-column justify-content-between pt-xl-1">
      <synchronized-waypoint
      @toggleOneDirectionVisible="toggleWaypointWithLines">
        <div>
          <split-text
          :visible='visibleWaypoint'
          type='lines'
          :wrapped='false'
          tag='h1'
          innerClasses="t2-md t3-xl glossy text-uppercase color-dark-text-opacity d-none d-md-block pl-md-05 pl-xl-1"
          :text="stripHtmlWithBreaks(model.fields.headline)"
          @splited="onSplited" />
        </div>

        <div 
        v-html='width > 768 ? AboutDecor : AboutDecorMob' 
        class='about-decor d-none d-md-block pl-md-05 pl-xl-1' />
      </synchronized-waypoint>
      
      <synchronized-waypoint
      @toggleOneDirectionVisible="toggleWaypoint"
      class="text-right w-100">
        <h2
        v-html="model.fields.content[0]"
        class="color-dark-text t9 t7-md t7-xl glossy text-uppercase lh-08 mb-xl-025 pr-md-025 pr-xl-2 should-animate"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.1' />
      </synchronized-waypoint>
    </div>

    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypoint"
    class="text-right w-100 pr-md-025 pr-xl-2 mt-md-05 mb-3 mb-md-0 position-relative">
      <h2
      v-if="index > 0"
      v-for="(item, index) in content"
      :key="index"
      v-html="item"
      class="t9 t7-md t7-xl glossy text-uppercase color-dark-text should-animate"
      data-preset='opacity,y'
      data-fromy='50'
      :data-delay='.1 + index / 10'/>
      
      <div 
      v-html='AboutDecorMob' 
      class='about-decor d-block d-md-none' />
    </synchronized-waypoint>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import _get from 'lodash/get'
import WaypointTrigger from "@/mixins/WaypointTrigger"

import AboutDecor from '@/assets/svg/about-desk.svg?raw'
import AboutDecorMob from '@/assets/svg/about-mob.svg?raw'

export default {
  name: "about-header",

  data: () => ({
    AboutDecor,
    AboutDecorMob
  }),

  props: ["model", "index"],

  mixins: [WaypointTrigger],

  computed: {
    ...mapState('app', [
      'width'
    ]),

    content() {
      return _get(this.model, 'fields.content')
    }
  }
};
</script>

<style lang="scss" scoped="">
.about-header {
  .about-decor {
    position: absolute;
    left: 0;
    bottom: -22rem;
    width: 22rem;
    height: auto;

    @include media-breakpoint-up(md) {
      position: relative;
      bottom: 0;
      width: 28vw;
      height: 28vw;
    }

    @include media-breakpoint-up(xl) {
      width: 31vw;
      height: 31vw;
    }
  }
}
</style>