<template>
  <synchronized-waypoint  
  tag='footer'
  @toggleOneDirectionVisible="toggleWaypoint"
  class="page-footer position-relative pb-2 text-center glossy"
  :class="{
    'bg-sandstone': sandstone,
    'bg-light-olive': olive,
    'bg-beige': beige
    }">

    <div 
    class="text-center pb-2 pb-md-6 should-animate"
    data-preset='opacity,y'
    data-fromy='50'
    data-delay='.1'>
        <span 
        v-if='!hideFin'
        class="roboto t14 t11-md t13-xl color-blackv3">FIN.</span>
    </div>
    
    <h2
    class="t8 t6-md t6-xl py-025 py-md-0 color-dark-text-opacity text-uppercase should-animate"
    data-preset='opacity,y'
    data-fromy='50'
    data-delay='.2'
    v-html="footer.fields.headline" />

    <div class="d-flex flex-column">
        <nuxt-link
        v-for="(page, index) in pages"
        :key="index"
        v-html="page.fields.title"
        :to="page.url"
        class="t8 t6-md t6-xl py-05 py-md-0 color-blackv3 text-uppercase color-blackv3 should-animate d-flex mx-auto service-link"
        data-preset='opacity,y'
        data-fromy='50'
        :data-delay='.3 + index / 10' />
    </div>

    <div class="d-flex flex-column justify-content-center">
        <div class="should-animate d-flex mx-auto"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.4'>
            <nuxt-link
            :to='{path: "/", query: {service: true}}'
            class="t8 t6-md t6-xl py-025 py-md-0 color-blackv3 text-uppercase service-link"
            v-html="$t('services')" />
        </div>

        <!-- Changed by client request -->
        <!-- <div 
        class="contact-wrapper"
        ref='contentWrapper'
        :class="{'disabled': !open}">
            <div 
            ref='content'
            class="d-flex flex-column">
                <nuxt-link
                v-for="(service, index) in services"
                :key="index"
                v-html="service.fields.title"
                :to="service.url"
                class="t10 t8-md t8-xl text-uppercase should-animate service-link"
                data-preset='opacity,y'
                data-fromy='50'
                :data-delay='.1 + index / 10' />
            </div>
        </div> -->
    </div>

    <div class="d-flex flex-column">
        <span class="t8 t6-md t6-xl py-05 py-md-0 color-dark-text-opacity text-uppercase should-animate"
        data-preset='opacity,y'
        data-fromy='80'
        data-delay='.5'>OR</span>
        <div class="d-flex mx-auto should-animate"
        data-preset='opacity,y'
        data-fromy='80'
        data-delay='.6'>
            <nuxt-link
            :to='{path: "/", query: {contact: true}}'
            v-html="$t('contact')"
            class="t8 t6-md t6-xl color-blackv3 text-uppercase service-link" />
        </div>
    </div>
  </synchronized-waypoint>
</template>

<script>
import { mapState } from 'vuex'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
// import Collapse from '@/mixins/collapse'
import WaypointTrigger from "@/mixins/WaypointTrigger"

export default {
  name: "page-footer",

  data: () => ({
    bounds: {}
  }),

  props: ["model", "sandstone", "olive", "beige", "hideFin"],

  mixins: [WaypointTrigger],

  // inject: ['scrollingProps'],

  watch: {
    // open(open) {
    //   if(open){
    //     return
    //   }

    //   this.bounds = this.$el.getBoundingClientRect()
    //   this.expandedHeight = this.$refs.contentWrapper.offsetHeight

    //   let top = this.bounds.y + this.scrollingProps.currentScroll - this.height + this.bounds.height - this.expandedHeight

    //   this.$bus.$emit('scrollTo', top)
    // }
  },

  mounted() {
    // console.log("FOOTER!", this.footer.fields.pages, this.activePage)
  },

  computed: {
    ...mapState('pages', [
      'footer',
      'activePage'
    ]),

    ...mapState('app', [
       'width',
       'height'
    ]),

    pages() {
        let pages = _get(this.footer, 'fields.pages')

        return _filter(pages, (p, i) => {
            let show = p.sys.id != this.activePage.sys.id
            return p.fields.pageTemplate == "ProjectsPage" ? (this.activePage.sys.contentType.sys.id != "project") && show : show
        })
    },

    services() {
        return _get(this.footer, 'fields.servicePages')
    }
  }
};
</script>

<style lang="scss" scoped="">
.page-footer {
    margin-top: -1px;
    .service-link {
        &:hover {
            color: $darkTextOpacity;
        }
    }
}
</style>