<template>
  <div class="services-wrapper px-xl-5 pt-xl-5 pb-xl-1">

    <synchronized-waypoint 
    @toggleOneDirectionVisible="toggleWaypoint"
    class="h-100 d-none d-xl-block enabled overflow-hidden">

        <div 
        data-props='yPercent'
        data-fromy-percent='80'
        data-toy-percent='0'
        data-delay='.1'
        data-dur='1.2'
        class="row bg-light-creme h-100 d-none d-xl-flex should-animate">
            <div class="col-md-6 position-relative">
                <!-- <img 
                src="/img/decor.webp"
                class="decor position-absolute r-0 t-0 h-100"
                alt="Kim Kneipp"> -->
                <synchronized-picture-div
                :fill="true"
                class="decor"
                :alt="'Kim Kneipp'"
                :src='"/img/decor.webp"'
                :sources="[
                    {
                      'src': '/img/decor.webp',
                      'srcx2': '/img/decor.webp',
                      'width': 1921
                    },

                    {
                      'src': '/img/decor.webp',
                      'srcx2': '/img/decor.webp',
                      'width': 1201
                    },

                    {
                      'src': '/img/decor.webp',
                      'width': 768
                    }
                ]" />

                <div class="gradient position-absolute r-0 t-0 h-100"></div>

                <div class="d-flex flex-column h-100 pb-1">

                    <div class="row mb-md-3 flex-grow-1">
                        <div class="col-md d-flex justify-content-between">
                            <span 
                            class="glossy text-right t16-xl p-1">1</span>
                            <p
                            v-html="$t('studio-services')"
                            class="text-uppercase t15-md t15-xl roboto text-right p-1 color-grey-text2" />
                        </div>
                        <div class="col-custom bg-light-creme2 pl-1 copy-box position-relative z-1 pb-2 d-flex flex-column">

                            <div 
                            v-if="model.fields.image"
                            class='flex-grow-1 position-relative'>
                              <synchronized-picture-div
                              :fill="true"
                              :alt="model.fields.image.fields.title"
                              :src='getImg(model, "image", "?w=560")'
                              :sources="[
                                {
                                  'src': getImg(model, 'image', '?w=658', 90),
                                  'srcx2': getImg(model, 'image', '?w=700'),
                                  'width': 1921
                                },

                                {
                                  'src': getImg(model, 'image', '?w=640'),
                                  'srcx2': getImg(model, 'image', '?w=700'),
                                  'width': 1201
                                },

                                {
                                  'src': getImg(model, 'image', '?w=600'),
                                  'width': 768
                                }
                                ]" />
                            </div>

                            <p class="color-grey-text2 t15-md t15-xl text-gray roboto text-uppercase pt-05">kim kneipp©</p>
                        </div>
                    </div>

                    <div 
                    data-preset='y'
                    data-fromy='180'
                    data-toy='0'
                    data-dur='2'
                    data-delay='.1'
                    class="text-center should-animate">
                        <nuxt-link
                        :to="model.fields.services[0].url"
                        v-html="model.fields.services[0].fields.title" 
                        class="t4-xl text-uppercase text-center glossy color-blackv2 d-block opacity-link"></nuxt-link>
                    </div>
                </div>
            </div>

            <!-- RIGHT SIDE -->
            <div class="col-md-6 position-relative">

                <!-- BOX - 2 - GOLDEN -->
                <nuxt-link
                :to="model.fields.services[1].url" 
                data-preset='yPercent'
                data-fromy-percent='40'
                data-toy-percent='0'
                data-dur='2'
                data-delay='.1'
                class="col-custom-2 flex-column pl-1 position-absolute t-0 l-0 pt-1 pb-md-1 pr-md-1 z-2 d-flex should-animate justify-content-end box">
                    <!-- <proportion-div
                    :proportion="1"> -->
                    <div class='bg bg-dark-gold full-absolute' />

                    <div class="box-1 flex-grow-1 d-flex flex-column justify-content-between h-100 position-relative z-2 mix-multiply">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="dot"></div>
                            <span 
                            class="glossy text-right t16-xl">2</span>
                        </div>
                        <div class="title">
                            <h2
                            v-html="model.fields.services[1].fields.title" 
                            class="t6-xl text-uppercase glossy color-blackv2 d-inline" />
                            <proportion-div
                            :proportion=".17"
                            class="col-2 d-inline-block">
                                <div class="w-100 h-100 image-frame">
                                    <synchronized-picture-div
                                    v-if="model.fields.services[1]"
                                    :alt="stripHtml(model.fields.services[1].fields.title)"
                                    :src='getImg(model.fields.services[1], "image", "?w=330")'
                                    :fill="true"
                                    innerClasses="px-025 pt-025 pb-075" 
                                    :sources="[
                                        {
                                          'src': getImg(model.fields.services[1], 'image', '?w=1370', 90),
                                          'srcx2': getImg(model.fields.services[1], 'image', '?w=2370'),
                                          'width': 1921
                                        },

                                        {
                                          'src': getImg(model.fields.services[1], 'image', '?w=370'),
                                          'srcx2': getImg(model.fields.services[1], 'image', '?w=1370'),
                                          'width': 1201
                                        },

                                        {
                                          'src': getImg(model.fields.services[1], 'image', '?w=360'),
                                          'width': 768
                                        }
                                    ]" />
                                </div>
                            </proportion-div>
                        </div>
                    </div>
                    <!-- </proportion-div> -->
                </nuxt-link>
                <!-- END :: BOX - 2 - GOLDEN -->

                <!-- BOX - 3 -->
                <nuxt-link
                data-preset='yPercent'
                data-fromy-percent='40'
                data-toy-percent='0'
                data-dur='2'
                data-delay='.1'
                :to="model.fields.services[2].url" 
                class="col-md-9 flex-column position-absolute t-0 l-0 pt-1 pb-md-1 pr-md-1 z-1 d-block should-animate box-2 box">
                    
                    <div class='bg bg-sandstone full-absolute' />

                    <div class="d-flex flex-column justify-content-between h-100 position-relative z-2 mix-multiply">
                        <span 
                        class="glossy text-right pr-xl-1">3</span>
                        
                        <!-- <div class='title'> -->
                            <div class="position-relative h-100 title">
                                <synchronized-picture-div
                                v-if="model.fields.services[2]"
                                :alt="stripHtml(model.fields.services[2].fields.title)"
                                :src='getImg(model.fields.services[2], "image", "?w=487")'
                                :fill="true"
                                innerClasses="pr-md-5"
                                  :sources="[
                                {
                                  'src': getImg(model.fields.services[2], 'image', '?w=910', 90),
                                  'srcx2': getImg(model.fields.services[2], 'image', '?w=1910'),
                                  'width': 1921
                                },

                                {
                                  'src': getImg(model.fields.services[2], 'image', '?w=640'),
                                  'srcx2': getImg(model.fields.services[2], 'image', '?w=1640'),
                                  'width': 1201
                                },

                                {
                                  'src': getImg(model.fields.services[2], 'image', '?w=648'),
                                  'width': 768
                                }
                                ]" />
                            </div>
                            <div class='title'>
                                <h2
                                v-html="model.fields.services[2].fields.title" 
                                class="t6-xl text-uppercase glossy color-blackv2 pl-1 mt-1" />
                            </div>
                        <!-- </div> -->

                    </div>

                </nuxt-link>
                <!-- END :: BOX - 3-->

                <!-- BOX - 4 -->
                <nuxt-link
                :to="model.fields.services[3].url" 
                class="w-100 h-100 bg-light-creme flex-column text-right position-absolute t-0 l-0 pt-1 pb-md-1 pr-1 d-flex justify-content-between opacity-link">
                    <span 
                    class="glossy">4</span>

                    <!-- <div class="h-100">
                        <div class="position-relative h-100">
                            <image-div
                            innerClasses="pr-md-7"
                            :src="getImg(model.fields.services[3], 'image', '?w=1400')" />
                        </div>
                    </div> -->

                    <h2
                    data-preset='y'
                    data-fromy='180'
                    data-toy='0'
                    data-dur='2'
                    data-delay='.1'
                    v-html="model.fields.services[3].fields.title" 
                    class="t6-xl text-uppercase glossy color-blackv2 mt-1 should-animate" />
                </nuxt-link>
                <!-- END :: BOX - 4 -->
            </div>
            <!-- END :: RIGHT SIDE -->
        </div>

    </synchronized-waypoint>

    <!-- MOBILE/TABLET -->
    <div class="d-block d-xl-none bg-light-creme pt-3 pt-md-4">
        <div class="col-9 col-md-5 col-xl-6 position-relative mx-auto on-top">
            <div
            v-html="$t('studio-services')"
            class="text-uppercase t15 roboto text-right color-grey-text2 mb-05" />

            <div class="d-flex flex-column justify-content-between h-100">
                <div class="bg-light-creme2 px-05 copy-box position-relative">
                   <synchronized-picture-div
                      :proportion='null'
                      v-if="model.fields.image"
                      :alt="stripHtml(model.fields.image.fields.title)"
                      :image='model.fields.image'
                      :src='getImg(model, "image", "?w=560")'
                      :sources="[
                        {
                          'src': getImg(model, 'image', '?w=1640', 90),
                          'srcx2': getImg(model, 'image', '?w=2640'),
                          'width': 1921
                        },

                        {
                          'src': getImg(model, 'image', '?w=640'),
                          'srcx2': getImg(model, 'image', '?w=1640'),
                          'width': 1201
                        },

                        {
                          'src': getImg(model, 'image', '?w=600'),
                          'width': 768
                        }
                        ]" />

                    <div 
                    class="color-grey-text2 t15 text-gray roboto text-uppercase pb-1 pb-xl-2 mt-05">kim kneipp©</div>
                </div>
            </div>
        </div>

        <div class="row inner-row">
            <div class="col-6 position-relative">
                <!-- BOX - 3 -->
                <nuxt-link
                :to="model.fields.services[2].url" 
                class="bg-sandstone flex-column pt-1 pt-md-2 pb-075 pb-md-1 pr-md-2 z-1 d-block">
                        <div class="d-flex flex-column justify-content-between h-100">
                            
                            <div class="position-relative h-100">
                                <synchronized-picture-div
                                  :src='getImg(model.fields.services[2], "image", "?w=450")'
                                  innerClasses="pr-2 pr-md-3 mix-multiply"
                                  :image="model.fields.image"
                                  v-if="model.fields.services[2]"
                                  :alt="stripHtml(model.fields.services[2].fields.title)"
                                  :sources="[
                                    {
                                      'src': getImg(model.fields.services[2], 'image', '?w=910', 90),
                                      'srcx2': getImg(model.fields.services[2], 'image', '?w=1910'),
                                      'width': 1921
                                    },

                                    {
                                      'src': getImg(model.fields.services[2], 'image', '?w=640'),
                                      'srcx2': getImg(model.fields.services[2], 'image', '?w=1640'),
                                      'width': 1201
                                    },

                                    {
                                      'src': getImg(model.fields.services[2], 'image', '?w=640'),
                                      'width': 768
                                    }
                                    ]" />
                            </div>
                            <div>
                                <h2
                                v-html="model.fields.services[2].fields.title" 
                                class="t8 t4-1-md t6-xl text-uppercase glossy color-blackv2 pl-075 pl-md-1 mt-1 mt-md-2" />
                            </div>
                        </div>
                </nuxt-link>
                <!-- END :: BOX - 3-->
            </div>
            <div class="col-6 interiour">

                <!-- BOX - 2 -->
                <nuxt-link
                :to="model.fields.services[1].url" 
                class="bg-dark-gold pl-075 pl-md-1 pt-2 mt-05 mt-md-0 pt-md-4 pb-md-1 pr-md-1 pr-lg-2 z-2 position-absolute l-0 t-0 box-2 w-100">
                    <div class="d-flex flex-column justify-content-between pb-md-7 box-2">
                        
                        <div class="mt-xl-2">
                            <h2
                            v-html="model.fields.services[1].fields.title" 
                            class="t8 t4-1-md t6-xl text-uppercase glossy color-blackv2 d-inline" />
                            <proportion-div
                            :proportion=".24"
                            class="custom-col d-inline-block">
                                <div class="w-100 h-100 image-frame">
                                        <synchronized-picture-div
                                        :src='getImg(model.fields.services[1], "image", "?w=330")'
                                        :fill="true"
                                        v-if="model.fields.services[1]"
                                        :alt="stripHtml(model.fields.services[1].fields.title)"
                                        innerClasses="px-025 pt-025 pb-075"
                                        :image="model.fields.services[1]" 
                                          :sources="[
                                        {
                                          'src': getImg(model.fields.services[1], 'image', '?w=1370', 90),
                                          'srcx2': getImg(model.fields.services[1], 'image', '?w=2370'),
                                          'width': 1921
                                        },

                                        {
                                          'src': getImg(model.fields.services[1], 'image', '?w=370'),
                                          'srcx2': getImg(model.fields.services[1], 'image', '?w=1370'),
                                          'width': 1201
                                        },

                                        {
                                          'src': getImg(model.fields.services[1], 'image', '?w=360'),
                                          'width': 768
                                        }
                                        ]" />
                                </div>
                            </proportion-div>
                        </div>
                    </div>
                </nuxt-link>
                <!-- END :: BOX - 2 -->

                <!-- BOX - 4 -->
                <nuxt-link
                :to="model.fields.services[3].url" 
                class="w-100 h-100 bg-light-creme flex-column text-right position-absolute t-0 l-0 pt-2 pb-md-1 pr-1 d-flex justify-content-end align-items-end">

                    <!-- <div class="h-100">
                        <div class="position-relative h-100">
                            <image-div
                            innerClasses="pr-md-7"
                            :src="getImg(model.fields.services[3], 'image', '?w=1400')" />
                        </div>
                    </div> -->

                    <h2
                    v-html="model.fields.services[3].fields.title" 
                    class="t8 t4-1-md text-uppercase glossy color-blackv2 mt-1 mb-05 mb-md-0" />
                </nuxt-link>
                <!-- END :: BOX - 4 -->
            </div>
        </div>

        <div class="text-center py-1 py-md-2 px-1 px-md-3 position-relative">
            <div class="gradiant" />

            <!-- <img 
            src="/img/services-decor.webp"
            class="decor position-absolute r-0 t-0 w-100"
            alt="Kim Kneipp"> -->

            <synchronized-picture-div
            :fill="true"
            class="decor"
            :alt="'Kim Kneipp'"
            :src="'/img/services-decor.webp'"
            :sources="[
                {
                  'src': '/img/services-decor.webp',
                  'srcx2': '/img/services-decor.webp',
                  'width': 1921
                },

                {
                  'src': '/img/services-decor.webp',
                  'srcx2': '/img/services-decor.webp',
                  'width': 1201
                },

                {
                  'src': '/img/services-decor.webp',
                  'width': 768
                }
            ]" />

            <nuxt-link
            :to="model.fields.services[0].url"
            v-html="model.fields.services[0].fields.title" 
            class="t6 t4-1-md text-uppercase text-center glossy color-blackv2 position-relative z-1"></nuxt-link>
        </div>
    </div>
    <!-- END :: MOBILE/TABLET -->

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import StickySection from '@/mixins/StickySection'
import _get from 'lodash/get'

export default {
  name: "services-wrapper",

  props: ["model"],

  mixins: [StickySection],

  watch: {
    isServicePage(iscp) {
        if(iscp){
            this.scrollTo()
        }
    }
  },

  mounted(){
    if(this.isServicePage){
        setTimeout(this.scrollTo, 150)
    }
  },

  methods: {
    scrollTo(){
        this.$bus.$emit('scrollTo', this.height, 10)
        this.$router.push("/")
    }
  },

  computed: {
    services() {
        return _get(this.model, 'fields.services')
    },

    isServicePage() {
        return this.$store.state.route.query.service == 'true'
    }
  }
};
</script>

<style lang="scss" scoped="">
.services-wrapper {
    // height: 120vh;
    @include media-breakpoint-up(xl) {
        height: 100%;
    }
    .col-custom {
        width: 40vh;
    }

    .box-1{

    }

    .box{
        .bg{
            top: -1.5rem;
            height: calc(100% + 1.5rem);
        }

        .bg, .title{
            transition: transform .4s, opacity .4s;
        }

        &:hover{
            .bg, .title{
                transform: translateY(1.5rem);
            }
            .title{
                opacity: .8;
            }
        }
    }

    .custom-col {
        flex: 0 0 22%;
        max-width: 22%;
        width: 100%;
        vertical-align: top;
    }

    .col-custom-2{
        width: 28vw;
        min-height: 48vh;
        &:hover {
            .title {
                @include media-breakpoint-up(xl) {
                    // padding-top: 5% !important;
                }
            }
        }
    }
    .decor {
        mix-blend-mode: soft-light;
        width: 19vw;
    }
    .copy-box, .image-frame {
        box-shadow: 0px 1px 2px rgba($black, .25)
    }
    .image-frame {
        mix-blend-mode: multiply;
        transform: rotate(3deg);
        @include media-breakpoint-up(xl) {
            transform: rotate(0deg);
        }
    }
    .box-2 {
        height: 55.2vw;

        @include media-breakpoint-up(md) {
            height: 52.5vw;
        }

        @include media-breakpoint-up(xl) {
            width: 45vw;
            height: 74vh;
        }
    }

    .inner-row {
        margin-top: -5vw;
        @include media-breakpoint-up(md) {
            margin-top: -6.5vw;
        }
    }

    .gradiant {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 30vw;
        background: linear-gradient(90deg, rgba(206, 206, 190, 0) 0%, rgba(147, 147, 116, 0.5) 100%);
        mix-blend-mode: multiply;
        opacity: 0.2;
        // transform: rotate(-90deg);

        @include media-breakpoint-up(xl){
            background: none;
        }
    }
    .dot {
        height: 1rem;
        width: 1rem;
        background-color: #3E3A32;
        border-radius: 100%;
    }
}
</style>