var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('synchronized-waypoint',{staticClass:"text-and-small-image color-dark-text d-flex px-075 pl-md-125 pl-xl-24 pr-xl-32 py-1 mb-4 mb-md-7 mb-xl-0 py-xl-2",class:{
  'with-content': _vm.model.fields.richContent,
  'without-content': !_vm.model.fields.richContent
},on:{"toggleOneDirectionVisible":_vm.toggleWaypointWithLines}},[_c('div',{staticClass:"col-md-10 col-xl-9 pr-md-3 pr-xl-5"},[(_vm.model.fields.richContent)?_c('split-text',{attrs:{"tag":"p","innerClasses":"t9 t7-md t8-xl text-uppercase glossy","text":_vm.model.fields.richContent},on:{"splited":_vm.onSplited}}):_vm._e(),_vm._v(" "),(_vm.model.fields.caption)?_c('div',{staticClass:"content color-dark-text pt-1 pr-md-1 pr-lg-155 should-animate",attrs:{"data-preset":"opacity,y","data-fromy":"-20","data-delay":"1"}},[_c('p',{staticClass:"t13-1 t16-2-md t13-xl text-uppercase",domProps:{"innerHTML":_vm._s(_vm.model.fields.caption)}})]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-2 col-xl-3 d-none d-md-flex justify-content-end "},[_c('div',{staticClass:"small-image should-animate mix-multiply",attrs:{"data-preset":"opacity,y,rotate","data-fromy":"50","data-delay":".3","data-fromrotate":"-20","data-torotate":"0"}},[_c('synchronized-picture-div',{staticClass:"position-relative",attrs:{"image":_vm.model.fields.image,"src":_vm.getImg(_vm.model, "image", "?w=350"),"sources":[
          {
              'src': _vm.getImg(_vm.model, 'image', '?w=500'),
              'srcx2': _vm.getImg(_vm.model, 'image', '?w=600'),
              'width': 1921
          },

          {
              'src': _vm.getImg(_vm.model, 'image', '?w=400'),
              'srcx2': _vm.getImg(_vm.model, 'image', '?w=500'),
              'width': 1201
          },

          {
              'src': _vm.getImg(_vm.model, 'image', '?w=370'),
              'width': 768
          }
          ]}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }