<template>
  <project-detail :model='model' />
</template>

<script>
import BasePage from '@/mixins/BasePage';
import ProjectDetail from '@/components/projectDetail/ProjectDetail'

export default {
  name: 'ProjectInnerPage',

  components: {
    ProjectDetail
  },

  mounted() {
    this.$store.dispatch('app/setStateApp', {
      passedViewport: false
    })
  },

  async asyncData ({$contentful, route, store, error}) {
    const urls = store.getters['pages/getState']('urls')

    let activePage = await $contentful.loadByType({
      'content_type': 'project',
      'fields.slug': route.params.id,
      'include': 3,
      'limit': 1
    }, {
        parse: true,
        single: true,
        urls
    })

    if(!activePage){
       error({
        statusCode: 404,
        message: "The server successfully processed the request and is not returning any content."
      })
      return
    }

    store.dispatch('pages/SET_STATE', {
      activePage
    })

    return {
      model: activePage
    }
  }
}
</script>