<template>
  <div 
  :style="cssVars"
  ref='slider' 
  :class='activeProgress < .4 ? "bg-pale-silver" : "bg-light-olive"'
  class="sticky-sections">

    <div class='sections-wrapper'>
      <component 
      class='sticky-section'
      v-for='(component, index) in sections'
      :model='component'
      :progress='activeProgress + 1'
      :is='getComponentTemplate(component)'
      :key='index'
      :index="index" />
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import ServicesWrapper from '@/components/services/ServicesWrapper'
import ContactComponent from '@/components/contactComponent/ContactComponent'
import BasePage from '@/mixins/BasePage'
import { ScrollingObservable } from "@/observables/ScrollingObservable.js"

export default {
  name: "sticky-sections",

  components: {
    ServicesWrapper,
    ContactComponent
  },

  data: () => ({
    snap: true,
    topBound: 0,
    playing: false
  }),

  props: {
    sections: Array
  },

  mixins: [
    BasePage
  ],

  methods: {
      ...mapActions('app', [
          'setStateApp'
      ]),

       // returning the scroll progress from 0 to 1 in between start/end pixels
      scrollProgress(start, end, scrollOffset)  {
          var scrollOffset = scrollOffset || this.scrollOffset,
              factor = (end - start) / 100,
              scrollStart = (scrollOffset - start) / factor,
              percentage = (scrollOffset >= start && scrollOffset <= end) ? scrollStart / 100 :
                  (scrollOffset > start) ? 1 : 0

          return percentage
      },

      resizeHandler()  {
          setTimeout(() => {
            if(!this.$refs.slider){
              return
            }
            let bounds = this.$refs.slider.getBoundingClientRect()
            this.topBound = Math.floor(bounds.top + ScrollingObservable.scrollTop - this.top)
            this.snap = this.width > 1024
          })
      }
  },

  computed: {
     ...mapState('app', [
      'width',
      'height'
    ]),

    cssVars() {
      return {
        '--height': this.elHeight + 'px',
        '--transform': `translate3d(0,${this.top}px,0)`,
        '--page-height': this.height + 'px'
      }
    },

    numOfSlides()  {
        return this.sections ? this.sections.length : 0
    },

    // making it sticky
    top() {
      return this.progress * this.move
    },

    // make the virual scrolling space
    elHeight() {
      return (this.numOfSlides + 1) * this.height
    },

    // total move
    move() {
      return this.elHeight - this.height 
    },

    // total progress
    progress() {
      if(this.mobileVersion){
        return 0
      }

      return this.scrollProgress(this.topBound, this.topBound + this.move, ScrollingObservable.scrollTop)
    },

    // currently animating slide
    activeProgress() {
      return (this.numOfSlides - 1) * this.progress
    }

  }

}
</script>

<style lang="scss" scoped="">
.sticky-sections {
  transition: background-color 1.4s;
  will-change: transform,background-color;

  .sticky-section{
    @include media-breakpoint-down(lg){
      transform: none!important;

      /deep/ {
        .process-content{
          height: auto!important;
        }

        .should-animate{
          opacity: 1!important;
          transform: none!important;
        }
      }
    }
  }

  @include media-breakpoint-up(xl){
    .sections-wrapper {
      height: 100vh;
      height: var(--page-height);
    }
    will-change: transform;
    transform: var(--transform);
    height: var(--height);
  }

}
</style>