<template>
    <button 
    @mouseenter='drawIt'
    class="hamburger d-flex align-items-center cursor-pointer" 
    type="button" 
    :class='{
        "is-active" : menuOpen || closeButton,
        "dark": dark,
        "white": !dark
    }'>

        <custom-transition :duration='.8' mode='out-in'>
            <div :key='menuOpen' class="mr-05">
                <span 
                :class='!dark ? "color-white" : "color-olivev2"'
                class="text-size "
                v-if="!menuOpen">MENU</span>
                <span 
                class="color-black text-size back-text" 
                v-if="menuOpen">BACK</span>
            </div>
        </custom-transition>

        <transition
        @enter='onEnter'
        @leave='onLeave'
        mode='out-in'>
            <div 
            :key='menuOpen' 
            class="hamburger-box d-flex flex-center">
                <svg 
                v-if="menuOpen"
                class="close-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path 
                    class='draw-me'
                    direction='reverse'
                    d="M8.02539 3.31543C11.7742 8.16928 16.0391 12.921 18.8362 18.4052C19.4314 19.5721 19.9881 20.5431 20.956 21.4684" stroke="#252525" stroke-width="3" stroke-linecap="square" stroke-linejoin="bevel"/>
                    <path 
                    class='draw-me'
                    direction='normal'
                    d="M20.108 2.64163C17.9036 4.50834 15.8525 6.17301 13.8698 8.29392C12.6175 9.63359 11.7656 11.4286 10.4119 12.6547C8.01396 14.8263 5.98076 17.869 2.9032 19.0045" stroke="#252525" stroke-width="3" stroke-linecap="square" stroke-linejoin="bevel"/>
                </svg>
                <patch
                v-else
                class="open-icon z-1" />
            </div>
        </transition>

    </button>
</template>
<script>
import Patch from '@/assets/svg/patch.svg?inline'
import { gsap } from 'gsap'
import DrawSVGPlugin from '~/plugins/scripts/DrawSVGPlugin.js'
import _each from 'lodash/each'

export default {
    name: "hamburger",

    props: ["closeButton", "dark"],

    components: {
        Patch
    },

    mounted() {
        gsap.registerPlugin(DrawSVGPlugin)

    },

    methods: {
        onEnter(el, onComplete) {
            if(this.menuOpen){

                let tl = gsap.timeline({})
                let els = el.querySelectorAll('.draw-me')

                _each(els, el => {
                    let reversed = el.dataset.direction == "reverse"
                    let from = reversed ? "0% 100%" : "0%"
                    let to = reversed ? "100% 100%" : "100%"
                    let dl = !reversed ? .25 : 0;

                    tl.fromTo(el, .5, {
                        drawSVG: from
                    }, {
                        drawSVG: to,
                        delay: .1
                    }, `-=${dl}`)
                })

            } else {
                gsap.from(el, 1, {
                    opacity: 0,
                    onComplete
                })
            }
        },

        onLeave(el, onComplete) {
            gsap.to(el, 1, {
                opacity: 0,
                onComplete
            })
        },

        drawIt(onComplete) {
            if(!this.menuOpen){
                return
            }

            let tl = gsap.timeline({})

            let els = this.$el.querySelectorAll('.draw-me')

            _each(els, el => {
                let drawSVG = el.dataset.direction == 'reverse' ? '0% 100%' : '0%'
                // let drawSVGReversed = el.dataset.direction == 'reverse' ? '0%' : '0% 100%'

                tl.to(el, .5, {
                    drawSVG,
                    onComplete: () => {
                        // tl.set(el, {
                        //     drawSVG: drawSVGReversed
                        // })
                    }
                }, "-=.25")
            })

            _each(els, (el, index) => {
                let drawSVG = el.dataset.direction == 'reverse' ? '100% 100%' : '100% 100%'
                let dl = index == 0 ? 0 : .25

                tl.to(el, .5, {
                    drawSVG: '100%'
                }, `-=${dl}`)
            })
            
        }
    },

    computed: {
        menuOpen() {
            return this.$store.getters["app/getState"]("menuOpen");
        }
    }
};

</script>
<style lang="scss" scoped="">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~/assets/css/variables.scss";

.hamburger {
    display: inline-block;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &.dark{
        .open-icon{
            fill: $olivev2;
        }
    }

    &.white{
        .open-icon{
            fill: $white;
        }
    }
}

.hamburger:focus {
    outline: none;
}
.hamburger:hover {
    .hamburger-box{
        transition: .4s;
        transform: scale(.8);
    }
}

.is-active:hover {
    .hamburger-box {
        // transform: rotate(90deg) scale(1.1);
    }
}

.hamburger-box {
    position: relative;
    display: block;
    cursor: pointer;
    transition: transform .3s;
    z-index: 2;
}


.close-icon, .open-icon {
    width: 1rem;
    height: 1rem;
    transition: 0.4s;
}
</style>
