var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-and-image-five padding-x bg-pale-silver bg-light-creme pb-1"},[_c('synchronized-waypoint',{staticClass:"text-center pt-3 pt-md-2 pt-xl-3",on:{"toggleOneDirectionVisible":_vm.toggleWaypointWithLines}},[(_vm.model.fields.caption)?_c('h3',{staticClass:"t14 t11-md t13-xl text-uppercase should-animate",attrs:{"data-preset":"opacity,y","data-fromy":"50","data-delay":".1"},domProps:{"innerHTML":_vm._s(_vm.model.fields.caption)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pb-2 roboto t14 t11-md t13-xl text-center py-2 py-md-4 color-dark-text number should-animate",attrs:{"data-preset":"opacity,y","data-fromy":"50","data-delay":".2"}},[_vm._v("III")]),_vm._v(" "),_vm._l((_vm.headline),function(item,index){return _c('h4',{key:index,staticClass:"d-inline d-md-block should-animate",class:{'ml-05 ml-md-0': index == 1},attrs:{"data-preset":"opacity,y","data-fromy":"50","data-delay":.3 + index / 10}},[_c('span',{staticClass:"t7 t5-md t4-xl glossy text-uppercase position-relative z-1 color-blackv2 color-dark-text-opacity",domProps:{"innerHTML":_vm._s(item)}})])}),_vm._v(" "),_c('div',{staticClass:"image-shadow col-custom mx-auto bg-light-creme3 px-025 px-md-05 pt-025 pt-md-05 pb-1 pb-md-2 mt-md-1 mix-multiply"},[_c('synchronized-picture-div',{staticClass:"h-100 w-100 should-animate",attrs:{"image":_vm.model.fields.image,"src":_vm.getImg(_vm.model, "image", "?w=415"),"sources":[
          {
              'src': _vm.getImg(_vm.model, 'image', '?w=582'),
              'srcx2': _vm.getImg(_vm.model, 'image', '?w=700'),
              'width': 1921
          },

          {
              'src': _vm.getImg(_vm.model, 'image', '?w=550'),
              'srcx2': _vm.getImg(_vm.model, 'image', '?w=650'),
              'width': 1201
          },

          {
              'src': _vm.getImg(_vm.model, 'image', '?w=450'),
              'width': 768
          }
          ],"proportion":1.052,"data-preset":"opacity,y,rotate","data-fromy":"50","data-delay":".2","data-fromrotate":"-10","data-torotate":"0","alt":_vm.model.fields.image.fields.title}})],1)],2),_vm._v(" "),_c('synchronized-waypoint',{staticClass:"text-center",on:{"toggleOneDirectionVisible":_vm.toggleWaypointWithLines}},[_c('split-text',{attrs:{"visible":_vm.visibleWaypoint,"type":"lines","wrapped":false,"tag":"h4","innerClasses":"t7 t5-md t4-xl glossy text-uppercase position-relative z-1 color-blackv2 color-dark-text-opacity","text":_vm.stripHtmlWithBreaks(_vm.model.fields.contentTwo)},on:{"splited":_vm.onSplited}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }