<template>
  <div class="gallery-and-text bg-pale-silver pb-1 px-075 px-md-1 px-xl-2">
    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines">
      <div class="d-flex align-items-center justify-content-between">
        <div 
        class="pb-2 roboto t14 t11-md t13-xl text-center py-2 py-md-3 color-dark-text number should-animate"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.1'>IV</div>

        <h3
        v-if="model.fields.caption"
        v-html="model.fields.caption"
        class="t14 t11-md t13-xl text-uppercase should-animate"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.1' />
        <div></div>
      </div>

      <!-- GALLERY -->
      <div class="d-none d-md-flex images-wrapper mb-md-05">
        <div
        v-for="(image, index) in images"
        :key="index"
        class="col-md-3 px-md-05">
          <div class='overflow-hidden'>
            <synchronized-picture-div
            :src='getImg(image, "image", "?w=636")'
            :sources="[
              {
                  'src': getImg(image, 'image', '?w=650'),
                  'srcx2': getImg(image, 'image', '?w=700'),
                  'width': 1921
              },

              {
                  'src': getImg(image, 'image', '?w=472'),
                  'srcx2': getImg(image, 'image', '?w=500'),
                  'width': 1201
              },

              {
                  'src': getImg(image, 'image', '?w=600'),
                  'width': 768
              }
            ]" 
            :proportion="1.337"
            :alt="image.fields.title"
            class="should-animate"
            data-preset="scale"
            data-dur="5"
            data-fromscale='1.1'
            data-toscale='1' />
          </div>
        </div>
      </div>
      <!-- END :: GALLERY -->

    </synchronized-waypoint>

    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines">
      <!-- MOBILE SWIPER -->
      <swiper 
      :customOptions="swiperOptions"
      :overflowVisible="false"
      class="slider-wrapper w-100 h-100 mb-05 d-block d-md-none">
        <div 
        v-for="(image, index) in images"
        :key="index"
        class="position-relative swiper-slide col-11">
          <div class="overflow-hidden">
            <image-div 
            class=" should-animate"
            :proportion="1.2"
            :src='getImg(image, "?w=1400")'
            data-preset="scale"
            data-dur="5"
            data-fromscale='1.1'
            data-toscale='1'
            :alt="image.fields.title" />
          </div>
        </div>
      </swiper>
    </synchronized-waypoint>

    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines">
      <div class="custom-padding">
        <div class="col-9 col-md-6 col-xl-3">
          <split-text
          :visible='visibleWaypoint'
          type='lines'
          :wrapped='false'
          tag='h4'
          innerClasses="color-dark-text t14 t11-md t13-xl text-uppercase roboto"
          :text="stripHtmlWithBreaks(model.fields.description)"
          @splited="onSplited" />
        </div>
      </div>

      <div class="d-flex flex-md-column flex-column-reverse">
        <div class="offset-wrapper col-10 offset-2 lh-08 col-md-9 offset-md-3 col-xl-6 offset-xl-6">
          <h4
          v-for="(item, index) in content"
          :key="index"
          v-html="item"
          class="color-dark-text t14 t11-md t13-xl text-uppercase roboto should-animate offset-text"
          data-preset='opacity,y'
          data-fromy='50'
          :data-delay='.2 + index / 10'
          :class="[
            index == 3 ? 'text-right' 
            : index == 2 ? 'text-md-right text-lg-left'
            : 'text-right text-md-left'
          ]" />
        </div>

        <div class="text-center pb-5 pb-xl-0 pt-5 pt-xl-6 should-animate"
        data-preset='opacity,y'
        data-fromy='50'
        data-delay='.3'>
          <span class="color-dark-text roboto t14 t11-md t13-xl">V</span>
        </div>
      </div>
    </synchronized-waypoint>

  </div>
</template>

<script>
import _get from 'lodash/get'
import WaypointTrigger from "@/mixins/WaypointTrigger"

export default {
  name: "gallery-and-text",

  props: ["model"],

  mixins: [WaypointTrigger],

  computed: {
    images() {
      return _get(this.model, 'fields.images')
    },
    content() {
      return _get(this.model, 'fields.content')
    },
    swiperOptions() {
      return {
        loop: false,
        effect: 'slide',
        spaceBetween: 10,
      }
    }
  }
};
</script>

<style lang="scss" scoped="">
.gallery-and-text {
  .images-wrapper {
    @include media-breakpoint-up(md) {
      margin: 0 -.5rem;
    }
  }

  .offset-wrapper {
    text-align: right;

    @include media-breakpoint-up(md){
      text-align: initial;
    }
    
    .offset-text {
      display: inline;

      @include media-breakpoint-up(md){
        display: block;
      }
    }
  }

  .custom-padding {
    @include media-breakpoint-up(md) {
      padding-bottom: 7.3rem;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: 17.6rem;
    }
  }
}
</style>