var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('synchronized-waypoint',{staticClass:"two-images d-flex flex-center",class:{
  'bg-dark-grey': _vm.model.fields.darkBackground,
  'pt-md-1 pt-lg-2': _vm.model.fields.paddingTop,
  'pb-075 pb-md-1 pb-lg-2': _vm.model.fields.paddingBottom,
},on:{"toggleOneDirectionVisible":_vm.toggleWaypoint}},[_c('div',{staticClass:"row w-100",class:{'col-11 col-md-7 col-xl-5 px-075 px-md-0 custom-padding': _vm.model.fields.smallImages}},_vm._l((_vm.images),function(image,index){return _c('div',{key:index,staticClass:"position-relative",class:[
      _vm.getElementClasses(index),
      {'pt-075 pt-md-0 mb-075 mb-md-0': index == 0 || _vm.model.fields.smallImages}
      ]},[(image)?_c('div',{staticClass:"image-wrapper w-100 h-100 overflow-hidden",class:{'mix-multiply': _vm.model.fields.multiply}},[_c('synchronized-picture-div',{staticClass:"should-animate",attrs:{"image":_vm.model.fields[_vm.getImgName(index)],"proportion":_vm.imageProportion,"data-preset":"scale","data-dur":"1.4","data-fromscale":"1.1","data-toscale":"1","src":image,"sources":[
            {
              'src': _vm.getImg(_vm.model, _vm.getImgName(index), ("?w=" + (1200 * _vm.imageRatio)), 90),
              'srcx2': _vm.getImg(_vm.model, _vm.getImgName(index), ("?w=" + (1400 * _vm.imageRatio)), 90),
              'width': 1921
            },

            {
              'src': _vm.getImg(_vm.model, _vm.getImgName(index), ("?w=" + (960 * _vm.imageRatio))),
              'srcx2': _vm.getImg(_vm.model, _vm.getImgName(index), ("?w=" + (1100 * _vm.imageRatio))),
              'width': 1200
            },

            {
              'src': _vm.getImg(_vm.model, _vm.getImgName(index), ("?w=" + (500 * _vm.imageRatio))),
              'width': 768
            }
          ]}})],1):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }