<template>
  <header
  :class='{
    "loaded": loaded,
    "pull-up": (scrolled && !activePage.fields.isHp) || isQuickView || filterOpen && !menuOpen
  }'
  class="navigation w-100">
    <!-- HIDDEN FOR NOW RETURN BEFORE AWWWARDS -->
      <!-- CONTACT -->
     <!--  <custom-transition 
      v-if="false"
      :noAdditionalClasses='true'> -->
        <div 
        :key='passedViewport'
        :class='{
          "difference": passedViewport
        }'
        class="color-white z-3 contact-link nav-element disabled">
          <div 
          data-preset='opacity'
          data-delay='1.6'
          class='should-animate'>
            <nuxt-link
            :to='{path: "/", query: {contact: true}}'
            v-html="`Contact`"
            class="text-uppercase text-size link float-left d-inline-block pull-up-element enabled" />
          </div>
        </div>
      <!-- </custom-transition> -->
      <!-- END :: CONTACT -->

    <!-- END :: HIDDEN FOR NOW RETURN BEFORE AWWWARDS -->

      <!-- LOGO -->
      <!-- <custom-transition :noAdditionalClasses='true'> -->
        <div
        :class='{
          "difference": passedViewport
        }'
        class="z-3 logo-wrapper nav-element disabled">

          <div class='pull-up-element'>
            <nuxt-link 
            data-preset='opacity'
            data-delay='1.2'
            class='should-animate d-inline-block enabled no-tap-highlight'
            to="/">
              <img
              src="/img/logo.svg" 
              class="logo"
              alt="Kim Kneipp" />
            </nuxt-link>
            <p 
            :class='loaded || passedViewport ? "color-white" : "color-black"'
            class="logo-text color-white text-center roboto text-uppercase position-relative d-none d-md-block">style & </br> sustainability</p>
          </div>

        </div>
      <!-- </custom-transition> -->
      <!-- END :: LOGO -->

      <!-- HAMBURGER -->
      <custom-transition 
      class="d-flex align-items-center"
      :noAdditionalClasses='true'
      :mode='menuOpen ? "out-in" : ""'>
        <div 
        :key='!menuOpen'
        :class='{
          "difference": passedViewport && !menuOpen
        }'
        class="hamburger-wrapper z-5 nav-element disabled no-tap-highlight">
          <div 
          data-preset='opacity'
          data-delay='1.6'
          class="cursor-pointer no-select should-animate not-will-change">
            <div 
            @click.prevent="toggleMenu()"
            class='d-flex align-items-center pull-up-element enabled'>
              <!-- <span class='lh-12 transition h6 cursor-pointer' :class="{'fade-out' : menuOpen}"></span> -->
              <hamburger></hamburger>
            </div>
          </div>

        </div>
      </custom-transition>
      <!-- END :: HAMBURGER -->
        
  </header>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import _get from 'lodash/get'
import Hamburger from '@/components/Hamburger'
import { AnimationsMixin } from 'synchronized-components'
import { ScrollingObservable } from "@/observables/ScrollingObservable.js"

export default {
  name: "navigation",

  data: () => ({
    loaded: false
  }),

  mixins: [AnimationsMixin],

  mounted() {
    let elems = this.$el.querySelectorAll('.should-animate')

    // setTimeout(() => {
      this.runAnimation({
        elems, 
        visible: true, 
        onComplete: () => {
          this.loaded = true
        }
      })
    // }, 3000)
  },

  methods: {
    ...mapActions('app', [
        'setStateApp',
        'scrollStart'
    ]),

    scrollToTop() {
      if(this.activePage.fields.isHp){
        this.$bus.$emit('scrollTo', 0)
      }
    },

    toggleMenu(state) {
      let menuOpen = state === false ? false : !this.menuOpen
      this.setStateApp({menuOpen})
      return menuOpen
    }
  },

  computed: {
    ...mapState('pages', [
      'navigation',
      'activePage'
    ]),

    ...mapState('app', [
      'menuOpen',
      'filterOpen'
    ]),

    scrolled() {
      return ScrollingObservable.scrollingDown && ScrollingObservable.scrollTop > 50
    },

    passedViewport() {
      return ScrollingObservable.passedViewport || _get(this.activePage, 'fields.darkNav')
    },

    isQuickView() {
      return this.$store.state.route.query.quickView
    }
  },

  components: {
    Hamburger
  }
};
</script>

<style lang="scss" scoped="">
.navigation {


  &.loaded{
    .should-animate, .logo-text {
      opacity: 1;
    }
  }

  .nav-element {
    position: fixed;
    top: 1.6rem;
    transition: transform .4s;

    &.contact-link{
      left: 1rem;
      margin-top: .25em;
      @include media-breakpoint-up(md) {
        // margin-top: 0;
      }
    }

    &.hamburger-wrapper{
      right: 1rem;
    }

    .logo{
      width: 9rem;
    }

    &.logo-wrapper{
        left: 50%;
        transform: translateX(-50%);
      @include media-breakpoint-up(md){
      }
    }
  }

  .pull-up-element {
    transition: transform .4s;
    // will-change: transform;
  }

  &.pull-up{
    pointer-events: none;
    .pull-up-element{
      transform: translateY(-8rem);
    }
  }

  .link:hover{
    &.color-black{
      color: black!important;
    }
    &.color-white{
      color: white!important;
    }
  }

  .logo {
    position: relative;
    width: 11rem;

    @include media-breakpoint-up(md) {
      width: 182px;
    }

    @media screen and (min-width: 1900px) {
      font-size: 182/1900*100vw;        
    }
  }

  .logo-text {
    font-size: 10px;
    line-height: 1;
    opacity: 0;
    will-change: transform;
  }

}
</style>