<template>
<div class='page error bg-beige d-flex flex-center flex-column text-center'>
    
  <div 
  v-for='(circle, key, index) in circles'
  ref='circle'
  data-preset='opacity,scale'
  :data-delay='1.2 + index/4'
  data-fromscale='.8'
  :class='`${key}-circle should-animate`' 
  v-html='circle' />

  <nuxt-link 
  class='link color-red position-fixed b-0 center-x mb-1 text-uppercase reversed d-inline-block'
  :to='"/"'>Back&nbsp;&nbsp;home</nuxt-link>

</div>
</template>

<script>
import LeftCircle from '@/assets/svg/left-404.svg?raw'
import RightCircle from '@/assets/svg/right-404.svg?raw'

import { gsap } from 'gsap'
import DrawSVGPlugin from '~/plugins/scripts/DrawSVGPlugin.js'

import { AnimationsMixin } from 'synchronized-components'

import _each from 'lodash/each'

export default {
  name: 'Error',

  mixins: [
    AnimationsMixin
  ],

  data: () => ({
    circles: {
      left: LeftCircle,
      right: RightCircle
    }
  }),

  head()  {
    return {
        title: 'Kim Kneipp - 404 Not Found',
    }
  },

  mounted () {
    this.$store.dispatch('app/setStateApp', {
      passedViewport: true
    })

    
    let elems = this.$el.querySelectorAll('.should-animate')
    this.runAnimation({
      elems, 
      visible: true, 
      onComplete: () => {
        this.loaded = true
      }
    })
  }
}
</script>

<style lang="scss">
/*! purgecss start ignore */

@keyframes rotateCircle {
    0% {
      transform: rotate3d(1, -.1, 1, 75deg) rotate(0deg);
    }

    100% {
      transform: rotate3d(1, -.1, 1, 75deg) rotate(360deg);
    }
  }

@keyframes rotateCircleLeft {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

@keyframes rotate1 {
    0% {
      transform: rotate3d(1, 0, 1, 30deg) rotate(0deg);
    }

    50% {
      transform: rotate3d(1, 0, 1, 30deg) rotate(180deg);
    }

    100% {
      transform: rotate3d(1, 0, 1, 30deg) rotate(360deg);
    }
  }


@keyframes rotate2 {
    0% {
      transform: rotate3d(1, 0, 1, -30deg) rotate(0deg);
    }

    50% {
      transform: rotate3d(1, 0, 1, -30deg) rotate(180deg);
    }

    100% {
      transform: rotate3d(1, 0, 1, -30deg) rotate(360deg);
    }
  }


@keyframes rotate3 {
    0% {
      transform: rotate3d(1, 0, 1, 0deg) rotate(0deg);
    }

    50% {
      transform: rotate3d(1, 0, 1, -30deg) rotate(180deg);
    }

    100% {
      transform: rotate3d(1, 0, 1, 0deg) rotate(360deg);
    }
  }


.error {
  .circle-element{
      transform: rotate3d(1, 0, 1, 55deg);
      transform-origin: center;
  }  



  .left-circle {
    position: absolute;
    margin-top: -5vw;
    left: 10vw;
    width: 50vw;
    top: 50%;
    transform: translateY(-50%);

    .rotation-element{
      transform-origin: center!important;

      &--1{
        animation: rotate1 15s linear infinite;
      }

      &--2{
        animation: rotate2 15s linear infinite;
      }

      &--3{
        animation: rotate3 15s linear infinite;
      }    

      &--circle{
        animation: rotateCircleLeft 18s linear infinite;
      }
    }
  }


  .right-circle{    
    position: absolute;

    position: absolute;
    margin-top: 5vw;
    top: 50%;
    transform: translateY(-50%);
    left: 10vw;
    width: 95vw;

    .rotation-element{
      transform-origin: center!important;

      &--1{
        animation: rotate1 18s linear infinite;
      }

      &--2{
        animation: rotate2 17s linear infinite;
      }

      &--3{
        animation: rotate3 16s linear infinite;
      }    

      &--circle{
        animation: rotateCircle 15s linear infinite;
      }
    }
  }

}
/*! purgecss end ignore */
</style>
