var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gallery-and-text bg-pale-silver pb-1 px-075 px-md-1 px-xl-2"},[_c('synchronized-waypoint',{on:{"toggleOneDirectionVisible":_vm.toggleWaypointWithLines}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"pb-2 roboto t14 t11-md t13-xl text-center py-2 py-md-3 color-dark-text number should-animate",attrs:{"data-preset":"opacity,y","data-fromy":"50","data-delay":".1"}},[_vm._v("IV")]),_vm._v(" "),(_vm.model.fields.caption)?_c('h3',{staticClass:"t14 t11-md t13-xl text-uppercase should-animate",attrs:{"data-preset":"opacity,y","data-fromy":"50","data-delay":".1"},domProps:{"innerHTML":_vm._s(_vm.model.fields.caption)}}):_vm._e(),_vm._v(" "),_c('div')]),_vm._v(" "),_c('div',{staticClass:"d-none d-md-flex images-wrapper mb-md-05"},_vm._l((_vm.images),function(image,index){return _c('div',{key:index,staticClass:"col-md-3 px-md-05"},[_c('div',{staticClass:"overflow-hidden"},[_c('synchronized-picture-div',{staticClass:"should-animate",attrs:{"src":_vm.getImg(image, "image", "?w=636"),"sources":[
            {
                'src': _vm.getImg(image, 'image', '?w=650'),
                'srcx2': _vm.getImg(image, 'image', '?w=700'),
                'width': 1921
            },

            {
                'src': _vm.getImg(image, 'image', '?w=472'),
                'srcx2': _vm.getImg(image, 'image', '?w=500'),
                'width': 1201
            },

            {
                'src': _vm.getImg(image, 'image', '?w=600'),
                'width': 768
            }
          ],"proportion":1.337,"alt":image.fields.title,"data-preset":"scale","data-dur":"5","data-fromscale":"1.1","data-toscale":"1"}})],1)])}),0)]),_vm._v(" "),_c('synchronized-waypoint',{on:{"toggleOneDirectionVisible":_vm.toggleWaypointWithLines}},[_c('swiper',{staticClass:"slider-wrapper w-100 h-100 mb-05 d-block d-md-none",attrs:{"customOptions":_vm.swiperOptions,"overflowVisible":false}},_vm._l((_vm.images),function(image,index){return _c('div',{key:index,staticClass:"position-relative swiper-slide col-11"},[_c('div',{staticClass:"overflow-hidden"},[_c('image-div',{staticClass:" should-animate",attrs:{"proportion":1.2,"src":_vm.getImg(image, "?w=1400"),"data-preset":"scale","data-dur":"5","data-fromscale":"1.1","data-toscale":"1","alt":image.fields.title}})],1)])}),0)],1),_vm._v(" "),_c('synchronized-waypoint',{on:{"toggleOneDirectionVisible":_vm.toggleWaypointWithLines}},[_c('div',{staticClass:"custom-padding"},[_c('div',{staticClass:"col-9 col-md-6 col-xl-3"},[_c('split-text',{attrs:{"visible":_vm.visibleWaypoint,"type":"lines","wrapped":false,"tag":"h4","innerClasses":"color-dark-text t14 t11-md t13-xl text-uppercase roboto","text":_vm.stripHtmlWithBreaks(_vm.model.fields.description)},on:{"splited":_vm.onSplited}})],1)]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-md-column flex-column-reverse"},[_c('div',{staticClass:"offset-wrapper col-10 offset-2 lh-08 col-md-9 offset-md-3 col-xl-6 offset-xl-6"},_vm._l((_vm.content),function(item,index){return _c('h4',{key:index,staticClass:"color-dark-text t14 t11-md t13-xl text-uppercase roboto should-animate offset-text",class:[
          index == 3 ? 'text-right' 
          : index == 2 ? 'text-md-right text-lg-left'
          : 'text-right text-md-left'
        ],attrs:{"data-preset":"opacity,y","data-fromy":"50","data-delay":.2 + index / 10},domProps:{"innerHTML":_vm._s(item)}})}),0),_vm._v(" "),_c('div',{staticClass:"text-center pb-5 pb-xl-0 pt-5 pt-xl-6 should-animate",attrs:{"data-preset":"opacity,y","data-fromy":"50","data-delay":".3"}},[_c('span',{staticClass:"color-dark-text roboto t14 t11-md t13-xl"},[_vm._v("V")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }