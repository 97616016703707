var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('synchronized-waypoint',{staticClass:"project-preview",class:[{
  "project-preview--hovered": _vm.hovered
}, ("project-preview--" + (_vm.model.fields.gridTemplate))],on:{"toggleOneDirectionVisible":_vm.toggleWaypoint}},[(_vm.width > 1200 && _vm.config.contentAbove)?_c('div',{staticClass:"row w-100 text-uppercase t13 mb-1 should-animate pl-xl-1",class:("justify-content-" + (_vm.config.justify)),attrs:{"data-preset":"opacity,y"}},[_c('div',{staticClass:"content col-md-6",domProps:{"innerHTML":_vm._s(_vm.model.fields.projectDescription)}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"should-animate d-block",attrs:{"data-preset":"opacity,y","data-delay":".1"}},[_c('div',{staticClass:"row w-10 gutter-md",class:("justify-content-" + (_vm.config.justify))},_vm._l((_vm.images),function(image,index){return _c('nuxt-link',{key:index,staticClass:"image-card item",class:[_vm.config.landscapeImage == index ? "col-12 col-xl-6" : "col-md-6 col-xl-3", {
          "d-none d-xl-block": _vm.config.landscapeImage ? index != _vm.config.landscapeImage : index > 0,
          "d-none d-md-block": _vm.config.landscapeImage ? index == _vm.config.landscapeImage : index < 2,
          "d-block": _vm.config.landscapeImage ? index == _vm.config.landscapeImage : index == 0,
        }],attrs:{"to":_vm.model.url},nativeOn:{"mouseenter":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false}}},[_c('synchronized-picture-div',{staticClass:"project-image",attrs:{"src":_vm.getImg(image, "image", "?h=750"),"alt":image.fields.title,"sources":[
              {
                  'src': _vm.getImg(image, 'image', ("?w=" + (1100 * _vm.getImgRatio(index)))),
                  'srcx2': _vm.getImg(image, 'image', ("?w=" + (1300 * _vm.getImgRatio(index)))),
                  'width': 1921
              },

              {
                  'src': _vm.getImg(image, 'image', ("?w=" + (920 * _vm.getImgRatio(index)))),
                  'srcx2': _vm.getImg(image, 'image', ("?w=" + (1120 * _vm.getImgRatio(index)))),
                  'width': 1201
              },

              {
                  'src': _vm.getImg(image, 'image', ("?w=" + (1150 * _vm.getImgRatio(index)))),
                  'width': 768
              }
          ],"proportion":_vm.config.landscapeImage == index ? .65 : 1.33}})],1)}),1)]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column flex-xl-row w-100 mt-1 should-animate",class:("justify-content-" + (_vm.config.justify)),attrs:{"data-preset":"opacity,y","data-delay":".25"}},[_c('div',{staticClass:"d-flex flex-column mb-05 mb-lg-1",class:[_vm.config.numberOfImages == 4 ? "col-xl" : "col-xl-6", {
        "pl-xl-025": (_vm.config.justify == "end" && _vm.config.numberOfImages != 4),
        "pl-xl-05": _vm.model.fields.gridTemplate == 2
      }]},[_c('div',{staticClass:"d-flex color-beigev4"},[_c('p',{staticClass:"text-uppercase enabled t13 t11-1-md t14-xl link pt-1 pt-md-0 pb-025",domProps:{"innerHTML":_vm._s(_vm.model.fields.client)}})]),_vm._v(" "),_c('nuxt-link',{staticClass:"pr-1 t9 t7-md t7-xl glossy lh-1 text-uppercase mb-md-025 mb-xl-0 project-title",attrs:{"to":_vm.model.url},domProps:{"innerHTML":_vm._s(_vm.stripHtml(_vm.model.fields.title))},nativeOn:{"mouseenter":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false}}}),_vm._v(" "),_c('div',{staticClass:"d-flex color-beigev4"},[_c('nuxt-link',{staticClass:"text-uppercase enabled t13 t11-1-md t14-xl link pt-1 pt-md-0 pb-025",attrs:{"to":_vm.quickUrl}},[_vm._v("Quick View")])],1)],1),_vm._v(" "),(_vm.width < 1200 || !_vm.config.contentAbove)?_c('div',{staticClass:"content col-xl-3"},[_c('div',{staticClass:"text-uppercase t13 t11-1-md t13-xl pl-xl-075 col-md-10 col-lg-8 col-xl-12",domProps:{"innerHTML":_vm._s(_vm.model.fields.projectDescription)}})]):_vm._e(),_vm._v(" "),(_vm.config.narroowWidth)?_c('div',{staticClass:"col-md-3"}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }