<template>
  <div 
  :style='elStyle'
  class="parallax-image">
    <div
    ref='wrapper'
    :style='wrapperStyle' 
    class='overflow-hidden h-100 img-wrapper position-relative'>
      <component
      :offset='0'
      :topOffset='.1'
      :debug='true'
      :is='featured ? "div" : "div"' 
      :wrapped='true'
      class='w-100 h-100'
      :absolute='true' 
      :speedFactor='.2' 
      :scaleFactor='.1'>
          <img 
          ref='img'
          class='h-100 cover position-relative'
          :src='src' 
          :style='imageStyle' />

        </component>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ParallaxMixin } from 'synchronized-components'
import ResizeHandler from '@/mixins/ResizeHandler'
import _get from 'lodash/get'
import _clamp from 'lodash/clamp'
import { gsap } from 'gsap'

export default {
  name: "parallax-image",

  data: () => ({
    bounds: {},
    leftBound: Number,
    visible: false
  }),

  props: {
    index: [Number, String],

    featured: Boolean,

    first: Boolean,
    last: Boolean,

    src: String,
    ratio: {
      type: Number,
      default: .1
    },
    image: Object,
    scrollLeft: Number,

    quickView: Boolean,

    scale: {
      type: Object,
      default: () => ({
        val: 1
      })
    },

    isPrev: Boolean,
    isNext: Boolean,

  },

  mixins: [
    ResizeHandler,
    ParallaxMixin
  ],

  watch: {
    quickView(q) {
      this.openFeatured()
    },

    visible(v) {
      if(v){
        this.$emit('update:currentIndex', this.index + 1)
      }
    }
  },

  mounted() {
    if (!('IntersectionObserver' in window) ||
        !('IntersectionObserverEntry' in window) ||
        !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
        this.visible = true
    } else{           

        // let thresholds = [];
        // let numSteps = 20;

        // for (let i=1.0; i<=numSteps; i++) {
        //   let ratio = i/numSteps;
        //   thresholds.push(ratio);
        // }

        this.observer = new IntersectionObserver(this.callback, {
          threshold: [.5]
        })

        setTimeout(() => {
            this.observer.observe(this.$el)
        })
    }

    this.openFeatured(true)

    let x = this.isPrev ? this.appWidth
    : this.isNext ? -this.appWidth
    : 0

    let scale = this.isPrev || this.isNext || this.featured ? .3 : null

    // if(scale){
    //   gsap.from(this.$refs.wrapper, 1.4, {
    //     scale,
    //     delay: .4,
    //     ease: "Power2.easeInOut",
    //     clearProps: 'transform'
    //   })
    // }
    
    if(x){
      gsap.from(this.$el, 1.4, {
        x,
        delay: .6,
        ease: "Power2.easeInOut"
      })
    }
  },

  methods: {
      ...mapActions('app', [
          'setStateApp'
      ]),

     callback(entries)  {
          let entry = entries[0]

          this.visible = entry.isIntersecting
      },

      openFeatured(instant) {        
        if(!this.featured){
          return
        }

        this.resizeTimeout && clearTimeout(this.resizeTimeout)
        this.resizeTimeout = setTimeout(() => {
          let width = this.bounds.width
          let height = this.bounds.height
          let scale = this.quickView ? 1 : Math.max(this.appWidth / width, this.appHeight / height) + .033
          let left = -this.leftBound + ((this.appWidth - width) / 2)

          console.log("[ParallaxImages] scale:", scale, this._uid)

          if(instant){
            this.$emit('update:scale', {
              val: scale
            })

            this.$emit('update:scrollLeft', left)
          } else{
            this.$emit('scaleTo', scale)
            this.$emit('scrollToLeft', left)
          }
        }, 65)
      },

      resizeHandler() {    
          this.bounds = this.$refs.wrapper.getBoundingClientRect()
          this.leftBound = this.bounds.left - this.scrollLeft

          this.$emit('update:scale', {
            val: 1
          })

          this.openFeatured(true)
      }
  },

  computed: {
    ...mapState('app', {
      appWidth: 'width',
      appHeight: 'height'
    }),

    currentProgress() {
      return this.scrollProgress(this.start, this.end, -this.scrollLeft)
    },

    start() {
        return this.leftBound - this.appWidth
    },

    end() {
        return this.start + this.appWidth + this.bounds.width
    },

    x() {
      return this.move * this.currentProgress
    },

    left() {
      return -this.move
    },

    originalWidth() {
      let image = _get(this.image, 'fields.file.details.image')
      let ratio = this.appHeight / image.height 
      let width = image.width * ratio

      return width
      // return this.featured ? this.appWidth : width
    },

    elStyle() {
      let paddingLeft = this.first ? `${(this.appWidth - this.bounds.width) / 2}px` : '10rem'
      let paddingRight = this.last ? `${(this.appWidth - this.bounds.width) / 2}px` : '10rem'

      return {
        // 'z-index': this.featured ? 2 : 1
        // paddingLeft,
        // paddingRight
      }
    },

    wrapperStyle() {
      return {
        width: this.originalWidth + 'px'
      }
    },

    imageStyle() {
      let width = `${this.originalWidth + Math.abs(this.move)}px`
      let transform = `translate3d(${this.x}px, 0, 0)`

      return {
        width: width,
        transform,
        left: this.left + 'px'
      }
    },

    move() {
      return this.appWidth * this.ratio
    }
  }
};
</script>

<style lang="scss" scoped="">
.parallax-image{
  box-sizing: content-box;
  padding: 0 3rem;

  img{
    // max-width: 80vw;
  }

  @include media-breakpoint-up(lg){
    // padding: 0 10rem;
  }
}
</style>