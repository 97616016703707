<template>
  <client-only>
    <div :key='width' class='split-text'>
      <component 
      :is='tag' 
      ref='slot' 
      :class='innerClasses'
      v-html='text'>
        <slot />
      </component>
    </div>
  </client-only>
</template>
<script>

import { gsap } from 'gsap'
import _get from 'lodash/get'
import SplitText from '~/plugins/scripts/SplitText.js'
gsap.registerPlugin(SplitText);

import { mapState } from 'vuex'

export default {
    name: 'waypoint',
    data()  {
        return {
            elements: null,
            firstRender: true
        }
    },

    mixins: [
    ],

    props: {
        type: {
          default: 'lines'
          // lines / chars / words,
        }, 

        visible: false,

        wrapped: {
          default: false
        },
        
        linesClass: {
          default: 'split-line'
        },
        
        charsClass: {
          default: 'split-char'
        },

        wordsClass: false,
        
        tag: {
            default: 'div'
        },

        text: false,

        innerClasses: false
    },

    watch: {
      fontsLoaded() {
        setTimeout(this.splitLines, 10)
      },
      width() {
        clearTimeout(this.t)
        this.t = setTimeout(this.splitLines, 50)
      }
    },

    computed: {
      ...mapState('app', [
        'fontsLoaded',
        'width'
      ])
    },

    mounted()  {
        setTimeout(() => {
            this.fontsLoaded && this.splitLines()
        }, 10)
    },

    methods: {
        splitLines(elems) {
          // let el = _get(this.$slots, 'default[0].elm')
          let el = this.$refs.slot

          if(!el){
            console.warn("no split element", el)
            return
          }
          
         // split the headline lines
         try{          
             this.mySplitText = new SplitText(el, {
                type: this.type,
                linesClass: this.linesClass,
                charsClass: this.charsClass,
                wordsClass: this.wordsClass
             })

            // an array of all the divs that wrap each character
            this.elements = this.mySplitText[this.type]

            if(this.wrapped){          
              Array.from(this.elements).forEach((el) => {
                const wrapper = document.createElement('div')
                wrapper.className = 'position-relative overflow-hidden '
                wrapper.className += this.type == "chars" ? 'd-inline-block' : 'w-100'

                el.parentNode.insertBefore(wrapper, el)
                wrapper.appendChild(el);
              })
            }

            // console.log("split the headline", this.visible, this.firstRender, this.elements)

            if(this.firstRender){
              this.$emit('splited', this.elements)
              this.firstRender = false
            } else{

              gsap.set(this.$el, {
                opacity: 1
              })

              gsap.set(this.elements, {
                opacity: 1
              })
            }

         } catch(err){
          console.log('split err:', err)
         }
      },
    }
}

</script>

<style lang="scss">
.split-line{
  white-space: nowrap;
}
.split-text {
    opacity: 0;
}
</style>