<template>
  <div class="service-info-fashiion-design bg-sandstone text-uppercase glossy">
    <!-- TOP CONTENT -->
    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines"
    class="t9 t7-md t7-xl top-content px-075 px-md-1 pt-1 mb-3 mb-xl-4 col-xl-8 pr-md-7 pr-xl-4">

      <split-text
      :visible='visibleWaypoint'
      type='lines'
      :wrapped='false'
      tag='p'
      innerClasses="pre-wrap-md pr-md-5 pr-xl-0"
      :text="stripHtmlWithBreaks(model.fields.content)"
      @splited="onSplited" />

    </synchronized-waypoint>
    <!-- END TOP CONTENT -->

    <!-- MIDDLE CONTENT -->
    <div class="middle-wrapper row px-075 px-md-0 mb-2 mb-md-0">
      <synchronized-waypoint
      @toggleOneDirectionVisible="toggleWaypointWithLines"
      class="custom-col-3 ml-0 ml-md-auto t9 t7-md t7-xl">

        <split-text
        :visible='visibleWaypoint'
        type='lines'
        :wrapped='false'
        tag='p'
        innerClasses="pre-wrap-md"
        :text="stripHtmlWithBreaks(model.fields.offsetContent)"
        @splited="onSplited" />

      </synchronized-waypoint>
    </div>
    <!-- END :: MIDDLE CONTENT -->

    <div class="row position-relative">
      <!-- LEFT SIDE BOX -->
      <div class="custom-col-1 order-1 order-md-0 d-flex flex-column justify-content-between">
        <!-- SMALL LINK -->
        <synchronized-waypoint
        @toggleOneDirectionVisible="toggleWaypoint"
        class="px-075 px-md-1 margin-top-offset d-none d-md-block">
          <div 
          class="should-animate"
          data-preset='opacity,y'
          data-fromy='50'
          data-delay='.1'>
            <nuxt-link
            to="/"
            class="t13 t9-md t10-xl work link reversed"
            v-text="'get in touch'" />
          </div>
        </synchronized-waypoint>
        <!-- END :: SMALL LINK -->

        <div class="left-image-wrapper position-relative overflow-hidden">
          <proportion-div
          :proportion="1.186">
            <custom-parallax 
            :wrapped='true'
            class='w-100 h-100'
            :absolute='true' 
            :speedFactor='.2' 
            :scaleFactor='.1'>
              <synchronized-picture-div
              :fill="true"
              :image="model.fields.leftImage"
              :src='getImg(model, "leftImage", "?w=675")'
              :sources="[
                  {
                      'src': getImg(model, 'leftImage', '?w=1300'),
                      'srcx2': getImg(model, 'leftImage', '?w=1400'),
                      'width': 1921
                  },

                  {
                      'src': getImg(model, 'leftImage', '?w=950'),
                      'srcx2': getImg(model, 'leftImage', '?w=1000'),
                      'width': 1201
                  },

                  {
                      'src': getImg(model, 'leftImage', '?w=750'),
                      'width': 768
                  }
                  ]"
              :alt="model.fields.leftImage.fields.title" />
            </custom-parallax>
          </proportion-div>
        </div>
      </div>
      <!-- END :: LEFT SIDE BOX -->

      <!-- RIGHT SIDE BOX -->
      <div class="custom-col-2 order-0 order-md-1 position-relative z-1 px-075 px-md-0">
        <!-- SMALL LINK MOBILE -->
        <div class="margin-top-offset d-md-none mb-1">
          <nuxt-link
          to="/"
          class="t13 t9-md t10-xl work link reversed"
          v-text="'get in touch'" />
        </div>
        <!-- END :: SMALL LINK MOBILE -->

        <!-- BOX WRAPPER -->
        <synchronized-waypoint
        @toggleOneDirectionVisible="toggleWaypoint">
          <div 
          class="box-wrapper margin-top-offset position-relative z-1 bg-white d-flex flex-column justify-content-between p-075 p-xl-1 t9 t7-md t7-xl should-animate"
          data-preset='y'
          data-fromy='200'
          data-delay='.1'>
            <div class="d-flex align-items-start align-items-md-end mb-05 mb-xl-075">
              <div
              class="pre-wrap-md col lh-07 d-none d-md-inline pl-025 pl-xl-0"
              v-html="model.fields.leftImageText" />

              <div class="d-flex flex-column d-md-none col">
                <div
                class="pre-wrap-md d-inline col lh-07"
                v-html="model.fields.leftImageText" />

                <div
                class="pre-wrap-md d-inline mb-1 mb-xl-6 lh-11 d-md-none"
                v-html="model.fields.bottomImageText" />
              </div>

              <div class="d-md-inline position-relative small-image overflow-hidden">
                <synchronized-picture-div
                :src='getImg(model, "smallImage", "?w=400")'
                :sources="[
                    {
                        'src': getImg(model, 'smallImage', '?w=650'),
                        'srcx2': getImg(model, 'smallImage', '?w=1650'),
                        'width': 1921
                    },

                    {
                        'src': getImg(model, 'smallImage', '?w=512'),
                        'srcx2': getImg(model, 'smallImage', '?w=1512'),
                        'width': 1201
                    },

                    {
                        'src': getImg(model, 'smallImage', '?w=500'),
                        'width': 768
                    }
                    ]"
                :proportion=".94"
                :alt="model.fields.smallImage.fields.title" />
              </div>
            </div>

            <div
            class="pre-wrap-md mb-1 mb-md-2 mb-xl-6 lh-1 d-none d-md-inline pl-025 pl-xl-0"
            v-html="model.fields.bottomImageText" />

            <div
            class="pre-wrap-md text-right mb-3 mb-md-0 lh-1"
            v-html="model.fields.boxText" />

            <div class="box-link t11 t9-md p2-xl">
              <arrow-link 
              text='view our projects'
              to='/our-projects' />
            </div>
          </div>
          
        </synchronized-waypoint>
        <!-- END :: BOX WRAPPER -->

        <!-- RIGHT IMAGE -->
        <div class="position-absolute w-100 h-100 l-0 b-0 d-none d-md-block">
          <div class="position-relative overflow-hidden">
            <proportion-div
            :proportion="width > 1200 ? 1.21 : 1.39">
              <custom-parallax 
              :wrapped='true'
              class='w-100 h-100'
              :absolute='true' 
              :speedFactor='.2' 
              :scaleFactor='.1'>
                <synchronized-picture-div
                :fill="true"
                :image="model.fields.rightImage"
                :src='getImg(model, "rightImage", "?w=675")'
                :sources="[
                    {
                        'src': getImg(model, 'rightImage', '?w=1300'),
                        'srcx2': getImg(model, 'rightImage', '?w=2300'),
                        'width': 1921
                    },

                    {
                        'src': getImg(model, 'rightImage', '?w=950'),
                        'srcx2': getImg(model, 'rightImage', '?w=1950'),
                        'width': 1201
                    },

                    {
                        'src': getImg(model, 'rightImage', '?w=750'),
                        'width': 768
                    }
                    ]"
                :alt="model.fields.rightImage.fields.title" />
              </custom-parallax>
            </proportion-div>
          </div>
        </div>
        <!-- END :: RIGHT IMAGE -->
        <div></div>
      </div>
      <!-- END :: RIGHT SIDE BOX -->
    </div>

    <!-- BOTTOM CONTENT -->
    <div>
      <div
      v-html="model.fields.imageText"
      class="t9 t7-md t7-xl" />
    </div>
    <!-- BOTTOM CONTENT -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import WaypointTrigger from "@/mixins/WaypointTrigger"

export default {
  name: "service-info-fashiion-design",

  data: () => ({
    delayAddition: .3
  }), 

  props: ["model"],

  mixins: [WaypointTrigger],

  computed: {
    ...mapState('app', [
      'width'
    ])
  }
};
</script>

<style lang="scss" scoped="">
.service-info-fashiion-design {
  .box-wrapper {

    @include media-breakpoint-up(md){
      left: -27vw;
      width: 130%;
    }

    @include media-breakpoint-up(lg){
      width: 120%;
    }

    @include media-breakpoint-up(xl){
      left: -22vw;
      width: 107%;      
    }
  }

  .small-image {
    width: 32%;

    @include media-breakpoint-up(md){
      width: 45%;
    }

    @include media-breakpoint-up(lg){
      width: 45%;
    }

    @include media-breakpoint-up(xl){
      width: 38%;
    }
  }

  .small-arrow {
    width: 1rem;
    height: 1rem;
    background: url('/img/arrow-right.svg') no-repeat;
  }

  .middle-wrapper {
    @include media-breakpoint-up(md){
      padding-bottom: 17vw;
    }
  }

  .margin-top-offset {
    @include media-breakpoint-up(md){
      margin-top: -15.5vw;
    }
  }

  .custom-col-3 {
    @include media-breakpoint-up(md){
      width: calc(46% + 27vw);
    }

    @include media-breakpoint-up(xl){
      width: calc(46% + 22vw);      
    }
  }

  .left-image-wrapper {
    @include media-breakpoint-up(md){
      top: 10rem;
    }
  }

  .custom-col-1 {
    margin-top: -30vw;
    width: 100%;

    @include media-breakpoint-up(md){
      margin-top: 0;
      width: 54%;
    }
  }

  .custom-col-2 {

    @include media-breakpoint-up(md){
      background-color: $lightCreme;
      width: 46%;
      padding-bottom: 22vw;
    }
  }
}
</style>