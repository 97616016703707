<template>
  <div class="text-and-image-five padding-x bg-pale-silver bg-light-creme pb-1">

    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines"
    class="text-center pt-3 pt-md-2 pt-xl-3">
      <h3
      v-if="model.fields.caption"
      v-html="model.fields.caption"
      class="t14 t11-md t13-xl text-uppercase should-animate"
      data-preset='opacity,y'
      data-fromy='50'
      data-delay='.1' />

      <div 
      class="pb-2 roboto t14 t11-md t13-xl text-center py-2 py-md-4 color-dark-text number should-animate"
      data-preset='opacity,y'
      data-fromy='50'
      data-delay='.2'>III</div>

      <h4
      v-for="(item, index) in headline"
      :key="index"
      class="d-inline d-md-block should-animate"
      :class="{'ml-05 ml-md-0': index == 1}"
      data-preset='opacity,y'
      data-fromy='50'
      :data-delay='.3 + index / 10'>
        <span 
        v-html="item"
        class="t7 t5-md t4-xl glossy text-uppercase position-relative z-1 color-blackv2 color-dark-text-opacity" />
      </h4>

      <div 
      class="image-shadow col-custom mx-auto bg-light-creme3 px-025 px-md-05 pt-025 pt-md-05 pb-1 pb-md-2 mt-md-1 mix-multiply">
        <synchronized-picture-div
        :image="model.fields.image"
        :src='getImg(model, "image", "?w=415")'
        :sources="[
            {
                'src': getImg(model, 'image', '?w=582'),
                'srcx2': getImg(model, 'image', '?w=700'),
                'width': 1921
            },

            {
                'src': getImg(model, 'image', '?w=550'),
                'srcx2': getImg(model, 'image', '?w=650'),
                'width': 1201
            },

            {
                'src': getImg(model, 'image', '?w=450'),
                'width': 768
            }
            ]"
        :proportion="1.052" 
        class="h-100 w-100 should-animate"
        data-preset='opacity,y,rotate'
        data-fromy='50'
        data-delay='.2'
        data-fromrotate="-10"
        data-torotate="0" 
        :alt="model.fields.image.fields.title" />
      </div>
    </synchronized-waypoint>

    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines"
    class="text-center">
      <split-text
      :visible='visibleWaypoint'
      type='lines'
      :wrapped='false'
      tag='h4'
      innerClasses="t7 t5-md t4-xl glossy text-uppercase position-relative z-1 color-blackv2 color-dark-text-opacity"
      :text="stripHtmlWithBreaks(model.fields.contentTwo)"
      @splited="onSplited" />
    </synchronized-waypoint>

  </div>
</template>

<script>
import _get from 'lodash/get'
import WaypointTrigger from "@/mixins/WaypointTrigger"

export default {
  name: "text-and-image-five",

  props: ["model"],

  mixins: [WaypointTrigger],

  computed: {
    headline() {
      return _get(this.model, 'fields.headline')
    },
    contentTwo() {
      return _get(this.model, 'fields.contentTwo')
    }
  }
};
</script>

<style lang="scss" scoped="">
.text-and-image-five {
  .number {
    opacity: .2;
  }
  .image-shadow {
    transform: rotate(-6.96deg);
  }
  .col-custom {
    flex: 0 0 57%;
    max-width: 57%;
    @include media-breakpoint-up(md) {
      flex: 0 0 34%;
      max-width: 34%;
    }
    @include media-breakpoint-up(xl) {
      flex: 0 0 19.03%;
      max-width: 19.03%;
    }
  }
}
</style>