<template>
  <section class="service-info-design-direction bg-beige text-uppercase glossy">
    <!-- TOP CONTENT -->
    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines"
    class="color-dark-text t9 t7-md t7-xl top-content px-075 px-md-1 px-xl-2 pt-1 mb-3 mb-md-4 mb-xl-7 row justify-content-between align-items-end">

      <split-text
      :visible='visibleWaypoint'
      type='lines'
      :wrapped='false'
      tag='p'
      innerClasses="pre-wrap-md"
      :text="stripHtmlWithBreaks(model.fields.content)"
      @splited="onSplited" />

      <div
      class="should-animate d-none d-md-block"
      data-preset='opacity,y'
      data-fromy='50'
      data-delay='.2'>
        <nuxt-link
        to="/"
        class="color-black t13 t9-md t10-xl work link reversed"
        v-text="'get in touch'" />
      </div>

    </synchronized-waypoint>
    <!-- END TOP CONTENT -->

    <!-- MIDDLE CONTENT -->
    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines"
    class="color-dark-text row px-075 pl-md-1 pl-xl-2 t9 t7-md t7-xl mb-3 mb-md-0 pr-md-7 pr-xl-8">

      <split-text
      :visible='visibleWaypoint'
      type='lines'
      :wrapped='false'
      tag='p'
      innerClasses="pre-wrap-md"
      :text="stripHtmlWithBreaks(model.fields.secondContent)"
      @splited="onSplited" />

      <split-text
      :visible='visibleWaypoint'
      type='lines'
      :wrapped='false'
      tag='p'
      innerClasses="pre-wrap-md"
      :text="stripHtmlWithBreaks(model.fields.offsetContent)"
      @splited="onSplited" />
    </synchronized-waypoint>
    <!-- END :: MIDDLE CONTENT -->

    <div 
    class="d-flex align-items-center t11 t9-md p2-xl px-075 mb-075 d-md-none">
      <!-- <div class="small-arrow mr-025" /> -->

      <nuxt-link
      to="/"
      class="t13 t9-md t10-xl work link reversed"
      v-text="'get in touch'" />
    </div>

    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines"
    class="outer-wrapper d-flex pr-md-3 pr-xl-6 w-100 px-075 px-md-0">
      <div class="color-dark-text position-relative t9 t7-md t7-xl pl-075 pr-075 pr-md-0 pl-md-1 pl-xl-2 pr-md-025 pr-lg-1 d-none d-md-block">

        <split-text
        :visible='visibleWaypoint'
        type='lines'
        :wrapped='false'
        tag='p'
        innerClasses="pre-wrap-md"
        :text="stripHtmlWithBreaks(model.fields.offsetContent)"
        @splited="onSplited" />
        
        <div class="image-wrapper t9 t7-md t7-xl">
          <div class="position-relative overflow-hidden">
            <proportion-div
            :proportion=".81">
              <custom-parallax 
              :wrapped='true'
              class='w-100 h-100'
              :absolute='true' 
              :speedFactor='.2' 
              :scaleFactor='.1'>
                <synchronized-picture-div
                :fill="true"
                :image="model.fields.largeImage"
                :src='getImg(model, "largeImage", "?w=700")'
                :sources="[
                    {
                        'src': getImg(model, 'largeImage', '?w=2150'),
                        'srcx2': getImg(model, 'largeImage', '?w=2200'),
                        'width': 1921
                    },

                    {
                        'src': getImg(model, 'largeImage', '?w=1450'),
                        'srcx2': getImg(model, 'largeImage', '?w=1500'),
                        'width': 1201
                    },

                    {
                        'src': getImg(model, 'largeImage', '?w=1050'),
                        'width': 768
                    }
                    ]"
                  :alt="model.fields.largeImage.fields.title" />
              </custom-parallax>
            </proportion-div>
          </div>
        </div>
      </div>

      <!-- BOX WRAPPER -->
      <div 
      class="box-wrapper flex-grow position-relative z-1 bg-white d-flex flex-column justify-content-between p-075 p-xl-1 t9 t7-md t7-xl should-animate"
      data-preset='y'
      data-fromy='100'
      data-delay='.1'>
        <div class="d-md-flex align-items-start align-items-md-end justify-content-end mb-2 mb-md-075">

          <split-text
          :visible='visibleWaypoint'
          type='lines'
          tag='p'
          innerClasses="pre-wrap-md col lh-07 d-none d-md-inline text-right"
          :text="stripHtmlWithBreaks(model.fields.smallImageLeftText)"
          @splited="onSplited" />

          <div class="d-inline position-relative small-image">
            <synchronized-picture-div
              :src='getImg(model, "smallImage", "?w=450")'
              :sources="[
                  {
                      'src': getImg(model, 'smallImage', '?w=620'),
                      'srcx2': getImg(model, 'smallImage', '?w=1620'),
                      'width': 1921
                  },

                  {
                      'src': getImg(model, 'smallImage', '?w=620'),
                      'srcx2': getImg(model, 'smallImage', '?w=1620'),
                      'width': 1201
                  },

                  {
                      'src': getImg(model, 'smallImage', '?w=460'),
                      'width': 768
                  }
                  ]"
            :proportion="1.04"
            :alt="model.fields.smallImage.fields.title" />
          </div>

          <div
          class="pre-wrap-md d-inline col lh-07 d-md-none"
          v-html="model.fields.smallImageLeftText" />

          <div
          class="pre-wrap-md d-inline mb-xl-6 lh-11 d-md-none"
          v-html="model.fields.smallImageBottomText" />

        </div>

        <split-text
        @splited="onSplited"
        :visible='visibleWaypoint'
        tag='p'
        class="pre-wrap-md text-md-right mb-1 mb-md-4 mb-xl-7 d-none d-md-inline"
        :text="stripHtmlWithBreaks(model.fields.smallImageBottomText)" />

        <div
        class="pre-md mb-3 mb-md-0 text-right text-md-left should-animate"
        data-preset='opacity,y'
        data-delay='.4'
        v-html="model.fields.boxText" />

        <div class="d-flex align-items-center justify-content-start justify-content-md-end t11 t9-md p2-xl top-1em">
            <div class="should-animate"
            data-preset='opacity,y'
            data-fromy='50'
            data-delay='.1'>
              <arrow-link 
              text='view our projects'
              to='/our-projects' />
            </div>
        </div>
      </div>
      <!-- END :: BOX WRAPPER -->
    </synchronized-waypoint>

    <!-- LEFT IMAGE BOX -->
    <div class="t9 t7-md t7-xl pr-md-3 pr-xl-6 mb-1 d-md-none negative-margin">
      <div class="position-relative">
        <proportion-div
        :proportion="width > 767 ? .81 : 1.13">
          <custom-parallax 
          :wrapped='true'
          class='w-100 h-100'
          :absolute='true' 
          :speedFactor='.2' 
          :scaleFactor='.1'>

            <synchronized-picture-div
            :src='getImg(model, "largeImage", "?w=450")'
            :alt="model.fields.largeImage.fields.title"
            :image="model.fields.largeImage"
            :fill="true"
            :sources="[
                {
                    'src': getImg(model, 'largeImage', '?w=620'),
                    'srcx2': getImg(model, 'largeImage', '?w=1620'),
                    'width': 1921
                },

                {
                    'src': getImg(model, 'largeImage', '?w=620'),
                    'srcx2': getImg(model, 'largeImage', '?w=1620'),
                    'width': 1201
                },

                {
                    'src': getImg(model, 'largeImage', '?w=460'),
                    'width': 768
                }
            ]" />
          </custom-parallax>
        </proportion-div>
      </div>
    </div>
    <!-- END :: LEFT IMAGE BOX -->

    <!-- BOTTOM CONTENT -->
    <synchronized-waypoint
    @toggleOneDirectionVisible="toggleWaypointWithLines"
    class="row justify-content-end position-relative px-075 px-md-1">
      <split-text
      @splited="onSplited"
      :visible='visibleWaypoint'
      tag='p'
      :text="stripHtmlWithBreaks(model.fields.bottomContent)"
      class="color-blackv2 t9 t7-md t7-xl pre-wrap-xl pb-md-075 text-md-right mb-3 mb-md-0 pl-xl-0 col-xl-10" />

      <!-- SMALL LINK -->
      <div class="smal-link px-md-1 b-0 l-0 pb-075 w-100 should-animate"
      data-preset='opacity'
      data-delay='.8'>
        <nuxt-link
        to="/"
        class="roboto link reversed"
        v-text="'get in touch'" />
      </div>
      <!-- END :: SMALL LINK -->
    </synchronized-waypoint>
    <!-- BOTTOM CONTENT -->

  </section>
</template>

<script>
import WaypointTrigger from "@/mixins/WaypointTrigger";
import { mapState } from 'vuex'

export default {
  name: "service-info-design-direction",

  data: () => ({
    delayAddition: .3
  }), 

  props: ["model"],

  computed: {
    ...mapState('app', [
      'width'
    ])
  },

  mixins: [WaypointTrigger]
};
</script>

<style lang="scss" scoped="">
.service-info-design-direction {
  .image-wrapper {
    position: absolute;
    left: 0;
    top: 2em;
    width: calc(100vw - #{$s3});

    @include media-breakpoint-up(lg) {
      width: calc(100vw - #{$s6});      
    }
  }

  .outer-wrapper {
    // padding-bottom: 24vw;

    @include media-breakpoint-up(md) {
      padding-bottom: 20vw;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 28vw;
    }
  }

  .small-image {
    width: 32%;
    float: right;

    @include media-breakpoint-up(md) {
      width: 36%;
    }

    @include media-breakpoint-up(lg) {
      width: 45%;
    }

    @include media-breakpoint-up(xl) {
      width: 27%;
    }
  }

  .smal-link {
    @include media-breakpoint-up(md) {
      position: absolute;
    }

  }
  .small-arrow {
    width: 1rem;
    height: 1rem;
    background: url('/img/arrow-right.svg') no-repeat;
  }

  .negative-margin {
    margin-top: -2.5em;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  .top-1em {
    position: relative;
    @include media-breakpoint-up(md) {
      margin-top: -1.2em;
    }
  }

}
</style>