<template>
<synchronized-waypoint
  class='services-header'
  data-reversed='true'
  :class='{
    "services-header--original-decor": originalDecor
  }'
  @toggleOneDirectionVisible="toggleWaypoint">
    <h2
    class="t3-1 t1-md t2-xl text-center mb-2 mb-md-0 should-animate"
    data-preset='opacity,y'
    data-fromy='50'
    data-delay='.1'
    v-html="$t('services')" />

    <!-- DECORATION -->
    <div class="image-wrapper d-none d-md-block position-relative p-1 pb-md-1">
      <div
      v-html="originalDecor ? ServicesDecor : ServicesDecor2" />
    </div>
    <!-- END :: DECORATION -->

  </synchronized-waypoint>
</template>

<script>
import ServicesDecor from '@/assets/svg/Services-red-decore.svg?raw'
import ServicesDecor2 from '@/assets/svg/services-decor-2.svg?raw'
import WaypointTrigger from "@/mixins/WaypointTrigger"

export default {
  name: "services-header",

  data: () => ({
    ServicesDecor,
    ServicesDecor2
  }),

  props: {
    originalDecor: Boolean
  },


  mixins: [WaypointTrigger]
}
</script>

<style lang="scss" scoped="">
.services-header {
  .image-wrapper {
    margin-top: -12vw;
    opacity: .4;
  }

  &--original-decor /deep/ svg {
    path {
      stroke-width: .5px;
    }
  }
  
}
</style>