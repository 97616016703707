var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about-header row bg-pale-silver px-075 px-md-1 pt-5 pt-md-6 pb-8 pb-md-025 position-relative"},[_c('synchronized-waypoint',{staticClass:"col-md-6",on:{"toggleOneDirectionVisible":_vm.toggleWaypointWithLines}},[_c('split-text',{attrs:{"visible":_vm.visibleWaypoint,"type":"lines","wrapped":false,"tag":"h1","innerClasses":"tlarge t2-md t3-xl glossy text-uppercase text-center color-dark-text d-block d-md-none mb-1","text":_vm.stripHtmlWithBreaks(_vm.model.fields.headline)},on:{"splited":_vm.onSplited}}),_vm._v(" "),_c('div',{staticClass:"bg-beigev2 mb-1 mb-md-0 px-05 pt-05 pb-2 pb-xl-4 should-animate",attrs:{"data-preset":"opacity,y,rotate","data-fromy":"500","data-delay":".1","data-fromrotate":"-25","data-torotate":"0"}},[_c('synchronized-picture-div',{attrs:{"loading":"eager","image":_vm.model.fields.image,"src":_vm.getImg(_vm.model, "image", "?w=750"),"sources":[
          {
              'src': _vm.getImg(_vm.model, 'image', '?w=1240'),
              'srcx2': _vm.getImg(_vm.model, 'image', '?w=1300'),
              'width': 1921
          },

          {
              'src': _vm.getImg(_vm.model, 'image', '?w=880'),
              'srcx2': _vm.getImg(_vm.model, 'image', '?w=1000'),
              'width': 1201
          },

          {
              'src': _vm.getImg(_vm.model, 'image', '?w=664'),
              'width': 768
          }
      ]}})],1)],1),_vm._v(" "),_c('div',{staticClass:"col-md-6 d-flex flex-column justify-content-between pt-xl-1"},[_c('synchronized-waypoint',{on:{"toggleOneDirectionVisible":_vm.toggleWaypointWithLines}},[_c('div',[_c('split-text',{attrs:{"visible":_vm.visibleWaypoint,"type":"lines","wrapped":false,"tag":"h1","innerClasses":"t2-md t3-xl glossy text-uppercase color-dark-text-opacity d-none d-md-block pl-md-05 pl-xl-1","text":_vm.stripHtmlWithBreaks(_vm.model.fields.headline)},on:{"splited":_vm.onSplited}})],1),_vm._v(" "),_c('div',{staticClass:"about-decor d-none d-md-block pl-md-05 pl-xl-1",domProps:{"innerHTML":_vm._s(_vm.width > 768 ? _vm.AboutDecor : _vm.AboutDecorMob)}})]),_vm._v(" "),_c('synchronized-waypoint',{staticClass:"text-right w-100",on:{"toggleOneDirectionVisible":_vm.toggleWaypoint}},[_c('h2',{staticClass:"color-dark-text t9 t7-md t7-xl glossy text-uppercase lh-08 mb-xl-025 pr-md-025 pr-xl-2 should-animate",attrs:{"data-preset":"opacity,y","data-fromy":"50","data-delay":".1"},domProps:{"innerHTML":_vm._s(_vm.model.fields.content[0])}})])],1),_vm._v(" "),_c('synchronized-waypoint',{staticClass:"text-right w-100 pr-md-025 pr-xl-2 mt-md-05 mb-3 mb-md-0 position-relative",on:{"toggleOneDirectionVisible":_vm.toggleWaypoint}},[_vm._l((_vm.content),function(item,index){return (index > 0)?_c('h2',{key:index,staticClass:"t9 t7-md t7-xl glossy text-uppercase color-dark-text should-animate",attrs:{"data-preset":"opacity,y","data-fromy":"50","data-delay":.1 + index / 10},domProps:{"innerHTML":_vm._s(item)}}):_vm._e()}),_vm._v(" "),_c('div',{staticClass:"about-decor d-block d-md-none",domProps:{"innerHTML":_vm._s(_vm.AboutDecorMob)}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }