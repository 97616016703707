var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"services-wrapper px-xl-5 pt-xl-5 pb-xl-1"},[_c('synchronized-waypoint',{staticClass:"h-100 d-none d-xl-block enabled overflow-hidden",on:{"toggleOneDirectionVisible":_vm.toggleWaypoint}},[_c('div',{staticClass:"row bg-light-creme h-100 d-none d-xl-flex should-animate",attrs:{"data-props":"yPercent","data-fromy-percent":"80","data-toy-percent":"0","data-delay":".1","data-dur":"1.2"}},[_c('div',{staticClass:"col-md-6 position-relative"},[_c('synchronized-picture-div',{staticClass:"decor",attrs:{"fill":true,"alt":'Kim Kneipp',"src":"/img/decor.webp","sources":[
                  {
                    'src': '/img/decor.webp',
                    'srcx2': '/img/decor.webp',
                    'width': 1921
                  },

                  {
                    'src': '/img/decor.webp',
                    'srcx2': '/img/decor.webp',
                    'width': 1201
                  },

                  {
                    'src': '/img/decor.webp',
                    'width': 768
                  }
              ]}}),_vm._v(" "),_c('div',{staticClass:"gradient position-absolute r-0 t-0 h-100"}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column h-100 pb-1"},[_c('div',{staticClass:"row mb-md-3 flex-grow-1"},[_c('div',{staticClass:"col-md d-flex justify-content-between"},[_c('span',{staticClass:"glossy text-right t16-xl p-1"},[_vm._v("1")]),_vm._v(" "),_c('p',{staticClass:"text-uppercase t15-md t15-xl roboto text-right p-1 color-grey-text2",domProps:{"innerHTML":_vm._s(_vm.$t('studio-services'))}})]),_vm._v(" "),_c('div',{staticClass:"col-custom bg-light-creme2 pl-1 copy-box position-relative z-1 pb-2 d-flex flex-column"},[(_vm.model.fields.image)?_c('div',{staticClass:"flex-grow-1 position-relative"},[_c('synchronized-picture-div',{attrs:{"fill":true,"alt":_vm.model.fields.image.fields.title,"src":_vm.getImg(_vm.model, "image", "?w=560"),"sources":[
                              {
                                'src': _vm.getImg(_vm.model, 'image', '?w=658', 90),
                                'srcx2': _vm.getImg(_vm.model, 'image', '?w=700'),
                                'width': 1921
                              },

                              {
                                'src': _vm.getImg(_vm.model, 'image', '?w=640'),
                                'srcx2': _vm.getImg(_vm.model, 'image', '?w=700'),
                                'width': 1201
                              },

                              {
                                'src': _vm.getImg(_vm.model, 'image', '?w=600'),
                                'width': 768
                              }
                              ]}})],1):_vm._e(),_vm._v(" "),_c('p',{staticClass:"color-grey-text2 t15-md t15-xl text-gray roboto text-uppercase pt-05"},[_vm._v("kim kneipp©")])])]),_vm._v(" "),_c('div',{staticClass:"text-center should-animate",attrs:{"data-preset":"y","data-fromy":"180","data-toy":"0","data-dur":"2","data-delay":".1"}},[_c('nuxt-link',{staticClass:"t4-xl text-uppercase text-center glossy color-blackv2 d-block opacity-link",attrs:{"to":_vm.model.fields.services[0].url},domProps:{"innerHTML":_vm._s(_vm.model.fields.services[0].fields.title)}})],1)])],1),_vm._v(" "),_c('div',{staticClass:"col-md-6 position-relative"},[_c('nuxt-link',{staticClass:"col-custom-2 flex-column pl-1 position-absolute t-0 l-0 pt-1 pb-md-1 pr-md-1 z-2 d-flex should-animate justify-content-end box",attrs:{"to":_vm.model.fields.services[1].url,"data-preset":"yPercent","data-fromy-percent":"40","data-toy-percent":"0","data-dur":"2","data-delay":".1"}},[_c('div',{staticClass:"bg bg-dark-gold full-absolute"}),_vm._v(" "),_c('div',{staticClass:"box-1 flex-grow-1 d-flex flex-column justify-content-between h-100 position-relative z-2 mix-multiply"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"dot"}),_vm._v(" "),_c('span',{staticClass:"glossy text-right t16-xl"},[_vm._v("2")])]),_vm._v(" "),_c('div',{staticClass:"title"},[_c('h2',{staticClass:"t6-xl text-uppercase glossy color-blackv2 d-inline",domProps:{"innerHTML":_vm._s(_vm.model.fields.services[1].fields.title)}}),_vm._v(" "),_c('proportion-div',{staticClass:"col-2 d-inline-block",attrs:{"proportion":.17}},[_c('div',{staticClass:"w-100 h-100 image-frame"},[(_vm.model.fields.services[1])?_c('synchronized-picture-div',{attrs:{"alt":_vm.stripHtml(_vm.model.fields.services[1].fields.title),"src":_vm.getImg(_vm.model.fields.services[1], "image", "?w=330"),"fill":true,"innerClasses":"px-025 pt-025 pb-075","sources":[
                                      {
                                        'src': _vm.getImg(_vm.model.fields.services[1], 'image', '?w=1370', 90),
                                        'srcx2': _vm.getImg(_vm.model.fields.services[1], 'image', '?w=2370'),
                                        'width': 1921
                                      },

                                      {
                                        'src': _vm.getImg(_vm.model.fields.services[1], 'image', '?w=370'),
                                        'srcx2': _vm.getImg(_vm.model.fields.services[1], 'image', '?w=1370'),
                                        'width': 1201
                                      },

                                      {
                                        'src': _vm.getImg(_vm.model.fields.services[1], 'image', '?w=360'),
                                        'width': 768
                                      }
                                  ]}}):_vm._e()],1)])],1)])]),_vm._v(" "),_c('nuxt-link',{staticClass:"col-md-9 flex-column position-absolute t-0 l-0 pt-1 pb-md-1 pr-md-1 z-1 d-block should-animate box-2 box",attrs:{"data-preset":"yPercent","data-fromy-percent":"40","data-toy-percent":"0","data-dur":"2","data-delay":".1","to":_vm.model.fields.services[2].url}},[_c('div',{staticClass:"bg bg-sandstone full-absolute"}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column justify-content-between h-100 position-relative z-2 mix-multiply"},[_c('span',{staticClass:"glossy text-right pr-xl-1"},[_vm._v("3")]),_vm._v(" "),_c('div',{staticClass:"position-relative h-100 title"},[(_vm.model.fields.services[2])?_c('synchronized-picture-div',{attrs:{"alt":_vm.stripHtml(_vm.model.fields.services[2].fields.title),"src":_vm.getImg(_vm.model.fields.services[2], "image", "?w=487"),"fill":true,"innerClasses":"pr-md-5","sources":[
                              {
                                'src': _vm.getImg(_vm.model.fields.services[2], 'image', '?w=910', 90),
                                'srcx2': _vm.getImg(_vm.model.fields.services[2], 'image', '?w=1910'),
                                'width': 1921
                              },

                              {
                                'src': _vm.getImg(_vm.model.fields.services[2], 'image', '?w=640'),
                                'srcx2': _vm.getImg(_vm.model.fields.services[2], 'image', '?w=1640'),
                                'width': 1201
                              },

                              {
                                'src': _vm.getImg(_vm.model.fields.services[2], 'image', '?w=648'),
                                'width': 768
                              }
                              ]}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"title"},[_c('h2',{staticClass:"t6-xl text-uppercase glossy color-blackv2 pl-1 mt-1",domProps:{"innerHTML":_vm._s(_vm.model.fields.services[2].fields.title)}})])])]),_vm._v(" "),_c('nuxt-link',{staticClass:"w-100 h-100 bg-light-creme flex-column text-right position-absolute t-0 l-0 pt-1 pb-md-1 pr-1 d-flex justify-content-between opacity-link",attrs:{"to":_vm.model.fields.services[3].url}},[_c('span',{staticClass:"glossy"},[_vm._v("4")]),_vm._v(" "),_c('h2',{staticClass:"t6-xl text-uppercase glossy color-blackv2 mt-1 should-animate",attrs:{"data-preset":"y","data-fromy":"180","data-toy":"0","data-dur":"2","data-delay":".1"},domProps:{"innerHTML":_vm._s(_vm.model.fields.services[3].fields.title)}})])],1)])]),_vm._v(" "),_c('div',{staticClass:"d-block d-xl-none bg-light-creme pt-3 pt-md-4"},[_c('div',{staticClass:"col-9 col-md-5 col-xl-6 position-relative mx-auto on-top"},[_c('div',{staticClass:"text-uppercase t15 roboto text-right color-grey-text2 mb-05",domProps:{"innerHTML":_vm._s(_vm.$t('studio-services'))}}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column justify-content-between h-100"},[_c('div',{staticClass:"bg-light-creme2 px-05 copy-box position-relative"},[(_vm.model.fields.image)?_c('synchronized-picture-div',{attrs:{"proportion":null,"alt":_vm.stripHtml(_vm.model.fields.image.fields.title),"image":_vm.model.fields.image,"src":_vm.getImg(_vm.model, "image", "?w=560"),"sources":[
                      {
                        'src': _vm.getImg(_vm.model, 'image', '?w=1640', 90),
                        'srcx2': _vm.getImg(_vm.model, 'image', '?w=2640'),
                        'width': 1921
                      },

                      {
                        'src': _vm.getImg(_vm.model, 'image', '?w=640'),
                        'srcx2': _vm.getImg(_vm.model, 'image', '?w=1640'),
                        'width': 1201
                      },

                      {
                        'src': _vm.getImg(_vm.model, 'image', '?w=600'),
                        'width': 768
                      }
                      ]}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"color-grey-text2 t15 text-gray roboto text-uppercase pb-1 pb-xl-2 mt-05"},[_vm._v("kim kneipp©")])],1)])]),_vm._v(" "),_c('div',{staticClass:"row inner-row"},[_c('div',{staticClass:"col-6 position-relative"},[_c('nuxt-link',{staticClass:"bg-sandstone flex-column pt-1 pt-md-2 pb-075 pb-md-1 pr-md-2 z-1 d-block",attrs:{"to":_vm.model.fields.services[2].url}},[_c('div',{staticClass:"d-flex flex-column justify-content-between h-100"},[_c('div',{staticClass:"position-relative h-100"},[(_vm.model.fields.services[2])?_c('synchronized-picture-div',{attrs:{"src":_vm.getImg(_vm.model.fields.services[2], "image", "?w=450"),"innerClasses":"pr-2 pr-md-3 mix-multiply","image":_vm.model.fields.image,"alt":_vm.stripHtml(_vm.model.fields.services[2].fields.title),"sources":[
                                  {
                                    'src': _vm.getImg(_vm.model.fields.services[2], 'image', '?w=910', 90),
                                    'srcx2': _vm.getImg(_vm.model.fields.services[2], 'image', '?w=1910'),
                                    'width': 1921
                                  },

                                  {
                                    'src': _vm.getImg(_vm.model.fields.services[2], 'image', '?w=640'),
                                    'srcx2': _vm.getImg(_vm.model.fields.services[2], 'image', '?w=1640'),
                                    'width': 1201
                                  },

                                  {
                                    'src': _vm.getImg(_vm.model.fields.services[2], 'image', '?w=640'),
                                    'width': 768
                                  }
                                  ]}}):_vm._e()],1),_vm._v(" "),_c('div',[_c('h2',{staticClass:"t8 t4-1-md t6-xl text-uppercase glossy color-blackv2 pl-075 pl-md-1 mt-1 mt-md-2",domProps:{"innerHTML":_vm._s(_vm.model.fields.services[2].fields.title)}})])])])],1),_vm._v(" "),_c('div',{staticClass:"col-6 interiour"},[_c('nuxt-link',{staticClass:"bg-dark-gold pl-075 pl-md-1 pt-2 mt-05 mt-md-0 pt-md-4 pb-md-1 pr-md-1 pr-lg-2 z-2 position-absolute l-0 t-0 box-2 w-100",attrs:{"to":_vm.model.fields.services[1].url}},[_c('div',{staticClass:"d-flex flex-column justify-content-between pb-md-7 box-2"},[_c('div',{staticClass:"mt-xl-2"},[_c('h2',{staticClass:"t8 t4-1-md t6-xl text-uppercase glossy color-blackv2 d-inline",domProps:{"innerHTML":_vm._s(_vm.model.fields.services[1].fields.title)}}),_vm._v(" "),_c('proportion-div',{staticClass:"custom-col d-inline-block",attrs:{"proportion":.24}},[_c('div',{staticClass:"w-100 h-100 image-frame"},[(_vm.model.fields.services[1])?_c('synchronized-picture-div',{attrs:{"src":_vm.getImg(_vm.model.fields.services[1], "image", "?w=330"),"fill":true,"alt":_vm.stripHtml(_vm.model.fields.services[1].fields.title),"innerClasses":"px-025 pt-025 pb-075","image":_vm.model.fields.services[1],"sources":[
                                      {
                                        'src': _vm.getImg(_vm.model.fields.services[1], 'image', '?w=1370', 90),
                                        'srcx2': _vm.getImg(_vm.model.fields.services[1], 'image', '?w=2370'),
                                        'width': 1921
                                      },

                                      {
                                        'src': _vm.getImg(_vm.model.fields.services[1], 'image', '?w=370'),
                                        'srcx2': _vm.getImg(_vm.model.fields.services[1], 'image', '?w=1370'),
                                        'width': 1201
                                      },

                                      {
                                        'src': _vm.getImg(_vm.model.fields.services[1], 'image', '?w=360'),
                                        'width': 768
                                      }
                                      ]}}):_vm._e()],1)])],1)])]),_vm._v(" "),_c('nuxt-link',{staticClass:"w-100 h-100 bg-light-creme flex-column text-right position-absolute t-0 l-0 pt-2 pb-md-1 pr-1 d-flex justify-content-end align-items-end",attrs:{"to":_vm.model.fields.services[3].url}},[_c('h2',{staticClass:"t8 t4-1-md text-uppercase glossy color-blackv2 mt-1 mb-05 mb-md-0",domProps:{"innerHTML":_vm._s(_vm.model.fields.services[3].fields.title)}})])],1)]),_vm._v(" "),_c('div',{staticClass:"text-center py-1 py-md-2 px-1 px-md-3 position-relative"},[_c('div',{staticClass:"gradiant"}),_vm._v(" "),_c('synchronized-picture-div',{staticClass:"decor",attrs:{"fill":true,"alt":'Kim Kneipp',"src":'/img/services-decor.webp',"sources":[
              {
                'src': '/img/services-decor.webp',
                'srcx2': '/img/services-decor.webp',
                'width': 1921
              },

              {
                'src': '/img/services-decor.webp',
                'srcx2': '/img/services-decor.webp',
                'width': 1201
              },

              {
                'src': '/img/services-decor.webp',
                'width': 768
              }
          ]}}),_vm._v(" "),_c('nuxt-link',{staticClass:"t6 t4-1-md text-uppercase text-center glossy color-blackv2 position-relative z-1",attrs:{"to":_vm.model.fields.services[0].url},domProps:{"innerHTML":_vm._s(_vm.model.fields.services[0].fields.title)}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }