<template>
<nuxt-link 
:to="to"
:class='{
  "arrow-link--light": light
}'
class='arrow-link d-flex align-items-center'>

  <span class="small-arrow small-arrow--default" />

  <span class='d-inline-flex align-items-center arrow-wrapper'>
    <span
    class="roboto arrow-link-text d-inline-block"
    v-html="text" />
    <span class="small-arrow small-arrow--hover" />
  </span>

</nuxt-link>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "arrow-link",

  props: {
    to: {
      default: "/",
      type: [String, Object]
    },

    text: {
      type: String
    },

    light: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped="">
.arrow-link{  
  .small-arrow {
    width: 1rem;
    height: .85rem;
    background: url('/img/arrow-right.svg') no-repeat;
    background-size: contain;
    transition: .4s;
    display: inline-block;

    &--hover{
      opacity: 0;
      transform: translateX(-100%);
      margin-left: .4rem;
    }

    &--default{
      margin-right: .4rem;
      transition-delay: .15s;
    }
  }

  .arrow-wrapper{
    transition: .4s;
  }

  &:hover{
    .arrow-wrapper{
      transform: translateX(-1rem);
      transition-delay: .1s;
    }

    .small-arrow{
      &--default{
        opacity: 0;
        transform: translateX(100%);
        transition-delay: 0s;
      }
      &--hover{
        opacity: 1;
        transform: translateX(0);
        transition-delay: .25s;
      }
    }
  }

  &--light{
    .small-arrow--default{
      opacity: 0.4;
    }
    &:hover{
      .small-arrow--hover{
        opacity: 0.4;
      }
    }
  }

}

</style>