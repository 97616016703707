<template>
  <header 
  ref="header" 
  class="hero-header text-uppercase d-flex align-items-center flex-column position-relative overflow-hidden z-1">

      <custom-parallax
      class="image-wrapper"
      :wrapped='true'
      :scaleFactor='.1'
      :speedFactor='.4'>

        <synchronized-picture-div
        :image="model.fields.image"
        :src='getImg(model, "image", "?w=760&h=1120&fit=fill")'
        class='should-animate2'
        data-delay='.15'
        data-props="scale"
        loading="eager"
        :fill='true'
        :sources="[
            {
                'src': getImg(model, 'image', '?w=2220'),
                'srcx2': getImg(model, 'image', '?w=2400'),
                'width': 1921
            },

            {
                'src': getImg(model, 'image', '?w=1500'),
                'srcx2': getImg(model, 'image', '?w=1600'),
                'width': 1201
            },

            {
                'src': getImg(model, 'image', '?w=1250&h=1000&fit=fill'),
                'width': 768
            }
        ]" />

    </custom-parallax>

    <!-- Top Content -->
    <div 
      data-props="opacity,y"
      data-fromy="100"
      data-toy="0"
      data-fromopacity="0"
      data-toopacity="1"
      data-delay=".5"
      class="position-relative d-flex flex-grow align-items-center flex-column justify-content-center justify-content-md-start should-animate2 pt-4 pt-md-5 z-2">

      <split-text
      :text='stripHtmlWithBreaks(model.fields.headline)' 
      tag='h1'
      @splited="onSplited"
      v-if="model.fields.headline"
      :innerClasses="`headline mb-md-05 color-white`" />

      <h2
      data-preset='opacity,y'
      data-delay='.1'
      v-if="model.fields.tagHeadline"
      v-html="model.fields.tagHeadline"
      class="headline d-md-none mb-1 should-animate color-white" />

      <div 
      data-props="opacity,y"
      data-fromy="100"
      data-toy="0"
      data-fromopacity="0"
      data-toopacity="1"
      data-delay=".15"
      class="d-none d-md-flex flex-column flex-md-row mb-05 logo-text gutter-landing text-center should-animate position-relative on-top">
        <nuxt-link
        :to="service.url"
        v-for="(service, index) in model.fields.servicePages"
        :key="index + '02'"
        class="item roboto t15 t13-md t14-xl color-grayv2"
        v-html="stripHtml(service.fields.title)" />
      </div>

      <h2 
      data-props="opacity,y"
      data-fromy="100"
      data-toy="0"
      data-fromopacity="0"
      data-toopacity="1"
      data-delay=".25"
      v-if="model.fields.tagHeadline"
      v-html="model.fields.tagHeadline"
      class="headline d-none d-md-block should-animate color-white" />
    </div>

    <!-- SCROLLING INDICATOR -->
    <scrolling-indicator 
    text='Studio Services'
    caption='Next:'
    @onClick='scrollTo'
    class='position-absolute b-0 center-x d-none d-md-block z-2' />
    <!-- END :: SCROLLING INDICATOR -->

    <!-- SERVICES LINKS -->
    <div 
      data-props="opacity,y"
      data-fromy="100"
      data-toy="0"
      data-fromopacity="0"
      data-toopacity="1"
      data-delay=".15"
      class="row d-md-none should-animate b-0 z-1 w-100 link-wrapper">
        <nuxt-link
        :to="service.url"
        v-for="(service, index) in model.fields.servicePages"
        :key="index + '02'"
        class="col-6 text-center p-2 service-btn roboto t15 t13-md t15-xl color-grayv2"
        v-html="stripHtml(service.fields.title)" />
    </div>
    <!-- END :: SERVICES LINKS -->

  </header>
</template>

<script>

import WaypointTrigger from '@/mixins/WaypointTrigger'

import { gsap } from 'gsap'
// import MouseParallax from '@/components/MouseParallax'

import _get from 'lodash/get'
import _map from 'lodash/map'

export default {
  name: 'hero-header',


  components: {
    // MouseParallax
  },

  data: () => ({
    currentScroll: 0,
  }),

  props: ["model"],

  mixins: [
    WaypointTrigger
  ],

  mounted() {
    setTimeout(() => {      
      this.toggleWaypointWithLines({
        visible: true,
        el: this.$el
      })
    }, 400)
  },

  computed: {
    images() {
      return _map(this.model.fields.gallery, (img) => this.getImg(img, "image", "?w=1920"))
    }
  },

  methods:{
    scrollTo(){
      this.$bus.$emit('scrollTo', this.$el.offsetHeight, 1000)
    }
  }
}
</script>

<style lang="scss">
  .hero-header {
    height: 93vh;
    width: 100vw;

    @include media-breakpoint-up(md) {
      height: calc(100vh + 1px);
      min-height: 700px;
    }

    @media only screen and (max-width: 812px) and (orientation: landscape) {
        height: calc(180vh + 1px);
    }

    .service-btn{
      border-top: 1px solid $white;
      &:nth-child(even) {
        border-left: 1px solid $white;
      }
    }

    .image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .item {
        color: $grayv2;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            transform: scaleX(0);
            background-color: $grayv2;
            transition: 0.4s;
            transform-origin: left;
        }
        &:hover {
            @include media-breakpoint-up(lg){
              &:after {
                transform: scaleX(1) translateZ(0);
              }
            }
        }
    }

    .headline {
      position: relative;
      text-align: center;
      font-size: 2.875rem;
      font-family: $headline-font;
      line-height: .8;

      @include media-breakpoint-up(md) {
        font-size: 5.625rem;    
      }

      @include media-breakpoint-up(lg) {
        font-size: 120px;        
      }

      @include media-breakpoint-up(xl) {
        font-size: 150px;        
      }

      @media screen and (min-width: 1900px) {
        font-size: 150/1900*100vw;        
      }
    }

    .gutter-landing {
      margin: -.5rem -1.5rem 0 -1.5rem;

      .item {
        margin: 0 1.5rem;
        line-height: 1;
      }

      @include media-breakpoint-up(lg) {
        margin: 0 -1.5rem;        
      }
    }

    .landing-gradiant {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.61) 72.4%);

      @include media-breakpoint-up(md) {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.71) 77.6%);
      }

      @include media-breakpoint-up(lg) {
        background: none;
      }
    }
  }
</style>
