<template>
  <synchronized-scroll :isMobile='!$device.isDesktop'>
    <div class='overflow-hidden'>

      <!-- Page Components -->
      <component 
      v-if="model.fields.components"
      v-for='(component, index) in model.fields.components'
      :bigPadding='index == 0'
      :model='component'
      :is='getComponentTemplate(component)'
      :key='index'
      :index="index" />

      <!-- Page Footer -->
      <page-footer 
      v-if="!model.fields.disableFooter"
      :sandstone="true" />
    </div>
  </synchronized-scroll>
</template>

<script>
import PageComponents from '@/mixins/PageComponents'
import BasePage from '@/mixins/BasePage'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'BasicPage',

  mixins: [BasePage, PageComponents],

  mounted() {
    this.$store.dispatch('app/setStateApp', {
      passedViewport: this.model.fields.darkNav
    })
  },

  async asyncData ({$contentful, route, store}) {
    const urls = store.getters['pages/getState']('urls')

    const activePage = await $contentful.loadActivePage({
      route: route.path,
      id: route.name,
      urls,
      include: 4
    })

    store.dispatch('pages/SET_STATE', {
      activePage
    })

    return {
      model: activePage
    }
  }
}
</script>
